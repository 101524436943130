// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  proxyUrl: 'https://app.stacksmarket.co/proxy/?url=',
  production: false,
  stacksURL: "https://staging.stacksmarket.co/",
  premiumProductURL: "https://stacksmarket.co/?add-to-cart=15958&variation_id=72793&attribute_packages=premium",
  builderURL: "https://builder.staging.stacksmarket.co/wp-json/v4/",
  loggerURL: "https://logger.staging.stacksmarket.co/",
  debuggableJSON: true,
  ga: 'G-0C644PNRKD',
  hj: '2650044',
  fb: '123',
  // fb: '371064266731563',
  gtm: '123',
  googleLogin: '339787967114-ikkals4eq2r58s7i70up9f406q01vbd6.apps.googleusercontent.com',
  showStripe: true,
  stripePublishableKey: 'pk_test_51LEVyLDPC5jsdjOiW5jaMOlmKKV4rxG1TgbXvEi1MvMrhfAI0kDIWk3UxapuzSbbvesXOoQtuwqF2HDxpOKBAeRK004RKXelB6',
  manageSubscriptions: 'https://billing.stripe.com/p/login/test_eVa5li5g20z7d1ufYY',
  firebaseConfig: {
    apiKey: "AIzaSyA00BUWrxC6HeXoI18rKFXRavTEeZwuu2s",
    authDomain: "neat-planet-378416.firebaseapp.com",
    databaseURL: "https://neat-planet-378416-default-rtdb.firebaseio.com",
    projectId: "neat-planet-378416",
    storageBucket: "neat-planet-378416.appspot.com",
    messagingSenderId: "339787967114",
    appId: "1:339787967114:web:75ab124244232034241b73",
    measurementId: "G-RJRCW3EKC5"
  },
  pricing_packages: [
    {
      'pricing_id': 'price_1NIudeDPC5jsdjOinuYQtims',
      'type': 'Android',
      'price': '$24',
      'duration': 'Monthly',
      'service_type': 'server_with_stacks',
      'features': [
        'Android',
        'Push Navigation',
        'Support',
        'Facebook Login',
        'Analytics',
        'Payment Gateway',
      ]
    },
    {
      'pricing_id': 'price_1NIubCDPC5jsdjOiMAH1kyms',
      'type': 'Android & IOS',
      'price': '$29',
      'duration': 'Monthly',
      'service_type': 'server_with_stacks',
      'features': [
        'IOS',
        'Android',
        'Push Navigation',
        'Support',
        'Facebook Login',
        'Analytics',
        'Payment Gateway',
      ]
    },
    {
      'pricing_id': 'price_1NIudeDPC5jsdjOiRAGTldgY',
      'type': 'Android',
      'price': '$240',
      'duration': 'Yearly',
      'service_type': 'server_with_stacks',
      'features': [
        'Android',
        'Push Navigation',
        'Support',
        'Facebook Login',
        'Analytics',
        'Payment Gateway',
      ]
    },
    {
      'pricing_id': 'price_1NIubCDPC5jsdjOiY7avBb4E',
      'type': 'Android & IOS',
      'price': '$290',
      'duration': 'Yearly',
      'service_type': 'server_with_stacks',
      'features': [
        'IOS',
        'Android',
        'Push Navigation',
        'Support',
        'Facebook Login',
        'Analytics',
        'Payment Gateway',
      ]
    },
    {
      'pricing_id': 'price_1NIuf1DPC5jsdjOi0oO4woeC',
      'type': 'Android',
      'price': '$15',
      'duration': 'Monthly',
      'service_type': 'standalone',
      'features': [
        'Android',
        'Push Navigation',
        'Support',
        'Facebook Login',
        'Analytics',
        'Payment Gateway',
      ]
    },
    {
      'pricing_id': 'price_1NIufPDPC5jsdjOilTmgPbHS',
      'type': 'Android & IOS',
      'description': '4 USD for the first month',
      'price': '$20 / month',
      'duration': 'Monthly',
      'service_type': 'standalone',
      'features': [
        'IOS',
        'Android',
        'Push Navigation',
        'Support',
        'Facebook Login',
        'Analytics',
        'Payment Gateway',
      ]
    },
    {
      'pricing_id': 'price_1NIuf1DPC5jsdjOi20bT3W5G',
      'type': 'Android',
      'price': '$150',
      'duration': 'Yearly',
      'service_type': 'standalone',
      'features': [
        'Android',
        'Push Navigation',
        'Support',
        'Facebook Login',
        'Analytics',
        'Payment Gateway',
      ]
    },
    {
      'pricing_id': 'price_1NIufQDPC5jsdjOiX28aaDwL',
      'type': 'Android',
      'price': '$200',
      'duration': 'Yearly',
      'service_type': 'standalone',
      'features': [
        'IOS',
        'Android',
        'Push Navigation',
        'Support',
        'Facebook Login',
        'Analytics',
        'Payment Gateway',
      ]
    },
    {
      'pricing_id': 'price_1NPQZoDPC5jsdjOitjjTnrYb',
      'type': 'Lifetime Offer',
      'price': '$49',
      'duration': '',
      'service_type': 'self_ltd',
      'paypal': 'https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=HAABUCQLJS69J',
      'features': [
        'Android & IOS',
        'Push Notifications',
        'Loyalty Program',
        'Unlimited Design Modifications',
        'Analytics Integration',
        'All WooCommerce Payment Gateways Integrated',
      ]
    }
  ]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
