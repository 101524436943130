<section class="stacksBlockContainer" id="block-slider">
  <div id="settingsTabs" *ngIf="checkSettings()">
    <a (click)="showDataTab()" [ngClass]="checkData() ? 'active' : ''">{{'Data' | translate }}</a>
    <a (click)="showStyleTab()" [ngClass]="checkStyle() ? 'active' : ''">{{'Style' | translate }}</a>
  </div>
  <!-- Sidebar -->
  <div *ngIf="checkSidebar()" class="blockSidebar" (click)="appendBlock($event)">
    <img src="assets/images/blocks/slider.png" alt="" srcset="">
    <p>{{'Slider' | translate }}</p>
  </div>
  <!-- Mobile Preview -->
  <div class="previewSlider previewMobile" *ngIf="checkPreview()" id="{{'blockid'+blockId}}" cdkDrag [cdkDragData]="blockId">
    <div class="blockControlHandlers" *ngIf="!builderService.globals">
      <div class="editBlock blockActionBtn" (click)="showData(blockId)">
        <mat-icon>edit</mat-icon>
      </div>
      <div class="blockHandle blockActionBtn" cdkDragHandle>
        <mat-icon>open_with</mat-icon>
      </div>
      <div class="deleteBlock blockActionBtn" (click)="deleteBlock(blockId)">
        <mat-icon>close</mat-icon>
      </div>
    </div>
    <div class="blockPlaceholder" *cdkDragPlaceholder></div>
    <carousel  [ngStyle]="getBlockFields(blockId)?.style" [cellsToShow]="1" [autoplay]="true" [arrows]="true" [dots]="false" [autoplayInterval]="3000" [loop]="true">
      <div class="carousel-cell" *ngFor="let slide of getBlockFields(blockId).data?.slides">
        <img src="{{slide.img}}">
      </div>
      <div class="carousel-cell" *ngFor="let slide of getBlockFields(blockId).data?.slides">
        <img src="{{slide.img}}">
      </div>
    </carousel>
  </div>
  <!-- Data and Style Tabs -->
  <div class="previewFields" *ngIf="checkData()">
    <mat-progress-bar mode="indeterminate" *ngIf="showProgress"></mat-progress-bar>
    <ul>
      <li class="ulWrapper" *ngFor="let slides of getBlockFields(blockId).data.slides; let indexOfelement = index;">
        <button *ngIf="indexOfelement !== 0" class="deleteslides material-icons-outlined" (click)="deleteslides(blockId, indexOfelement)">delete</button>
        <mat-accordion multi>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{'Slide' | translate }} {{indexOfelement + 1}}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <label class="fileUpload" for="image{{indexOfelement}}">{{'Choose an Image' | translate }}
              <input type="file" id="image{{indexOfelement}}" (change)="this.processFile($event, '',indexOfelement,'img', blockId)">
            </label>
            <p class="note">{{getBlockFields(blockId).data.slides[indexOfelement].img}}</p>
            <!-- <mat-form-field appearance="outline">
              <mat-label>Title</mat-label>
              <input matInput placeholder="Type your title here"
                value="{{getBlockFields(blockId).data?.slides[{{indexOfelement}}].title}}"
                (keyup)="queryChange('indexOfelement','title', blockId, $event, 'text')" />
            </mat-form-field> -->
            <ul class = "dropDownMenu">
                <div class="slidesData" [ngClass]="slides.index == 0 ? 'firstslidesData': ''">
                  <ul class="menuLinker">
                    <li>
                      <mat-form-field appearance="outline">
                          <mat-label>{{'Link Type' | translate }}</mat-label>
                          <mat-select (selectionChange)="queryChange('link', blockId, $event, 'multilevelSelect', slides.index, 'slides')"
                            value="{{getBlockFields(blockId).data?.slides[slides.index]?.link}}">
                            <mat-option value="home">
                              {{'Home' | translate }}
                            </mat-option>
                            <mat-option value="posts" *ngIf="!builderService.standalone">
                              {{'WP Posts' | translate }}
                            </mat-option>
                            <mat-option value="products">
                               {{'Products' | translate }}
                            </mat-option>
                            <mat-option value="allProductsCategories">
                              {{'All Products Categories' | translate }}
                            </mat-option>
                            <mat-option value="productsCategories">
                               {{'Product Category' | translate }}
                            </mat-option>
                            <!-- <mat-option value="stacksProducts" *ngIf="builderService.standalone">
                              Products
                            </mat-option>
                            <mat-option value="stacksCategories" *ngIf="builderService.standalone">
                              Categories
                            </mat-option> -->
                            <mat-option value="externalURL">
                               {{'External URL' | translate }}
                            </mat-option>
                            <mat-option value="account">
                               {{'Account Page' | translate }}
                            </mat-option>
                            <mat-option value="signup">
                               {{'Signup / Signin' | translate }}
                            </mat-option>
                            <mat-option value="contact">
                               {{'Contact Us' | translate }}
                            </mat-option>
                            <mat-option value="about">
                               {{'About Us' | translate }}
                            </mat-option>
                            <mat-option value="orders">
                               {{'Orders' | translate }}
                            </mat-option>
                            <mat-option value="cart">
                               {{'Cart' | translate }}
                            </mat-option>
                            <mat-option value="terms">
                               {{'Terms' | translate }}
                            </mat-option>
                            <mat-option  *ngFor="let customView of builderService.getCustomViews()" value="{{customView.view_name}}" >
                              {{customView.view_name}}
                            </mat-option>
                          </mat-select>
                      </mat-form-field>
                    </li>
                    <li *ngIf="getBlockFields(blockId).data?.slides[slides.index]?.link == 'products'  && !builderService.standalone">
                      <mat-form-field appearance="outline">
                        <mat-label>{{'Woo-Product' | translate }}</mat-label>
                        <mat-select (selectionChange)="queryChange('product', blockId, $event, 'multilevelSelect', slides.index, 'slides')" 
                          [(value)]="getBlockFields(blockId).data.slides[slides.index].product" >
                            <mat-option *ngFor="let product of storedProducts"
                              [value]="product.id">
                              {{product.name}} 
                            </mat-option>
                          </mat-select>
                      </mat-form-field>
                    </li>
                    <li *ngIf="getBlockFields(blockId).data?.slides[slides.index]?.link == 'productsCategories'  && !builderService.standalone">
                      <mat-form-field appearance="outline">
                          <mat-label>{{'Woo Product Category' | translate }}</mat-label>
                          <mat-select (selectionChange)="queryChange('categories', blockId, $event, 'multilevelSelect', slides.index, 'slides')" 
                            [(value)]="getBlockFields(blockId).data?.slides[slides.index].categories" >
                              <mat-option *ngFor="let productCategory of categoriesService.storedCategories"
                                [value]="productCategory.id">
                                {{productCategory.name}} 
                              </mat-option>
                            </mat-select>
                      </mat-form-field>
                    </li>
                    <li *ngIf="getBlockFields(blockId).data?.slides[slides.index]?.link == 'products' && builderService.standalone">
                      <mat-form-field appearance="outline">
                        <mat-label>{{'Standalone Product' | translate}}</mat-label>
                        <mat-select (selectionChange)="queryChange('product', blockId, $event, 'multilevelSelect', slides.index, 'menuItems')" 
                          [(value)]="getBlockFields(blockId).data.slides[slides.index].product" >
                            <mat-option *ngFor="let product of standaloneProductsService.storedStandaloneProducts"
                              [value]="product.id">
                              {{product.name}} 
                            </mat-option>
                          </mat-select>
                      </mat-form-field>
                    </li>
                    <li *ngIf="getBlockFields(blockId).data?.slides[slides.index]?.link == 'productsCategories' && builderService.standalone">
                      <mat-form-field appearance="outline">
                          <mat-label>{{'Product Category' | translate }}</mat-label>
                          <mat-select (selectionChange)="queryChange('categories', blockId, $event, 'multilevelSelect', slides.index, 'menuItems')" 
                            [(value)]="getBlockFields(blockId).data?.slides[slides.index].categories" >
                              <mat-option *ngFor="let productCategory of standaloneCategoriesService.storedStandaloneCategories"
                                [value]="productCategory.id">
                                {{productCategory.name}} 
                              </mat-option>
                            </mat-select>
                      </mat-form-field>
                    </li>
                    <!-- <li *ngIf="getBlockFields(blockId).data?.slides[slides.index].link == 'posts'">
                      <mat-label>{{'Post-Type' | translate }}</mat-label>
                      <mat-select (selectionChange)="queryChange('post_type', blockId, $event, 'multilevelSelect', slides.index, 'slides')"
                        value="{{getBlockFields(blockId).data?.slides[slides.index].post_type}}">
                        <mat-option *ngFor="let postType of postTypes" [value]="postType">
                          {{postType}}
                        </mat-option>
                      </mat-select>
                    </li> -->
                    <li *ngIf="getBlockFields(blockId).data?.slides[slides.index]?.link == 'posts'">
                      <mat-form-field appearance="outline">
                        <mat-label>{{'WP Posts' | translate }}</mat-label>
                        <mat-select (selectionChange)="queryChange('post', blockId, $event, 'multilevelSelect', slides.index, 'slides')" 
                          [(value)]="getBlockFields(blockId).data?.slides[slides.index].post" >
                            <mat-option *ngFor="let post of storedPosts"
                              [value]="post.ID">
                              {{post.post_title}} 
                            </mat-option>
                          </mat-select>
                      </mat-form-field>
                    </li>
<!-- 
                    <li *ngIf="getBlockFields(blockId).data?.slides[slides.index].link == 'stacksProducts'">
                      <mat-form-field appearance="outline">
                        <mat-label>{{'Product' | translate }}</mat-label>
                        <mat-select (selectionChange)="queryChange('stacksProduct', blockId, $event, 'multilevelSelect', slides.index, 'slides')" 
                          [(value)]="getBlockFields(blockId).data?.slides[slides.index].post" >
                            <mat-option *ngFor="let post of storedPosts"
                              [value]="post.ID">
                              {{post.post_title}} 
                            </mat-option>
                          </mat-select>
                      </mat-form-field>
                    </li>

                    <li *ngIf="getBlockFields(blockId).data?.slides[slides.index].link == 'stacksCategories'">
                      <mat-form-field appearance="outline">
                        <mat-label>Category</mat-label>
                        <mat-select (selectionChange)="queryChange('stacksCategory', blockId, $event, 'multilevelSelect', slides.index, 'slides')" 
                          [(value)]="getBlockFields(blockId).data?.slides[slides.index].post" >
                            <mat-option *ngFor="let post of storedPosts"
                              [value]="post.ID">
                              {{post.post_title}} 
                            </mat-option>
                          </mat-select>
                      </mat-form-field>
                    </li> -->
                    
                    <li *ngIf="getBlockFields(blockId).data?.slides[slides.index]?.link == 'externalURL'">
                      <mat-form-field appearance="outline">
                          <mat-label>{{'External URL' | translate }}</mat-label>
                          <input matInput value="{{getBlockFields(blockId).data?.slides[slides.index].externalURL}}"
                                (keyup)="queryChange('externalURL', blockId, $event, 'multilevelText', slides.index, 'slides')" />
                      </mat-form-field>
                    </li>
                  </ul>
                </div>
            </ul>
          </mat-expansion-panel>
        </mat-accordion>
      <li>
        <button class="addItem" (click)="addSliderItem(blockId)">{{"Add Item" | translate}}</button>
      </li>
    </ul>
  </div>
  <div class="styleFields" *ngIf="checkStyle()">
    <h4 class="listTitle">{{'Margins' | translate }}</h4>
    <mat-divider></mat-divider>
    <ul>
      <li>
        <mat-label>{{'Margin Top' | translate}}</mat-label>
        <mat-slider showTickMarks discrete  max="100" min="-100" step="1" thumbLabel="true">
          <input matSliderThumb ngModel="{{getBlockFields(blockId).style['margin-top']?.slice(0, -2)}}"
          (change)="styleChange('margin-top',
          blockId,
          $event, 'slider')">
        </mat-slider>
      </li>
      <li>
        <mat-label>{{'Margin Right' | translate}}</mat-label>
        <mat-slider showTickMarks discrete max="100" min="-100" step="1" thumbLabel="true">
          <input matSliderThumb ngModel="{{getBlockFields(blockId).style['margin-right']?.slice(0, -2)}}" (change)="styleChange('margin-right',
          blockId,
          $event, 'slider')">
        </mat-slider>
      </li>
      <li>
        <mat-label>{{'Margin Bottom' | translate}}</mat-label>
        <mat-slider showTickMarks discrete  max="100" min="-100" step="1" thumbLabel="true"
          >
          <input matSliderThumb ngModel="{{getBlockFields(blockId).style['margin-bottom']?.slice(0, -2)}}" (change)="styleChange('margin-bottom',
          blockId,
          $event, 'slider')">
        </mat-slider>
      </li>
      <li>
        <mat-label>{{'Margin Left' | translate}}</mat-label>
        <mat-slider showTickMarks discrete max="100" min="-100" step="1" thumbLabel="true"
          >
          <input matSliderThumb ngModel="{{getBlockFields(blockId).style['margin-left']?.slice(0, -2)}}" (change)="styleChange('margin-left',
          blockId,
          $event, 'slider')">
        </mat-slider>
      </li>
    </ul>
    <mat-label>{{'Paddings' | translate }}</mat-label>

    <mat-divider></mat-divider>
    <ul>
      <li>
        <mat-label>{{'Padding Top' | translate}}</mat-label>
        <mat-slider showTickMarks discrete max="100" min="1" step="1" thumbLabel="true"
          >
          <input matSliderThumb ngModel="{{getBlockFields(blockId).style['padding-top']?.slice(0, -2)}}" (change)="styleChange('padding-top',
          blockId,
          $event, 'slider')">
        </mat-slider>
      </li>
      <li>
        <mat-label>{{'Padding Right' | translate}}</mat-label>
        <mat-slider showTickMarks discrete max="100" min="1" step="1" thumbLabel="true"
          >
          <input matSliderThumb ngModel="{{getBlockFields(blockId).style['padding-right']?.slice(0, -2)}}"
          (change)="styleChange('padding-right',
          blockId,
          $event, 'slider')">
        </mat-slider>
      </li>
      <li>
        <mat-label>{{'Padding Bottom' | translate}}</mat-label>
        <mat-slider showTickMarks discrete max="100" min="1" step="1" thumbLabel="true"
          >
          <input matSliderThumb ngModel="{{getBlockFields(blockId).style['padding-bottom']?.slice(0, -2)}}"
          (change)="styleChange('padding-bottom',
          blockId,
          $event, 'slider')">
        </mat-slider>
      </li>
      <li>
        <mat-label>{{'Padding Left' | translate}}</mat-label>
        <mat-slider showTickMarks discrete max="100" min="1" step="1" thumbLabel="true"
          >
          <input matSliderThumb ngModel="{{getBlockFields(blockId).style['padding-left']?.slice(0, -2)}}" (change)="styleChange('padding-left',
          blockId,
          $event, 'slider')">
        </mat-slider>
      </li>
    </ul>
  </div>
</section>
