import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BuilderService } from '../../builder-services/builder.service';
import { Observable } from 'rxjs';
import { DatabaseService } from 'src/app/builder-services/database.service';
import { DiscordService } from 'src/app/builder-services/discord.service';
import { MixpanelService } from 'src/app/tracking/mixpanel.service';
import { LoginService } from 'src/app/login/login.service';
import { Router } from '@angular/router';


export interface DialogData {
  name: string;
}

@Component({
  selector: 'works-dialog',
  templateUrl: './works-dialog.component.html',
  styleUrls: ['./works-dialog.component.scss']
})


export class WorksDialogComponent {

  message = '';

  currentGifIndex = 0;
  gifList = [
    'assets/gifs/new gifs/choose temp.gif',
    'assets/gifs/new gifs/add block and edits.gif',
    'assets/gifs/new gifs/add product and category.gif',
    'assets/gifs/new gifs/analytics.gif',
    'assets/gifs/new gifs/build apk.gif',
    // Add more paths to your GIFs as needed
  ];

  constructor(public dialogRef: MatDialogRef<WorksDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public builderService: BuilderService,
    private db: DatabaseService,
    private discordService: DiscordService,
    public mixPanel: MixpanelService,
    public loginService: LoginService,
    private router: Router
    ) { }

  ngOnInit() {
    this.loadNextGif();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  get currentGifPath() {
    return this.gifList[this.currentGifIndex];
  }

  loadNextGif() {
    this.currentGifIndex = (this.currentGifIndex + 1) % this.gifList.length;
  }


  completeTour(dialogOrder: any) {
    this.builderService.checklist[dialogOrder].done = true;
    this.dialogRef.close();
    localStorage.setItem("checklist", JSON.stringify(this.builderService.checklist));
  }

  closeDialog = (dialogName: string) => {
    localStorage.setItem(dialogName, 'closed');
    this.dialogRef.close();
  }
  claimOffer = (dialogName: string) => {
    localStorage.setItem(dialogName, 'closed');
    this.dialogRef.close();
    this.router.navigate(['/payment']);
  }

  submitFeedback(comment: string){
    this.message = comment;
    if(this.message === ""){
      this.message = "N/A"
    }
    if(this.builderService.starRating === 0){
      this.builderService.starRating = "N/A"
    }
    this.sendMessageToDiscord();
    this.setFeedback(this.message).subscribe((result:any) => {
      this.mixPanel.track('onboarding_feedback ', {
        comment: this.message,
        rating: this.builderService.starRating,
      });
      this.dialogRef.close();
    })
  }

  sendMessageToDiscord() {
    // console.log(this.message)
    let content = 'rating : ' + this.builderService.starRating + ' and comment : ' + this.message + ' from ' + this.loginService.getCurrentUser().username
    this.discordService.sendMessage( content, "Feedback" )
      .subscribe(
        () => {
          console.log('Message sent to Discord successfully!');
          // Handle success
        },
        error => {
          console.error('Error sending message to Discord:', error);
          // Handle error
        }
      );
  }

  setFeedback(comment: string) {
    return new Observable<any>((observer) => {
      this.db.setDatabase('projects/' + this.builderService.selectedProject + '/onboarding/feedback/', {comment: comment, rating: this.builderService.starRating}).subscribe((response) => {
        observer.next(response); // Notify the observer that the operation is complete
        observer.complete(); // Complete the Observable
      }, (error) => {
        observer.error(error); // Notify the observer of an error
      });
    })
  }

}
