import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HelpersService } from '../helpers.service';
import { BlockDataService } from '../block-services/block-data.service';

@Injectable({
  providedIn: 'root'
})
export class SignupService extends BlockDataService {

  private builderUrl;

  projects = {};
  selectedProject = 0;
  headerBtn = '';
  options = {
    headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
  };
  constructor(
    private http: HttpClient, 
    public helpers: HelpersService, 
    ) {
    super();
    this.builderUrl = this.helpers.getBuilderUrl();
  }

  signup = (email: string, password: string, firstname: string, lastname: string, phone_number: string, type = ''): Observable<any> => {

    let postData = new URLSearchParams();
    postData.set('email', email);
    postData.set('password', password);
    postData.set('firstname', firstname);
    postData.set('lastname', lastname);
    postData.set('phone_number', phone_number);
    if (window.screen.width <= 768) { // 768px portrait
      postData.set('mobileUser', "true");
    } else {
      postData.set('mobileUser', "false");
    }
    postData.set('type', type);

    return this.http.post(this.builderUrl + 'signupBuilder', postData.toString(), this.options);
  }

  signupSocial = (email: string, firstname: string, lastname: string, type = ''): Observable<any> => {

    let postData = new URLSearchParams();
    postData.set('process', "sync_ui");
    postData.set('email', email);
    postData.set('firstname', firstname);
    postData.set('lastname', lastname);
    postData.set('type', type);

    return this.http.post(this.builderUrl + 'signupSocialBuilder', postData.toString(), this.options);
  }
}
