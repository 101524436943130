import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BlockDataService } from 'src/app/block-services/block-data.service';

@Pipe({
  name: 'safe'
})
export class SafePipe implements PipeTransform {

  transform(video: string) { 
    return this.sanitizer.bypassSecurityTrustResourceUrl( video );
  }

  constructor(private blockDataService: BlockDataService, 
    private sanitizer: DomSanitizer
  ) {
  
  };


}
