import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { BuilderService } from '../../builder-services/builder.service';
import { WorksDialogComponent } from '../../pages/works-dialog/works-dialog.component';
import { PushNotificationsService } from './push-notifications.service';
import { AppSettingsService } from '../../settings/services/app-settings.service';

export interface PeriodicElement {
  id: number;
  name: string;
  email: string;
}

@Component({
  selector: 'app-push-notifications',
  templateUrl: './push-notifications.component.html',
  styleUrls: ['./push-notifications.component.scss']
})
export class PushNotificationsComponent implements OnInit {

  ELEMENT_DATA: PeriodicElement[] = [];

  displayedColumns: string[] = ['name', 'email'];
  displayedValidationColumns: string[] = ['field', 'value'];
  // dataSource = this.ELEMENT_DATA;
  dataSource:any;
  clickedRows = new Set<PeriodicElement>();
  clickedUsers:any = [];
  
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  // MatPaginator Inputs
  length = 1;
  pageSize = 10;

  titleCounter= 65;
  titleClass= '';
  messageCounter= 240;
  messageClass= '';
  mobile = false;
  toggledEmoji: boolean = false;
  toggledEmojiMsg: boolean = false;

  pnValidation = {
    auth_key: false,
    google_sender_id: false,
    google_service_json: false
  };

  validationData: any = [];
  allUsers: any = [];
  applicationSettingsData: any = {};
  PNForm!: FormGroup;
  public jsonFile!: File;
  public plistFile!: File;
  constructor(
    private appSettingsService: AppSettingsService, 
    private formBuilder: FormBuilder, 
    private pushNotifications: PushNotificationsService, 
    public builderService: BuilderService,
    private router: Router,
    public dialog: MatDialog) {
    let projectId = String(localStorage.getItem("selectedProject"));
    let usersList:any = [];
    this.builderService.showUpdateProgress = false;
    this.pushNotifications.getUsers(projectId).subscribe(
      (response: any) => {
      if(this.builderService.standalone) {
        for (const key in response) {
          if (Object.prototype.hasOwnProperty.call(response, key)) {
            const element = response[key];
            console.log(element);
            if(element.device_id) {
              usersList.push({ id: key, name: element.displayName, email: element.email, device_id: element.device_id, device_type: element.device_type});
            }
          }
        }
      } else {
        for (let index = 0; index < response.length; index++) {
          const element = response[index];
          usersList.push({ id: element.data.ID, name: element.data.display_name, email: element.data.user_email});
        }
      }
        this.allUsers = usersList;
        console.log(usersList);
        this.ELEMENT_DATA = usersList;
        this.dataSource = new MatTableDataSource<PeriodicElement>(this.ELEMENT_DATA);
        this.dataSource.paginator = this.paginator;
        this.length = usersList.length;
      }
    );

    /**
     * Verify Google Service JSON and Authentication Fields are Correct
     */
    this.pushNotifications.validatePN(projectId).subscribe(
      (response: any) => {
        this.pnValidation = response;
        let formattedValidation: any = [];
        for (const key in response) {
          if (Object.prototype.hasOwnProperty.call(response, key)) {
            const element = response[key];
            let formattedKey = key.replace(/_/g, " ");
            let fields = { 'field': formattedKey, 'value': element };
            formattedValidation.push(fields);
          }
        }
        this.validationData = new MatTableDataSource<PeriodicElement>(formattedValidation);
      }
    );
   }

  ngOnInit(): void {
    if (window.screen.width <= 768) { // 768px portrait
      this.mobile = true;
    }
    this.PNForm = this.formBuilder.group({
      googleSenderId: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
      googleServiceJson: '',
      googleServicePlist: '',
      message: '',
      title: ''
    });
    this.getApplicationSettings();
    /**
     * Disable Access for free users
     */
    // this.builderService.getProject(String( localStorage.getItem("selectedProject") ) ).subscribe((project: any) => {
    //   if( project.details[0].access == 'free' ) {
    //     const dialogTemplateLocked = this.dialog.open(WorksDialogComponent, {
    //       width: '700px',
    //       maxWidth: '80%',
    //       data: {name: "viewLocked"},
    //     });
    
    //     dialogTemplateLocked.afterClosed().subscribe(result => {
    //       this.router.navigate(['/']);
    //     });
    //   }
    // });
  }

  characterCounter = (type: string) => {
    if( type == "title") {
      if(this.PNForm.value.title){
      this.titleCounter = 65 - this.PNForm.value.title.length;
      if(this.titleCounter < 10 && this.titleCounter > 0) {
        this.titleClass = 'limitWarning';
      }
      else if(this.titleCounter <= 0) {
        this.titleClass = 'limitReached';
      } else {
        this.titleClass = '';
      }
    }
    }

    if( type == "message") {
      if(this.PNForm.value.message){
      this.messageCounter = 240 - this.PNForm.value.message.length;
      if(this.messageCounter < 10 && this.messageCounter > 0) {
        this.messageClass = 'limitWarning';
      } else if(this.messageCounter <= 0) {
        this.messageClass = 'limitReached';
      } else {
        this.messageClass = '';
      }
    }
    }
  }

  /**
   * Append user ID to the list of clicked users
   * @param userId the id of the user clicked
   */
  clickedUser = (user: any) => {
    console.log(user);
    this.clickedUsers.push(user);
  }
  sendMessage = () => {
    let users = this.clickedUsers.join(", ");
    if (!(this.PNForm.value.title && this.PNForm.value.message && users)) {
      alert("Please make sure to write title, message and choose a user");
      return;
    }
    let projectId = String(localStorage.getItem("selectedProject"));
    this.builderService.showUpdateProgress = true;
    console.log(this.clickedUsers);
    console.log("List of users ",users, this.allUsers,this.allUsers[users]);
    this.pushNotifications.sendMessage(projectId, this.PNForm.value.title, this.PNForm.value.message, this.clickedUsers)
    .subscribe(
      (response: any) => {
        this.builderService.showUpdateProgress = false;
        alert("Message Sent Successfully");
      },
      (err) => {
      }
    );
  }

  emoji = (event:any, field:string) => {
    if (field == 'message') {
      this.PNForm.patchValue({ message: this.PNForm.value.message + event.char });
    } else {
      this.PNForm.patchValue({ title: this.PNForm.value.title +event.char });
    }
    
  }

  handleCharDelete(event: any, field: string) {
    if (field == 'message') {
      if(this.PNForm.value.message){
      if (this.PNForm.value.message.length > 0) {
        this.PNForm.patchValue({ message: this.PNForm.value.message.substr(0, this.PNForm.value.message.length - 2)  });
      }
    } else {
      if(this.PNForm.value.title){
      if (this.PNForm.value.title.length > 0) {
        this.PNForm.patchValue({ title: this.PNForm.value.title.substr(0, this.PNForm.value.title.length - 2) });
      }
    }
    }
  }
  }

  howItWorksDialog = () => {
    const dialogRef = this.dialog.open(WorksDialogComponent, {
      width: '700px',
      maxWidth: '80%',
      data: {name: "pushNotifications"},
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  processFile(fileInput: any, type: string, name: string) {
    console.log(fileInput.target.files)
    const file: File = fileInput.target.files[0];
    if (fileInput.target.files[0].type == 'application/json') {
      this.jsonFile = fileInput.target.files[0];
    }
    if (fileInput.target.files[0].type == '') {
      this.plistFile = fileInput.target.files[0];
    }
    
    const reader = new FileReader();
    reader.addEventListener('load', (event: any) => {
      this.appSettingsService.uploadFile(file, this.builderService.selectedProject, type, name).subscribe(
        (res) => {
          this.applicationSettingsData[name] = res;
          this.builderService.showUpdateProgress = false;
        },
        (err) => {
          this.builderService.showUpdateProgress = false;
          alert("error uploading File, please contact support");
        })
    });
    reader.readAsDataURL(file);
  }

  saveChanges() {
    const projectId = this.builderService.selectedProject;

    this.appSettingsService.getApplicationSettings(projectId).subscribe(
      (currentSettings: any) => {
        const updatedSettings = {
          ...currentSettings,
          android_sender_id: this.PNForm.value.googleSenderId,
          jsonFile: this.jsonFile,
          plistFile: this.plistFile,
        };

        this.appSettingsService.setApplicationSettings(projectId, updatedSettings).subscribe(
          () => {
            this.appSettingsService.setWPApplicationSettings(this.builderService.selectedProject, updatedSettings).subscribe(
              () => {
                console.log('Push notifications login data saved successfully', updatedSettings);
              },
              (error: any) => {
                console.error('Error updating push notifications login data', error);
              }
            );
          },
          (error: any) => {
            console.error('Error updating push notifications login data', error);
          }
        );
      },
      (error: any) => {
        console.error('Error saving push notifications login data', error);
      }
    );
  }

  getApplicationSettings() {
    const projectId = this.builderService.selectedProject;
    this.appSettingsService.getApplicationSettings(projectId).subscribe(
      (settings: any) => {
        if (settings && settings.android_sender_id) {
          this.PNForm.patchValue({ googleSenderId: settings.android_sender_id });
        }
      },
      (error: any) => {
        console.error('Error fetching application settings', error);
      }
    );
  }
}
