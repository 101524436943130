import { BuilderService } from 'src/app/builder-services/builder.service';
import { CategoriesService } from 'src/app/e-commerce/categories/categories.service';
import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/builder-services/database.service';
import { forkJoin, of } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { map } from 'rxjs/operators';
import { MultilingualPipe } from '../multilingual.pipe';

@Component({
  selector: 'app-category-translation',
  templateUrl: './category-translation.component.html',
  styleUrl: './category-translation.component.scss'
})
export class CategoryTranslationComponent implements OnInit {

  categories: any[] = [];
  categoryTranslations!: FormGroup;
  displayedColumns: string[] = [];
  ELEMENT_DATA: any[] = [];
  languages: { title: string }[] = [];
  pagedTableData: any[] = [];
  pageSize: number = 5;
  pageSizeOptions: number[] = [5, 10, 25, 50];
  productProgress: boolean = false;
  stacksTranslation = false;

  constructor(
    private BuilderService: BuilderService,
    private categoriesService: CategoriesService,
    private db: DatabaseService,
    private fb: FormBuilder,
    private translatePipe: MultilingualPipe
  ) { }

  /*
 * ngOnInit: Initializes the component by setting up the form and loading necessary data.
 * - Creates a form group for category translations with a languages array.
 * - Calls loadLanguages and, upon successful completion, invokes getCategoryNames.
 * - Logs an error message if initialization fails.
 */

  ngOnInit(): void {
    this.categoryTranslations = this.fb.group({
      languages: this.fb.array([])
    });
    this.loadLanguages().then(() => {
      this.getCategoryNames();
    }).catch(error => console.error("Initialization failed:", error));
  }

  /*
 * updatePageData: Updates the data displayed on the current page of the table.
 * - Calculates the start and end indices based on the given pageIndex and pageSize.
 * - Slices the ELEMENT_DATA array to set pagedTableData for the current page.
 */

  updatePageData(pageIndex: number) {
    const startIndex = pageIndex * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.pagedTableData = this.ELEMENT_DATA.slice(startIndex, endIndex);
  }

  /*
 * onPageChange: Handles page change events triggered by pagination controls.
 * - Updates the pageSize based on the new value from the event.
 * - Calls updatePageData to refresh the displayed data for the current page.
 */

  onPageChange(event: any) {
    this.pageSize = event.pageSize;
    this.updatePageData(event.pageIndex);
  }

  /*
   * getCategoryNames: Fetches category data from the categories service.
   * - On successful retrieval, updates ELEMENT_DATA with the categories.
   * - If stacksTranslation is enabled, calls handleMissingTranslations to manage any missing translations.
   * - Resets the displayed page to the first page and loads translations for categories.
   * - Updates the displayed columns to reflect the current languages.
   * - Logs a success message or an error if fetching categories fails.
   */

  getCategoryNames(): void {
    this.categoriesService.getCategories().subscribe({
      next: (categoriesObject) => {
        this.ELEMENT_DATA = Object.values(categoriesObject);
        if (this.stacksTranslation) {
          this.handleMissingTranslations(this.ELEMENT_DATA);
        }
        this.updatePageData(0);
        console.log('Categories loaded successfully.');
        this.getTranslate(); // Call to load translations right after categories are loaded
        this.updateDisplayedColumns();
      },
      error: (error) => {
        console.error('Error fetching categories:', error);
      }
    });
  }

  /*
   * toggleEdit: Toggles the editing state for a category in a specific language.
   * - Iterates through ELEMENT_DATA to reset the editingLang property for all categories except the selected one.
   * - Sets the editingLang property of the specified category (cat) to the provided language title (langTitle).
   */

  toggleEdit(cat: any, langTitle: string): void {
    this.ELEMENT_DATA.forEach(c => {
      if (c !== cat) {
        c.editingLang = null;
      }
    });
    cat.editingLang = langTitle;
  }

  /*
   * getTranslate: Fetches translations for each category in all available languages.
   * - Retrieves the selected project ID.
   * - Iterates through ELEMENT_DATA (categories) and languages.
   * - For each category and language, constructs a database path to fetch translations.
   * - If a translation is found, updates the corresponding category object with the translated value.
   * - Logs an error message if the translation fetch fails.
   */

  getTranslate(): void {
    const project_id = this.BuilderService.selectedProject;
    this.ELEMENT_DATA.forEach(category => {
      this.languages.forEach(lang => {
        const path = `/projects/${project_id}/translations/languages/${lang.title}/Categories/${category.categoryName}`;
        this.db.getDatabase(path).subscribe({
          next: (response: any) => {
            if (response && response.value) {
              category[lang.title] = response.value;
            }
          },
          error: (error) => console.error(`Failed to load translation for ${category.categoryName} in ${lang.title}:`, error)
        });
      });
    });
  }

  /*
 * loadLanguages: Loads available languages for the selected project.
 * - Returns a Promise that resolves when languages are successfully fetched.
 * - Constructs the path for retrieving language data from the database using the selected project ID.
 * - Upon successful retrieval, formats the languages data and sorts them to prioritize "English".
 * - If an error occurs during data fetching, logs the error and rejects the Promise.
 */

  loadLanguages(): Promise<void> {
    return new Promise((resolve, reject) => {
      const project_id = this.BuilderService.selectedProject;
      const path = `/projects/${project_id}/translations/languages`;
      this.db.getDatabase(path).subscribe({
        next: (languagesData: any) => {
          this.languages = this.formatLanguages(languagesData);
          this.sortLanguages('English');
          resolve();
        },
        error: (error) => {
          console.error('Error fetching languages:', error);
          reject(error);
        }
      });
    });
  }

  /*
 * formatLanguages: Transforms raw language data into an array of language objects.
 * - Takes in a languagesData object where keys are language titles.
 * - Maps each key to an object containing the title and associated properties from languagesData.
 * - Returns an array of structured language objects.
 */

  formatLanguages(languagesData: any): any[] {
    return Object.keys(languagesData).map(key => ({ title: key, ...languagesData[key] }));
  }

  /*
   * sortLanguages: Moves a specified primary language to the front of the languages array.
   * - Takes a primaryLanguage string as input.
   * - Finds the index of the specified primary language in the languages array.
   * - If found, removes it from its current position and adds it to the beginning of the array.
   */

  sortLanguages(primaryLanguage: string): void {
    const index = this.languages.findIndex(lang => lang.title === primaryLanguage);
    if (index > -1) {
      const [primaryLang] = this.languages.splice(index, 1);
      this.languages.unshift(primaryLang);
    }
  }

  /*
   * saveChanges: Handles the saving of translations for categories in multiple languages.
   * - Sets productProgress to true to indicate a saving operation is in progress.
   * - Deletes old translation data for each category in all languages.
   * - After deletion, it iterates over each category to save new translations.
   * - For English, it replaces hyphens with spaces for the category name.
   * - For other languages, it uses the current translation value or an empty string.
   * - Saves the new translation data to the database, logging the results of each operation.
   */

  saveChanges(): void {
    this.productProgress = true;
    const project_id = this.BuilderService.selectedProject;
    // First, delete the old data
    this.languages.forEach(lang => {
      this.ELEMENT_DATA.forEach(category => {
        const uniquePath = `/projects/${project_id}/translations/languages/${lang.title}/Categories/${category.categoryName}`;
        // Delete the translation data
        this.db.removeDatabaseEndpoint(uniquePath).subscribe({
          next: (response) => console.log(`Translation for ${category.categoryName} in ${lang.title} deleted:`, response),
          error: (error) => console.error(`Failed to delete translation for ${category.categoryName} in ${lang.title}:`, error)
        });
      });
    });
    // Then, add the new data
    this.ELEMENT_DATA.forEach(category => {
      this.languages.forEach(lang => {
        let value;
        if (lang.title === 'English') {
          value = category.categoryName.replace(/-/g, ' ');
        } else {
          value = category[lang.title] || ''; // Use input value or an empty string if undefined
        }
        const translationObject = {
          value: value
        };
        const uniquePath = `/projects/${project_id}/translations/languages/${lang.title}/Categories/${category.categoryName}`;
        // Save the new translation data
        this.db.setDatabase(uniquePath, translationObject).subscribe({
          next: (response) => {
            console.log(`Translation for ${category.categoryName} in ${lang.title} saved:`, response)
            this.productProgress = false;
          },
          error: (error) => {
            console.error(`Failed to save translation for ${category.categoryName} in ${lang.title}:`, error)
            this.productProgress = false;
          }
        });
      });
    });
  }

  /*
   * updateDisplayedColumns: Updates the displayed columns in the table to reflect the current languages.
   * - Sets the displayedColumns property to an array of language titles, ensuring the table displays the correct columns for each language.
   */

  updateDisplayedColumns(): void {
    this.displayedColumns = this.languages.map(lang => lang.title);
  }

  /*
   * callStacksTranslation: Initiates the stacks translation process by setting stacksTranslation to true
   * and then calling the getCategoryNames function to fetch category data.
   */

  callStacksTranslation() {
    this.stacksTranslation = true;
    this.getCategoryNames();
  }

  /*
   * handleMissingTranslations: Handles translation for missing language entries in allData.
   * It iterates through each data row and checks for translations in different languages.
   * If a translation is missing, it uses the translatePipe to fetch the translation for the 
   * category name and updates the row. It uses forkJoin to handle multiple translation requests 
   * simultaneously and updates the ELEMENT_DATA with the translated rows, 
   * followed by updating the displayed page data.
   */
  handleMissingTranslations(allData: { [key: string]: any }[]): void {
    const translationRequests = allData.map(row => {
      const missingTranslations = this.languages.map(lang => {
        const langTitle = lang.title;
        // Proceed with translation if categoryName text is available
        if (!row[langTitle]) {
          return this.translatePipe.transform(row.categoryName, langTitle).pipe(
            map(translatedText => {
              row[langTitle] = translatedText;
              return translatedText;
            })
          );
        } else {
          console.log(`${langTitle} translation already exists:`, row[langTitle]);
          return of(row[langTitle]);
        }
      });

      return forkJoin(missingTranslations).pipe(
        map(translations => {
          this.languages.forEach((lang, index) => {
            row[lang.title] = translations[index];
          });
          return row;
        })
      );
    });

    forkJoin(translationRequests).subscribe(translatedRows => {
      this.ELEMENT_DATA = translatedRows;
      this.updatePageData(0);
    });
  }

}
