import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HelpersService } from 'src/app/helpers.service';
import { BuilderService } from 'src/app/builder-services/builder.service';

@Injectable({
  providedIn: 'root'
})
export class SiteTypeService {

  private builderUrl;

  projects = {};
  selectedProject = 0;
  headerBtn = '';
  options = {
    headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
  };

  constructor(private http: HttpClient, public helpers: HelpersService, public builderService: BuilderService) { 
    this.builderUrl = this.helpers.getBuilderUrl();
  }

  siteType = (type: string, websiteURL: string): Observable<any> => {

    let postData = new URLSearchParams();
    postData.set('project_id', this.builderService.selectedProject);
    postData.set('site_url', websiteURL);
    postData.set('site_type', type);

    return this.http.post(this.builderUrl + 'site-type', postData.toString(), this.options);
  }
}
