<div class="pointsContainer">
  <div class="pointPart pointPartText tableWrapper">
    <div class="tableHeader">
      <h1>{{ "Users" | translate }}</h1>
      <div class="headerBtns">
        <form class="searchBar">
          <input
            type="text"
            matInput
            placeholder="{{ 'Search' | translate }}"
            id="searchInput"
            [(ngModel)]="searchText"
            (input)="applyFilter()"
            name="searchText"
            data-search
          />
        </form>
      </div>
    </div>
    <table
      mat-table
      [dataSource]="userDataSource"
      class="mat-elevation-z8 userTable"
      matSort
    >
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>{{ "ID" | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.uid }}</td>
      </ng-container>

      <ng-container matColumnDef="userName">
        <th mat-header-cell *matHeaderCellDef>{{ "User Name" | translate }}</th>
        <td mat-cell *matCellDef="let element">
          {{ element.displayName || element.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>{{ "Email" | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.email }}</td>
      </ng-container>

      <ng-container matColumnDef="points">
        <th mat-header-cell *matHeaderCellDef class="green">
          {{ "Points" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.totalPoints }}</td>
      </ng-container>

      <ng-container matColumnDef="redeem">
        <th mat-header-cell *matHeaderCellDef class="red">
          {{ "Redeem" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.redeem }}</td>
      </ng-container>

      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>{{ "Date" | translate }}</th>
        <td mat-cell *matCellDef="let element">
          {{ element.lastInitialDate }}
        </td>
      </ng-container>

      <ng-container matColumnDef="transactionsHistory">
        <th mat-header-cell *matHeaderCellDef>
          {{ "Transactions History" | translate }}
        </th>
        <td mat-cell *matCellDef="let element" class="history">
          <button mat-icon-button (click)="showUserHistory(element)">
            <mat-icon>visibility</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator
      class="tablePaginator"
      #paginator1
      [pageSizeOptions]="[5, 10, 20, 50, 100]"
      showFirstLastButtons
      aria-label="Select page of periodic dataSource"
    >
    </mat-paginator>

    <div *ngIf="selectedUser" class="selectedUser">
      <p *ngIf="!selectedUser.pointsArray" class="noHistory">
        {{ "User has no history" | translate }}
      </p>

      <div>
        <button class="stacksBtn blue pointsButton" (click)="addPoints()">
          {{ "Add Points" | translate }}
        </button>
        <input
          (blur)="saveChanges(this.selectedUser)"
          [(ngModel)]="newPoints.points"
          [ngModelOptions]="{ standalone: true }"
          *ngIf="toAddPoints"
          name="points"
          type="number"
        />
      </div>

      <table
        mat-table
        *ngIf="selectedUser.pointsArray"
        [dataSource]="pointsDataSource"
        class="mat-elevation-z8"
      >
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef>{{ "Date" | translate }}</th>
          <td mat-cell *matCellDef="let point">{{ point.initialDate }}</td>
        </ng-container>

        <ng-container matColumnDef="Earned">
          <th mat-header-cell *matHeaderCellDef>{{ "Earned" | translate }}</th>
          <td mat-cell *matCellDef="let point">
            {{ point.points > 0 ? point.points : "0" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="redeemed">
          <th mat-header-cell *matHeaderCellDef>
            {{ "Redeemed" | translate }}
          </th>
          <td mat-cell *matCellDef="let point">
            {{ point.points < 0 ? point.points : "0" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef>{{ "Action" | translate }}</th>
          <td mat-cell *matCellDef="let point">
            <button
              mat-icon-button
              class="deleteIcon"
              (click)="deletePoint(selectedUser, point)"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="['date', 'Earned', 'redeemed', 'action']"
        ></tr>
        <tr
          mat-row
          *matRowDef="
            let row;
            columns: ['date', 'Earned', 'redeemed', 'action']
          "
        ></tr>
      </table>
      <mat-paginator
        #paginator2
        class="tablePaginator"
        [pageSizeOptions]="[5, 10, 20, 50, 100]"
        showFirstLastButtons
      ></mat-paginator>
    </div>
  </div>
</div>
