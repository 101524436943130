import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PostsService } from '../posts.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BuilderService } from 'src/app/builder-services/builder.service';




@Component({
  selector: 'app-new-post',
  templateUrl: './new-post.component.html',
  styleUrls: ['./new-post.component.scss']
})
export class NewPostComponent implements OnInit {


  mobile = false;
  selectedFile: File | null = null;
  postProgress = false;
  post: any;
  imageUrl = '';
  postIdFromRoute: any;
  showUploadProgress = false;


  constructor(private formBuilder: FormBuilder, private postsService: PostsService, public router: Router, public builderService: BuilderService, private route: ActivatedRoute,) { }


  postForm = this.formBuilder.group({
    username: '',
    post: '',
    imageUrl: '',
    date: '',
  });


  ngOnInit(): void {
    const routeParams = this.route.snapshot.paramMap;
    this.postIdFromRoute = routeParams.get('productId');

    this.postForm = this.formBuilder.group({
      username: ['', Validators.compose([
        Validators.required,
      ])],
      post: ['', Validators.compose([
        Validators.required,
      ])],
      imageUrl: [''],
      date: [''],
    })

    if (window.screen.width <= 768) { // 768px portrait
      this.mobile = true;
    }
  }


  onSubmit = () => {
    this.postProgress = true;
    let postFormVal = this.postForm.value;
    postFormVal.post = this.post;
    postFormVal.imageUrl = this.imageUrl;
    if (this.postForm.status == 'INVALID') {
      this.postProgress = false;
      return;
    }
    if (this.postIdFromRoute) {
      this.postsService.submitPost(postFormVal, this.postIdFromRoute).subscribe((result: any) => {
        this.postProgress = false;
        this.router.navigate(['/posts/list']);
      })
    } else {
      postFormVal.date = new Date().toLocaleDateString();
      this.postsService.submitPost(postFormVal).subscribe((result: any) => {
        this.postProgress = false;
        this.router.navigate(['/posts/list']);
      })
    }
  }

  onFileSelected(event: any, type = 'simple', index = 0) {
    this.selectedFile = event.target.files[0] as File;
    if (!this.selectedFile) {
      console.error('No file selected.');
      return;
    }
    this.showUploadProgress = true;
    this.builderService.uploadImage(this.selectedFile, this.builderService.selectedProject, 'posts')?.subscribe((response) => {
      this.imageUrl = response;
      this.showUploadProgress = false;
    });
  }
  removeImg() {
    this.imageUrl = " ";
  }
}
