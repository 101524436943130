import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { BuilderService } from 'src/app/builder-services/builder.service';
import { AppSettingsService } from '../services/app-settings.service';

class ImageSnippet {
  constructor(public src: string, public file: File) { }
}
@Component({
  selector: 'apple-settings',
  templateUrl: './apple-settings.component.html',
  styleUrls: ['./apple-settings.component.scss']
})
export class AppleSettingsComponent {


  public disabled = false;
  public color: ThemePalette = 'primary';
  public touchUi = false;

  selectedFile!: ImageSnippet;


  appleSettingsForm = this.formBuilder.group({
    key_id: '',
    issuer_id: '',
    key_filepath: '',
    email: '',
    app_specific_pass: '',
    team_name: '',
    team_id: '',
  });

  appleSettingsData: any = {};
  constructor(private formBuilder: FormBuilder,
    private appSettingsService: AppSettingsService,
    public builderService: BuilderService) {

    let project_id = this.builderService.getSelectedProject();
    this.appleSettingsData = this.appSettingsService.getAppleSettings(project_id).subscribe((settings: any) => {
      this.appleSettingsData = settings !== null ? settings : {};

    }, (err) => {
      console.log(err);
    });

  }

  onSubmit(): void {
    // Process checkout data here
    this.builderService.showUpdateProgress = true;

    let key_filepath = this.appleSettingsData.key_filepath ? this.appleSettingsData.key_filepath : this.appleSettingsForm.value.key_filepath;
    key_filepath = decodeURI( key_filepath );
    // /**
    //  * Loop on the Values and if the value isn't changed from the UI then retrieve the current available value
    //  */
    let newSubmittedData: any = {};
    let submittedData:any = this.appleSettingsForm.value;
    let retrievedData = this.appleSettingsData;
    for (var k in submittedData) {
      if (submittedData[k] != "") {
        newSubmittedData[k] = submittedData[k];
      } else {
        newSubmittedData[k] = retrievedData[k];
      }
    }
    /**
     * Step 1 Submit the files
     */
    newSubmittedData['key_filepath'] = key_filepath;

    this.appSettingsService.setAppleSettings(this.builderService.selectedProject, newSubmittedData).subscribe((response) => {
      this.builderService.showUpdateProgress = false;
    }, (err) => {

    });
  }

  processFile(fileInput: any, type: string, name: string) {
    const file: File = fileInput.target.files[0];
    const reader = new FileReader();
    reader.addEventListener('load', (event: any) => {
      this.appSettingsService.uploadFile(file, this.builderService.selectedProject, type).subscribe(
        (res) => {
          this.appleSettingsData[name] = res;
          this.builderService.showUpdateProgress = false;
        },
        (err) => {
          this.builderService.showUpdateProgress = false;
          alert("error uploading file, please contact support");
        })
    });

    reader.readAsDataURL(file);
  }

}
