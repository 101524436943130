<div id="middleContainerWrapper">
  <h2 class="couponBanner" *ngIf="this.builderService.selected_coupon_id">Coupon Applied Successfully</h2>
  <div class="pricingToggles" *ngIf="service_type !== 'self_ltd' && show_plans && !show_single_plan">
    <div class="buttonsToggle">
      <button class="stacksBtn" [ngClass]="duration == 'Once' ? 'green' : ''" (click)="durationSelect('Once')">Once</button>
      <button class="stacksBtn" [ngClass]="duration == 'Monthly' ? 'green' : ''" (click)="durationSelect('Monthly')">Per Month</button>
      <button class="stacksBtn" [ngClass]="duration == 'Yearly'  ? 'green' : ''" (click)="durationSelect('Yearly')">Per Year</button>
    </div>
    <div class="buttonsToggle right">
      <button class="stacksBtn" [ngClass]="service_type == 'standalone' ? 'blue' : ''" (click)="serviceTypeSelect('standalone')">I have WordPress Site</button>
      <button class="stacksBtn" [ngClass]="service_type == 'server_with_stacks' ? 'blue' : ''" (click)="serviceTypeSelect('server_with_stacks')">I don't have a WordPress Site</button>
      <button class="stacksBtn hidden" [ngClass]="service_type == 'test' ? 'blue' : ''" (click)="serviceTypeSelect('test')">Test</button>
    </div>
  </div>
  <div>
    <button class="stacksBtn green" *ngIf="builderService.getTour()" [routerLink]="['/site-type']">Skip the Offer and start the trial</button>
  </div>
  <div class="pricing_table" *ngIf="show_plans">
    <ng-container *ngFor="let package of packages">
      <div class="pricing_plan" *ngIf="package.duration == duration && package.service_type == service_type" [ngClass]="subscribedPackage == package.pricing_id ? 'selectedPackage' : ''">
        <div class="pricing_header">
          <h2 class="pricingType">
            {{package.type | googleTranslate | async | extractGoogleTranslation }}
            </h2>
            <h3 class="packageDescription" *ngIf="package.description">{{package.description | googleTranslate | async | extractGoogleTranslation }}</h3>
          <div class="ribbon-wrapper">
            <div class="ribbon-front">
              <h2 class="packagePrice">
                {{package.price | googleTranslate | async | extractGoogleTranslation }}
              </h2>
              <div class="ribbon-edge-bottomleft"></div>
              <div class="ribbon-edge-bottomright"></div>
            </div>
            <div class="ribbon-edge-topright"></div>

          </div>
          
          <!-- <h2 class="packageDuration">{{package.duration}}</h2> -->
        </div>
        <div class="pricing_body">
          <ul>
            <li *ngFor="let feature of package.features">
              <mat-icon class="material-icons-outlined">check_circle</mat-icon>
              {{feature | googleTranslate | async | extractGoogleTranslation }}
            </li>
          </ul>
          <button class="stacksBtn blue" *ngIf="subscribedPackage !== package.pricing_id && !loadingStripe" (click)="redirectToCheckout(package.pricing_id)">
            {{"Pay with Stripe" | googleTranslate | async | extractGoogleTranslation }}
          </button>
          <button class="stacksBtn blue" disabled="disabled" *ngIf="subscribedPackage !== package.pricing_id && loadingStripe">
            {{"Redirecting to Stripe" | googleTranslate | async | extractGoogleTranslation }}
          </button>
          <div class="paypalBtn" id="paypal-button-container-P-59C59100SH728615CMWE237I" *ngIf="subscribedPackage !== package.pricing_id && package.service_type !== 'self_ltd'" (click)="redirectToCheckout(package.pricing_id)">

          </div>
          <button (click)="paypalSelfSubmit(package.paypal)" class="paypalBtn selfLTDPaypal stacksBtn green" *ngIf="package.service_type == 'self_ltd' && package.paypal">
            {{"Pay with Paypal" | googleTranslate | async | extractGoogleTranslation }}
          </button>
            
          <button class="stacksBtn blue" *ngIf="subscribedPackage == package.pricing_id" (click)="redirectToCheckout(package.pricing_id, true)">
            {{"Purchase another License" | googleTranslate | async | extractGoogleTranslation }}
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</div>