import { Pipe, PipeTransform } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Pipe({
  name: 'multilingual',
})
export class MultilingualPipe implements PipeTransform {
  constructor(private http: HttpClient) {}

  private languageCodes: { [key: string]: string } = {
    'serbo-croatian': 'sh',
    arabic: 'ar',
    armenian: 'hy',
    bengali: 'bn',
    bulgarian: 'bg',
    burmese: 'my',
    chichewa: 'ny',
    chinese_simplified: 'zh-CN',
    chinese_traditional: 'zh-TW',
    croatian: 'hr',
    czech: 'cs',
    danish: 'da',
    dari: 'dar',
    dutch: 'nl',
    en: 'en',
    estonian: 'et',
    fijian: 'fj',
    filipino: 'tl',
    finnish: 'fi',
    french: 'fr',
    frisian: 'fy',
    gaelic: 'gd',
    georgian: 'ka',
    german: 'de',
    hebrew: 'iw',
    hindi: 'hi',
    hungarian: 'hu',
    indonesian: 'id',
    inuktitut: 'iu',
    italian: 'it',
    japanese: 'ja',
    kannada: 'kn',
    khmer: 'km',
    korean: 'ko',
    kyrgyz: 'ky',
    lao: 'lo',
    latvian: 'lv',
    lithuanian: 'lt',
    malagasy: 'mg',
    malay: 'ms',
    malayalam: 'ml',
    maltese: 'mt',
    marathi: 'mr',
    nauru: 'na',
    nepali: 'ne',
    norwegian: 'no',
    pashto: 'ps',
    persian: 'fa',
    portuguese: 'pt',
    punjabi: 'pa',
    quichua: 'qu',
    romanian: 'ro',
    russian: 'ru',
    samoan: 'sm',
    serbian: 'sr',
    sinhala: 'si',
    slovak: 'sk',
    slovenian: 'sl',
    somali: 'so',
    sotho: 'st',
    spanish: 'es',
    sudanese: 'su',
    swahili: 'sw',
    swedish: 'sv',
    tagalog: 'tl',
    tamil: 'ta',
    telugu: 'te',
    thai: 'th',
    tigrinya: 'ti',
    tongan: 'to',
    turkish: 'tr',
    ukrainian: 'uk',
    urdu: 'ur',
    uzbek: 'uz',
    vanuatu: 'bi',
    vietnamese: 'vi',
    welsh: 'cy',
    yiddish: 'yi',
    zulu: 'zu',
    // Add more mappings as needed
  };

  /*
 * transform: Translates a given text to a specified target language using the Google Translation API.
 * - Takes `text` and `targetLanguage` as inputs; trims and normalizes the target language.
 * - If the target language is English, returns the original text as an Observable.
 * - Otherwise, constructs an API request URL with the encoded text and language code.
 * - Fetches the translation from the API and returns the translated text, defaulting to the original text if the translation fails.
 * - Logs any errors from the API and returns the original text in case of errors.
 */

  transform(text: string, targetLanguage: string): Observable<string> {
    const apiKey = 'AIzaSyDZBU79QDwE28lMF9htfBV3cff92KISeLk';
    const encodedText = encodeURIComponent(text);

    // Trim whitespace from target language and convert to lowercase
    targetLanguage = targetLanguage.trim().toLowerCase();
    const langCode = this.languageCodes[targetLanguage] || 'en';
    if (langCode === 'en') {
      return of(text); // Return the original text as an Observable
    }

    const apiUrl = `https://translation.googleapis.com/language/translate/v2?target=${langCode}&key=${apiKey}&q=${encodedText}`;
    return this.http.get(apiUrl).pipe(
      map((response: any) => {
        return response.data.translations[0].translatedText || text; // Default to original text if not found
      }),
      catchError((error) => {
        console.error('Translation API error:', error);
        return of(text);
      })
    );
  }
}
