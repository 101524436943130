import { Component, OnInit } from '@angular/core';
import { BuilderService } from 'src/app/builder-services/builder.service';
import { ShippingService } from './shipping.service';



export interface countriesTableColumns {
  id: string;
  name: string;
  price: string;
  date: string;
  action: string;
}



@Component({
  selector: 'app-shipping',
  templateUrl: './shipping.component.html',
  styleUrls: ['./shipping.component.scss']
})
export class ShippingComponent implements OnInit {

  constructor(public builderService: BuilderService, public shippingService: ShippingService,) { }
  mobile = false;

  countries:any = [
  ];
  saveState = false;
  countriesProgress = false;

  ngOnInit(): void {
    this.shippingService.getCountries().subscribe((countries: any) => {
      if(countries) {
        this.countries = countries;
      }
      if (!Array.isArray(this.countries)) {
        this.countries = [];
      }
    })

    if (window.screen.width <= 768) { // 768px portrait
      this.mobile = true;
    }
  }

  addCountry() {
    
    this.countries.push({ countryId: this.generateUniqueId(), countryName: '', countryPrice: '', governorates: [] });
    this.activateSave();
  }
  removeCountry(index: number) {
    this.countries.splice(index, 1);
    this.activateSave();
  }
  removeGov(country: any, index: number) {
    country.governorates.splice(index, 1);
    this.activateSave();
  }
  
  saveCountries() {
    // You can implement the save functionality here
    this.countriesProgress = true;
    this.shippingService.submitCountry(this.countries).subscribe((result:any) => {
      this.countriesProgress = false;
      this.saveState = false;
      // this.router.navigate(['/products']);
    })
  }
  addGovernorate(gov: any) {
    if (!gov.governorates) {
      gov.governorates = [];
    }
    gov.governorates.push({ governorateId: this.generateUniqueId(), governorateName: '', governoratePrice: '' });
    this.activateSave();
  }
  activateSave() {
    this.saveState = true;
  }

  generateUniqueId = () => {
    const timestamp = new Date().getTime().toString();
    const random = Math.floor(Math.random() * 10000).toString();
    return timestamp + '-' + random;
  }

}
