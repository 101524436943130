<div id="ConnectWrapper" class="containerWrapper">
  <!-- <mat-progress-bar mode="indeterminate" *ngIf="PNProgress"></mat-progress-bar> -->
  <div id="titleContainer">
    <h1>{{'Stacks Connection' | translate }}</h1>
    <div class="howItWorks noselect" (click)="howItWorksDialog()">
      <span class="material-icons-outlined">play_circle_filled</span>
      {{'How it works' | translate }}
    </div>
  </div>
  <div class="containerSecondLine" *ngIf="mobile">
    <mat-expansion-panel hideToggle>
      <mat-expansion-panel-header>
        <h3>{{'Connect your WordPress Website' | googleTranslate | async | extractGoogleTranslation }}</h3>
        <span>
          {{'Click to know more' | googleTranslate | async | extractGoogleTranslation }}
        </span>
      </mat-expansion-panel-header>
      <p>{{'Here you can connect your website with Stacks Builder, simply install the plugin on your website, Add the website link and click Save button, Note: the Website must start with https://' | googleTranslate | async | extractGoogleTranslation }}</p>
    </mat-expansion-panel>
  </div>
  <div class="containerSecondLine" *ngIf="!mobile">
      <h3>{{'Connect your WordPress Website' | googleTranslate | async | extractGoogleTranslation }}</h3>
      <p>{{'Here you can connect your website with Stacks Builder, simply install the plugin on your website, Add the website link and click Save button, Note: the Website must start with https://' | googleTranslate | async | extractGoogleTranslation }}</p>
  </div>
  <div id="ConnectContainer">
    <!-- two boxes that switches between native and wordpress integration -->
    <div class="connectBoxWrapper">
      <div class="connectBox" [ngClass]="{'active': this.builderService.standalone}" (click)="switchNative(true);">
        <div class="connectBoxIcon">
          <img src="assets/images/Icon_for_native.png" alt="">
        </div>
        <h3>{{'Standalone App' | translate }}</h3>
        <p>{{'No Website is required' | translate }}</p>
      </div>
      <div class="connectBox" [ngClass]="{'active': !this.builderService.standalone}" (click)="switchNative(false);">
        <div class="connectBoxIcon">
          <img id="wordpressImg" src="../../assets/images/wordpress.png" alt="">
        </div>
        <h3>{{'WordPress' | translate }}</h3>
        <p>{{'Connect your WordPress website' | translate }}</p>
      </div>
    </div>
    <div id="wordpressWrapper">
    <div id="siteURLWrapper" *ngIf="!this.builderService.standalone">
      <form [formGroup]="connectivity">
        <mat-form-field appearance="outline" id="siteURL">
          <mat-label>{{'Site URL' | translate }}</mat-label>
          <input matInput formControlName="siteURL" placeholder="https://yourawesomewebsite.com" max-length="65" value=""
            type="url"
            pattern="https://.*">
        </mat-form-field>
      </form>
       <button mat-icon-button id="updateConnection" (click)="updateConnectionLink()">
         <span *ngIf="!this.mobile">{{'Connect' | translate }}</span>
         <mat-icon class="material-icons-outlined" *ngIf="this.mobile">done</mat-icon>
       </button>
    </div>
      <table mat-table [dataSource]="connectivityData" *ngIf="!this.builderService.standalone">
        <ng-container matColumnDef="field">
          <th mat-header-cell *matHeaderCellDef>{{'Field' | translate }}</th>
          <td mat-cell *matCellDef="let element"> {{element.field}} 
            <a target="_blank" href="https://wordpress.org/plugins/stacks-mobile-app-builder/" id="downloadPlugin"
              *ngIf="element.field == 'Latest Version' && !mobile">{{'Download Latest Plugin' | translate }}</a>
          </td>
        </ng-container>
        <ng-container matColumnDef="value">
          <th mat-header-cell *matHeaderCellDef> Value </th>
          <td mat-cell *matCellDef="let element">{{element.value}}
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
  <div *ngIf="!this.builderService.standalone" >
  <a target="_blank" href="https://wordpress.org/plugins/stacks-mobile-app-builder/" id="downloadPlugin"
              *ngIf="mobile">{{'Download Latest Plugin' | translate }}</a></div>
  <div *ngIf="connectedLogo && showStatus && !this.builderService.standalone" class="connectedIcon">
    <img src="../../assets/images/connected.png" alt="">
    <p>{{'Website Connected' | translate }}</p>
  </div>
  <div *ngIf="!connectedLogo && showStatus && !this.builderService.standalone" class="connectedIcon">
    <img src="../../assets/images/disconnected.png" alt="">
    <p *ngIf="noConnect">{{'Cant Connect with your website' | translate }}</p>
    <p>
      {{'Site Connection Failed' | translate }}
    </p>
  </div>
  <div id="tourActionBtns"  *ngIf="builderService.getTour()">
    <button *ngIf="this.builderService.getTour()" id="tourNextAction" type="button" (click)="tourNext()">{{'Next' | translate }}</button>
    <button *ngIf="this.builderService.getTour()" class="stacksBtn lightBtn green skipBtn" type="button" (click)="tourNext()">{{'Skip' | translate }}</button>
  </div>
</div>
