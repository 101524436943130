import { Injectable } from '@angular/core';
import { child, get, getDatabase, push, ref, remove, set } from 'firebase/database';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {

  constructor() { }

   /**
   * Generic Setter for Database
   */
  setDatabase = (databasePath: string, data: any, newItem = false) => {
    const db = getDatabase();
    return new Observable<any>((observer) => {
      let dbRef = ref(db, databasePath);
      if( newItem ) {
        dbRef = push(dbRef);
      }
      
    set(dbRef, data)
      .then((response) => {
        observer.next(response); // Notify the observer that the operation is complete
        observer.complete(); // Complete the Observable
      })
      .catch((error) => {
        observer.error(error); // Notify the observer of an error
      });
    });
  };

  /**
   * Generic Getter for Database
   */
  getDatabase = (databasePath: string) => {
    const dbRef = ref(getDatabase());
    return new Observable<any>((observer) => {
      get(child(dbRef, databasePath)).then((snapshot) => {
        if (snapshot.exists()) {
          observer.next(snapshot.val()); // Notify the observer that the operation is complete
          observer.complete(); // Complete the Observable
        } else {
          observer.next({});
          observer.complete(); // Complete the Observable

        }
      }).catch((error) => {
        observer.error(error); // Notify the observer of an error
      });
    });
  }

  /**
   * Remove Database Endpoint
   */

  removeDatabaseEndpoint = (databasePath: string) => {
    const db = getDatabase();
    return new Observable<any>((observer) => {
      remove(ref(db, databasePath))
      .then(() => {
        observer.next({});
        observer.complete(); // Complete the Observable
      })
      .catch((error) => {
        observer.error(error); // Notify the observer of an error
      });
    });
  }
}

