import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BuilderService } from 'src/app/builder-services/builder.service';
import { HelpersService } from 'src/app/helpers.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  options = {
    headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
  };

  loggerURL = environment.loggerURL + 'api/getAppPages/';

  token: any = localStorage.getItem('token');
  project_id = String(localStorage.getItem("selectedProject"));
  builderUrl = this.helper.getBuilderUrl();
  
  constructor(private http: HttpClient, private builderService: BuilderService, private helper: HelpersService) { }

  getPageStats = () => {
    let postData = new URLSearchParams();
    postData.set('token', this.token);
    postData.set('project_id', this.project_id);
    return this.http.post(this.builderUrl +'stacks-get-mobile-stats', postData.toString(), this.options);
  }

  getMobileOpenedCount = () => {
    let postData = new URLSearchParams();
    postData.set('token', this.token);
    postData.set('project_id', this.project_id);
    return this.http.post(this.builderUrl +'stacks-get-mobile-users-count', postData.toString(), this.options);
  }

  getOrders = () => {
    let postData = new URLSearchParams();
    postData.set('token', this.token);
    postData.set('project_id', this.project_id);
    return this.http.post(this.builderUrl +'stacks-get-mobile_orders', postData.toString(), this.options);
  }
}
