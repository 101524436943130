<mat-toolbar color="accent">
    <span>Trials Converted to Sales</span>
</mat-toolbar>
<mat-form-field appearance="outline">
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applySubFilter($event)" placeholder="Ex. ium" #input>
</mat-form-field>


<form [formGroup]="form" class="dateModule" (submit)="applyDateFilter()">
    <mat-form-field>
        <input 
        matInput 
        placeholder="Date From" 
        [matDatepicker]="picker1"
        formControlName="fromDate"
        required
        (click)="picker1.open()"
        >
        <mat-datepicker-toggle 
        matSuffix [for]="picker1"
        ></mat-datepicker-toggle>
        <mat-datepicker 
        #picker1></mat-datepicker>
    </mat-form-field>
    <mat-form-field>
        <input 
        matInput 
        placeholder="toDate" 
        [matDatepicker]="picker2"
        formControlName="toDate"
        required
        (click)="picker2.open()"
        >
        <mat-datepicker-toggle 
        matSuffix [for]="picker2"
        ></mat-datepicker-toggle>
        <mat-datepicker 
        #picker2></mat-datepicker>
    </mat-form-field>
    <button 
    class="dateActionBtn" 
    mat-raised-button color="accent" 
    (click)="applyDateFilter()"
    [disabled]="form.invalid">Submit</button>
    <button mat-raised-button class="dateActionBtn" color="accent" type="button" [disabled]="form.invalid" (click)="clearDateFilter()">Clear</button>
</form>

<table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
    
    <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef  mat-sort-header> date. </th>
    <td mat-cell *matCellDef="let elements"> {{elements.date}} </td>
    </ng-container>

    <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef> E-Mail </th>
    <td mat-cell *matCellDef="let elements"> {{elements.email}} </td>
    </ng-container>

    <ng-container matColumnDef="order_id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Order Id </th>
        <td mat-cell *matCellDef="let elements"> {{elements.order_id}} </td>
    </ng-container>

    <ng-container matColumnDef="apps_number">
        <th mat-header-cell *matHeaderCellDef > Apps Number </th>
        <td mat-cell *matCellDef="let elements"> {{elements.apps_number}} </td>
    </ng-container>

    <ng-container matColumnDef="sale_attribution">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Sale Attribution </th>
        <td mat-cell *matCellDef="let elements"> {{elements.sale_attribution}} </td>
    </ng-container>

    <ng-container matColumnDef="coupon_description">
        <th mat-header-cell *matHeaderCellDef> Coupon Description </th>
        <td mat-cell *matCellDef="let elements"> {{elements.coupon_description}} </td>
    </ng-container>

    <ng-container matColumnDef="coupon_name">
        <th mat-header-cell *matHeaderCellDef > coupon_name </th>
        <td mat-cell *matCellDef="let elements"> {{elements.coupon_name}} </td>
    </ng-container>

    <ng-container matColumnDef="product_name">
        <th mat-header-cell *matHeaderCellDef> Product Name </th>
        <td mat-cell *matCellDef="let elements"> {{elements.product_name}} </td>
    </ng-container>

    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef  mat-sort-header> Status </th>
        <td mat-cell *matCellDef="let elements"> {{elements.status}} </td>
    </ng-container>

    <ng-container matColumnDef="value">
        <th mat-header-cell *matHeaderCellDef  mat-sort-header> Value </th>
        <td mat-cell *matCellDef="let elements"> {{elements.value}} </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="salesColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: salesColumns;"></tr>
</table>
<mat-paginator [pageSizeOptions]="[5, 10, 20, 50, 100]"
            showFirstLastButtons 
            aria-label="Select page of periodic dataSource">
</mat-paginator>

<div class="highlightedArea">
    Conversion Rate {{conversionRate}}%
</div>