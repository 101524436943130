import { Component, Input, OnInit } from '@angular/core';
import { BlockFieldsService } from 'src/app/block-services/block-fields.service';
import { BuilderService } from 'src/app/builder-services/builder.service';
import { BlockDataService } from '../../block-services/block-data.service';
import { CategoriesService } from '../block-categories/categories.service';
import { ProductsService } from './products.service';
import { MixpanelService } from 'src/app/tracking/mixpanel.service';

@Component({
  selector: 'block-products',
  templateUrl: './block-products.component.html',
  styleUrls: ['./block-products.component.scss']
})
export class BlockProductsComponent implements OnInit {

  previewBlock = false;
  styleFields = false;
  blockData: any;
  /**
   * If the block is rendered from saved data, then this should be true, if added dynamically by drag and drop or click then it will be "initial"
   **/ 
  initialData:any = false;
  previewFields = false;

  blockFields: any;

  blockId = '';

  blockProducts = '';

  productsData = [];
  currency : any;
  categories = [];

  @Input() nestedBlock: any = {};
  @Input() importedBlockData: any;
  showCategoriesList = false;
  constructor(
    public blockDataService: BlockDataService, 
    private blockFieldsService: BlockFieldsService, 
    private productsService: ProductsService,
    public builderService: BuilderService,
    private categoriesService: CategoriesService,
    private mixPanel: MixpanelService
    ) {

    };

  ngOnInit() {
    if(this.importedBlockData ) {
      this.previewBlock = true;
      this.previewFields = false;
      this.styleFields = false;
    }
    let projectId = this.builderService.getSelectedProject();
    if (this.initialData !== 'initial' ) {
      if (this.nestedBlock.type == "products") {
        this.blockData = this.nestedBlock;
        this.blockId = this.nestedBlock.id;
        this.previewBlock = true;
      } else {
        this.blockData = this.importedBlockData ? this.importedBlockData : this.blockFieldsService.getProductsBlockFields();
        if (!this.blockId) {
          this.blockId = this.blockDataService.getUniqueId(2);
        }
      }
      this.getProducts(projectId, this.blockData.data, false);
    } else {
      this.blockData = this.blockDataService.getSelectedBlockData(this.blockId);
      this.getProducts(projectId, this.blockData.data, true);
    }
    this.blockProducts = this.blockData.data.value;
    let categories: any
    if (localStorage.getItem('categories')) {
      categories = localStorage.getItem('categories');
      categories = JSON.parse(categories);
    } else {
      this.categoriesService.getInitialCategories();
    }
    this.setCategoriesData(this.blockId, categories);
    this.builderService.showSettingsProgress = false;
    this.refetchData();  
  }

  getProducts = (projectId: number, args: object, cached = true) => {
    this.builderService.showSettingsProgress = true;
    if( cached && this.blockDataService.productsCached ) {
      this.productsService.getProductsCached().subscribe((response) => {
        this.builderService.showSettingsProgress = false;
        this.setProductsData(this.blockId, response.products);
        let categories: any = [];
        for (const key in response.categories) {
          if (Object.prototype.hasOwnProperty.call(response.categories, key)) {
            const element = response.categories[key];
            if(element.count > 0) {
              categories.push({ 'id': element.term_id, "name": element.name });
            } 
          }
        }
        this.currency = response.currency_symbol;
      }, (err) => {
      },
      () => {
        // The observable completed without emitting any values, it is empty
        console.log('Observable is empty');
      });
    } else {
      this.productsService.getProducts(projectId, args).subscribe((response) => {
        this.builderService.showSettingsProgress = false;
        this.setProductsData(this.blockId, response.products);
        let categories: any = [];
        for (const key in response.categories) {
          if (Object.prototype.hasOwnProperty.call(response.categories, key)) {
            const element = response.categories[key];
            if(element.count > 0) {
              categories.push({ 'id': element.term_id, "name": element.name });
            } 
          }
        }
        this.currency = response.currency_symbol;
      }, (err) => {
  
      });
    }

    return true;
  }

  /**
   * Getter for the Products Data, The case of changing the modifying in one component and reflects into another component needs getters and setters
   * @param blockId 
   * @returns 
   */
  getProductsData = (blockId: string) => {
    if(this.importedBlockData) {
      return this.importedBlockData.productsData;
    }
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      return;
    }
    return this.blockData.productsData;
  }

  /**
   * Setter for the Products Data, The case of changing the modifying in one component and reflects into another component needs getters and setters
   * @returns
   */
  setProductsData = (blockId: string, data: object) => {
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      return;
    }
    this.blockData = this.blockDataService.getSelectedBlockData(blockId);
    this.blockData.productsData = data;
    console.log(this.blockData);
  }

  /**
   * Getter for the Categories, The case of changing the modifying in one component and reflects into another component needs getters and setters
   * @param blockId 
   * @returns 
   */
  getCategoriesData = (blockId: string) => {
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      return;
    }
    let productsBlockFields = this.blockDataService.getSelectedBlockData(blockId);
    return productsBlockFields.categories;
  }

  /**
   * Setter for the Categories, The case of changing the modifying in one component and reflects into another component needs getters and setters
   * @returns
   */
  setCategoriesData = (blockId: string, data: object) => {
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      return;
    }
    let productsBlockFields = this.blockDataService.getSelectedBlockData(blockId);
    productsBlockFields.categories = data;
  }
  /**
   * Appends Block to the Preview, Initial checks if the block is added as initial block addition or appended live
   */
  addBlockToPreview(initial: any = '') {
    let blockProductsData = {
      component: BlockProductsComponent,
      index: 'latest',
      element: this,
      initial: initial
    };
    this.blockDataService.sendBlockData(blockProductsData);
    this.builderService.blocksCollapsed = true;
  }

  appendBlock($event: any) {
    if( !this.blockDataService.woocommerceActive || this.builderService.standalone ) {
      return false;
    }
    if(this.builderService.getTour()) {
      this.builderService.onboardingBlockAdded = true;
    }
    let clonedBlockData = { ...this.blockData };
    this.blockId = this.blockDataService.appendBlocksData(clonedBlockData);
    this.addBlockToPreview();
    this.builderService.logData('Products Block Added', 'Block Add', 'Prodicts Block Added').subscribe((response) => {

    }, (err) => {

    });
    this.mixPanel.track('Block Added', {
      projectid: this.builderService.getSelectedProject(),
      block: 'products',
    });
    return true;
  }

  /**
   * Sync the clicked block on click inside the mobile preview
   */
  showData(blockId: any) {
    // Check Block Availability before taking action
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      // show data of the last element instead
      blockId = this.blockDataService.getLatestBlockId();
    }
    this.blockFieldsService.clickedBlockId = blockId;
    this.blockDataService.sendCurrentBlock(BlockProductsComponent);
  }

  queryChange(query: any, blockId: any, $event: any, type: string) {
    // Check Block Availability before taking action
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      return;
    }
    let productsBlockFields = this.blockDataService.getSelectedBlockData(blockId);
    /**
     * Modifying Nested Object Directly doesn't work, so we have to clone the data object first then modify it
     */
    let clonedBlockFieldsData = { ...productsBlockFields.data };
    let data = '';
    if (type == 'select') {
      data = $event.value
    } else if (type == 'text') {
      data = $event.currentTarget.value;
    } else if (type == 'slider') {
      data = $event.currentTarget.value;
    }
    
    clonedBlockFieldsData[query] = data;
    productsBlockFields.data = clonedBlockFieldsData;

    if (productsBlockFields.data.query_post_type == 'by_category') {
      this.showCategoriesList = true;
    } else {
      this.showCategoriesList = false;
    }
    // this.args = clonedBlockFieldsData;
    let projectId = this.builderService.selectedProject;

    this.getProducts(projectId, clonedBlockFieldsData, false);
  }

  styleChange(style: any, blockId: any, $event: any, type: string) {
    // Check Block Availability before taking action
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      return;
    }
    let productsBlockFields = this.blockDataService.getSelectedBlockData(blockId);
    /**
     * Modifying Nested Object Directly doesn't work, so we have to clone the data object first then modify it
     */
    let clonedBlockFieldsData = { ...productsBlockFields.style };
    let data = '';
    if (type == 'select') {
      data = $event.value
    } else if (type == 'text') {
      data = $event.currentTarget.value;
    } else if (type == 'slider') {
      data = $event.currentTarget.value + 'px';
    }

    clonedBlockFieldsData[style] = data;
    productsBlockFields.style = clonedBlockFieldsData;
  }

  getBlockFields = (id: string) => {
    if(this.importedBlockData) {
      return this.importedBlockData;
    }
    return this.blockDataService.getSelectedBlockData(id);
  }

  showStyleTab = () => {
    this.previewBlock = false;
    this.previewFields = false;
    this.styleFields = true;
  }

  showDataTab = () => {
    this.previewBlock = false;
    this.previewFields = true;
    this.styleFields = false;
  }

  checkSidebar = () => {
    if (!this.previewBlock && !this.previewFields && !this.styleFields) {
      return true;
    }
    return false;
  }

  checkPreview = () => {
    if (this.previewBlock && !this.previewFields && !this.styleFields) {
      return true;
    }
    return false;
  }

  checkData = () => {
    if (this.previewFields) {
      return true;
    }
    return false;
  }

  checkStyle = () => {
    if (this.styleFields && !this.previewFields && !this.previewBlock) {
      return true;
    }
    return false;
  }

  checkSettings = () => {
    if (!this.checkSidebar() && !this.checkPreview()) {
      return true;
    }
    return false;
  }

  /** 
  * Deletes the Block from the mobile Preview
  * Deletes the Block from the JSON Object
  */
  deleteBlock = (blockId: any) => {
    this.blockDataService.removeBlockData(blockId);
  }

  /**
   * Refetch the Data of the Block from the customer server and refills the localstorage
   */
   refetchData = () => {
    this.getProducts(this.builderService.getSelectedProject(), this.blockData.data, false);
    this.categoriesService.getInitialCategories();
   }
}
