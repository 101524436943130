import { Injectable } from '@angular/core';
import { BlockDataService } from '../../block-services/block-data.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { HelpersService } from '../../helpers.service';
import { BuilderService } from 'src/app/builder-services/builder.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PostsService extends BlockDataService {

  private builderUrl;

  projects = {};
  selectedProject = 0;
  headerBtn = '';
  options = {
    headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
  };
  token: any;
  storedCategories: any = [];
  constructor(private http: HttpClient, public helpers: HelpersService, private builderService: BuilderService) {
    super();
    this.token = this.builderService.getToken();
    this.builderUrl = this.helpers.getBuilderUrl();
  }


  /**
   * Get all templates from Backend
   */
  getPosts = (id: any, args: any): Observable<any> => {
    this.token = this.builderService.getToken();
    let postData = new URLSearchParams();
    postData.set('process', "sync_ui");
    postData.set('token', this.token);
    postData.set('projectId', id);
    postData.set('args', JSON.stringify([args]));

    return this.http.post(this.builderUrl + 'getPosts', postData.toString(), this.options);
  }

  /**
   * get posts once at the start of the application and save it inside the localstorage to use it in the products and posts blocks
   * We need to set it inside the after view init to make sure that the Token is set
   */
  getInitialPosts = () => {
    let args: object = {
      'posts': [],
      'hide_empty': true,
      'orderby': 'date',
      'order': 'DESC',
      'source': 'by_id',
      'limit': '200' // a general high number to retrieve all
    };
    this.getPosts(this.builderService.getSelectedProject(), args).subscribe((response) => {
      let posts: any = [];
      for (const key in response) {
        if (Object.prototype.hasOwnProperty.call(response, key)) {
          const element = response[key];
          if (element.count > 0) {
            posts.push({ 'id': element.term_id, "name": element.name, "thumbnail": element.image_url });
          }
        }
      }
      localStorage.setItem('posts', JSON.stringify(posts));
    }, (err) => {

    });
  }

  /**
   * get categories once at the start of the application and save it inside the localstorage to use it in the products and categories blocks
   * We need to set it inside the after view init to make sure that the Token is set
   */
  getInitialCategories = () => {
    let args: object = {
      'categories': [],
      'hide_empty': true,
      'orderby': 'date',
      'order': 'DESC',
      'source': 'by_id',
      'limit': '200' // a general high number to retrieve all
    };
    this.getCategories(this.builderService.getSelectedProject(), args).subscribe((response) => {
      let categories: any = [];
      for (const key in response) {
        if (Object.prototype.hasOwnProperty.call(response, key)) {
          const element = response[key];
          if (element.count > 0) {
            categories.push({ 'id': element.term_id, "name": element.name, "thumbnail": element.image_url });
          }
        }
      }
      localStorage.setItem('postsCategories', JSON.stringify(categories));
      this.storedCategories = categories;
    }, (err) => {

    });
  }

  /**
   * Get all templates from Backend
   */
  getCategories = (id: any, args: any, type: any = 'post'): Observable<any> => {
    if(type == 'pmpro_course') {
      return this.http.get(this.builderService.selectedProjectDomain + '/wp-json/wp/v2/pmpro_course_category?per_page=100');
    }
    let websiteUrl = this.builderService.selectedProjectDomain;
    if(this.builderService.standalone) {
      websiteUrl = args['wordpress_url'];
    }
    if(websiteUrl == undefined) {
      return of(false);
    }
    websiteUrl = environment.proxyUrl + websiteUrl;
    return this.http.get(websiteUrl + '/wp-json/wp/v2/categories?per_page=100');
  }

  /**
   * Get all post types from Backend
   */
   getPostTypes = (): Observable<any> => {
    if( !this.builderService.selectedProjectDomain ) {
      return of(false);
    }
    let websiteUrl = environment.proxyUrl + this.builderService.selectedProjectDomain;
    return this.http.get(websiteUrl + '/wp-json/wp/v2/types');
  }
}
