
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { CloudService } from '../cloud.service';
import {MatSort, Sort} from '@angular/material/sort';
import { ChartConfiguration, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { BuilderService } from 'src/app/builder-services/builder.service';

export interface unSubscribersTableColumns {
  name: string;
  date: string;
  id: number;
  mail: string;
  value: string;
}


let ELEMENT_DATA: unSubscribersTableColumns[] = [];

@Component({
  selector: 'cloud-unsubs',
  templateUrl: './cloud-unsubs.component.html',
  styleUrls: ['./cloud-unsubs.component.scss']
})
export class CloudUnsubsComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatPaginator) subPaginator!: MatPaginator;
  @ViewChild(MatTable)
  table!: MatTable<unSubscribersTableColumns>;

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;
  
  constructor(private cloudService: CloudService, public builderService: BuilderService) { }
  public unSubscribersColumns: string[] = [];
  public unSubDataSource: any = [];
  public subscribersColumns: string[] = [];
  public subDataSource: any = [];
  unsubs_dates: any = [];
  unsubs_data_counts: any = [];
  subs_dates: any = [];
  subs_data_counts: any = [];
  ngAfterViewInit() {
    this.unSubDataSource.paginator = this.paginator;
    this.subDataSource.paginator = this.subPaginator;
  }

  
  ngOnInit(): void {
    this.getUnsubscribers();
  }

  /**
   * Fill Unsubscribers Table
   */
  getUnsubscribers = () => {
    this.builderService.showUpdateProgress = true;
    this.unSubscribersColumns = ['date', 'name', 'id', 'mail', 'value'];
    ELEMENT_DATA = [];
    let cloudUnsubs = localStorage.getItem('cloudUnsubs');
    if(cloudUnsubs) {
      this.renderUnsubscribers(JSON.parse(cloudUnsubs));
    } else {
      this.fetchNewUnSubscribers();
    }
  }
  fetchUnSubscribers = () => {
    localStorage.removeItem('cloudUnsubs');
    this.getUnsubscribers();
  }
  /**
   * Retrieves the new subscribers from the server
   */
   fetchNewUnSubscribers = () => {
    this.cloudService.getUnsubscribers().subscribe((result: any) => {
      localStorage.setItem('cloudUnsubs',JSON.stringify(result));
      this.renderUnsubscribers(result);
    });
  }
  /**
   * Renders the subscriptions data
   */
  renderUnsubscribers = (result: any) => {
      this.builderService.showUpdateProgress = false;
      let all_unsubs = result.data.all_unsubs;
      all_unsubs = Object.entries(all_unsubs).sort().reduce( (o:any,[k,v]) => (o[k]=v,o), {} );
      this.unsubs_data_counts = [];
      this.unsubs_dates = [];
      for (const key in all_unsubs) {
        if (Object.prototype.hasOwnProperty.call(all_unsubs, key)) {
          const element = all_unsubs[key];
          this.unsubs_dates.push(key);
          this.unsubs_data_counts.push(element.length);

          this.cloudService.allUnSubsData[key.substring(0,7)] = element.length;

          for (const key_data in element) {
            if (Object.prototype.hasOwnProperty.call(element, key_data)) {
              const element_data = element[key_data];
              ELEMENT_DATA.push(
                {date: element_data.date, name: element_data.paid_unsubs_name, id: element_data.paid_unsubs_sub_id, mail: element_data.paid_unsubs_mail, value: element_data.paid_unsubs_value},
              );
            }
          }
          
        }
      }
      this.unSubDataSource = new MatTableDataSource<unSubscribersTableColumns>(ELEMENT_DATA);
      this.unSubDataSource.paginator = this.paginator;
      this.unSubDataSource.sort = this.sort;
      this.unSubChartData.labels = this.unsubs_dates;
      this.unSubChartData.datasets[0].data = this.unsubs_data_counts;
      this.chart?.update();
      setTimeout(() => {
        this.table.renderRows();
      }, 1000);
    };
    applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.unSubDataSource.filter = filterValue.trim().toLowerCase();
    }
  
  
    public unSubChartData: ChartConfiguration['data'] = {
      datasets: [
        {
          data: [ ],
          label: 'Unsubscribers',
          yAxisID: 'y-axis-1',
          backgroundColor: 'rgba(255,0,0,0.3)',
          borderColor: 'red',
          pointBackgroundColor: 'rgba(148,159,177,1)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(148,159,177,0.8)',
          fill: 'origin',
        }
      ],
      // labels: [ 'January', 'February', 'March', 'April', 'May', 'June', 'July' ]
    };
  
    public unSubChartOptions: ChartConfiguration['options'] = {
      elements: {
        line: {
          tension: 0.5
        }
      },
      scales: {
        // We use this empty structure as a placeholder for dynamic theming.
        x: {},
        'y-axis-1': {
          position: 'right',
          grid: {
            color: 'rgba(255,0,0,0.3)',
          },
          ticks: {
            color: 'red'
          }
        }
      },
    };

}


