import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { BlockDataService } from '../block-services/block-data.service';
import { BlockImageComponent } from './block-image/block-image.component';
import { BlockTextComponent } from './block-text/block-text.component';
import { BlockSectionComponent } from './block-section/block-section.component';
// import { BlockCategoriesComponent } from './block-categories/block-categories.component';
import { BlockHeaderComponent } from './block-header/block-header.component';
import { BlockFieldsService } from '../block-services/block-fields.service';
import { BlockVideoComponent } from './block-video/block-video.component';
import { BlockSliderComponent } from './block-slider/block-slider.component';
import { environment } from 'src/environments/environment';
import { BlockPostsComponent } from './block-posts/block-posts.component';
import { BlockButtonComponent } from './block-button/block-button.component';
import { LoginService } from '../login/login.service';
import { BuilderService } from '../builder-services/builder.service';
import { BlockStaticComponent } from './block-static/block-static.component';
import { BlockFooterComponent } from './block-footer/block-footer.component';
import { MatDialog } from '@angular/material/dialog';
import { WorksDialogComponent } from '../pages/works-dialog/works-dialog.component';
import { StacksProductsComponent } from './stacks-products/stacks-products.component';
import { StacksCategoriesComponent } from './stacks-categories/stacks-categories.component';
import { DatabaseService } from '../builder-services/database.service';
import { BlockProductsComponent } from './block-products/block-products.component';
import { BlockCategoriesComponent } from './block-categories/block-categories.component';

@Component({
  selector: 'blocks-wrapper',
  templateUrl: './blocks-wrapper.component.html',
  styleUrls: ['./blocks-wrapper.component.scss'],
})
export class BlocksWrapperComponent implements OnInit, AfterViewInit {

  @ViewChild(BlockTextComponent)
  blockTextComponent!: BlockTextComponent;

  @ViewChild(BlockImageComponent)
  blockImageComponent!: BlockImageComponent;

  @ViewChild(BlockProductsComponent)
  blockProductsComponent!: BlockProductsComponent;
  
  @ViewChild(BlockCategoriesComponent)
  blockCategoriesComponent!: BlockCategoriesComponent;

  @ViewChild(StacksProductsComponent)
  StacksProductsComponent!: StacksProductsComponent;

  @ViewChild(StacksCategoriesComponent)
  StacksCategoriesComponent!: StacksCategoriesComponent;

  @ViewChild(BlockHeaderComponent)
  blockHeaderComponent!: BlockHeaderComponent;

  @ViewChild(BlockSectionComponent)
  blockSectionComponent!: BlockSectionComponent;

  @ViewChild(BlockVideoComponent)
  blockVideoComponent!: BlockVideoComponent;

  @ViewChild(BlockSliderComponent)
  blockSliderComponent!: BlockSliderComponent;

  @ViewChild(BlockPostsComponent)
  blockPostsComponent!: BlockPostsComponent;

  @ViewChild(BlockButtonComponent)
  blockButtonComponent!: BlockButtonComponent;
  
  @ViewChild(BlockStaticComponent)
  blockStaticComponent!: BlockStaticComponent;

  @ViewChild(BlockFooterComponent)
  blockFooterComponent!: BlockFooterComponent;

  mobile = false;
  environment = environment;
  allBlocks:any;
  constructor( 
    public blockDataService: BlockDataService, 
    private blockFieldsService: BlockFieldsService, 
    public builderService: BuilderService, 
    public loginService: LoginService,
    public dialog: MatDialog,
    private db: DatabaseService ) {
      /**
       * whenever setRenderAllBlocks is called we need to resubmit the block data
       */
    this.blockDataService.getRenderAllBlocks().subscribe(()=> {
      let localSelectedView:any = localStorage.getItem("selectedView");
      this.blockDataService.selectedView = JSON.parse(localSelectedView);
      this.allBlocks = this.blockDataService.blocksData;
      this.initialBlocksDrawer();
      // this.selectedViewGetter();
    });
  }

  ngOnInit() {
    if (window.screen.width <= 768) { // 768px portrait
      this.mobile = true;
    }
  }
  /**
   * Switching between Views calls this function
   */
  ngAfterViewInit() {
    Promise.resolve().then(() => {
      this.builderService.showUpdateProgress = true;
      let localSelectedView:any = localStorage.getItem("selectedView");
      this.blockDataService.sendSelectedView(JSON.parse(localSelectedView));
      // setTimeout(() => {
      //   this.introPopup();  
      // }, 2000);
      this.selectedViewGetter();
    } );
    
  }

  introPopup = () => {
    if( this.builderService.user_type == 'self_ltd' && localStorage.getItem('mainScreen_Self_LTD') !== 'closed' ) {
      let dialogRef = this.dialog.open(WorksDialogComponent, {
        width: '700px',
        maxWidth: '80%',
        data: {name: "mainScreen_Self_LTD"},
      });
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        localStorage.setItem('mainScreen_Self_LTD', 'closed');
      });
    }

  }

  selectedViewGetter = () => {
    if( this.blockDataService.selectedView ) {
      let requested_view_name = this.blockDataService.selectedView ? this.blockDataService.selectedView.view_name : 'home';
      let requested_view_id = this.blockDataService.selectedView ? this.blockDataService.selectedView.view_id : 1;
      let requestedViewTitle = this.blockDataService.selectedView ? this.blockDataService.selectedView.title : 'home';
      this.blockDataService.sendSelectedView({view_id:requested_view_id,view_name:requested_view_name, title: requestedViewTitle});
        /**
         * Retrieve the data from the Database and replace the blocks data variable
         */
        this.db.getDatabase('projects/' + this.builderService.selectedProject + '/settings/standalone').subscribe((response) => {
          this.builderService.standalone = response;
          this.builderService.getView( requested_view_id ).subscribe((blocksData: any) => {
            // check if blocksData is parsable
            if(typeof blocksData == 'string') {
              blocksData = JSON.parse(blocksData);
            }
            // if the blocks data is directly having data, then we have to use it
            if( blocksData[0] && blocksData[0]?.type == 'header' ) {
              let newBlocksData:any = [];
              newBlocksData.data = blocksData;
              blocksData = newBlocksData;
            }
            if( blocksData.data && blocksData.data !== "null" && blocksData.data.length ) {

              this.allBlocks = blocksData.data;
              this.blockDataService.blocksData = this.allBlocks;
            } else if ( blocksData.allBlocks && blocksData.allBlocks !== "null" && blocksData.allBlocks.length ) {

              this.allBlocks = blocksData.allBlocks;
              this.blockDataService.blocksData = this.allBlocks;
            
            } else {
              // The database has empty data here, we have to fill it and submit
              // switch (requested_view_name) {
              //   case 'categoryView':
              //     this.blockDataService.blocksData = this.blockDataService.categoryView;
              //     break;
              //   case 'singleView':
              //     this.blockDataService.blocksData = this.blockDataService.singleView;
              //     break;
              //   case 'loginView':
              //     this.blockDataService.blocksData = this.blockDataService.loginView;
              //     break;
              //   case 'signupView':
              //     this.blockDataService.blocksData = this.blockDataService.signupView;
              //     break;
              //   case 'forgotView':
              //     this.blockDataService.blocksData = this.blockDataService.forgotView;
              //     break;
              //   case 'myAccountView':
              //     this.blockDataService.blocksData = this.blockDataService.myAccountView;
              //     break;
              //   case 'singleBlogPostView':
              //     this.blockDataService.blocksData = this.blockDataService.singleBlogPostView;
              //     break;
              //   case 'cartView':
              //     this.blockDataService.blocksData = this.blockDataService.cartView;
              //     break;
              //   case 'checkoutView':
              //     this.blockDataService.blocksData = this.blockDataService.checkoutView;
              //     break;
              //   case 'aboutView':
              //     this.blockDataService.blocksData = this.blockDataService.aboutView;
              //     break;
              //   case 'contactView':
              //     this.blockDataService.blocksData = this.blockDataService.contactView;
              //     break;
              //   case 'termsView':
              //     this.blockDataService.blocksData = this.blockDataService.termsView;
              //     break;
              //   default:
              //     // this.blockDataService.blocksData = this.blockDataService.defaultView;
              //     break;
              // }
              // this.allBlocks = this.blockDataService.blocksData;
              // // Now update the project in the database
              // this.builderService.updateProject(this.builderService.selectedProject, this.blockDataService.blocksData).subscribe((project) => {
              // }, (err) => {
              //   console.log(err);
              // });
            }
            this.initialBlocksDrawer();
          }, (err) => {
            // console.log(err);
          });
        });
    } else {
      this.builderService.getView( 1 ).subscribe((blocksData: any) => {
        this.allBlocks = blocksData.data;
        this.blockDataService.blocksData = this.allBlocks;
        this.initialBlocksDrawer();
      }, (err) => {
        // console.log(err);
      });
    }
  }


  initialBlocksDrawer = () => {
    let allBlocks = this.allBlocks;
    if (!allBlocks || allBlocks.length == 0) {
      return;
    }
    this.blockDataService.blockId = this.blockDataService.getUniqueId(2);
    if (allBlocks[0].type !== 'header') {
      let headerData = this.blockFieldsService.getHeaderBlockFields();
      headerData.id = this.blockDataService.getUniqueId(2);
      allBlocks.unshift(headerData);
    }

    if (allBlocks[allBlocks.length - 1].type !== 'footer') {
      let footerData = this.blockFieldsService.getFooterBlockFields();
      footerData.id = this.blockDataService.getUniqueId(2);
      allBlocks.push(footerData);
    }
    /**
     * Clear All Blocks in the Mobile Preview
     */
    let mobileBlocks = document.querySelectorAll('#mainMobileAppPreview .ng-star-inserted');
    Array.prototype.forEach.call(mobileBlocks, function (node) {
      node.parentNode.removeChild(node);
    });
// 
    // console.log(mobileBlocks);
    for (const key in allBlocks) {
      if (Object.prototype.hasOwnProperty.call(allBlocks, key)) {
        const element = allBlocks[key];
        // The real id of the element should be the same as the id inside the all blocks data
         this.blockDataService.blockId = element.id;
        if(this.builderService.globals) {
          if (element.type == "section") {
            this.blockSectionComponent.addBlockToPreview('initial');
          }
          switch (element.type) {
            case "header":
              if(key == '0' ) {
                this.blockHeaderComponent.addBlockToPreview('initial');
              } else {
                this.blockDataService.blocksData.splice(key,1);
              }
              
            break;
            case "text":
              this.blockTextComponent.addBlockToPreview('initial');
              break;
            case "image":
              this.blockImageComponent.addBlockToPreview('initial');
              break;
              case "stacksProducts":
                this.StacksCategoriesComponent ? this.StacksProductsComponent.addBlockToPreview('initial') : '';
                break;
              case "stacksCategories":
                this.StacksCategoriesComponent ? this.StacksCategoriesComponent.addBlockToPreview('initial') : '';
                break;
              case "video":
                this.blockVideoComponent.addBlockToPreview('initial');
                break;
              case "slider":
                this.blockSliderComponent.addBlockToPreview('initial');
                break;
              case "button":
                  this.blockButtonComponent.addBlockToPreview('initial');
                  break;
            case "footer":
              this.blockFooterComponent.addBlockToPreview('initial');
              break;
          }
        } else {
          if (element.type == "section") {
            this.blockSectionComponent.addBlockToPreview('initial');
          }
          switch (element.type) {
            case "text":
              this.blockTextComponent.addBlockToPreview('initial');
              break;
            case "image":
              this.blockImageComponent.addBlockToPreview('initial');
              break;
            case "stacksProducts":
              this.StacksProductsComponent.addBlockToPreview('initial');
              break;
            case "stacksCategories":
              this.StacksCategoriesComponent.addBlockToPreview('initial');
              break;
            case "products":
              this.blockProductsComponent.addBlockToPreview('initial');
              break;
            case "categories":
              this.blockCategoriesComponent.addBlockToPreview('initial');
              break;
            case "header":
              if(key == '0' ) {
                this.blockHeaderComponent.addBlockToPreview('initial');
              } else {
                this.blockDataService.blocksData.splice(key,1);
              }
              
              break;
            case "footer":
              this.blockFooterComponent.addBlockToPreview('initial');
              break;
            case "video":
              this.blockVideoComponent.addBlockToPreview('initial');
              break;
            case "slider":
              this.blockSliderComponent.addBlockToPreview('initial');
              break;
            case "posts":
              this.blockPostsComponent.addBlockToPreview('initial');
              break;
            case "button":
                this.blockButtonComponent.addBlockToPreview('initial');
                break;
            case "static":
              // this.blockStaticComponent.test();
              this.blockStaticComponent.addBlockToPreview('initial');
              // this.blockButtonComponent.addBlockToPreview('initial');
              break;
            default:
              break;
          }
        }
      }
    }
    this.builderService.showUpdateProgress = false;
    this.blockDataService.sendGlobalHeader(this.builderService.globalHeader);
    this.blockDataService.sendGlobalFooter(this.builderService.globalFooter);
  };

  blocksHome = () => {
    this.builderService.blocksCollapsed = !this.builderService.blocksCollapsed;
  }

  howItWorksDialog = () => {
    let dialogRef = this.dialog.open(WorksDialogComponent, {
      width: '700px',
      maxWidth: '80%',
      data: {name: "mainScreen"},
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
}
