import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockDataService } from 'src/app/block-services/block-data.service';
import { BuilderService } from 'src/app/builder-services/builder.service';
import { LoginService } from 'src/app/login/login.service';
import { AdminService } from './admin.service';
import { AppsUploadedService } from '../admin-analytics/apps-uploaded/apps-uploaded.service';

@Component({
  selector: 'admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  loginProjectDomain = '';

  response : any;

  loginProgress = false;
  loginError = false;

  constructor(public adminService: AdminService,
    public blockDataService: BlockDataService,
    private router: Router,
    private route: ActivatedRoute,
    public builderService: BuilderService,
    public loginService: LoginService,
    public appsUploadedService: AppsUploadedService ) { }

  ngOnInit(): void {
    this.appsUploadedService.getAllApps().subscribe((response: any) => {
    });
  }


  updateLoginValue = (event: any) => {
    this.loginProjectDomain = event.currentTarget.value;
  }
  loginAsUser = () => {
    this.loginProgress = true;
    this.loginService.logout();
    this.adminService.loginAsUser(this.loginProjectDomain).subscribe((response) => {
      this.loginProgress = false;
      this.loginError = false;
      localStorage.setItem('token',response.token);
      this.blockDataService.sendTokenData(response.token);
      let returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');
      response.tour_ended == "1" ? this.builderService.setTour(false) : this.builderService.setTour(true);
      
      if (!response.tour_ended ) {
        this.router.navigate(['/site-type']);
        // this.router.navigate(['/templates']);
      } else {
        this.router.navigate([returnUrl || '/'], { relativeTo: this.route });
      }
    }, () => {
      this.loginProgress = false;
      this.loginError = true;
    });
  }
}
