<div class="productsWrapper">
    <div class="addProductWrapper" *ngIf="(products | keyvalue).length === 0 && this.pageLoaded">
        <div class="imageWrapper">
            <img src="assets/images/products/add-product.png" alt="">
        </div>
        <button [routerLink]="['new-product']">{{'Add New Product' | translate }}</button>
    </div>
    <div *ngIf="(products | keyvalue).length !== 0 && this.pageLoaded" class="tableWrapper">
        <app-stepper step="4" *ngIf="builderService.getTour()"></app-stepper>
        <div class="tableHeader">
                <h1 *ngIf="!mobile">{{'Products' | translate }}</h1>
                <div class="headerBtns">
                    <button mat-flat-button
                        (click)="deleteCheckedItems()"
                        *ngIf="hasCheckedItems"
                        class="deleteButton">
                        {{'Delete' | translate }}
                    </button>
                    <button [routerLink]="['new-product']" class="stacksBtn blue" [ngClass]="builderService.getTour() ? 'glow' : ''">{{'Add New Product' | translate }}</button>
                    <form class="searchBar">
                        <input
                        (input)="applyFilter()"
                        [(ngModel)]="searchText"
                        data-search
                        matInput
                        name="searchText"
                        placeholder="{{ 'Search' | translate }}"
                        type="text"
                    />
                    </form>
                  </div>                  
        </div>
        <!-- <mat-form-field appearance="outline">
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applySubFilter($event)" placeholder="Ex. ium" #input>
        </mat-form-field> -->
        <!-- <button mat-raised-button class="fetchData" (click)="fetchproducts()">Fetch New Data</button> -->
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort (matSortChange)="announceSortChange($event)">
            <!-- Position Column -->
            <ng-container matColumnDef="image">
                <th mat-header-cell *matHeaderCellDef class="firstTitle">
                    <mat-checkbox #selectAllCheckbox (change)="toggleSelectAllItems()" class="selectAllCheckbox"></mat-checkbox>
                    {{'Image' | translate }} </th>
                <td mat-cell *matCellDef="let elements" class="checkbox-container">
                    <mat-checkbox class="checkbox" [ngModelOptions]="{standalone: true}" [(ngModel)]="elements.checked" (change)="onCheckboxItem()"></mat-checkbox>
                    <div class="productImgContainer">
                        <img *ngIf="elements.image" src="{{elements.image}}" class="image"/>
                        <img *ngIf="!elements.image" src="assets/images/empty-image.png" alt="">
                    </div>
                </td>
              </ng-container>
              
        
            <!-- Weight Column -->
            <ng-container matColumnDef="name" >
            <th mat-header-cell *matHeaderCellDef id="name" mat-sort-header="name" sortActionDescription="Sort by name">
                <div class="hasIcons">{{'name' | translate }}</div> 
            </th>
            <td [class.productName]="mobile" mat-cell *matCellDef="let elements"> {{elements.name}} </td>
            </ng-container>
        
            <!-- Weight Column -->
            <ng-container matColumnDef="sku">
                <th [class.hideTableHeaders]="mobile" mat-header-cell *matHeaderCellDef>
                    <div class="hasIcons"> {{'sku' | translate }} </div> 
                </th>
                <td [class.hideTableHeaders]="mobile" mat-cell *matCellDef="let elements"> {{elements.sku}} </td>
            </ng-container>

            <ng-container matColumnDef="stock">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="stock" sortActionDescription="Sort by stock"> stock </th>
                <td mat-cell *matCellDef="let elements"> {{elements.stock >= 0 ? elements.stock == 0 ? 'Out of Stock' : elements.stock : 'In Stock'}} </td>
            </ng-container>
            
            <ng-container matColumnDef="price">
                <th mat-header-cell *matHeaderCellDef [class.hideTableHeaders]="mobile">
                    <div class="hasIcons"> {{'Price/Sale' | translate }} </div>  
                </th>
                <td mat-cell *matCellDef="let elements" [class.hideTableHeaders]="mobile"> {{elements.price}} </td>
            </ng-container>

            <ng-container matColumnDef="date">
                <th [class.hideTableHeaders]="mobile" mat-header-cell *matHeaderCellDef>
                    <div class="hasIcons">{{'Date' | translate }}</div> 
                </th>
                <td [class.hideTableHeaders]="mobile" mat-cell *matCellDef="let elements"> {{elements.date}} </td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="lastTitle"> {{'action' | translate }} </th>
                <td mat-cell *matCellDef="let elements"> 
                    <button mat-icon-button (click)="!builderService.getTour() ? editProduct(elements.id) : ''" [ngClass]="builderService.getTour() ? 'disabledBtn' : ''">
                        <span class="mat-button-wrapper">
                            <span class="material-icons material-icons-outlined editIcon">edit</span>
                        </span>
                    </button>
                    <button mat-icon-button (click)="!builderService.getTour() ? deleteProduct(elements.id, elements.image) : ''" [ngClass]="builderService.getTour() ? 'disabledBtn' : ''">
                        <span class="mat-button-wrapper">
                            <span class="material-icons material-icons-outlined deleteIcon">delete</span>
                        </span>
                    </button>
                </td>
            </ng-container>
        
        
            <tr mat-header-row *matHeaderRowDef="productsColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: productsColumns;"></tr>
        </table>
        <mat-paginator class="tablePaginator" [pageSizeOptions]="[5, 10, 20, 50, 100]"
                showFirstLastButtons 
                aria-label="Select page of periodic dataSource">
        </mat-paginator>
    </div>
    <!-- <pre>
        {{products | json}}
    </pre> -->
</div>