<div class="containerWrapper">
    <div class="tableWrapper">
        <div class="tableHeader">
            <h1>{{'Categories' | translate }}</h1>
            <div class="headerBtns">
                <button (click)="addCategory()">{{'Add New Category' | translate }}</button>
            </div>
        </div>
        <table class="table table-bordered">
            <thead>

            </thead>
            <tbody>
                <tr *ngFor="let cat of categories; let i = index">
                    <td>
                        <mat-accordion>
                            <mat-expansion-panel [expanded]="panelStates[i] && (!cat.categoryImage)" (opened)="panelOpened(i)">
                                <mat-expansion-panel-header>
                                    <img src="{{cat.categoryImage}}" alt="">
                                    <p>{{cat.categoryName}}</p>
                                    <div class="headerBtns">
                                        <button mat-icon-button class="input-group-addon" type="button"
                                            (click)="addSub(cat)" (click)="$event.stopPropagation()">
                                            <p class="addSub" *ngIf="!mobile">{{'Add sub Category' | translate }}</p>
                                            <span *ngIf="mobile" class="material-icons material-icons-outlined addSub">add</span>
                                        </button>
                                        <button mat-icon-button class="input-group-addon" type="button"
                                            (click)="removeItem(i)">
                                            <span *ngIf="mobile" class="material-icons material-icons-outlined removeCat">remove</span>
                                            <p class="removeCat" *ngIf="!mobile">{{'Remove' | translate }}</p>
                                        </button>
                                    </div>
                                </mat-expansion-panel-header>
                                <div class="catDesc">
                                    <mat-label>{{'Category Name' | translate }}</mat-label>
                                    <mat-form-field class="attrValue">
                                        <input matInput [(ngModel)]="cat.categoryName" (ngModelChange)="activateSave()"
                                            class="form-control" />
                                    </mat-form-field>
                                </div>
                                <div class="fileWrapper">
                                    <mat-progress-bar mode="determinate" value="{{this.builderService.fileUploadProgress}}"
                                    *ngIf="showUploadProgress[i]"></mat-progress-bar>
                                    <img src="{{cat.categoryImage}}" alt="">
                                    <div class="inputContainer">
                                        <input type="file" class="custom-file-input" (change)="onCatFileSelected($event, cat, i, 0, 'category')" accept="image/*">
                                    </div>
                                    <button (click)="removeImg(cat)" type="button">{{'Remove' | translate }}</button>
                                    <p class="preferred">"{{'preferred size' | translate }}"</p>
                                    <p class="preferred">"{{'Extensions png' | translate }}"</p>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                        <div class="subCat">
                            <div class="input-group subs" *ngFor="let sub of cat.subcategories; let j = index">
                                <span *ngIf="mobile" class="material-icons-outlined closeDialog" (click)="removeSub(cat, j)">
                                    close
                                </span>
                                <div class="subCatHeader">
                                <!-- <div *ngIf="showUploadProgressSub && showUploadProgressSub[i] && showUploadProgressSub[i][j].length > 0">
                                    <mat-progress-bar mode="determinate" value="{{this.builderService.fileUploadProgress}}"
                                    *ngIf="showUploadProgressSub[i][j]"></mat-progress-bar>
                                </div> -->
                                <img src="{{sub.categoryImage}}" alt="">
                                <mat-label>{{'Sub Category Name' | translate }}</mat-label>
                                <mat-form-field class="subCatName">
                                    <input matInput [(ngModel)]="sub.subcategory" (ngModelChange)="activateSave()"
                                        class="form-control" />
                                </mat-form-field>
                            </div>
                                <div class="fileWrapper">
                                    <div class="inputContainer">
                                        <input type="file" class="custom-file-input" (change)="onCatFileSelected($event, sub, i, j, 'subcategory')" accept="image/*">
                                    </div>
                                    <button (click)="removeSubImg(sub)" type="button">{{'Remove Image' | translate }}</button>
                                </div>
                                <button *ngIf="!mobile" mat-icon-button class="input-group-addon removesub" type="button" (click)="removeSub(cat, j)">
                                    {{'Remove' | translate }}
                                </button>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <button class="btn btn-primary saveCat" (click)="saveCategories()" [disabled]="!saveState">
            <i class="ion-checkmark-round"></i> {{'Save' | translate }}
        </button>

        <!-- <pre class="margin-top">{{ categories | json }}</pre> -->

    </div>
</div>