import { Component, OnInit, ViewChild } from '@angular/core';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatSort, Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { BuilderService } from 'src/app/builder-services/builder.service';
import { Observable } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { CategoriesService } from '../categories.service';


export interface categoriesTableColumns {
  id: string;
  image: string;
  name: string;
  date: string;
  action: string;
}





@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {

  public dataSource: any = [];
  isPanelOpen = false;
  isSubPanelOpen = false;
  editText = 'Edit';
  editSub = 'Edit';
  mobile = false;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatTable)
  table!: MatTable<categoriesTableColumns>;

  @ViewChild(MatSort, { static: true })
  matSort!: MatSort;
  pageLoaded = false;
  public categoriesColumns: string[] = [];
  categoriesProgress = false;
  selectedFile: File | null = null;
  showUploadProgress:any = [];
  showUploadProgressSub: any = [][0];
  imageUrl = '';
  categories_data_counts: any = [];


  ELEMENT_DATA: categoriesTableColumns[] = [];

  categories:any = [
  ];


  constructor( private categoriesService: CategoriesService, private _liveAnnouncer: LiveAnnouncer, private router: Router, private route: ActivatedRoute, public builderService: BuilderService,) { 
  }

  ngOnInit(): void {
    this.categoriesService.getCategories().subscribe((categories: any) => {
      console.log(categories);
      if(categories) {
        this.categories = categories;
      }
      if (!Array.isArray(this.categories)) {
        this.categories = [];
      }
    })

    if (window.screen.width <= 768) { // 768px portrait
      this.mobile = true;
    }
  }

  saveState = false;

  panelStates: boolean[] = []; // Array to track expanded state for each panel

  panelOpened(index: number) {
    // Update the state when a panel is opened
    this.panelStates[index] = true;
    
    // Now you know which panel is expanded
    // console.log(`Panel ${index} expanded.`);
  }

  togglePanel(index:number){
    this.isPanelOpen = !this.isPanelOpen;
    this.editText = this.isPanelOpen ? 'Collapse' : 'Edit';
  }

  toggleSubPanel(index:number){
    this.isSubPanelOpen  = !this.isSubPanelOpen;
    this.editSub = this.isSubPanelOpen ? 'Collapse' : 'Edit';
  }

  activateSave() {
    this.saveState = true;
  }

  removeItem(index: number) {
    this.categories.splice(index, 1);
    this.activateSave();
  }

  removeSub(cat: any, index: number) {
    cat.subcategories.splice(index, 1);
    this.activateSave();
  }

  addSub(cat: any) {
    if (!cat.subcategories) {
      cat.subcategories = [];
    }
    cat.subcategories.push({ subCategoryId: this.generateUniqueId(), subcategory: '', categoryImage: '' });
    this.activateSave();
  }

  addCategory() {
    const newCategory = {
      categoryId: this.generateUniqueId(),
      categoryName: '',
      categoryImage: '',
      subcategories: [],
    };
    
    this.categories.push(newCategory);
    this.activateSave();
    const newIndex = this.categories.indexOf(newCategory); 
      this.panelStates[newIndex] = true; 
  }
  

  saveCategories() {
    // You can implement the save functionality here
    this.categoriesProgress = true;
    for (let index = 0; index < this.categories.length; index++) {
      const element = this.categories[index];
      if(element.categoryName == "") {
        alert("Please enter category name");
        return;
      }
    }
    this.categoriesService.submitCategory(this.categories).subscribe((result:any) => {
      this.categoriesProgress = false;
      this.saveState = false;
      // this.router.navigate(['/products']);
    })
  }


  onCatFileSelected(event: any, cat:any, index: number, subIndex: number, type = "category") {
    this.selectedFile = event.target.files[0] as File;
    if (!this.selectedFile) {
      console.error('No file selected.');
      return;
    }
    if(type == "category") {
      this.showUploadProgress[index] = true;
    } else {
      // if(!this.showUploadProgressSub) {
      //   this.showUploadProgressSub = [];
      // }
      // // if(!this.showUploadProgressSub[index]) {
      // //   this.showUploadProgressSub[index] = [];
      // // }
      // this.showUploadProgressSub[index][subIndex] = true;
    }
    
    this.builderService.uploadImage(this.selectedFile, this.builderService.selectedProject, 'categories')?.subscribe((response) => {
      cat.categoryImage = response;
      this.activateSave();
      if(type == "category") {
        this.showUploadProgress[index] = false;
      } else {
        // this.showUploadProgressSub[index][subIndex] = false;
      }
    });
  }

  removeImg(cat:any){
    cat.categoryImage = "";
    this.activateSave();
  }

  removeSubImg(sub:any){
    sub.categoryImage = "";
    this.activateSave();
  }

  generateUniqueId = () => {
    const timestamp = new Date().getTime().toString();
    const random = Math.floor(Math.random() * 10000).toString();
    return timestamp + '-' + random;
  }

  
}
