<div class="largeWrapper">
    <!-- <mat-progress-bar mode="indeterminate" *ngIf="PNProgress"></mat-progress-bar> -->
    <div id="titleContainer">
      <h1>{{'We are here to help' | translate}}</h1>
    </div>
    <div class="centeredContainer columnContainer">
        <!-- Calendly inline widget begin -->
        <h2>{{'Have A Question Book A Demo Call Now' | translate}}</h2>
        <p>{{'Schedule a free call with our experts now' | translate}}</p>
        <p>{{'Get all the answers you need for your app building process' | translate}}</p>
        <div id="calendly-inline-widget" class="calendly-inline-widget"></div>
    </div>
  </div>