<div class="containerWrapper">
    <div class="orderWrapper">
        <div class="itemsAndInfo">
            <div class="orderDetails">
                <h1>{{'Order Details' | translate }}</h1>
                <ul *ngIf="orderData?.order">
                    <li>{{'Date' | translate }}:  <span>{{orderData.order.date}}</span></li>
                    <li>{{'Status' | translate }}: 
                        <mat-form-field appearance="outline">
                            <mat-label>{{orderData?.order.status}}</mat-label>
                            <mat-select (selectionChange)="allowUpdateStatus = true;" value="{{orderData?.order.status}}" [(ngModel)]="selectedStatus">
                                <mat-option *ngFor="let status of possibleStatuses" value="{{status}}">{{status}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <input type="button" class="stacksBtn blue" disabled="{{allowUpdateStatus ? '' : 'disabled' }}" value="Update Status" (click)="updateStatus(selectedStatus)">
                    </li>
                    <li>{{'Country' | translate }}:  <span>{{orderData?.order.shippingCountry}}</span></li>
                    <li *ngIf="orderData?.order.shippingState">State:  <span>{{orderData?.order.shippingState}}</span></li>
                </ul>
            </div>
            <div class="items">
                <h1>{{'Items' | translate }}</h1>
                <ul *ngIf="orderData?.order">
                    <li *ngFor="let product of orderData?.order.products">
                        <img src="{{product.imageUrl}}" alt="">
                        <div class="productDetails">
                                <p class="productName">{{product.productName}}</p>
                                <p>{{ product.selectedVariation?.values }}</p>
                                <p class="subDetails">Price: <span [ngClass]="{'regularPrice': product.salePrice}">
                                    {{ this.currency }}{{ product.regularPrice }}
                                </span>
                                <span *ngIf="product.salePrice">| {{this.currency}}{{product.salePrice}}</span></p>
                        </div>
                        <div class="priceAndQuantity">
                            <p class="quantity">X {{product.quantity}}</p>
                            <p class="totalPrice">{{this.currency}}{{product.item_finalPrice}}</p>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="customerInfo">
                <h1>{{'Customer Info' | translate }}</h1>
                <div class="customerWrapper">
                    <ul *ngIf="orderData">
                        <li *ngIf="orderData?.user_data">
                            <img *ngIf="orderData?.user_data.photoURL" src="{{orderData?.user_data.photoURL}}" alt="">
                            <div class="customerDetails">
                                <p>{{orderData?.user_data.displayName}}</p>
                                <p class="subDetails">{{orderData?.user_data.email}}</p>
                                <p class="subDetails">{{orderData?.address.billing_phone}}</p>
                            </div>
                        </li>
                        <!-- <pre>
                            {{ orderData | json }}
                        </pre> -->
                        <li *ngIf="!orderData?.user_data && orderData?.address.billing_first_name">{{'First Name' | translate }}: <span> {{orderData?.address.billing_first_name}}</span></li>
                        <li *ngIf="!orderData?.user_data && orderData?.address.billing_last_name">{{'Last Name' | translate }}: <span> {{orderData?.address.billing_last_name}}</span></li>
                        <li *ngIf="!orderData?.user_data && orderData?.address.billing_email">{{'Email' | translate }}: <span> {{orderData?.address.billing_email}}</span></li>
                        <li *ngIf="!orderData?.user_data">{{'Phone Number' | translate }}: <span>{{orderData?.address.billing_phone}}</span></li>
                    </ul>
                </div>
            </div>

            <div class="addressInfo">
                <h1>{{'Address Details' | translate }}</h1>
                <div class="customerWrapper">
                    <ul *ngIf="orderData?.order">
                        <li>{{'Billing Address 1' | translate }}: <span> {{orderData?.address.billing_address_1}}</span></li>
                        <li>{{'Billing Address 2' | translate }}: <span>{{orderData?.address.billing_address_2}}</span></li>
                        <li>{{'Postal Code' | translate }}: <span>{{orderData?.address.billing_postcode}}</span></li>
                        <li>{{'Other Comments' | translate }}: <span>{{orderData?.address.order_comments}}</span></li>
                    </ul>
                </div>
            </div>

        </div>
        <div class="summaryWrapper">
            <div class="summary">
                <h1>{{'Order Summary' | translate }}</h1>
                <div class="summaryDetails" *ngIf="orderData?.order">
                    <ul>
                        <li>{{'Subtotal' | translate }} <span>{{this.currency}}{{orderData.order.subTotal}}</span></li>
                        <!-- <li>Dicount <span>$0.00</span></li> -->
                        <li>{{'Shipping' | translate }} <span>{{this.currency}}{{orderData.order.shippingPrice}}</span></li>
                        <!-- <li>Tax <span>$0.00</span></li> -->
                    </ul>
                    <p>{{'Total' | translate }}: {{this.currency}}{{orderData.order.total}}</p>
                </div>
            </div>
            <button class="stacksBtn blue backToOrders" [routerLink]="['/orders']">Go back to Orders</button>
        </div>
    </div>
</div>