<div class="pointsContainer" [formGroup]="points">
  <div class="pointPart pointPartText">
    <h3>{{ "Point Message" | translate }}</h3>
    <textarea
      formControlName="customization"
      rows="5"
      cols="30"
      placeholder="Enter your text here"
    ></textarea>
  </div>

  <div class="pointPart pointPartText">
    <h3>{{ "Popup Message when earned points" | translate }}</h3>
    <textarea
      formControlName="popupMessage"
      rows="5"
      cols="30"
      placeholder="Enter your text here"
    ></textarea>
  </div>

  <div class="pointPart pointPartText">
    <h3>{{ "Points Push Notification" | translate }}</h3>
    <span class="gray">*{{
      "by active this Feature you will automated reminders for points nearing expiry"
        | translate
    }}</span>
    <div class="pointPart">
      <div>
        <mat-slide-toggle class="sendNot" color="primary" formControlName="pointsPushNotification">
          {{ "Send Push Notification Before Points Expire" | translate }}
        </mat-slide-toggle>
      </div>
      <div class="dateSending">
        <span>{{ "Date Sending Notification" | translate }}</span>
        <input type="number" placeholder="0" formControlName="expirationDate" />
        <span>( {{ "Days" | translate }} )</span>
      </div>
    </div>

    <textarea
      formControlName="notificationDate"
      rows="5"
      cols="30"
      placeholder="Enter your text here"
    ></textarea>
  </div>

  <div class="pointPart pointPartText">
    <h3>{{ "Terms & Conditions" | translate }}</h3>
    <textarea
      formControlName="termsConditions"
      rows="5"
      cols="30"
      placeholder="Enter terms and conditions"
    ></textarea>
  </div>
</div>
<form id="points" (ngSubmit)="saveChanges()"></form>
