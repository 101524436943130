import { Injectable } from '@angular/core';
import { BlockDataService } from '../../block-services/block-data.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { JwtHelperService } from "@auth0/angular-jwt";
import { HelpersService } from '../../helpers.service';
import { BuilderService } from 'src/app/builder-services/builder.service';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService extends BlockDataService {

  private builderUrl;

  projects = {};
  selectedProject = 0;
  headerBtn = '';
  options = {
    headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
  };
  token: any;
  storedCategories: any = [];
  constructor(private http: HttpClient, public helpers: HelpersService, private builderService: BuilderService) {
    super();
    this.token = this.builderService.getToken();
    this.builderUrl = this.helpers.getBuilderUrl();
  }


  /**
   * Get all templates from Backend
   */
  getCategories = (id: any, args: any): Observable<any> => {
    this.token = this.builderService.getToken();
    let postData = new URLSearchParams();
    postData.set('process', "sync_ui");
    postData.set('token', this.token);
    postData.set('projectId', id);
    postData.set('args', JSON.stringify([args]));

    return this.http.post(this.builderUrl + 'getCategories', postData.toString(), this.options);
  }

  /**
   * get categories once at the start of the application and save it inside the localstorage to use it in the products and categories blocks
   * We need to set it inside the after view init to make sure that the Token is set
   */
  getInitialCategories = () => {
    let args: object = {
      'categories': [],
      'hide_empty': true,
      'orderby': 'date',
      'order': 'DESC',
      'source': 'by_id',
      'limit': '200' // a general high number to retrieve all
    };
    this.getCategories(this.builderService.getSelectedProject(), args).subscribe((response) => {
      let categories: any = [];
      for (const key in response) {
        if (Object.prototype.hasOwnProperty.call(response, key)) {
          const element = response[key];
          if (element.count > 0) {
            categories.push({ 'id': element.term_id, "name": element.name, "thumbnail": element.image_url });
          }
        }
      }
      localStorage.setItem('categories', JSON.stringify(categories));
      this.storedCategories = categories;
    }, (err) => {

    });
  }

}
