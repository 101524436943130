<div class="containerWrapper">
    <h1>Add New Subscriber</h1>
    <div class="productDetail">
        <mat-progress-bar mode="indeterminate" *ngIf="subscriberProgress"></mat-progress-bar>
        <div class="productHeader">
            <h1>SUBSCRIBERS</h1>
            <button [routerLink]="['/admin/subList']">SEE ALL Subscribers</button>
        </div>
        <form [formGroup]="subscriberForm" (ngSubmit)="onSubmit()">
            <div class="productForm">
                <div class="left">
                    <mat-form-field>
                        <mat-label>Subscriber Name</mat-label>
                        <input required matInput formControlName="subscriberName">
                        <mat-error>
                            Subscriber Name is required
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Subscriber Email</mat-label>
                        <input type="email" required matInput formControlName="subscriberEmail" [(ngModel)]="subscriberEmail">
                        <mat-error>
                            Subscriber Email is required
                        </mat-error>
                    </mat-form-field>

                    <label>Subscriber Notes</label>
                            <editor
                            formControlName="notes"
                            [(ngModel)]="notes"
                            apiKey="dh6nv3idqamf38bn1tnknqxd0qn9vz2yli1zjpjehily2stk"
                            [init]="{
                            base_url: '/tinymce',
                            suffix: '.min',
                            height: 320,
                            menubar: false,
                            plugins: [
                            'lists',
                            'help',
                            'textcolor',
                            'colorpicker',
                            'code',
                            ],
                            toolbar:
                            'code | undo redo | bold italic | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent forecolor backcolor | help'
                            }"></editor>
                </div>
                <div class="right">
                    <mat-form-field>
                        <mat-label>Subscription Price</mat-label>
                        <input required type="number" min="0" matInput formControlName="subscriptionPrice">
                        <mat-error>
                            Subscription Price is required
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field >
                        <mat-label>Subscription Currency</mat-label>
                        <mat-select formControlName="currency">
                            <mat-option value="EGP">
                                EGP
                            </mat-option>
                            <mat-option value="Dollar">
                                Dollar
                            </mat-option>
                            <mat-option value="Euro">
                                Euro
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Subscription Plan</mat-label>
                        <input required matInput formControlName="subscriptionPlan">
                        <mat-error>
                            Subscription Plan is required
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Renewal Date</mat-label>
                        <input required type="date" matInput formControlName="renewalDate">
                        <mat-error>
                            Renewal Date is required
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field >
                        <mat-label>Status</mat-label>
                        <mat-select (selectionChange)="statusChange($event.value)" formControlName="status">
                            <mat-option value="active">
                                Active
                            </mat-option>
                            <mat-option value="onhold">
                                Onhold
                            </mat-option>
                            <mat-option value="cancelled">
                                Cancelled
                            </mat-option>
                            <mat-option value="refund">
                                Refund
                            </mat-option>
                        </mat-select>
                        <mat-error>
                            Status is required
                        </mat-error>
                    </mat-form-field>
                    <div class="actionBtnsWrapper">
                        <button mat-icon-button class="stacksBtn green" type="button" (click)="createProject()">
                            <span>Create Project</span>
                        </button>
                        <button mat-icon-button class="stacksBtn blue" type="submit">
                            <span>PUBLISH</span>
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>