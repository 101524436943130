import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { CouponsService } from '../coupons.service';
import { Observable } from 'rxjs';
import { BuilderService } from 'src/app/builder-services/builder.service';

export interface Coupon {
  id: string | null;
  name: string;
  expirationDate: string;
  amount: number;
  discountType: 'percentage' | 'specific_amount';
  discountCriteria:
    | 'fixed_products_discount'
    | 'fixed_categories_discount'
    | 'whole_cart_discount';
    active:boolean;
}

@Component({
  selector: 'app-coupons',
  templateUrl: './coupons.component.html',
  styleUrls: ['./coupons.component.scss'],
})
export class CouponsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) matSort!: MatSort;
  @ViewChild(MatTable) table!: MatTable<Coupon>;
  @ViewChild('searchInput') searchInput!: ElementRef;
  displayedColumns: string[] = [
    'name',
    'expirationDate',
    'amount',
    'uses',
    'discountType',
    'discountCriteria',
    'active',
    'actions',
  ];
  dataSource = new MatTableDataSource<Coupon>();
  searchTerm: string = '';
project_id = this.builderService.selectedProject;

  discountCriteriaMap: { [key: string]: string } = {
    fixed_products_discount: 'Fixed Products Discount',
    fixed_categories_discount: 'Fixed Category Discount',
    whole_cart_discount: 'Whole Cart Discount',
  };
  discountTypeMap: { [key: string]: string } = {
    percentage: 'Percentage',
    specific_amount: 'Specific Amount',
  };
  constructor(private couponsService: CouponsService, private builderService:BuilderService) {}

  ngOnInit() {
    console.log(this.paginator);
    this.loadCoupons();
    console.log(this.dataSource);
  }

  ngAfterViewChecked() {
    if (!this.dataSource.paginator && this.paginator) {
      console.log('Paginator in AfterViewChecked:', this.paginator);
      this.dataSource.paginator = this.paginator;
    }
  }

  /**
   this method call the getCoupons() from couponsService to get the list of the coupons from the database
   @input null
   @returns null
   */
  loadCoupons() {
    this.couponsService.getCoupons().subscribe(
      (coupons: any) => {
        console.log('Fetched coupons:', coupons);

        // Transform the data to include id as a property
        const couponsArray: Coupon[] = Object.keys(coupons).map((key) => ({
          id: key,
          ...coupons[key], // Spread the properties from the coupon object
        }));

        console.log('Processed coupons:', couponsArray);
        this.dataSource = new MatTableDataSource<Coupon>(couponsArray);
        console.log(this.dataSource);
      },
      (error) => {
        console.error('Error fetching coupons:', error);
      }
    );
  }
  /**
   * this method is used to filter the displayed data in the Material table based on the user's input so i can use it to make the search bar.
   * @param filterValue which is a string
   * @returns null
   */
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  /**
   *  this method is used to call the delete method from the coupons service so i can use it in the delete button to delete any
   * coupon fron the list of the coupons and form the database.
   * @param couponId which is a string
   * @returns null
   */
  deleteCoupon(couponId: string) {
    if (confirm('Are you sure you want to delete this coupon?')) {
      this.couponsService.deleteCoupon(couponId).subscribe(
        () => {
          console.log('Coupon deleted successfully');
          this.loadCoupons(); // Reload the coupons after deletion
        },
        (error) => {
          console.error('Error deleting coupon:', error);
        }
      );
    }
  }
  hasCoupons(): boolean {
    return this.dataSource.data.length > 0;
  }
  activeCoupon(couponId: string, status: boolean): Observable<any> {
    const url = `projects/${this.project_id}/coupons/${couponId}`;
    
    return new Observable<any>((observer) => {
      // First, get the coupon from the database
      this.couponsService.getCoupon(couponId).subscribe(
        (coupon) => {
          // Update the active status of the coupon
          coupon.active = status;
          
          // Log the updated coupon data
          console.log('Updating active status for coupon:', coupon);
  
          // Save the updated coupon back to the database
          this.couponsService.updateCoupon(couponId, coupon).subscribe(
            (response) => {
              console.log('Coupon active status updated successfully:', response);
              observer.next(response);
              observer.complete();
            },
            (error) => {
              console.error('Error updating active status:', error);
              observer.error(error);
            }
          );
        },
        (error) => {
          console.error('Error fetching coupon:', error);
          observer.error(error);
        }
      );
    });
  }
  toggleActiveStatus(couponId: string, status: boolean) {
    this.activeCoupon(couponId, status).subscribe(
      (response) => {
        console.log('Coupon active status updated successfully:', response);
      },
      (error) => {
        console.error('Error updating active status:', error);
      }
    );
  }

}
