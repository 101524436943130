<div #TemplatesWrapper *ngIf="!mobile">
  <div id="templatesHeader">Create Template</div>
  <div id="templatesBody">
    <form [formGroup]="templatesForm" id="templatesForm" (ngSubmit)="onSubmit($event)">
      <mat-progress-bar mode="indeterminate" *ngIf="showTemplatesProgress"></mat-progress-bar>
      <ul>
        <li>
          <mat-form-field appearance="outline">
            <mat-label>Choose Template to update</mat-label>
            <mat-select formControlName="templateSelected" (selectionChange)="queryChange($event, 'select')">
              <mat-option *ngFor="let template of templatesList; let template_id = index;" [value]="template_id">
                {{template.template_name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </li>
        <li>
          <mat-form-field appearance="outline">
            <mat-label>Template Name</mat-label>
            <input matInput placeholder="Pizza Template" formControlName="templateName" value="{{this.templateName}}" [(ngModel)]="this.templateName">
          </mat-form-field>
        </li>
        <li>
          <h2>Template Image</h2>
          <img src="{{this.templateImageSrc}}" class="templateImageSrc" />
          <label class="fileUpload" for="templateImage">Choose an image
            <input type="file" formControlName="templateImage" id="templateImage"
              (change)="this.processFile($event, '', 'templateImage')">
          </label>
        </li>
        <li>
          <mat-form-field appearance="outline">
            <mat-label>Template Category</mat-label>
            <mat-select formControlName="templateCategorySelected" [(ngModel)]="templateCategorySelected">
              <mat-option [value]="templateCategorySelected" *ngFor="let templatesCategory of templatesCategories">
                {{templatesCategory.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </li>
        <li>
          <button mat-icon-button class="updateBtn" form="templatesForm">
            <span>{{'Update' | translate }}</span>
          </button>
          <button mat-icon-button class="createTemplate" form="templatesForm">
            <span>Create</span>
          </button>
        </li>
      </ul>
    </form>
  </div>
</div>