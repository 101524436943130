import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { isConstructSignatureDeclaration } from 'typescript';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import {MatSort, Sort} from '@angular/material/sort';
import { ChartConfiguration, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { SelfService } from '../self.service';
import { BuilderService } from 'src/app/builder-services/builder.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';


export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};


export interface salesTableColumns {
  order_id: number;
  date: string;
  date_formatted: any;
  email: string;
  apps_number: string;
  sale_attribution: string;
  coupon_description: string;
  coupon_name: string;
  product_name: string;
  value: string;
  status: string;
}


let ELEMENT_DATA: salesTableColumns[] = [];

@Component({
  selector: 'self-trials-conversion',
  templateUrl: './self-trials-conversion.component.html',
  styleUrls: ['./self-trials-conversion.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class SelfTrialsConversionComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatTable)
  table!: MatTable<salesTableColumns>;

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;
  
  constructor(private selfService: SelfService, public builderService: BuilderService) { }
  public salesColumns: string[] = [];
  public dataSource: any = [];
  sales_dates: any = [];
  sales_data_counts: any = [];

  trialsEmails:any = [];
  salesEmails:any = [];
  selfTrials: any;
  selfSales: any;

  convertedTrials: any = [];

  conversionRate: any;


  initial_datasource: any;
  form = new FormGroup({
    fromDate: new FormControl(null, { validators: [Validators.required]}),
    toDate: new FormControl(null, { validators: [Validators.required]})
  });

  ngOnInit(): void {
    let selfTrials = localStorage.getItem('selfTrials');
    if(selfTrials) {
      this.selfTrials = JSON.parse(selfTrials);
      this.fillTrialsEmails();
    }

    let selfSales = localStorage.getItem('selfSales');
    if(selfSales) {
      this.selfSales = JSON.parse(selfSales);
      this.fillSelfEmails();
    }

    let trialsPurchases = this.getArraysIntersection(this.trialsEmails,this.salesEmails);
    this.conversionRate = trialsPurchases.length / this.trialsEmails.length * 100;
    this.conversionRate = this.conversionRate.toFixed(2);
    this.getTrialsWhoConverted(trialsPurchases);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  fillTrialsEmails = () => {
    for (const key in this.selfTrials) {
      if (Object.prototype.hasOwnProperty.call(this.selfTrials, key)) {
        const element = this.selfTrials[key];
        for (const key_element in element) {
          if (Object.prototype.hasOwnProperty.call(element, key_element)) {
            const element_data = element[key_element];
            this.trialsEmails.push(element_data.email);
          }
        }
      }
    }
  }

  fillSelfEmails = () => {
    for (const key in this.selfSales) {
      if (Object.prototype.hasOwnProperty.call(this.selfSales, key)) {
        const element = this.selfSales[key];
        for (const key_element in element) {
          if (Object.prototype.hasOwnProperty.call(element, key_element)) {
            const element_data = element[key_element];
            if(element_data.sale_attribution == 'Giveaways') {
              continue;
            }
            this.salesEmails.push(element_data.email);
          }
        }
      }
    }
  }

  getTrialsWhoConverted = (trialsPurchases: any) => {
    this.salesColumns = ['date', 'email', 'order_id', 'apps_number', 'sale_attribution', 'coupon_description', 'coupon_name', 'product_name', 'value', 'status'];
    ELEMENT_DATA = [];

    for (const key in trialsPurchases) {
      if (Object.prototype.hasOwnProperty.call(trialsPurchases, key)) {
        const element = trialsPurchases[key];
        let convertedTrialsObj = this.findEmailInData(element,this.selfSales);
        this.convertedTrials.push(convertedTrialsObj);
        ELEMENT_DATA.push(
          { date: convertedTrialsObj.date, 
            date_formatted: new Date( convertedTrialsObj.date ), 
            email: convertedTrialsObj.email,
            order_id: convertedTrialsObj.id, 
            apps_number: convertedTrialsObj.apps_number, 
            sale_attribution: convertedTrialsObj.sale_attribution, 
            coupon_description: convertedTrialsObj.coupon_description, 
            coupon_name: convertedTrialsObj.coupon_name, 
            product_name: convertedTrialsObj.product_name, 
            value: convertedTrialsObj.value, 
            status: convertedTrialsObj.status, 
          },
        );
      }
    }
    
    this.dataSource = new MatTableDataSource<salesTableColumns>(ELEMENT_DATA);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.initial_datasource = [...this.dataSource.data];
    setTimeout(() => {
      this.table.renderRows();
    }, 1000);
  }

  findEmailInData = (email:string, data: any) => {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const element = data[key];
        for (const key_element in element) {
          if (Object.prototype.hasOwnProperty.call(element, key_element)) {
            const element_data = element[key_element];
            if(element_data.email == email) {
              return element_data;
            }
          }
        }
      }
    }
    return false;
  }

  getArraysIntersection = (a1: any,a2: any) => {
    return  a1.filter(function(n: any) { return a2.indexOf(n) !== -1;});
  }

  applySubFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  applyDateFilter() {  
    const fromDate = this.form.value?.fromDate;

if (fromDate !== null && fromDate !== undefined) {
  console.log(fromDate);
  this.dataSource.data = this.dataSource.data.filter((e: { date_formatted: any; })=> e.date_formatted >= fromDate && e.date_formatted <= fromDate);
} else {
  console.error("fromDate is null or undefined");
}  
    if (this.form.invalid) {
      return
    }
    //this.dataSource.data = this.dataSource.data.filter(e=>e.date >= this.fromDate && e.date <= this.toDate);
  }

  clearDateFilter = () => {
    this.dataSource.data = this.initial_datasource;
  }

}
