<section class="stacksBlockContainer staticContainer">
    <div id="settingsTabs" *ngIf="checkSettings()">
      <a (click)="showDataTab()" class="active">Data</a>
    </div>
    <!-- Mobile Preview -->
    <div class="previewStatic previewMobile" *ngIf="checkPreview()" id="{{'blockid'+blockId}}"
      cdkDrag [cdkDragData]="blockId">
      <div class="blockControlHandlers" *ngIf="!builderService.globals">
        <div class="editBlock blockActionBtn" (click)="showData(blockId)">
          <mat-icon>edit</mat-icon>
        </div>
        <div class="blockHandle blockActionBtn" cdkDragHandle>
          <mat-icon>open_with</mat-icon>
        </div>
        <div class="deleteBlock blockActionBtn" *ngIf="getBlockFields(blockId).data?.deletable !== false" (click)="deleteBlock(blockId)">
          <mat-icon>close</mat-icon>
        </div>
      </div>
      <!-- <div class="blockPlaceholder" *cdkDragPlaceholder></div> -->
      <!-- <div *ngIf="getBlockFields(blockId).data.block == 'shop_filters'">
          <img src="../../../assets/images/views/components/{{getBlockFields(blockId).data.block}}" alt="">
      </div> -->
      <img *ngIf="getBlockFields(blockId).data && !getBlockFields(blockId).data.variations" src="../../../assets/images/views/components/{{getBlockFields(blockId).data.view}}/{{getBlockFields(blockId).data.block}}.png" alt="">
      <img *ngIf="getBlockFields(blockId).data.variations" src="../../../assets/images/views/components/{{getBlockFields(blockId).data.view}}/{{getBlockFields(blockId).data.variations[getBlockFields(blockId).style?.columns_number]}}.png" alt="">
      <img *ngIf="getBlockFields(blockId).data.variation_1" src="../../../assets/images/views/components/{{getBlockFields(blockId).data.view}}/{{getBlockFields(blockId).data.variation_1[getBlockFields(blockId).style?.grid_list]}}_{{getBlockFields(blockId).data.variation_2[getBlockFields(blockId).style?.columns_number]}}_{{getBlockFields(blockId).data.variation_3[getBlockFields(blockId).style?.products_style]}}.png" alt="">
    </div>
    <!-- Data and Style Tabs -->
    <div class="previewFields" *ngIf="checkData()">
      <mat-progress-bar mode="indeterminate" *ngIf="showProgress"></mat-progress-bar>
      <ul *ngIf="getBlockFields(blockId).data.block == 'singleblogpost'">
        <li>
          <mat-label>Choose whether the required field is Phone or Email</mat-label>
        </li>
        <li>
          <mat-select (selectionChange)="queryChange('webview_or_native', blockId, $event, 'select')"
            value="{{getBlockFields(blockId).data?.webview_or_native ? getBlockFields(blockId).data?.webview_or_native : 'native'}}">
            <mat-option value="native">
              Native
            </mat-option>
            <mat-option value="webview">
              Webview
            </mat-option>
          </mat-select>
        </li>
      </ul>

      <ul *ngIf="getBlockFields(blockId).data.block == 'signup'">
        <li>
          <mat-label>Choose whether the required field is Phone or Email</mat-label>
        </li>
        <li>
          <mat-select (selectionChange)="queryChange('email_or_phone', blockId, $event, 'select')"
            value="{{getBlockFields(blockId).data?.email_or_phone ? getBlockFields(blockId).data?.email_or_phone : 'email'}}">
            <mat-option value="email">
              Email
            </mat-option>
            <mat-option value="phone">
              Phone
            </mat-option>
          </mat-select>
        </li>
      </ul>
      <ul *ngIf="getBlockFields(blockId).data.view == 'cart'">
        <li>
        <mat-label>Show Shipping Fee</mat-label>
          <mat-select (selectionChange)="queryChange('shippingFee', blockId, $event, 'select')"
            value="{{getBlockFields(blockId).data?.shippingFee ? getBlockFields(blockId).data?.shippingFee : 'on'}}">
            <mat-option value="on">
              Show
            </mat-option>
            <mat-option value="off">
              Hide
            </mat-option>
          </mat-select>
      </li>
      </ul>
      <ul *ngIf="getBlockFields(blockId).data.view == 'categories'">
        <li>
          <mat-label>{{ 'Categories Style' | translate }}</mat-label>
          <mat-select [(ngModel)]="getBlockFields(blockId).style.columns_number" 
                      (ngModelChange)="queryChange('columns_number', blockId, $event, 'select')">
            <mat-option value="oneColumn">
              {{ 'One Column' | translate }}
            </mat-option>
            <mat-option value="TwoColumns">
              {{ 'Two Columns' | translate }}
            </mat-option>
            <mat-option value="List">
              {{ 'List' | translate }}
            </mat-option>
            <mat-option value="List2">
              {{ 'List 2' | translate }}
            </mat-option>
          </mat-select>
        </li>
      </ul>
      <ul *ngIf="getBlockFields(blockId).data.view == 'singleCategory'">
        <li>
          <mat-label>{{ 'Grid Style' | translate }}</mat-label>
          <mat-select [(ngModel)]="getBlockFields(blockId).style.grid_list" 
                      (ngModelChange)="queryChange('grid_list', blockId, $event, 'select')">
            <mat-option value="gridStyle">
              {{ 'Grid Style' | translate }}
            </mat-option>
            <mat-option value="listStyle">
              {{ 'List Style' | translate }}
            </mat-option>
          </mat-select>
        </li>
        <li>
          <mat-label>{{ 'Number of Columns' | translate }}</mat-label>
          <mat-select [(ngModel)]="getBlockFields(blockId).style.columns_number" 
              (ngModelChange)="queryChange('columns_number', blockId, $event, 'select')">
              <mat-option value="oneColumn">
                {{ 'One Column' | translate }}
              </mat-option>
              <mat-option value="twoColumns">
                {{ 'Two Columns' | translate }}
              </mat-option>
              <mat-option value="threeColumns">
                {{ 'Three Columns' | translate }}
              </mat-option>
          </mat-select>
        </li>
        <li>  
          <mat-label>{{ 'Products Shapes' | translate }}</mat-label>
          <mat-select [(ngModel)]="getBlockFields(blockId).style.products_style" 
                      (ngModelChange)="queryChange('products_style', blockId, $event, 'select')">
            <mat-option value="simpleStyle">
              {{ 'Simple Style' | translate }}
            </mat-option>
            <mat-option value="roundedStyle">
              {{ 'Rounded Style' | translate }}
            </mat-option>
            <mat-option value="glassStyle">
              {{ 'Glass Style' | translate }}
            </mat-option>
            <mat-option value="sharpStyle">
              {{ 'Sharp Style' | translate }}
            </mat-option>
          </mat-select>
        </li>
        <li>
          <mat-label>{{ 'Add to Cart Visibility' | translate }}</mat-label>
          <mat-select [(ngModel)]="getBlockFields(blockId).style.cart_visibility" 
                      (ngModelChange)="queryChange('cart_visibility', blockId, $event, 'select')">
            <mat-option value="show_cart">
              {{ 'Show Cart' | translate }}
            </mat-option>
            <mat-option value="hide_cart">
              {{ 'Hide Cart' | translate }}
            </mat-option>
          </mat-select>
        </li>
      </ul>
      <ul *ngIf="getBlockFields(blockId).data.view == 'checkout'">
        <li>
          <mat-label>Last Name</mat-label>
          <mat-select (selectionChange)="queryChange('lastname', blockId, $event, 'select')"
            value="{{getBlockFields(blockId).data?.lastname ? getBlockFields(blockId).data?.lastname : 'on'}}">
            <mat-option value="on">
              Show
            </mat-option>
            <mat-option value="off">
              Hide
            </mat-option>
          </mat-select>
        </li>
        <li>
          <mat-label>Company</mat-label>
          <mat-select (selectionChange)="queryChange('company', blockId, $event, 'select')"
            value="{{getBlockFields(blockId).data?.company ? getBlockFields(blockId).data?.company : 'on'}}">
            <mat-option value="on">
              Show
            </mat-option>
            <mat-option value="off">
              Hide
            </mat-option>
          </mat-select>
        </li>
        <li>
          <mat-label>Email</mat-label>
          <mat-select (selectionChange)="queryChange('email', blockId, $event, 'select')"
            value="{{getBlockFields(blockId).data?.email ? getBlockFields(blockId).data?.email : 'on'}}">
            <mat-option value="on">
              Show
            </mat-option>
            <mat-option value="off">
              Hide
            </mat-option>
          </mat-select>
        </li>
        <!-- <li>
          <mat-label>Phone</mat-label>
          <mat-select (selectionChange)="queryChange('phone', blockId, $event, 'select')"
            value="{{getBlockFields(blockId).data?.phone ? getBlockFields(blockId).data?.phone : 'on'}}">
            <mat-option value="on">
              Show
            </mat-option>
            <mat-option value="off">
              Hide
            </mat-option>
          </mat-select>
        </li> -->
        <li>
          <mat-label>Postal</mat-label>
          <mat-select (selectionChange)="queryChange('postal', blockId, $event, 'select')"
            value="{{getBlockFields(blockId).data?.postal ? getBlockFields(blockId).data?.postal : 'on'}}">
            <mat-option value="on">
              Show
            </mat-option>
            <mat-option value="off">
              Hide
            </mat-option>
          </mat-select>
        </li>
        <li>
          <mat-label>Additional</mat-label>
          <mat-select (selectionChange)="queryChange('additional', blockId, $event, 'select')"
            value="{{getBlockFields(blockId).data?.additional ? getBlockFields(blockId).data?.additional : 'on'}}">
            <mat-option value="on">
              Show
            </mat-option>
            <mat-option value="off">
              Hide
            </mat-option>
          </mat-select>
        </li>
        <li>
          <mat-label>Ship to a different address?</mat-label>
          <mat-select (selectionChange)="queryChange('differentShippment', blockId, $event, 'select')"
            value="{{getBlockFields(blockId).data?.differentShippment ? getBlockFields(blockId).data?.differentShippment : 'on'}}">
            <mat-option value="on">
              Show
            </mat-option>
            <mat-option value="off">
              Hide
            </mat-option>
          </mat-select>
        </li>
        <li>
          <mat-label>Show Shipping Fee</mat-label>
          <mat-select (selectionChange)="queryChange('shippingFee', blockId, $event, 'select')"
            value="{{getBlockFields(blockId).data?.shippingFee ? getBlockFields(blockId).data?.shippingFee : 'on'}}">
            <mat-option value="on">
              Show
            </mat-option>
            <mat-option value="off">
              Hide
            </mat-option>
          </mat-select>
        </li>
      </ul>
    </div>
    <div class="styleFields" *ngIf="checkStyle()">
      <ul *ngIf="getBlockFields(blockId).data?.view == 'categories'">
        <li>
          <mat-label>Columns Number</mat-label>
          <mat-select (selectionChange)="styleChange('columns_number', blockId, $event, 'select')"
            value="{{getBlockFields(blockId).style?.columns_number ? getBlockFields(blockId).style?.columns_number : 'oneColumn'}}">
            <mat-option value="oneColumn">
              {{ 'One Column' | translate }}
            </mat-option>
            <mat-option value="TwoColumns">
              {{ 'Two Columns' | translate }}
            </mat-option>
            <mat-option value="List">
              List
            </mat-option>
            <mat-option value="List2">
              {{ 'List 2' | translate }}
            </mat-option>
          </mat-select>
        </li>
      </ul>
    </div>
  </section>