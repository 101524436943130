<div id="middleContainerWrapper" *ngIf="package?.name !== 'LTD'">
    <h1>You have successfully subscribed to Package</h1>
    <span class="premiumDetails">{{package?.name}} - {{package?.type}} - {{package?.period}} Plan 🎉</span> 
    <button [routerLink]="['/']" class="stacksBtn blue">Back to builder</button>
</div>

<div id="middleContainerWrapper" *ngIf="package?.name == 'LTD'">
    <img src="assets/images/payment_success.png" alt="">
    <h1>You have successfully purchased our <span class="premiumDetails">Lifetime Offer</span>🎉</h1>
    <button [routerLink]="['/site-type']" class="stacksBtn blue">Back to builder</button>
</div>

