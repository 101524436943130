<form [formGroup]="iosBuildForm" id="iosBuildWrapper" class="containerWrapper buildWrapper" (ngSubmit)="onSubmit()">
    <div *ngIf="!buildStart && !checkingStatus && !error" id="titleContainer">
      <h1>{{'IOS Builder' | translate }}</h1>
      <div class="howItWorks noselect" (click)="howItWorksDialog()">
        <span class="material-icons-outlined">play_circle_filled</span>
        {{'How it works' | translate }}
      </div>
    </div>
      <ul *ngIf="!settingsError">
        <li>
          <h2>{{'IOS Version' | translate }}</h2>
          <p>{{'the version of Application' | translate }}</p>
          <mat-form-field appearance="outline">
            <mat-label>{{'IOS Version' | translate }}</mat-label>
            <input matInput placeholder="1.0" type="number" min="0.1" step="0.1" formControlName="iosVersion">
          </mat-form-field>
        </li>
      </ul>
      <div *ngIf="settingsError" id="settingsError">
        <h2>{{'Missing Data Found' | translate }}</h2>
        <p>{{'go to Apple Settings' | translate }} {{settingsError}}</p>
        <button mat-icon-button id="appleSettingsNav" class="stacksBtn blue" [routerLink]="['/applesettings']">
          <span>{{'Apple Settings' | translate }}</span>
        </button>
      </div>
    <div *ngIf="buildStart && !checkingStatus && !error" class="buildStart">
      <h1 *ngIf="value < 100">{{'Generating ios' | translate }}</h1>
      <h1 *ngIf="value == 100">{{'Ios Application Uploaded' | translate }}</h1>
      {{value}}%
      <mat-progress-bar mode="determinate" class="buildingBar" [value]="value"></mat-progress-bar>
      <div *ngIf="value == 100" id="qrWrapper">
        <h2>{{'After few minutes' | translate }}</h2>
        <div class="actionBtns">
          <a class="rebuildApp" (click)="rebuildApp()">{{'Rebuild App' | translate }}</a>
        </div>
      </div>
    </div>
    <div *ngIf="error" class="buildError">
      <h2 *ngIf="buildErrorMsg && buildErrorMsg !== ''">{{buildErrorMsg}}</h2>
      <h2 *ngIf="!buildErrorMsg && buildErrorMsg !== ''">{{'Error occured while building' | translate }}<br/>
      {{'please try again' | translate }} <br/> {{'If the error' | translate }}  </h2>
      <div class="actionBtns">
        <a class="rebuildApp" (click)="rebuildApp()">{{'Rebuild App' | translate }}</a>
      </div>
    </div>
    <div *ngIf="checkingStatus && !error" class="checkingStatus">
      <h1>{{'Checking Build Status' | translate }} </h1>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
  </form>