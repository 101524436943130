import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { WorksDialogComponent } from 'src/app/pages/works-dialog/works-dialog.component';
import { AppSettingsService } from 'src/app/settings/services/app-settings.service';
import { MixpanelService } from 'src/app/tracking/mixpanel.service';
import { BuilderService } from '../../builder-services/builder.service';
import { AndroidbuildService } from './androidbuild.service';

@Component({
  selector: 'android-build',
  templateUrl: './android-build.component.html',
  styleUrls: ['./android-build.component.scss'],
})
export class AndroidBuildComponent {

  buildStart = false;
  value = 0;
  qrLink = "";
  downloadBtn = '';
  downloadAABBtn = '';
  checkingStatus = true;
  error = false;
  playType = 'apk';
  buildErrorMsg = '';
  webviewApp = false;
  constructor(private formBuilder: FormBuilder,
    public builderService: BuilderService,
    private androidService: AndroidbuildService, 
    public dialog: MatDialog,
    private mixPanel: MixpanelService,
    private appSettingsService: AppSettingsService) { 

    this.getBuildStatus();
    this.checkWebview();
    }

  androidBuildForm = this.formBuilder.group({
    androidVersion: '',
  });

  checkWebview = () => {
    this.appSettingsService.getContentSettings(this.builderService.selectedProject).subscribe((settings: any) => {
      if (settings.mobile_webview_link !== null) {
        this.webviewApp = true;
      }

    }, (err) => {
      console.log(err);
    });
  }

  /**
   * Submits a build request to the server
   */
  onSubmit(): void {
    this.value=0;
    this.buildStart = true;
    this.builderService.headerBtn = '';
    this.error = false;
    if (!this.androidBuildForm.value.androidVersion) {
      this.androidBuildForm.value.androidVersion = '0.1';
    }
    this.mixPanel.track('APK Build', {
      projectid: this.builderService.selectedProject
    });
    this.androidService.startBuild(this.builderService.getSelectedProject(), this.androidBuildForm.value.androidVersion).subscribe( (response: any) => {
      this.getBuildStatus();
    });
  }

  /**
   * Generate ABB Files
   */
   generateAAB = () => {
    this.value=0;
    this.buildStart = true;
    this.builderService.headerBtn = '';
    this.error = false;
    if (!this.androidBuildForm.value.androidVersion) {
      this.androidBuildForm.value.androidVersion = '0.1';
    }
    this.mixPanel.track('AAB Build', {
      projectid: this.builderService.selectedProject
    });
    this.androidService.startBuild(this.builderService.getSelectedProject(), this.androidBuildForm.value.androidVersion, 'aab').subscribe( (response: any) => {
      this.getBuildStatus();
    });
  }

  /**
   * Gets the build status from the builder and brain, incase the app is still progressing then we will rerun the same function checker again after 5 seconds
   */
  getBuildStatus = () => {
    this.androidService.getProgress(this.builderService.getSelectedProject(), this.androidBuildForm.value.androidVersion).subscribe((progressRes: any) => {
      this.value = progressRes.data;
      this.checkingStatus = false;
      if(this.value === 0 && progressRes.status !== "error") {
        this.buildStart = false;
      } else {
        if (this.value <= 100) {
          if (progressRes.status !== "error" && this.value == 100) {
            this.downloadBtn = progressRes.android_url;
            this.downloadAABBtn = progressRes.aab_url;
            this.qrLink = "https://qrcode.tec-it.com/API/QRCode?data=" + progressRes.android_url;
            this.error = false;
            this.buildStart = false;
          } else if (progressRes.status == "error") {
            this.error = true;
            this.getBuildError();
            this.builderService.headerBtn == 'android';
          } else {
            setTimeout(() => {
              this.getBuildStatus();
            }, 5000);
            this.error = false;
          }
          this.buildStart = true;
        }
      }
    })
  }

  getBuildError = () => {
    this.androidService.getBuildError(this.builderService.getSelectedProject()).subscribe((response: any) => {
      this.buildErrorMsg = decodeURIComponent(response.message.replace(/\+/g, ' '));
    })
  }

  rebuildApp = () => {
    this.buildStart = false;
    this.checkingStatus = false;
    this.error = false;
  }

  howItWorksDialog = () => {
    const dialogRef = this.dialog.open(WorksDialogComponent, {
      width: '700px',
      maxWidth: '80%',
      data: {name: "android"},
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
}
