<div class="SocialContainer">
  <mat-accordion>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <p>
          {{ "Auth0 Credentials" | translate }}
          </p>

        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="GooglePlus">
        <div>
          <mat-form-field appearance="outline">
            <mat-label>{{ "Auth0 Domain" | translate }}</mat-label>
            <input
              [(ngModel)]="auth0_domain"
              matInput
              placeholder="{{ 'Enter Auth0 Domain' | translate }}"
            />
          </mat-form-field>
        </div>

        <div>
          <mat-form-field appearance="outline">
            <mat-label>{{ "Auth0 Client ID'" | translate }}</mat-label>
            <input
              [(ngModel)]="auth0_clientId"
              matInput
              placeholder="{{ 'Enter Auth0 Client ID' | translate }}"
            />
          </mat-form-field>
        </div>

        <div>
          <mat-form-field appearance="outline">
            <mat-label>{{ "Auth0 Redirect Url'" | translate }}</mat-label>
            <input
              [(ngModel)]="auth0_redirect_uri"
              matInput
              placeholder="{{ 'Enter Auth0 Redirect Url' | translate }}"
            />
          </mat-form-field>
        </div>


        <div *ngIf="error" class="buildError">
          <h2>
            {{ error }}
          </h2>
        </div>

      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <form
    (ngSubmit)="saveToApplicationSettings()"
    [formGroup]="social"
    id="social"
  ></form>
</div>
