import { Component, ElementRef, ViewChild } from '@angular/core';
import {  stripeService } from './stripe.service';
import { environment } from 'src/environments/environment';
import { BuilderService } from 'src/app/builder-services/builder.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { PixelService } from 'ngx-multi-pixel';
import { MixpanelService } from 'src/app/tracking/mixpanel.service';
import { DiscordService } from 'src/app/builder-services/discord.service';
import { LoginService } from 'src/app/login/login.service';
import { AppSettingsService } from 'src/app/settings/services/app-settings.service';
import { Router } from '@angular/router';


declare var Stripe: any;
declare var paypal: any;

@Component({
  selector: 'payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']

})
export class PaymentComponent {

  private stripe: any;
  public subscribedPackage = '';
  public packages = environment.pricing_packages;
  public specificPackage:any;
  duration = 'Monthly';
  service_type = 'standalone';
  project_access = 'free';
  project_type = 'free';
  show_plans = false;
  show_single_plan = '';
  loadingStripe = false;
  constructor(private stripeService: stripeService, public builderService: BuilderService,
    private gaService: GoogleAnalyticsService,
    private pixel: PixelService,
    private mixPanel: MixpanelService,
    private discordService: DiscordService,
    private loginService: LoginService,
    private appSettings: AppSettingsService,
    private router: Router
  ) {
    this.getPayment();
    this.builderService.showUpdateProgress = true;
    this.builderService.getProject(this.builderService.selectedProject).subscribe((project: any) => {
      if(!this.builderService.selected_coupon_id && this.builderService.cookieGetter('coupon_id')) {
        this.builderService.selected_coupon_id = this.builderService.cookieGetter('coupon_id');
      }
      if(!this.builderService.selected_pricing_id && this.builderService.cookieGetter('pricing_id')) {
        this.builderService.selected_pricing_id = this.builderService.cookieGetter('pricing_id');
      }
      this.show_plans = true;
      this.builderService.showUpdateProgress = false;
      this.project_access = project.details[0].access;
      this.project_type = project.details[0].type;
      this.builderService.user_type = project.user_type;
      if( this.project_type == 'ltd' && (this.project_access == 'free' || this.project_access == 'full') ) {
        this.service_type = 'self_ltd';
        this.duration = 'Once';
      }
        // Remove this when you need to show all the packages
        if(environment.production) {
          this.show_single_plan = 'price_1OKQimDPC5jsdjOiWhKWOOmU'; 
        } else {
          this.show_single_plan = 'price_1NIufPDPC5jsdjOilTmgPbHS';
        }
        if(this.builderService.selected_pricing_id) {
          this.show_single_plan = this.builderService.selected_pricing_id; 
        }
        for (const key in this.packages) {
          if (Object.prototype.hasOwnProperty.call(this.packages, key)) {
            const element = this.packages[key];
            if(element.pricing_id == this.show_single_plan) {
              this.specificPackage = [element];
            }
          }
        }
        if(this.specificPackage) {
          this.duration = this.specificPackage[0].duration;
          this.service_type = this.specificPackage[0].service_type;
          // check for applied coupons
          if(this.specificPackage[0][this.builderService.selected_coupon_id]) {
            this.specificPackage[0].description = this.specificPackage[0][this.builderService.selected_coupon_id].description;
            this.specificPackage[0].price = this.specificPackage[0][this.builderService.selected_coupon_id].price;
            if( this.specificPackage[0][this.builderService.selected_coupon_id].paypal ) {
              this.specificPackage[0].paypal = this.specificPackage[0][this.builderService.selected_coupon_id].paypal;
            }
            
          }
          console.log(this.specificPackage[0].paypal);
          this.packages = this.specificPackage;
          if(this.specificPackage[0].paypal) {
            setTimeout(() => {
              this.paypalInit();  
            }, 4000);
          }
        }
      
    });
  }

  paypalSelfSubmit = (packagePaypal: string) => {
    this.notifyStacks('paypal');
    window.location.href = packagePaypal;
  }

  paypalInit() {
    let specificPackage = this.specificPackage[0];
    let that = this;
    paypal.Buttons({
      style: {
          shape: 'rect',
          color: 'gold',
          layout: 'vertical',
          label: 'subscribe'
      },
      createSubscription: function(data:any, actions:any) {
        that.notifyStacks('paypal');
        return actions.subscription.create({
          /* Creates the subscription */
          // plan_id: 'P-59C59100SH728615CMWE237I'  // live plan
          plan_id:  specificPackage.paypal// sandbox plan
        });
      },
      onApprove: function(data:any, actions:any) {
        that.createPremiumProject(data.subscriptionID);
      },
      fundingSource: "paypal" // Only allow PayPal and PayPal Credit
  }).render('#paypal-button-container-P-59C59100SH728615CMWE237I'); // Renders the PayPal button
  }

  createPremiumProject(paypal_sub_id:any) {
    let email = this.loginService.getCurrentUser().email;
    this.builderService.createFreeProject(email).subscribe((response:any) => {
      console.log(response);
      if(response.success == false) {
        alert(response.data);
        return;
      }
      this.builderService.selectedProject = response.project_id;
      localStorage.setItem('selectedProject', response.project_id);
      this.appSettings.addProjectDefaultSettings(response.project_id).subscribe(() => {
        this.builderService.convertToManualPaymentProject(email, response.project_id, true, paypal_sub_id, this.specificPackage[0].pricing_id ).subscribe((response_manual:any) => {
          // alert("Project Created Successfully, Project ID:" + response.project_id);
          // redirect to payment success page
          let package_type = {name: this.specificPackage[0].description, type: this.specificPackage[0].type, period: this.specificPackage[0].duration}
          this.router.navigate(['/payment_success'], { queryParams: { project_id: response.project_id, package: JSON.stringify(package_type) }});
        })
      });
    });
  }

  ngAfterViewInit() {
    this.stripe = Stripe(environment.stripePublishableKey);
  }

  notifyStacks(type = 'stripe') {
    this.gaService.gtag('event', 'Add to Cart', {'send_to': environment.gtm});
    this.gaService.gtag('event', 'Add to Cart', {'send_to': environment.ga});
    this.pixel.trackCustom('Add to Cart');
    this.mixPanel.init(this.builderService.selectedProject);
    this.mixPanel.track('Add to Cart', {
      projectid: this.builderService.selectedProject,
    });

    let content = 'New Customer Add to Cart through '+ type +' with email ' + this.loginService.getCurrentUser().email + ' for package ' + this.specificPackage[0].description;
    this.discordService.sendMessage( content, "Sales" )
      .subscribe(
        () => {
          console.log('Message sent to Discord successfully!');
          // Handle success
        },
        error => {
          console.error('Error sending message to Discord:', error);
          // Handle error
        }
      );
  }
  async redirectToCheckout(price_id: string, newLicense = false) {
    this.loadingStripe = true;
    this.notifyStacks('stripe');
    // There exists a license and we are purchasing a new license
    if( newLicense ) {
      this.stripeService.createSession(price_id, true).subscribe((session: any) => {
        this.loadingStripe = false;
        const result = this.stripe.redirectToCheckout({
          sessionId: session // Replace with the actual session ID
        });
        if (result.error) {
          // Handle any errors
          console.error(result.error.message);
        }
      })
    }
    else {
      if( this.subscribedPackage) {
        // We are already subscribed to a package
        this.stripeService.changePlan(price_id).subscribe((response: any) => {
          this.loadingStripe = false;
          window.location.href = response;
        });
      } else {
        this.stripeService.createSession(price_id).subscribe((session: any) => {
          this.loadingStripe = false;
          const result = this.stripe.redirectToCheckout({
            sessionId: session // Replace with the actual session ID
          });
          if (result.error) {
            // Handle any errors
            console.error(result.error.message);
          }
        })
      }
    }
    
  }

  getPayment() {
    this.builderService.getPackage().subscribe((response: any) => {
      for (let index = 0; index < response.length; index++) {
        const element = response[index];
        if( element.status == 'active' ) {
          let subscription_package = JSON.parse( element.package );
          this.subscribedPackage = subscription_package.price_id;
        }
      }
    });
  }

  durationSelect = (duration: string) => {
    this.duration = duration;
  }

  serviceTypeSelect = (serviceType: string) => {
    this.service_type = serviceType;
  }
}
