<div id="PNWrapper" class="containerWrapper">
<mat-accordion>
  <!-- Main Title: Stacks Broadcaster -->
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h1>{{ 'Stacks Broadcaster' | translate }}</h1>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div>
      <div class="howItWorks noselect" (click)="howItWorksDialog()">
        <span class="material-icons-outlined">play_circle_filled</span>
        {{ 'How it works' | translate }}
      </div>
      <h3>{{ 'Your place for push notifications' | translate }}</h3>

      <!-- Validation Form -->
      <div id="PNValidator" [formGroup]="PNForm">
        <p class="sectionHint">{{ 'To know how those fields work' | translate }} 
          <a href="https://stacksmarket.co/knowledge-base/activate-push-notifications-on-your-mobile-application/" target="_blank">{{ 'please go here' | translate }}</a>
        </p>
        <div class="senderContainer">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'Android Sender ID' | translate }}</mat-label>
            <input matInput formControlName="googleSenderId" placeholder="Android Sender ID" type="text">
          </mat-form-field>
        </div>
        <div class="notSettingsContainer">
          <div class="notSettings">
            <mat-progress-bar mode="determinate" value="{{ this.builderService.fileUploadProgress }}" *ngIf="this.builderService.showUpdateProgress"></mat-progress-bar>
            <label class="fileUpload" for="google_service_json">{{ 'Google Service JSON' | translate }}
              <input type="file" formControlName="googleServiceJson" id="google_service_json" name="google_service_json"
                accept="application/JSON" (change)="processFile($event, 'application/json', 'googleServiceJson')" #fileInput>
            </label>
            <div *ngIf="jsonFile" class="insertedFile">
              <img src="assets/images/file.png" alt="">
              <h3>{{ jsonFile.name }}</h3>
            </div>
          </div>

          <div class="notSettings">
            <label class="fileUpload" for="google_service_plist">{{ 'Google Service Plist' | translate }}
              <input type="file" formControlName="googleServicePlist" id="google_service_plist" name="google_service_plist"
                accept="*" (change)="processFile($event, 'application/octet-stream', 'googleServicePlist')" #fileInput>
            </label>
            <div *ngIf="plistFile" class="insertedFile">
              <img src="assets/images/file.png" alt="">
              <h3>{{ plistFile.name }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>

<!-- Push Notification Main Container -->
<div id="PNMainContainer" *ngIf="PNForm.valid">
  <div id="PNMessageContainer" [formGroup]="PNForm">
    <div id="PNFormFieldsWrapper">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'Title' | translate }}</mat-label>
        <input matInput placeholder="Awesome Offer" max-length="65" formControlName="title"
          value="{{ this.PNForm.value.title }}" (keyup)="characterCounter('title')">
      </mat-form-field>
      <span class="characterCounter" [ngClass]="titleClass">{{ titleCounter }} {{ 'Characters Left' | translate }}</span>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'Message' | translate }}</mat-label>
        <textarea matInput placeholder="An offer that you can't miss" max-length="240"
          formControlName="message" (keyup)="characterCounter('message')">{{ this.PNForm.value.message }}</textarea>
      </mat-form-field>
      <span class="characterCounter" [ngClass]="messageClass">{{ messageCounter }} {{ 'Characters Left' | translate }}</span>
    </div>
    <table mat-table [dataSource]="dataSource">
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>{{ 'name' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <input type="checkbox" name="userId" (click)="clickedUser(element)">
          {{ element.name }}
        </td>
      </ng-container>

      <!-- Username Column -->
      <ng-container matColumnDef="username">
        <th mat-header-cell *matHeaderCellDef>{{ 'Username' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.username }}</td>
      </ng-container>

      <!-- Email Column -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>{{ 'Email' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.email }}</td>
      </ng-container>

      <!-- Role Column -->
      <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef>{{ 'Role' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.role }}</td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row [class.demo-row-is-clicked]="clickedRows.has(row)"
        *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [length]="length" [pageSize]="50" aria-label="Select page">
    </mat-paginator>
    <button mat-icon-button id="sendPushNotifications" (click)="sendMessage()">
      <span *ngIf="!this.mobile">Send</span>
      <mat-icon class="material-icons-outlined" *ngIf="this.mobile">done</mat-icon>
    </button>
  </div>
  <div id="mainMobilePreviewShape">
    <div class="mobileAppPreview" id="mainMobileAppPreview">
      <div id="PNPreview">
        <div id="PNTitlePreview">{{ this.PNForm.value.title }}</div>
        <div id="PNMessagePreview">{{ this.PNForm.value.message }}</div>
      </div>
    </div>
    <div *ngIf="!this.mobile">
      <span id="firstMobBtn" class="mobBtns"></span>
      <span id="secondMobBtn" class="mobBtns"></span>
      <span id="thirdMobBtn" class="mobBtns"></span>
      <span id="fourthMobBtn" class="mobBtns"></span>
    </div>
  </div>
</div>
</div>
<form [formGroup]="PNForm" id="PNForm" (ngSubmit)="saveChanges()">
</form>
