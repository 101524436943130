<div class="containerWrapper">
    <!-- <mat-progress-bar mode="indeterminate" *ngIf="showTemplatesProgress"></mat-progress-bar> -->
    <div id="titleContainer" *ngIf="!builderService.getTour()">
        <h1>{{'App Preview' | translate }}</h1>
        <div class="howItWorks noselect" (click)="howItWorksDialog()">
            <span class="material-icons-outlined">play_circle_filled</span>
            {{'How it works' | translate }}
        </div>
    </div>
    <app-stepper step="6" *ngIf="builderService.getTour()"></app-stepper>
    <div *ngIf="builderService.getTour() && builderService.previewStarted" class="tourActionBtns">
        <!-- <button class="stacksBtn glow blue" (click)="subscribeNow()">Subscribe Now</button> -->
        <!-- <button class="stacksBtn green" (click)="editApp()">Edit the App</button> -->
    </div>
    <div *ngIf="buildStart && !checkingStatus && !error && value < 100" class="buildStart">
        <h1>{{'Hang Tight' | translate }}</h1>
        {{value}}%
        <mat-progress-bar mode="determinate" class="buildingBar" [value]="value"></mat-progress-bar>
    </div>
    <div id="stacksAppPreview">
        <button class="stacksBtn green" *ngIf="builderService.getTour()" (click)="editApp()">{{'Edit the App Design' | translate}}</button>
        <div class="mainMobilePreviewShape">
            <div id="mainMobileAppPreview">
                <div id="mobileBG" *ngIf="!builderService.previewStarted" (click)="startPreview()">
                    <h2>{{'Loading App' | translate }}</h2>
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                    <!-- <button class="stacksBtn green">Start Preview</button> -->
                </div>
                <iframe [src]="builderService.previewUrl | safe" width="300px" height="600px" frameborder="0" data-hj-allow-iframe=""></iframe>
            </div>
        </div>
        <!-- <iframe *ngIf="public_key"
        [src]="src"
        width="378px" height="800px" frameborder="0" scrolling="no"></iframe> -->
    </div>
</div>