<div class="containerWrapper">
    <!-- <mat-progress-bar mode="indeterminate" *ngIf="showTemplatesProgress"></mat-progress-bar> -->
    <div class="titleContainer">
        <h1>All Projects</h1>
        <mat-slide-toggle 
            [(ngModel)]="showAllProjects"
            color="primary"
            (ngModelChange)="showAllProjectsFn($event)"
            class="projectsStatus extrudedItem">
                Show All Projects
        </mat-slide-toggle>
        <mat-slide-toggle 
        [(ngModel)]="checked"
        color="primary"
        class="projectsStatus extrudedItem" *ngIf="showAllProjects">
            Hide Inactive Projects
        </mat-slide-toggle>
        <mat-form-field appearance="outline" id="searchProjectNumber"  *ngIf="!showAllProjects" (keyup)="applyFilter($event)">
            <mat-label>Search Project Number</mat-label>
            <input matInput placeholder="1111">
        </mat-form-field>
        <div id="projectsLimit" class="extrudedItem">
            You are using <span>{{builderService.projectsPremiumCount}}</span> projects out of <span>{{builderService.projectsLimit}}</span>
        </div>
    </div>
    <div class="titleContainer" *ngIf="loginService.getCurrentUser().admin">
        <mat-slide-toggle 
            [(ngModel)]="showActiveSubscriptions"
            color="primary"
            (ngModelChange)="showActiveSubscriptionsFn()"
            class="projectsStatus extrudedItem">
                Show Active Subscriptions
        </mat-slide-toggle>
    </div>
    <div class="grid">
        <div class="item addProject" *ngIf="builderService.projectsPremiumCount < builderService.projectsLimit" >
            <div class="content" (click)="createProject()">
                <mat-icon id="addProject">add</mat-icon>
            </div>
            <div class="footer">
                <p>Add New Project</p>
            </div>
        </div>
        <!-- Item -->
        <div class="item {{$any(project).type}} {{$any(project).status}}"
            [ngClass]="this.checked ? 'hideItem' : 'showItem'"
            *ngFor="let project of builderService.projects | slice:0:renderProjectsLimit">
            <div class="content" (click)="$any(project).status == 'active' ? getProject($any(project).id, 'clicked') : ''">
                <ul>
                    <!-- <li><p class="itemTitle">Project Name:</p><p class="itemContent">{{$any(project).id}}</p></li> -->
                    <li *ngIf="$any(project).premium_status == 'active'">
                        <p class="itemTitle">Project Type:</p>
                        <p class="itemContent itemType premium">Active Subscription</p>
                    </li>
                    <li><p class="itemTitle">Project Status:</p><p class="itemContent">{{$any(project).status}}</p></li>
                    <li><p class="itemTitle">Project ID:</p><p class="itemContent">{{$any(project).id}}</p></li>
                    <li><p class="itemTitle">URL:</p><p class="itemContent"><a href="{{$any(project).domain}}" target="_blank">{{$any(project).domain}}</a></p></li>
                    <li><p class="itemTitle">Email:</p><p class="itemContent">{{$any(project).email}}</p></li>
                </ul>
            </div>
            <div class="footer">
                <p>{{$any(project).id}}</p>
                <div class="actions">
                    <button class="material-icons"  *ngIf="$any(project).status == 'active'" (click)="$any(project).status == 'active' ? deleteProject($any(project).id) : ''">
                        power_settings_new
                    </button>
                    <button *ngIf="$any(project).status !== 'active' && builderService.projectsPremiumCount < builderService.projectsLimit && $any(project).type !== 'trial'" class="material-icons-outlined" (click)="$any(project).status !== 'active' ? activateProject($any(project).id) : ''">
                        power_settings_new
                    </button>
                    <!-- <button class="material-icons-outlined">
                        edit
                    </button> -->
                </div>
            </div>
        </div>

        <div class="item {{projectResult.type}} {{projectResult.status}}"
            [ngClass]="this.checked ? 'hideItem' : 'showItem'"
            *ngIf="showSearchResults">
            <div class="content" (click)="projectResult.status == 'active' ? getProject(projectResult.id, 'clicked') : ''">
                <ul>
                    <!-- <li><p class="itemTitle">Project Name:</p><p class="itemContent">{{projectResult.id}}</p></li> -->
                    <li *ngIf="projectResult.premium_status == 'active'">
                        <p class="itemTitle">Project Type:</p>
                        <p class="itemContent itemType premium">Active Subscription</p>
                    </li>
                    <li><p class="itemTitle">Project Status:</p><p class="itemContent">{{projectResult.status}}</p></li>
                    <li><p class="itemTitle">Project ID:</p><p class="itemContent">{{projectResult.id}}</p></li>
                    <li><p class="itemTitle">URL:</p><p class="itemContent"><a href="{{projectResult.domain}}" target="_blank">{{projectResult.domain}}</a></p></li>
                    <li><p class="itemTitle">Email:</p><p class="itemContent">{{projectResult.email}}</p></li>
                </ul>
            </div>
            <div class="footer">
                <p>{{projectResult.id}}</p>
                <div class="actions">
                    <button class="material-icons"  *ngIf="projectResult.status == 'active'" (click)="projectResult.status == 'active' ? deleteProject(projectResult.id) : ''">
                        power_settings_new
                    </button>
                    <button *ngIf="projectResult.status !== 'active' && builderService.projectsPremiumCount < builderService.projectsLimit && projectResult.type !== 'trial'" class="material-icons-outlined" (click)="projectResult.status !== 'active' ? activateProject(projectResult.id) : ''">
                        power_settings_new
                    </button>
                    <!-- <button class="material-icons-outlined">
                        edit
                    </button> -->
                </div>
            </div>
        </div>
    </div>
</div>
