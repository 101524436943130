import { Injectable } from '@angular/core';
import { BuilderService } from '../../builder-services/builder.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HelpersService } from '../../helpers.service';
import { DatabaseService } from '../../builder-services/database.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationsService {

  private builderUrl: string;
  options = {
    headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
  };
  token: any;

  constructor(private builderService: BuilderService, private helper: HelpersService, private http: HttpClient,
    private databaseService: DatabaseService) {
    this.builderUrl = this.helper.getBuilderUrl();
  }
  /**
   * Get all the users of the Customer website
   * @param id the Project ID
   */
  getUsers = (id: any) => {
    if(this.builderService.standalone) {
      return new Observable<any>((observer) => {
        this.databaseService.getDatabase('projects/' + this.builderService.selectedProject + '/users').subscribe((response: any) => {
          observer.next(response); // Notify the observer that the operation is complete
          observer.complete();
        });
      })
    } else {
      this.token = this.builderService.getToken();
      let postData = new URLSearchParams();
      postData.set('token', this.token);
      postData.set('projectId', id);
      return this.http.post(this.builderUrl + 'get-users', postData.toString(), this.options);
    }
  }
  /**
   * 
   * @param id Project Id
   * @param title The title of the Notification
   * @param message The Message of the Notification
   * @param users Comma Separated List of the users
   * @returns 
   * Send Push Notification
   */
  sendMessage = (id: any, title: string, message: string, users: any) => {
    this.token = this.builderService.getToken();
    // let postData = new URLSearchParams();
    // postData.set('projectId', id);
    // postData.set('title', title);
    // postData.set('message', message);
    // console.log(users);
    // if(this.builderService.standalone) {
    //   postData.set('users', JSON.stringify(users));
    // } else {
    //   postData.set('users', users);
    // }
    
    // postData.set('token', this.token);
    let data = { 
      'projectId'        : id,
      'title'        : title,
      'message'           : message,
      'users'         : users,
      'standalone'         : this.builderService.standalone,
      'token'         : this.token
    }
    let options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    }

    return this.http.post(this.builderUrl + 'send-notifications', JSON.stringify(data), options);
  }

  /**
   * Validates that the fields of the push notifications are saved correctly
   * @param id project id
   */
  validatePN = (id: any) => {
    this.token = this.builderService.getToken();
    let postData = new URLSearchParams();
    postData.set('projectId', id);
    postData.set('token', this.token);

    return this.http.post(this.builderUrl + 'validate-notifications', postData.toString(), this.options);
  }
}
