import { Component, Input, OnInit } from '@angular/core';
import { BlockDataService } from 'src/app/block-services/block-data.service';
import { BlockFieldsService } from 'src/app/block-services/block-fields.service';
import { BuilderService } from 'src/app/builder-services/builder.service';
import { IconsSelectorComponent } from 'src/app/pages/icons-selector/icons-selector.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProductsService } from '../block-products/products.service';
import { ProductsService as standaloneProductsService } from '../../e-commerce/products/products.service';
import { CategoriesService as standaloneCategoriesService } from '../../e-commerce/categories/categories.service';
import { CategoriesService } from '../block-categories/categories.service';
import { PostsService } from '../block-posts/posts.service';
import { LoginService } from 'src/app/login/login.service';

@Component({
  selector: 'block-footer',
  templateUrl: './block-footer.component.html',
  styleUrls: ['./block-footer.component.scss']
})
export class BlockFooterComponent implements OnInit {

  previewBlock = true;
  styleFields = false;
  blockData: any;

  previewFields = false;

  blockHeaderSrc = "";
  blockHeaderBg = "";
  blockFields: any;
  blockFieldsCustomPage: any;

  blockId = '';

  showProgress = false;

  toggleChecked = false;

  storedCategories: any = [];
  storedPosts: any = [];
  storedProducts: any = [];
  storedStandaloneProducts: any = [];
  storedStandaloneCategories: any = [];
  postTypes: any = [];
  rejectedTypes: any = ['attachment','nav_menu_item','page','product','wp_block','wp_navigation','wp_template','wp_template_part'];

  @Input() importedBlockData: any;

  constructor(public blockDataService: BlockDataService, 
    public blockFieldsService: BlockFieldsService, 
    public builderService: BuilderService,
    public dialog: MatDialog,
    private productsService: ProductsService,
    public standaloneProductsService: standaloneProductsService,
    public categoriesService: CategoriesService,
    public standaloneCategoriesService: standaloneCategoriesService,
    private postsService: PostsService,
    public loginService: LoginService) {
      this.blockDataService.getGlobalFooter().subscribe(() => {
        if (!this.blockId) {
          this.blockId = this.blockDataService.getUniqueId(2);
        }
        this.blockFields = this.getBlockFields(this.blockId);
        this.blockFieldsCustomPage = this.getBlockFieldsCustomPage(this.blockId);
        if(this.importedBlockData ) {
          this.previewBlock = true;
          this.previewFields = false;
          this.styleFields = false;
        }
        // if(this.builderService.globals || this.blockFieldsService.readGlobalFooter) {
        //   this.blockData = this.builderService.globalFooter;
        // } else {
        //   this.blockData = this.importedBlockData ? this.importedBlockData : this.blockFieldsService.getFooterBlockFields();
        // }
        let footerBlockData = this.blockDataService.getAllBlocksData()[this.blockDataService.getAllBlocksData().length - 1];
        // this.blockHeaderSrc = this.blockData.data?.src;
    
        // let footerBlockData = this.blockDataService.getSelectedBlockData(this.blockId);
        if( footerBlockData && footerBlockData.data.global_footer == undefined) {
          footerBlockData.data.global_footer = true;
        }
  
        if(this.builderService.globals || footerBlockData?.data?.global_footer || footerBlockData?.data?.global_footer == undefined) {
          this.blockData = this.builderService.globalFooter;
          footerBlockData = this.builderService.globalFooter ? this.builderService.globalFooter : {};
          this.blockFieldsService.readGlobalFooter = true;
          if(footerBlockData && footerBlockData.data) {
            footerBlockData.data.global_footer = true;
          } else {
            footerBlockData.data = {
              global_footer : true
            }
          }
          
          // console.log("Read from Global Footer", footerBlockData);
        } else {
          this.blockData = this.importedBlockData ? this.importedBlockData : this.blockFieldsService.getFooterBlockFields();
          footerBlockData = this.blockFieldsCustomPage;
          this.blockFieldsService.readGlobalFooter = footerBlockData.data?.global_footer == undefined ? true : footerBlockData.data?.global_footer;
          // console.log("Read from Local Footer");
        }
  
  
        this.toggleChecked = footerBlockData.data?.type == 'icon' ? true : false;
        this.blockFieldsService.readGlobalFooter = footerBlockData.data?.global_footer == undefined ? true : footerBlockData.data?.global_footer;
        
        console.log(footerBlockData.data?.footerStyle);
        if(footerBlockData.data && !footerBlockData.data.footerStyle) {
          footerBlockData.data.footerStyle = 'classic';
        }
        if( this.categoriesService.storedCategories.length == 0 ) {
          this.getCategories(this.builderService.getSelectedProject(), this.blockFieldsService.categoriesBlockData.data, true);
        }
        
        this.getPosts(this.builderService.getSelectedProject(), this.blockFieldsService.postsBlockData.data, true);
    
        this.getProducts(this.builderService.getSelectedProject(), this.blockFieldsService.productsBlockData.data, true);
        this.getStandaloneProducts();
        this.getStandaloneCategories();
        // this.toggleChanged(this.blockId, 'global_footer');
  
        // if( this.builderService.globals ) {
        //   let footerBlockFields = this.blockDataService.getSelectedBlockData(this.blockId);
        //   this.builderService.globalFooter = footerBlockFields;
        // }
    
        let gAdMob = footerBlockData.data?.gFooterBanners;
        if(gAdMob) {
          let gAdmobFooterFlag = false;
          for (const key in gAdMob) {
            if (Object.prototype.hasOwnProperty.call(gAdMob, key)) {
              const element = gAdMob[key];
              // console.log(element);
              if( element.status == 'active' ) {
                gAdmobFooterFlag = true;
              }
            }
          }
          this.blockDataService.gAdMobActiveFooter = gAdmobFooterFlag;
        }
    
        /**
           * Get Post Types and load the variables
           */
         this.getPostTypes();
      })
  };

  ngOnInit() {
    
  }

  getPostTypes = () => {
    this.postsService.getPostTypes().subscribe((response) => {
      let formattedResult:any = [];
      for (const key in response) {
          const element = response[key].slug;
          let index = this.rejectedTypes.indexOf(element);
          if (index !== -1) {
            delete response.element;
          } else {
            formattedResult.push( element );
          }
      }
      this.postTypes = formattedResult;
    }, (err) => {

    });
  };
  toggleChanged(blockId:any, type: string = 'background') {
    // Check Block Availability before taking action
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      return;
    }
    // let productsBlockFields = this.blockDataService.getSelectedBlockData(blockId);
    let productsBlockFields = this.getBlockFields(blockId);
    /**
     * Modifying Nested Object Directly doesn't work, so we have to clone the data object first then modify it
     */
    let clonedBlockFieldsData = { ...productsBlockFields.data };
    let clonedBlockFieldsStyle = { ...productsBlockFields.style };

    if( type == 'background') {
      
      clonedBlockFieldsData['type'] = this.toggleChecked ? 'color' : 'image';

    } else if(type == 'global_footer') {

      clonedBlockFieldsData['global_footer'] = this.blockFieldsService.readGlobalFooter ? true : false;
      this.getBlockFieldsCustomPage(blockId).data.global_footer = clonedBlockFieldsData['global_footer'];
      this.blockFields = this.getBlockFields(this.blockId);

    }

    productsBlockFields.data = clonedBlockFieldsData;
    productsBlockFields.style = clonedBlockFieldsStyle;
    if( this.builderService.globals ) {
      this.builderService.globalFooter = productsBlockFields;
    }
  }
  /**
   * Appends Block to the Preview, Initial checks if the block is added as initial block addition or appended live
   */
  addBlockToPreview(initial: any = '') {
    let blockHeaderData = {
      component: BlockFooterComponent,
      index: 'latest',
      element: this,
      initial: initial
    };
    this.blockDataService.sendBlockData(blockHeaderData);
  }

  appendBlock() {
    let clonedBlockData = { ...this.blockData };
    this.blockId = this.blockDataService.appendBlocksData(clonedBlockData);
  }

  /**
   * Sync the clicked block on click inside the mobile preview
   */
  showData(blockId: any) {
    // Check Block Availability before taking action
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      // show data of the last element instead
      blockId = this.blockDataService.getLatestBlockId();
    }
    this.blockFieldsService.clickedBlockId = blockId;
    this.blockDataService.sendCurrentBlock(BlockFooterComponent);
  }

  queryChange(query: any, blockId: any, $event: any, type: string, index: 0, dataType:string = 'menuItems') {
    // Check Block Availability before taking action
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      return;
    }
    let productsBlockFields = this.getBlockFields(blockId);
    /**
     * Modifying Nested Object Directly doesn't work, so we have to clone the data object first then modify it
     */
    let clonedBlockFieldsData = { ...productsBlockFields.data };
    let data = '';
    if (type == 'select') {
      data = $event.value
    } else if (type == 'text') {
      data = $event.currentTarget.value;
    } else if (type == 'slider') {
      data = $event.currentTarget.value;
    } else if (type == 'multilevelText') {
      data = $event.currentTarget.value;
    } else if (type == 'multilevelSelect') {
      data = $event.value;
    }

    if( type == 'multilevelText' || type == 'multilevelSelect' ) {
      clonedBlockFieldsData[dataType][index][query] = data;  
    } else {
      clonedBlockFieldsData[query] = data;
    }
    productsBlockFields.data = clonedBlockFieldsData;
    // console.log(productsBlockFields.data);
    // this.builderService.globalFooter = productsBlockFields;
    let gAdMob = productsBlockFields.data.gFooterBanners;
    if(gAdMob) {
      let gAdmobFooterFlag = false;
      for (const key in gAdMob) {
        if (Object.prototype.hasOwnProperty.call(gAdMob, key)) {
          const element = gAdMob[key];
          if( element.status == 'active' ) {
            gAdmobFooterFlag = true;
          }
        }
      }
      this.blockDataService.gAdMobActiveFooter = gAdmobFooterFlag;
    }
    if(query == 'post_type') {
      this.blockFieldsService.postsBlockData.data.post_type = data
      this.getPosts(this.builderService.selectedProject, this.blockFieldsService.postsBlockData.data, false);
    }

    if( data == 'posts') {
      this.getPosts(this.builderService.getSelectedProject(), clonedBlockFieldsData, false);
    }

    if( data == 'products' && !this.builderService.standalone) {
      this.getProducts(this.builderService.getSelectedProject(), clonedBlockFieldsData, false);
    }
    if( data == 'products' && this.builderService.standalone) {
      this.getStandaloneProducts()
    }
    if( data == 'productsCategories' && this.builderService.standalone) {
      this.getStandaloneCategories();
    }
    
  }

  

  processFile(fileInput: any, type: string, name: string, blockId: string) {
    const file: File = fileInput.target.files[0];
    const reader = new FileReader();
    this.showProgress = true;
    reader.addEventListener('load', (event: any) => {
      this.builderService.uploadFileCustomerServer(file, this.builderService.selectedProject, type).subscribe(
        (res) => {
          this.showProgress = false;
          let imgBlockFields = this.getBlockFields(blockId);
          /**
           * Modifying Nested Object Directly doesn't work, so we have to clone the data object first then modify it
           */
          let clonedBlockFieldsData = { ...imgBlockFields.data };
          clonedBlockFieldsData.src = res;
          imgBlockFields.data = clonedBlockFieldsData;
        },
        (err) => {
          this.showProgress = false;
          alert("error uploading file, please contact support");
        })
    });

    reader.readAsDataURL(file);
  }
  /**
   * get all the image block fields from the service and adjust the src only
   * @param $event change event on the input field
   */
  imgSrcChange($event: any, blockId: any) {
    // Check Block Availability before taking action
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      return;
    }

  }

  styleChange(style: any, blockId: any, $event: any, type: string, index: 0) {
    // Check Block Availability before taking action
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      return;
    }
    let textBlockFields = this.getBlockFields(blockId);
    /**
     * Modifying Nested Object Directly doesn't work, so we have to clone the data object first then modify it
     */
    let clonedBlockFieldsData = { ...textBlockFields.style };
    let data = '';
    if (type == 'select') {
      data = $event.value
    } else if (type == 'text') {
      data = $event.currentTarget.value;
    } else if (type == 'slider') {
      data = $event.value + 'px';
    }

    clonedBlockFieldsData[style] = data;
    textBlockFields.style = clonedBlockFieldsData;
  }

  getBlockFields = (id: string) => { 
    if( !this.builderService.globals && !this.blockFieldsService.readGlobalFooter ) {
      return this.getBlockFieldsCustomPage(id);
    }
    if(this.builderService.globalFooter == undefined) {
      this.builderService.globalFooter = this.blockDataService.tempFooter;
    }
    // if(this.builderService.globalFooter && this.builderService.globalFooter?.data?.global_footer == this.builderService.globalFooter?.global_footer ) {
    //   const data = { ...this.builderService.globalFooter };
    //   delete data.data;
    //   this.builderService.globalFooter.data = data;
    // }
    return this.builderService.globalFooter;
  }

  getBlockFieldsCustomPage = (id: string) => { 
    if(this.importedBlockData) {
      return this.importedBlockData;
    }
    if(!this.blockDataService.blocksData) {
      return false;
    }
    return this.blockDataService.getSelectedBlockData(id);
  }

  showStyleTab = () => {
    this.previewBlock = false;
    this.previewFields = false;
    this.styleFields = true;
  }

  showDataTab = () => {
    this.previewBlock = false;
    this.previewFields = true;
    this.styleFields = false;
  }

  checkSidebar = () => {
    if (!this.previewBlock && !this.previewFields && !this.styleFields) {
      return true;
    }
    return false;
  }

  checkPreview = () => {
    if (this.previewBlock && !this.previewFields && !this.styleFields) {
      return true;
    }
    return false;
  }

  checkData = () => {
    if (this.previewFields) {
      return true;
    }
    return false;
  }

  checkStyle = () => {
    if (this.styleFields && !this.previewFields && !this.previewBlock) {
      return true;
    }
    return false;
  }

  checkSettings = () => {
    if (!this.checkSidebar() && !this.checkPreview()) {
      return true;
    }
    return false;
  }

  addMenuItem = (blockId: string) => {
    // Check Block Availability before taking action
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      return;
    }
    let footerBlockFields = this.getBlockFields(blockId);
    /**
     * Modifying Nested Object Directly doesn't work, so we have to clone the data object first then modify it
     */
    let clonedBlockFieldsData = { ...footerBlockFields.data };
    console.log(clonedBlockFieldsData);
    let data: any = '';
    if(!clonedBlockFieldsData.menuItems) {
      clonedBlockFieldsData = {
        'menuItems' : [{
          'index' : 0,
          'icon'  : 'home',
          'color' : '#000000',
          'link'  : 'home', 
          'menuText'  : 'Home' 
        }]
      }
    } else {
      clonedBlockFieldsData.menuItems.push(
        {
          'index' : clonedBlockFieldsData.menuItems.length,
          'icon'  : 'home',
          'color' : '#000000',
          'link'  : 'home', 
          'menuText'  : 'Home' 
        }
      );
    }
    footerBlockFields.data = clonedBlockFieldsData;
    this.builderService.globalFooter = footerBlockFields;
  }

  addGBannerItem = (blockId: string) => {
    // Check Block Availability before taking action
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      return;
    }
    let footerBlockFields = this.getBlockFields(blockId);
    /**
     * Modifying Nested Object Directly doesn't work, so we have to clone the data object first then modify it
     */
    let clonedBlockFieldsData = { ...footerBlockFields.data };
    let data: any = '';
    if( !clonedBlockFieldsData.gFooterBanners ) {
      clonedBlockFieldsData["gFooterBanners"] = [{
          'index' : 0,
          'status'  : 'inactive',
          'bannerAdId' : '',
          'gFooterBannerPage'  : 'Home', 
        }];
    } else {
      clonedBlockFieldsData.gFooterBanners.push(
        {
          'index' : clonedBlockFieldsData.gFooterBanners.length,
          'status'  : 'inactive',
          'bannerAdId' : '',
          'gFooterBannerPage'  : 'home', 
        }
      );
    }
    footerBlockFields.data = clonedBlockFieldsData;
    
  }

  selectIconDialog = (blockId: string, menuIndex: any) => {
    const dialogRef = this.dialog.open(IconsSelectorComponent, {
      width: '1000px',
      height: '650px',
      maxWidth: '100%',
    });
    dialogRef.afterClosed().subscribe(() => {
      let menuItemsBlockFields = this.getBlockFields(blockId);
      for (let index = 0; index < menuItemsBlockFields.data.menuItems.length; index++) {
        const element = menuItemsBlockFields.data.menuItems[index];
        if( element.index == menuIndex) {
          menuItemsBlockFields.data.menuItems[menuIndex].icon = this.builderService.selectedIcon;
          this.builderService.globalFooter.data.menuItems[menuIndex].icon = this.builderService.selectedIcon;
        }
      }
    });
  }

  getCategories = (projectId: number, args: object, cached = true) => {
    this.showProgress = true;
    if( cached ) {
      this.categoriesService.getCategoriesCached().subscribe((response) => {
        let categories: any = [];
        for (const key in response) {
          if (Object.prototype.hasOwnProperty.call(response, key)) {
            const element = response[key];
            if (element.count > 0) {
              categories.push({ 'id': element.term_id, "name": element.name, "thumbnail": element.image_url });
            }
          }
        }
        this.showProgress = false;
        this.categoriesService.storedCategories = categories;
      }, (err) => {
  
      });
    } else {
      this.categoriesService.getCategories(projectId, args).subscribe((response) => {
        let categories: any = [];
        for (const key in response) {
          if (Object.prototype.hasOwnProperty.call(response, key)) {
            const element = response[key];
            if (element.count > 0) {
              categories.push({ 'id': element.term_id, "name": element.name, "thumbnail": element.image_url });
            }
          }
        }
        this.showProgress = false;
        this.categoriesService.storedCategories = categories;
      }, (err) => {
  
      });
    }
    
    return true;
  }

  getPosts = (projectId: number, args: object, cached = true) => {
    this.showProgress = true;
    if(cached) {
      this.postsService.getPostsCached().subscribe((response) => {
        this.showProgress = false;
        let posts: any = [];
        for (const key in response) {
          if (Object.prototype.hasOwnProperty.call(response, key)) {
            const element = response[key];
            if (element) {
              posts.push({ 'ID': element.ID, "post_title": element.post_title, "post_image": element.post_image});
            }
          }
        }
        this.storedPosts = posts;
      }, (err) => {
  
      });
    } else {
      this.postsService.getPosts(projectId, args).subscribe((response) => {
        this.showProgress = false;
        let posts: any = [];
        for (const key in response) {
          if (Object.prototype.hasOwnProperty.call(response, key)) {
            const element = response[key];
            if (element) {
              posts.push({ 'ID': element.ID, "post_title": element.post_title, "post_image": element.post_image});
            }
          }
        }
        this.storedPosts = posts;
      }, (err) => {
  
      });
    }
    
    return true;
  }

  getProducts = (projectId: number, args: object, cached = true) => {
    this.showProgress = true;
    if(cached) {
      this.productsService.getProductsCached().subscribe((response) => {
        this.showProgress = false;
        let products: any = [];
        let responseProducts = response.products;
        for (const key in responseProducts) {
          if (Object.prototype.hasOwnProperty.call(responseProducts, key)) {
            const element = responseProducts[key];
            if (element) {
              products.push({ 'id': element.id, "name": element.name});
            }
          }
        }
        this.storedProducts = products;
      }, (err) => {
  
      });
    } else {
      this.productsService.getProducts(projectId, args).subscribe((response) => {
        this.showProgress = false;
        let products: any = [];
        let responseProducts = response.products;
        for (const key in responseProducts) {
          if (Object.prototype.hasOwnProperty.call(responseProducts, key)) {
            const element = responseProducts[key];
            if (element) {
              products.push({ 'id': element.id, "name": element.name});
            }
          }
        }
        this.storedProducts = products;
      }, (err) => {
  
      });
    }
    
    return true;
  }

  getStandaloneProducts = () => {
    this.standaloneProductsService.getProducts().subscribe((response_products: any) => {
      let products: any = [];
      for (const key in response_products) {
        if (Object.prototype.hasOwnProperty.call(response_products, key)) {
          const element = response_products[key];
          products.push({ 'id': key, "name": element.productName});
        }
      }
      this.standaloneProductsService.storedStandaloneProducts = products;
    });
  }
  getStandaloneCategories = () => {
    this.standaloneCategoriesService.getCategories().subscribe((response: any) => {
      let categories: any = [];
      for (const key in response) {
        if (Object.prototype.hasOwnProperty.call(response, key)) {
          const element = response[key];
          categories.push({ 'id': element.categoryId, "name": element.categoryName, "thumbnail": element.categoryImage });
        }
      }
      this.standaloneCategoriesService.storedStandaloneCategories = categories;
    })
  }

  deleteMenuItem = (blockId: string, menuIndex: number) => {
    let menuItemsBlockFields = this.getBlockFields(blockId);
    menuItemsBlockFields.data.menuItems.splice(menuIndex,1);
    this.reindexMenuItems(blockId);
  }

  deleteGBannerItem = (blockId: string, gFooterBannerIndex: number) => {
    let menuItemsBlockFields = this.getBlockFields(blockId);
    menuItemsBlockFields.data.gFooterBanners.splice(gFooterBannerIndex,1);
    this.reindexMenuItems(blockId);
  }

  reindexMenuItems = (blockId: string) => {
    let menuItemsBlockFields = this.getBlockFields(blockId);
    for (let i = 0; i < menuItemsBlockFields.data.menuItems.length; i++) {
      menuItemsBlockFields.data.menuItems[i].index = i;
    }
  }

}
