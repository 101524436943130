import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrdersService } from '../orders.service';
import { SettingsService } from '../../settings/settings.service';
import { BuilderService } from 'src/app/builder-services/builder.service';
import { DatabaseService } from 'src/app/builder-services/database.service';

@Component({
  selector: 'app-single-order',
  templateUrl: './single-order.component.html',
  styleUrls: ['./single-order.component.scss']
})
export class SingleOrderComponent implements OnInit {

  public orderIdFromRoute: any = [];
  mobile = false;
  orderData:any;
  currency = '';
  possibleStatuses = [
    'Pending',
    'Order Received',
    'Processing',
    'Completed',
    'Cancelled',
    'Refunded',
    'Failed',
    'On-Hold',
    'Trash'
  ];
  selectedStatus = 'Order Received';
  allowUpdateStatus = false;
  constructor(private route: ActivatedRoute, public orderService: OrdersService, private settingsService: SettingsService,private builderService: BuilderService,private db: DatabaseService) { }

  ngOnInit(): void {
    const routeParams = this.route.snapshot.paramMap;
    this.orderIdFromRoute = routeParams.get('orderId');
    this.settingsService.getSettings().subscribe((response: any) => {
      this.currency = response.currency;
    })
    if (this.orderIdFromRoute) {
      this.orderService.getOrder(this.orderIdFromRoute).subscribe((orderData: any) => {
        this.orderData = orderData[0];
        this.selectedStatus = this.orderData.status;
        for (const key in this.orderData.address) {
          if (Object.prototype.hasOwnProperty.call(this.orderData.address, key)) {
            const element = this.orderData.address[key];
            this.orderData.address = element;
          }
        }
      })

    }

    if (window.screen.width <= 768) { // 768px portrait
      this.mobile = true;
    }
  }

  updateStatus(status: string) {
    this.orderService.updateOrderStatus(this.orderIdFromRoute, status, this.orderData.user_id).subscribe((response: any) => {
      this.orderData.status = status;
      this.allowUpdateStatus = false;

        if (this.orderData.status === 'Completed') {
          this.addPointsToUser();
        } else {
          this.removePointsFromUser();
        }
      });
  }

/*
  The `addPointsToUser` and `removePointsFromUser` functions manage the points data for a user within the database.
  1. **Adding Points to User**:
    - Constructs the database path where the points data will be saved using `selectedProject`, `user_id`, and `order_id` from `orderData`.
    - Checks if `toBeEarned` data exists and is not empty:
      - If `toBeEarned` exists:
        - Maps the `toBeEarned` array to prepare the points data.
        - Fetches existing points data from the database:
          - If existing data is found, it appends the new points to the existing data.
          - If no data exists, it uses only the new points.
        - Calls `setDatabase` with the updated points array.
        - **Success Handling**:
          - Logs a message indicating successful saving of the points data.
        - **Error Handling**:
          - Logs an error message if fetching or saving the points data fails.
      - If `toBeEarned` is undefined or empty:
        - Logs a message indicating that there is no data to move.

  2. **Removing Points from User**:
    - Constructs the database path where the points data will be removed using the `selectedProject`, `user_id`, and `order_id` from `orderData`.
    - Calls `removeDatabaseEndpoint` with the constructed path to remove the points data.
    - **Success Handling**:
      - Logs a message indicating successful removal of points data.
    - **Error Handling**:
      - Logs an error message if removing the points data fails.
*/

  addPointsToUser() {
    if (this.orderData.order.toBeEarned) {
      const databasePath = `/projects/${this.builderService.selectedProject}/users/${this.orderData.user_id}/points/${this.orderData.order_id}`;
      const pointsData: any[] = this.orderData.order.toBeEarned.map((item: any) => item);
      this.db.getDatabase(databasePath).subscribe(
        (existingData: any[] | null) => {
          let updatedPoints: any[] = [];
          if (Array.isArray(existingData)) {
            updatedPoints = [...existingData, ...pointsData];
          } else {
            updatedPoints = pointsData;
          }
  
          this.db.setDatabase(databasePath, updatedPoints, false).subscribe(
            (response) => {
              console.log('toBeEarned points moved successfully');
            },
          );
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      console.log('toBeEarned is either undefined or empty. Nothing to move.');
    }
  }

  /*
  The `removePointsFromUser` function removes points data from the database for a specific user and order.
    1. **Construct Database Path**:
      - Builds the path to the points data using:
        - `selectedProject` from `builderService`
        - `user_id` from `orderData`
        - `order_id` from `orderData`

    2. **Remove Points Data**:
      - Calls `removeDatabaseEndpoint` on `db` with the constructed path to delete the points data.
      - **Success Handling**:
        - Logs a message indicating that points were removed successfully.
      - **Error Handling**:
        - Logs an error message if the removal operation fails.
*/

  removePointsFromUser() {
    const databasePath = `/projects/${this.builderService.selectedProject}/users/${this.orderData.user_id}/points/${this.orderData.order_id}`;

    this.db.removeDatabaseEndpoint(databasePath).subscribe(
      (response) => {
        console.log('Points removed successfully');
      },
      (error) => {
        console.error('Error removing points', error);
      }
    );
  }
}
