import { Component, OnInit } from '@angular/core';
import { BuilderService } from 'src/app/builder-services/builder.service';
import { stripeService } from '../payment/stripe.service';
import { environment } from 'src/environments/environment';
import { BlockDataService } from 'src/app/block-services/block-data.service';

@Component({
  selector: 'billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent implements OnInit {
  environment = environment;
  constructor(public builderService: BuilderService, public stripeService: stripeService
    , private blockDataService: BlockDataService) {
      this.blockDataService.getStripeData().subscribe((subId: any) => {
        this.builderService.showUpdateProgress = true;
        this.stripeService.getInvoices(subId).subscribe((response: any) => {
          this.builderService.showUpdateProgress = false;
          this.builderService.invoices = response;
          this.builderService.subscription_package = JSON.parse( this.builderService.active_subscription_plan.package );
        })
      });
      if( this.builderService.stripe_subscription_id ) {
        this.blockDataService.sendStripeData(this.builderService.stripe_subscription_id);
      }

     }

  ngOnInit(): void {
    

  }

  billingRedirect = () => {
    this.stripeService.gotoBilling().subscribe((response: any) => {
      window.location.href = response;
    })    
  }

}
