import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { BuilderService } from 'src/app/builder-services/builder.service';
import { DatabaseService } from 'src/app/builder-services/database.service';
import { HelpersService } from 'src/app/helpers.service';

@Injectable({
  providedIn: 'root'
})
export class AppSettingsService {

  private builderUrl: string;

  options = {
    headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
  };
  token: any;

  constructor(private http: HttpClient, private helper: HelpersService, private builderService: BuilderService,
    private db: DatabaseService) {
    this.builderUrl = this.helper.getBuilderUrl();
    this.token = this.builderService.getToken();
  }

  public uploadImage(image: File, id: any, type: string): Observable<any>  {
//     console.log(image.name, id, type);
//     // Initialize Firebase
// //     // Create a root reference
//     const storage = getStorage();
// //     // Create a reference to 'mountains.jpg'
//     const mountainsRef = refStorage(storage, '/project_assets/images/'+id+'/'+image.name);
//     // 'file' comes from the Blob or File API
//     uploadBytes(mountainsRef, image).then((snapshot) => {
//       const formData = new FormData();
//       this.token = this.builderService.getToken();
//       formData.append('image', image);
//       formData.append('token', this.token);
//       formData.append('projectId', id);
//       formData.append('image_type', type);

//       return this.http.post(this.builderUrl + 'receive-image', formData);
//     });
    

    // // // Create a reference to 'images/mountains.jpg'
    // const mountainImagesRef = refStorage(storage, 'images/mountains.jpg');

    const formData = new FormData();
    this.token = this.builderService.getToken();
    formData.append('image', image);
    formData.append('token', this.token);
    formData.append('projectId', id);
    formData.append('image_type', type);

    return this.http.post(this.builderUrl + 'receive-image', formData);
  }

  public uploadFile(file: File, id: any, type: string, name: string = ''): Observable<any> {
    const formData = new FormData();
    this.token = this.builderService.getToken();
    formData.append('file', file);
    formData.append('token', this.token);
    formData.append('projectId', id);
    formData.append('file_type', type);
    formData.append('file_name', name);

    return this.http.post(this.builderUrl + 'receive-file', formData);
  }

  setGeneralSettings = (id: any, generalSettings: Object) => {
    if(this.builderService.standalone) {
      generalSettings = JSON.parse(JSON.stringify(generalSettings));
      return new Observable<any>((observer) => {
        this.db.setDatabase('projects/' + id + '/settings/general_settings', generalSettings).subscribe((response) => {
          // Save the General Settings to the WP Database, in order for the splash and icon to be updated
          // @TODO make the splash and icon update to be done from the firebase database
          let postData = new URLSearchParams();
          this.token = this.builderService.getToken();
          postData.set('process', "sync_ui");
          postData.set('projectId', id);
          postData.set('generalSettings', JSON.stringify(generalSettings));
          postData.set('token', this.token);

          this.http.post(this.builderUrl + 'update-general-settings', postData.toString(), this.options).subscribe((response) => {
            
          });

          observer.next(response); // Notify the observer that the operation is complete
          observer.complete(); // Complete the Observable
        }, (error) => {
          observer.error(error); // Notify the observer of an error
        });
      });
    } else {
      this.db.setDatabase('projects/' + id + '/settings/general_settings', generalSettings).subscribe((response) => {
        // Save the General Settings to the WP Database, in order for the splash and icon to be updated
        // @TODO make the splash and icon update to be done from the firebase database
        let postData = new URLSearchParams();
        this.token = this.builderService.getToken();
        postData.set('process', "sync_ui");
        postData.set('projectId', id);
        postData.set('generalSettings', JSON.stringify(generalSettings));
        postData.set('token', this.token);

        this.http.post(this.builderUrl + 'update-general-settings', postData.toString(), this.options).subscribe((response) => {
          
        });

      }, (error) => {
      });
      let postData = new URLSearchParams();
      this.token = this.builderService.getToken();
      postData.set('process', "sync_ui");
      postData.set('projectId', id);
      postData.set('generalSettings', JSON.stringify(generalSettings));
      postData.set('token', this.token);

      return this.http.post(this.builderUrl + 'update-general-settings', postData.toString(), this.options);
    }

  }

  getGeneralSettings = (id: any) => {
    // if(this.builderService.standalone)  {
    //   return new Observable<any>((observer) => {
    //     this.db.getDatabase('projects/' + id + '/settings/general_settings').subscribe((response) => {
    //       if(response) {
    //         observer.next(response); // Notify the observer that the operation is complete
    //         observer.complete(); // Complete the Observable
    //       } else {
    //         console.log("No data available");
    //         observer.next({});
    //         observer.complete(); // Complete the Observable
    //       }
    //     }, (error) => {
    //       observer.error(error); // Notify the observer of an error 
    //     });
    //   });
    // } else {
      let postData = new URLSearchParams();
      this.token = this.builderService.getToken();
      postData.set('process', "sync_ui");
      postData.set('projectId', id);
      postData.set('token', this.token);

      return this.http.post(this.builderUrl + 'get-general-settings', postData.toString(), this.options);
    // }
  }

  setApplicationSettings = (id: any, applicationSettings: Object) => {
    if(this.builderService.standalone) {
      applicationSettings = JSON.parse(JSON.stringify(applicationSettings));
      return new Observable<any>((observer) => {
        this.db.setDatabase('projects/' + id + '/settings/application_settings', applicationSettings).subscribe((response) => {
          observer.next(response); // Notify the observer that the operation is complete
          observer.complete(); // Complete the Observable
        }, (error) => {
          observer.error(error); // Notify the observer of an error
        })
      });
    } else {
      applicationSettings = JSON.parse(JSON.stringify(applicationSettings));
      return new Observable<any>((observer) => {
        this.db.setDatabase('projects/' + id + '/settings/application_settings', applicationSettings).subscribe((response) => {
          let postData = new URLSearchParams();
          this.token = this.builderService.getToken();
          postData.set('process', "sync_ui");
          postData.set('projectId', id);
          postData.set('applicationSettings', JSON.stringify(applicationSettings));
          postData.set('token', this.token);

          this.http.post(this.builderUrl + 'update-application-settings', postData.toString(), this.options);
          observer.next(response); // Notify the observer that the operation is complete
          observer.complete(); // Complete the Observable
        }, (error) => {
          observer.error(error); // Notify the observer of an error
        })
      });
      
    }
  }

  setWPApplicationSettings = (id: any, applicationSettings: Object) => {
    let postData = new URLSearchParams();
    this.token = this.builderService.getToken();
    postData.set('process', "sync_ui");
    postData.set('projectId', id);
    postData.set('applicationSettings', JSON.stringify(applicationSettings));
    postData.set('token', this.token);

    return this.http.post(this.builderUrl + 'update-application-settings', postData.toString(), this.options);
  }

  getApplicationSettings = (id: any) => {
    // if(this.builderService.standalone) {
    //   return new Observable<any>((observer) => {
    //     this.db.getDatabase('projects/' + id + '/settings/application_settings').subscribe((response) => {
    //       if(response) {
    //         observer.next(response); // Notify the observer that the operation is complete
    //         observer.complete(); // Complete the Observable
    //       } else {
    //         console.log("No data available");
    //         observer.next({});
    //         observer.complete(); // Complete the Observable
    //       }
    //     }, (error) => {
    //       observer.error(error); // Notify the observer of an error
    //     });
    //   });
    // } else {
      let postData = new URLSearchParams();
      this.token = this.builderService.getToken();
      postData.set('process', "sync_ui");
      postData.set('projectId', id);
      postData.set('token', this.token);

      return this.http.post(this.builderUrl + 'get-application-settings', postData.toString(), this.options);
    // }
  }


  setContentSettings = (id: any, contentSettings: Object) => {
    if(this.builderService.standalone) {
      contentSettings = JSON.parse(JSON.stringify(contentSettings));
      return new Observable<any>((observer) => {
        this.db.setDatabase('projects/' + id + '/settings/content_settings', contentSettings).subscribe((response) => {
          let postData = new URLSearchParams();
          this.token = this.builderService.getToken();
          postData.set('process', "sync_ui");
          postData.set('projectId', id);
          postData.set('contentSettings', JSON.stringify(contentSettings));
          postData.set('token', this.token);

          this.http.post(this.builderUrl + 'update-content-settings', postData.toString(), this.options).subscribe((response_content) => {
            
          });
          observer.next(response); // Notify the observer that the operation is complete
          observer.complete(); // Complete the Observable
        }, (error) => {
          observer.error(error); // Notify the observer of an error
        });
      });
    } else {
      this.db.setDatabase('projects/' + id + '/settings/content_settings', contentSettings).subscribe((response) => {
          let postData = new URLSearchParams();
          this.token = this.builderService.getToken();
          postData.set('process', "sync_ui");
          postData.set('projectId', id);
          postData.set('contentSettings', JSON.stringify(contentSettings));
          postData.set('token', this.token);

        }, (error) => {
        });
      let postData = new URLSearchParams();
      this.token = this.builderService.getToken();
      postData.set('process', "sync_ui");
      postData.set('projectId', id);
      postData.set('contentSettings', JSON.stringify(contentSettings));
      postData.set('token', this.token);
      return this.http.post(this.builderUrl + 'update-content-settings', postData.toString(), this.options);

    }
  }

  getContentSettings = (id: any) => {
    // if(this.builderService.standalone) {
    //   return new Observable<any>((observer) => {
    //     this.db.getDatabase('projects/' + id + '/settings/content_settings').subscribe((response) => {
    //       if(response) {
    //         observer.next(response); // Notify the observer that the operation is complete
    //         observer.complete(); // Complete the Observable
    //       } else {
    //         console.log("No data available");
    //         observer.next({});
    //         observer.complete(); // Complete the Observable
    //       }
    //     }, (error) => {
    //       observer.error(error); // Notify the observer of an error
    //     });
    //   });
    // } else {
      let postData = new URLSearchParams();
      this.token = this.builderService.getToken();
      postData.set('process', "sync_ui");
      postData.set('projectId', id);
      postData.set('token', this.token);

      return this.http.post(this.builderUrl + 'get-content-settings', postData.toString(), this.options);
    // }
  }

  setAppleSettings = (id: any, appleSettings: Object) => {
    if(this.builderService.standalone) {
      appleSettings = JSON.parse(JSON.stringify(appleSettings));
      return new Observable<any>((observer) => {
        this.db.setDatabase('projects/' + id + '/settings/apple_settings', appleSettings).subscribe((response) => {
          let postData = new URLSearchParams();
          this.token = this.builderService.getToken();
          postData.set('process', "sync_ui");
          postData.set('projectId', id);
          postData.set('appleSettings', JSON.stringify(appleSettings));
          postData.set('token', this.token);

          this.http.post(this.builderUrl + 'update-apple-settings', postData.toString(), this.options).subscribe((response_apple) => {
            
          });
          observer.next(response); // Notify the observer that the operation is complete
          observer.complete(); // Complete the Observable
        }, (error) => {
          observer.error(error); // Notify the observer of an error
        });
      });
  } else {
    this.db.setDatabase('projects/' + id + '/settings/apple_settings', appleSettings).subscribe((response) => {
      let postData = new URLSearchParams();
      this.token = this.builderService.getToken();
      postData.set('process', "sync_ui");
      postData.set('projectId', id);
      postData.set('appleSettings', JSON.stringify(appleSettings));
      postData.set('token', this.token);

    }, (error) => {
    });
      let postData = new URLSearchParams();
      this.token = this.builderService.getToken();
      postData.set('process', "sync_ui");
      postData.set('projectId', id);
      postData.set('appleSettings', JSON.stringify(appleSettings));
      postData.set('token', this.token);

      return this.http.post(this.builderUrl + 'update-apple-settings', postData.toString(), this.options);
  
    }
  }

  getAppleSettings = (id: any) => {
    // if(this.builderService.standalone) {
    //   return new Observable<any>((observer) => {
    //     this.db.getDatabase('projects/' + id + '/settings/apple_settings').subscribe((response) => {
    //       if(response) {
    //         observer.next(response); // Notify the observer that the operation is complete
    //         observer.complete(); // Complete the Observable
    //       } else {
    //         console.log("No data available");
    //         observer.next({});
    //         observer.complete(); // Complete the Observable
    //       }
    //     }, (error) => {
    //       observer.error(error); // Notify the observer of an error
    //     });
    //   });
    // } else {
      let postData = new URLSearchParams();
      this.token = this.builderService.getToken();
      postData.set('process', "sync_ui");
      postData.set('projectId', id);
      postData.set('token', this.token);

      return this.http.post(this.builderUrl + 'get-apple-settings', postData.toString(), this.options);
    // }
  }

  setGlobalSettings = (id: any, data: any) => {
    if(this.builderService.standalone) {
      return new Observable<any>((observer) => {
        this.db.setDatabase('projects/' + id + '/settings/global_settings', data).subscribe((response) => {
          observer.next(response); // Notify the observer that the operation is complete
          observer.complete(); // Complete the Observable
        }, (error) => {
          observer.error(error); // Notify the observer of an error
        });
      });
    } else {
      let postData = new URLSearchParams();
      this.token = this.builderService.getToken();
      postData.set('process', "sync_ui");
      postData.set('projectId', id);
      postData.set('token', this.token);
      postData.set('data', JSON.stringify(data));
      return this.http.post(this.builderUrl + 'update-global-settings', postData.toString(), this.options);
    }
  }
  
  getGlobalSettings = (id: any) => {
    if(this.builderService.standalone) {
      return new Observable<any>((observer) => {
        this.db.getDatabase('projects/' + id + '/settings/global_settings').subscribe((response) => {
          if(response) {
            observer.next(response); // Notify the observer that the operation is complete
            observer.complete(); // Complete the Observable
          } else {
            console.log("No data available");
            observer.next({});
            observer.complete(); // Complete the Observable
          }
        }, (error) => {
          observer.error(error); // Notify the observer of an error
        });
      });
    } else {
      let postData = new URLSearchParams();
      this.token = this.builderService.getToken();
      postData.set('process', "sync_ui");
      postData.set('projectId', id);
      postData.set('token', this.token);

      return this.http.post(this.builderUrl + 'get-global-settings', postData.toString(), this.options);
    }
    
  }

  getMenueData(id:any){
    return new Observable<any>((observer) => {
    this.db.getDatabase('projects/' + id + '/settings/menus').subscribe((response: any) => {
      if(response) {
        observer.next(response); // Notify the observer that the operation is complete
        observer.complete(); // Complete the Observable
      } else {
        console.log("No data available");
        observer.next({});
        observer.complete(); // Complete the Observable
      }
    })
    })
  }

  addProjectDefaultSettings = (id: any) => {
    return new Observable<any>((observer) => {
      let defaults = {
      'application_settings' : {
        "mobile_title_family" : "poppins",
        "mobile_title_font_weight" : "bold",
        "mobile_title_font_color" : "#5e5e5e",
        "mobile_base_font_size" : "11",
        "mobile_text_font_weight" : "regular",
        "mobile_text_color" : "#707070",
        "mobile_primary_color" : "#567edc",
        "mobile_secondary_color" : "#44AF69",
        "facebook_hash_id" : "",
        "facebook_app_id" : "",
        "facebook_app_name" : "",
        "android_server_key" : "",
        "android_sender_id" : "",
        "google_service_json" : "",
        "email_login" : true,
        "google_login" : true,
        "phone_login" : true,
        "twitter_login" : true,
      },
      'content_settings' : {
        "category_sub_page" : "",
        "header_background" : "",
        "contact_email" : "",
        "mobile_webview_link" : "",
        "product_title_length" : "",
        "product_title_dots" : ""
      },
      'general_settings' : {
        "application_name" : "Stacks",
        "application_description" : "This description is for building process only and it won’t be published to your store accounts.",
        "package_name": "com.stacks.www",
        "ipa_sku_number": "1",
        "icon": "demo_app_icon.png",
        "splash_screen": "Stacks_splash.png",
      },
      'apple_settings' : {
        "email" : "",
        "password": "",
        "phone": "",
        "app_specific_pass": "",
        "team_name": "",
        "team_id": ""
      },
      'global_settings' : {
        "font-family":"Poppins",
        "font-size":"14px",
        "file":""
      },
      "currency": "$",
      "standalone": true,
    };
      let default_shipping =  [
          {
            "countryId": "1703504692659-6573",
            "countryName": "Country 1",
            "countryPrice": 50,
            "governorates": [
              {
                "governorateId": "1704199536604-3818",
                "governorateName": "Sub 1",
                "governoratePrice": "20"
              }
            ]
          },
          {
            "countryId": "1703504692659-6573",
            "countryName": "Country 2",
            "countryPrice": 30
          }
        ];
      this.http.get<string[]>('assets/i18n/txtTranslation.json').subscribe(
        (translationData) => {
          const translationsObject: { [key: string]: { value: string } } = {};
          translationData.forEach((text) => {
            const key = this.generateValidKey(text);
            translationsObject[key] = { value: text };
          });
          
          this.db.setDatabase('projects/' + id + '/settings/', defaults).subscribe(() => {
            this.db.setDatabase('projects/' + id + '/shipping/', default_shipping).subscribe(
                () => {
                  const translationsPath = `projects/${id}/translations/languages/English/texts`;
                  this.db.setDatabase(translationsPath, translationsObject).subscribe(
                    () => {
      // let application_settings = {
      //   "mobile_title_family" : "poppins",
      //   "mobile_title_font_weight" : "bold",
      //   "mobile_title_font_color" : "#5e5e5e",
      //   "mobile_base_font_size" : "11",
      //   "mobile_text_font_weight" : "regular",
      //   "mobile_text_color" : "#707070",
      //   "mobile_primary_color" : "#707070",
      //   "mobile_secondary_color" : "#f53c3d",
      //   "facebook_hash_id" : "",
      //   "facebook_app_id" : "",
      //   "facebook_app_name" : "",
      //   "android_server_key" : "",
      //   "android_sender_id" : "",
      //   "google_service_json" : ""
      // };
      // this.setApplicationSettings(id, application_settings).subscribe((resp: any) => {

      // });
                      observer.next(); // Notify the observer that the operation is complete
                      observer.complete(); // Complete the Observable
                    },
                    (error) => {
                      observer.error(error);
                    }
                  );
                },
                (error) => {
                  observer.error(error);
                }
              );
            },
            (error) => {
              observer.error(error);
            }
          );
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  
  generateValidKey(input: string): string {
    return input.replace(/[.#$\/\[\] ]/g, ' ');
  }

  /**
   * Retrieve Google Fonts to be used in the application
   */
  getGoogleFonts = () => {
    return this.http.get('https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyA2Z5RS6mlbf7LwObccjvhFYjeRLKj40CE');
  }

  /**
   * Retrieve and save all the settings again
   */
  syncSettings = (id: any) => {
    return new Observable<any>((observer) => {
      // Sync the general settings
      this.getGeneralSettings(id).subscribe((generalSettings: any) => {
        this.setGeneralSettings(id, generalSettings).subscribe(() => {

          // Sync the application settings
          this.getApplicationSettings(id).subscribe((applicationSettings: any) => {
            this.setApplicationSettings(id, applicationSettings).subscribe(() => {

              // Sync the content settings
              this.getContentSettings(id).subscribe((contentSettings: any) => {
                this.setContentSettings(id, contentSettings).subscribe(() => {
      
                  // Sync the apple settings
                  this.getAppleSettings(id).subscribe((appleSettings: any) => {
                    this.setAppleSettings(id, appleSettings).subscribe(() => {
          
                      observer.next('All settings Synced'); // Notify the observer that the operation is complete
                      observer.complete(); // Complete the Observable
                    });
                  });
      
                });
              });
            });
          });
        });
      });
    });
  }
}
