import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BlockDataService } from 'src/app/block-services/block-data.service';
import { BuilderService } from 'src/app/builder-services/builder.service';
import { GoogleIconsService } from 'src/app/builder-services/google-icons.service';

@Component({
  selector: 'icons-selector',
  templateUrl: './icons-selector.component.html',
  styleUrls: ['./icons-selector.component.scss']
})
export class IconsSelectorComponent implements OnInit {

  constructor(
    public googleIconsService: GoogleIconsService, 
    public builderService: BuilderService, 
    public blockDataService: BlockDataService,
    public dialogRef: MatDialogRef<IconsSelectorComponent>,) { }

  ngOnInit(): void {
  }

  selectedIcon = (icon: any) => {
    this.builderService.selectedIcon = icon;
    this.dialogRef.close();
  }
}
