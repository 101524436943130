<mat-progress-bar mode="indeterminate" *ngIf="productProgress"></mat-progress-bar>
<div class="productsWrapper">
  <div class="tableWrapper">
    <div class="tableHeader">
      <div class="headerBtns">
        <button (click)="addRow()">{{ 'ADD LANGUAGE' | translate }}</button>
      </div>
    </div>

    <table mat-table [dataSource]="language" class="LangTable mat-elevation-z8" matSort>
      <ng-container matColumnDef="language">
        <th mat-header-cell *matHeaderCellDef>{{ 'Languages' | translate }}</th>
        <td mat-cell *matCellDef="let lang">
          <ng-container *ngIf="lang.isNew">
            <input class="translateTextInput" type="text" [(ngModel)]="lang.title" (blur)="finalizeLanguage(lang)">
          </ng-container>
          <ng-container *ngIf="!lang.isNew">
            {{ lang.title }}
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="actionColumn">{{ 'Action' | translate }}</th>
        <td mat-cell *matCellDef="let lang" class="actionColumn">
          <ng-container *ngIf="!lang.isNew && language.length > 1">
            <button class="default" (click)="selectDefaultLanguage(lang.title)" 
                    [ngClass]="{
                      'defaultNotSelected': defaultLanguage !== lang.title, 
                      'defaultSelected': defaultLanguage === lang.title
                    }"
                    [disabled]="defaultLanguage === lang.title">
              {{ defaultLanguage === lang.title ? 'Default' : 'Set as Default' }}
            </button>
            <span class="material-icons material-icons-outlined deleteIcon" (click)="deleteRow(lang.title)">delete</span>
          </ng-container>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="['language', 'action']"></tr>
      <tr mat-row *matRowDef="let row; columns: ['language', 'action'];"></tr>
    </table>
  </div>
</div>

<form [formGroup]="multilingual" id="multilingual" (ngSubmit)="onSubmit()">
</form>