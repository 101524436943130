import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { BlockFieldsService } from 'src/app/block-services/block-fields.service';
import { BuilderService } from 'src/app/builder-services/builder.service';
import { BlockDataService } from '../../block-services/block-data.service';
import { PostsService } from './posts.service';
import { MixpanelService } from 'src/app/tracking/mixpanel.service';

@Component({
  selector: 'block-posts',
  templateUrl: './block-posts.component.html',
  styleUrls: ['./block-posts.component.scss']
})
export class BlockPostsComponent implements OnInit, AfterViewInit {

  previewBlock = false;
  styleFields = false;
  blockData: any;

  previewFields = false;

  blockFields: any;

  blockId = '';

  postsData = [];
  currency: any;
  posts = [];

  storedPosts: any = [];

  storedCategories: any = [];
  postTypes: any = [];
  
  rejectedTypes: any = ['attachment','nav_menu_item','page','product','wp_block','wp_navigation','wp_template','wp_template_part'];
  @Input() nestedBlock: any = {};
  @Input() importedBlockData: any;
  /**
   * If the block is rendered from saved data, then this should be true, if added dynamically by drag and drop or click then it will be "initial"
   **/
  initialData: any = false;

  selectedPostType: any = 'post';

  constructor(
    public blockDataService: BlockDataService,
    private blockFieldsService: BlockFieldsService,
    public postsService: PostsService,
    public builderService: BuilderService,
    private mixPanel: MixpanelService
  ) {
  };

  ngOnInit() {
    if(this.importedBlockData ) {
      this.previewBlock = true;
      this.previewFields = false;
      this.styleFields = false;
    }
    if (this.initialData !== 'initial') {
      if (this.nestedBlock.type == "posts") {
        this.blockData = this.nestedBlock;
        this.blockId = this.nestedBlock.id;
        this.previewBlock = true;
      } else {
        this.blockData = this.importedBlockData ? this.importedBlockData : this.blockFieldsService.getPostsBlockFields();
        if (!this.blockId) {
          this.blockId = this.blockDataService.getUniqueId(2);
        }
      }
      this.getPosts(this.builderService.getSelectedProject(), this.blockData.data, false);
    } else {
      this.blockData = this.blockDataService.getSelectedBlockData(this.blockId);
    }
    let categories: any
    if (localStorage.getItem('postsCategories')) {
      categories = localStorage.getItem('postsCategories');
      categories = JSON.parse(categories);
    } else {
      this.postsService.getInitialCategories();
    }
    this.setCategoriesData(this.blockId, categories);
    this.selectedPostType = this.blockData.data.post_type;

  }

  ngAfterViewInit() {
    /**
     * Initially get the posts from the localstorage
     */
    Promise.resolve().then(() => {
      let localPosts = localStorage.getItem('posts');
      if (localPosts) {
        this.storedPosts = JSON.parse(localPosts);
      }
      if (localPosts && this.initialData !== 'initial') {
        this.setPostsData(this.blockId, JSON.parse(localPosts));
      } else {
        /**
         * Get and set if for the first time there is no posts in the localstorage
         */
        this.getPosts(this.builderService.getSelectedProject(), this.blockData.data, true);
      }

      /**
       * Get Post Types and load the variables
       */
        this.getPostTypes();
    });

    
  }

  getPostTypes = () => {
    this.postsService.getPostTypes().subscribe((response) => {
      let formattedResult:any = [];
      for (const key in response) {
          const element = response[key].slug;
          let index = this.rejectedTypes.indexOf(element);
          if (index !== -1) {
            delete response.element;
          } else {
            formattedResult.push( element );
          }
      }
      this.postTypes = formattedResult;
    }, (err) => {

    });
  }

  getPosts = (projectId: number, args: any, cached = true) => {
    args['standalone'] = this.builderService.standalone;
    if (cached) {
      this.postsService.getPostsCached().subscribe((response) => {
        this.builderService.showSettingsProgress = false;
        let posts: any = [];
        for (const key in response) {
          if (Object.prototype.hasOwnProperty.call(response, key)) {
            const element = response[key];
            if (element) {
              posts.push({ 'ID': element.ID, "post_title": element.post_title, "post_image": element.post_image});
            }
          }
        }
        this.setPostsData(this.blockId, posts);
        this.currency = response.currency_symbol;
      }, (err) => {
        this.builderService.showSettingsProgress = false;
      });
    } else {
      this.postsService.getPosts(projectId, args).subscribe((response) => {
        this.builderService.showSettingsProgress = false;
        let posts: any = [];
        for (const key in response) {
          if (Object.prototype.hasOwnProperty.call(response, key)) {
            const element = response[key];
            if (element) {
              posts.push({ 'ID': element.ID, "post_title": element.post_title, "post_image": element.post_image, "link": element.guid});
            }
          }
        }
        this.setPostsData(this.blockId, posts);
        this.currency = response.currency_symbol;
      }, (err) => {
        this.builderService.showSettingsProgress = false;
      });
    }
  }

  /**
   * Getter for the Posts Data, The case of changing the modifying in one component and reflects into another component needs getters and setters
   * @param blockId 
   * @returns 
   */
  getPostsData = (blockId: string) => {
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      return;
    }
    let postsBlockFields = this.blockDataService.getSelectedBlockData(blockId);
    if( typeof postsBlockFields.postsData === "undefined" || postsBlockFields.postsData.length === 0) {
      return [];
    }
    return postsBlockFields.postsData;
  }

  /**
   * Setter for the Posts Data, The case of changing the modifying in one component and reflects into another component needs getters and setters
   * @returns
   */
  setPostsData = (blockId: string, data: object) => {
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      return;
    }
    let postsBlockFields = this.blockDataService.getSelectedBlockData(blockId);
    postsBlockFields.postsData = data;
  }


  getCategories = (projectId: number, args: any, cached = true) => {
    this.builderService.showSettingsProgress = true;
    args['standalone'] = this.builderService.standalone;
    if(cached) {
      this.postsService.getCategoriesCached().subscribe((response) => {
        this.builderService.showSettingsProgress = false;
        let categories: any = [];
        for (const key in response) {
          if (Object.prototype.hasOwnProperty.call(response, key)) {
            const element = response[key];
            if (element.count > 0) {
              categories.push({ 'id': element.id, "name": element.name, "count": element.count });
              // this.categoriesIds.push(element.term_id);
            }
          }
        }
        this.setCategoriesData(this.blockId, categories);
        this.currency = response.currency_symbol;
      }, (err) => {
        this.builderService.showSettingsProgress = false;
      });
    } else {
      this.postsService.getCategories(projectId, args, this.selectedPostType).subscribe((response) => {
        this.builderService.showSettingsProgress = false;
        let categories: any = [];
        for (const key in response) {
          if (Object.prototype.hasOwnProperty.call(response, key)) {
            const element = response[key];
            if (element.count > 0) {
              categories.push({ 'id': element.id, "name": element.name, "count": element.count });
              // this.categoriesIds.push(element.term_id);
            }
          }
        }
        this.setCategoriesData(this.blockId, categories);
        this.currency = response.currency_symbol;
      }, (err) => {
        this.builderService.showSettingsProgress = false;
      });
    }
    return true;
  }

  /**
   * Getter for the Categories Data, The case of changing the modifying in one component and reflects into another component needs getters and setters
   * @param blockId 
   * @returns 
   */
  getCategoriesData = (blockId: string) => {
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      return;
    }
    let categoriesBlockFields = this.blockDataService.getSelectedBlockData(blockId);
    return categoriesBlockFields.categoriesData;
  }

  /**
   * Setter for the Categories Data, The case of changing the modifying in one component and reflects into another component needs getters and setters
   * @returns
   */
  setCategoriesData = (blockId: string, data: object) => {
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      return;
    }
    let categoriesBlockFields = this.blockDataService.getSelectedBlockData(blockId);
    categoriesBlockFields.categoriesData = data;
  }

  /**
   * Appends Block to the Preview, Initial checks if the block is added as initial block addition or appended live
   */
  addBlockToPreview(initial: any = '') {
    let blockPostsData = {
      component: BlockPostsComponent,
      index: 'latest',
      element: this,
      initial: initial
    };
    this.blockDataService.sendBlockData(blockPostsData);
    this.builderService.blocksCollapsed = true;
  }

  appendBlock($event: any) {
    if(this.builderService.getTour()) {
      this.builderService.onboardingBlockAdded = true;
    }
    let clonedBlockData = { ...this.blockData };
    this.blockId = this.blockDataService.appendBlocksData(clonedBlockData);
    this.addBlockToPreview();
    this.builderService.logData('Post Block Added', 'Block Add', 'Post Block Added').subscribe((response) => {

    }, (err) => {

    });

    this.mixPanel.track('Block Added', {
      projectid: this.builderService.getSelectedProject(),
      block: 'post',
    });
    return true;
  }

  /**
   * Sync the clicked block on click inside the mobile preview
   */
  showData(blockId: any) {
    // Check Block Availability before taking action
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      // show data of the last element instead
      blockId = this.blockDataService.getLatestBlockId();
    }
    this.blockFieldsService.clickedBlockId = blockId;
    this.blockDataService.sendCurrentBlock(BlockPostsComponent);
  }

  queryChange(query: any, blockId: any, $event: any, type: string) {
    // Check Block Availability before taking action
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      return;
    }
    let postsBlockFields = this.blockDataService.getSelectedBlockData(blockId);
    /**
     * Modifying Nested Object Directly doesn't work, so we have to clone the data object first then modify it
     */
    let clonedBlockFieldsData = { ...postsBlockFields.data };
    let data: any = '';
    if (type == 'select') {
      data = $event.value
    } else if (type == 'text') {
      data = $event.currentTarget.value;
    } else if (type == 'slider') {
      data = $event.currentTarget.value;
    }
    if(query == 'post_type') {
      this.selectedPostType = data;
    }
      
    // } else {
    clonedBlockFieldsData[query] = data;
    // }
    postsBlockFields.data = clonedBlockFieldsData;

    let projectId = this.builderService.selectedProject;
    this.getPosts(projectId, clonedBlockFieldsData, false);
    this.getCategories(projectId, this.blockData.data, false);
  }

  styleChange(style: any, blockId: any, $event: any, type: string, stylePosition = 'extenalStyle') {
    // Check Block Availability before taking action
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      return;
    }
    let postsBlockFields = this.blockDataService.getSelectedBlockData(blockId);
    /**
     * Modifying Nested Object Directly doesn't work, so we have to clone the data object first then modify it
     */
    let clonedBlockFieldsData = { ...postsBlockFields.style };
    let clonedBlockFieldsDataInternal = { ...postsBlockFields.internalStyle };
    
    let data = '';
    if (type == 'select') {
      data = $event.value
    } else if (type == 'text') {
      data = $event.currentTarget.value;
    } else if (type == 'slider') {
      data = $event.currentTarget.value + 'px';
    }

    if(stylePosition == 'internalStyle') {
      clonedBlockFieldsDataInternal[style] = data;
      postsBlockFields.internalStyle = clonedBlockFieldsDataInternal;
    } else {
      clonedBlockFieldsData[style] = data;
      postsBlockFields.style = clonedBlockFieldsData;
    }
    
  }

  getBlockFields = (id: string) => {
    if(this.importedBlockData) {
      return this.importedBlockData;
    }
    return this.blockDataService.getSelectedBlockData(id);
  }

  getPostsList = (blockid: string) => {
    let block = this.blockDataService.getSelectedBlockData(blockid);
    return [block.data?.posts];
  }

  showStyleTab = () => {
    this.previewBlock = false;
    this.previewFields = false;
    this.styleFields = true;
  }

  showDataTab = () => {
    this.previewBlock = false;
    this.previewFields = true;
    this.styleFields = false;
  }

  checkSidebar = () => {
    if (!this.previewBlock && !this.previewFields && !this.styleFields) {
      return true;
    }
    return false;
  }

  checkPreview = () => {
    if (this.previewBlock && !this.previewFields && !this.styleFields) {
      return true;
    }
    return false;
  }

  checkData = () => {
    if (this.previewFields) {
      return true;
    }
    return false;
  }

  checkStyle = () => {
    if (this.styleFields && !this.previewFields && !this.previewBlock) {
      return true;
    }
    return false;
  }

  checkSettings = () => {
    if (!this.checkSidebar() && !this.checkPreview()) {
      return true;
    }
    return false;
  }

  /** 
  * Deletes the Block from the mobile Preview
  * Deletes the Block from the JSON Object
  */
  deleteBlock = (blockId: any) => {
    this.blockDataService.removeBlockData(blockId);
  }
}
