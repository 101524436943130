import { Component, OnInit } from '@angular/core';
import { BuilderService } from 'src/app/builder-services/builder.service';
import { DatabaseService } from 'src/app/builder-services/database.service';


@Component({
  selector: 'app-addons',
  templateUrl: './addons.component.html',
  styleUrls: ['./addons.component.scss']
})
export class AddonsComponent implements OnInit {
  plugins: any[] = [{
    name: "Points",
    description: "this is a description",
    isChecked: false
  }];
  addedPlugins: any[] = []


  constructor(public builderService: BuilderService,
    private db: DatabaseService
  ) { }

  ngOnInit(): void {
    console.log(this.plugins.length)
    this.db.getDatabase('projects/' + this.builderService.selectedProject + '/addons').subscribe((response: any) => {
      if (Array.isArray(response)) {
        for (let i = 0; i < response.length; i++) {
          for (let j = 0; j < this.plugins.length; j++) {
            if (response[i].name == this.plugins[j].name) {
              this.plugins[j].isChecked = response[i].isChecked;
            }
          }
        }
        for (let plugin of this.plugins) {
          if (plugin.isChecked)
            this.addedPlugins.push(plugin)
        }
      }
    })
  }

  onToggleChange(index: number) {
    this.builderService.showUpdateProgress = true;
    console.log(this.plugins[index].isChecked)
    if (!this.plugins[index].isChecked) {
      this.addedPlugins.splice(index, 1)
    } else {
      this.addedPlugins.push(this.plugins[index]);
    }
    console.log(this.addedPlugins);
    this.db.setDatabase('projects/' + this.builderService.selectedProject + '/addons', this.addedPlugins).subscribe((response: any) => {
      console.log(response)
    })
    this.builderService.showUpdateProgress = false;
  }

}
