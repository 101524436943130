import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BlockDataService } from '../block-services/block-data.service';
import { BuilderService } from '../builder-services/builder.service';
import { ConnectivityService } from '../connectivity/connectivity.service';
import { ViewsService } from './views.service';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
@Component({
  selector: 'views',
  templateUrl: './views.component.html',
  styleUrls: ['./views.component.scss']
})

/** Error when invalid control is dirty, touched, or submitted. */


export class ViewsComponent implements OnInit {

  constructor(
    private router: Router,
    private blockDataService: BlockDataService, 
    public connectivity: ConnectivityService, 
    public viewsService: ViewsService, 
    public builderService: BuilderService) { 
      
  }

  mobile = false;
  viewNameError = false;
  viewNameFormControl: any;
  matcher: any;
  customViews:any = [];
  editViewMode = false;
  ngOnInit(): void {
    if (window.screen.width <= 768) { // 768px portrait
      this.mobile = true;
    }
    this.viewNameFormControl = new FormControl('', [Validators.required]);
    this.builderService.getViews();
  }

  viewChange = (view_name: string, view_id: number, title: string) => {
      this.blockDataService.sendSelectedView({view_name: view_name, view_id: view_id, title: title});
      this.router.navigate(['/']);
  }


  newView = () => {
    if(this.viewNameFormControl.value == '') {
      this.viewNameFormControl.markAsTouched();
      return;
    }
    let empty_blocks = this.blockDataService.blankView;
    this.viewsService.submitView(this.viewNameFormControl.value, this.builderService.selectedProject).subscribe((response: any) => {
      if( !response.success ) {
        alert(response.message);
      } else {
        if(this.builderService.standalone) {
          this.builderService.updateProject(this.builderService.selectedProject, empty_blocks, false, response.view_id, this.viewNameFormControl.value ).subscribe((project) => {
            this.blockDataService.sendSelectedView({view_name: this.viewNameFormControl.value, view_id: response.view_id, title: this.viewNameFormControl.value});
            this.router.navigate(['/']);
          });
        } else {
          this.blockDataService.sendSelectedView({view_name: this.viewNameFormControl.value, view_id: response.view_id, title: this.viewNameFormControl.value});
          this.router.navigate(['/']);
        }
      }
    })
  }

  editView = (view_name: string, view_id: any) => {
    this.builderService.showUpdateProgress = true;
    this.viewsService.editView(view_name, this.builderService.selectedProject, view_id).subscribe((response: any) => {
      this.builderService.showUpdateProgress = false;
    });
  }

  deleteView = (view_id: any) => {
    this.builderService.showUpdateProgress = true;
    this.viewsService.deleteView(this.builderService.selectedProject, view_id).subscribe((response: any) => {
      this.builderService.showUpdateProgress = false;
      this.builderService.getViews();
    });
  }

}
