import { Component, OnInit } from '@angular/core';

declare var Calendly: any;

@Component({
  selector: 'help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})


export class HelpComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    Calendly.initInlineWidget({
      url: 'https://calendly.com/stacks-team-1/15-minute-meeting?hide_event_type_details=0&hide_gdpr_banner=1',
      parentElement: document.getElementById('calendly-inline-widget'),
    });
  }

}
