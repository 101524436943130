import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BuilderService } from 'src/app/builder-services/builder.service';
import { DatabaseService } from 'src/app/builder-services/database.service';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
export interface productsTableColumns {
  id: string;
  image: string;
  name: string;
  sku: string;
  stock: string;
  price: string;
  date: string;
  checked?: boolean;
}
export interface categoriesTableColumns {
  id:string;
  image: string; // Optional property
  name: string;
  checked?: boolean;
}
export interface Coupon {

  name: string;
  expirationDate: string;
  amount: number;
  discountType: 'percentage' | 'specific_amount';
  discountCriteria: 'fixed_products_discount' |   'fixed_categories_discount' | 'whole_cart_discount';
  usageTime: number;
  usageTimes: number;
  uses:number;
  min: number;
  max: number;
  selectedProducts?: productsTableColumns[];
  selectedCategories?: categoriesTableColumns[]; 
  active: boolean;


}
@Injectable({
  providedIn: 'root'
})
export class CouponsService {
  project_id = this.builderService.selectedProject;
  public users: any = [];
  constructor(
    private builderService: BuilderService,
    private db: DatabaseService,
    private http: HttpClient
  ) {}

/**
 * this is method is for submitting the new coupon in the database /coupons and
 *   it used in the editing process to edit an existed coupon
 * 
 * @params (coupon which is a Coupon type which is an interface,couponId which is a string of null)
 * @returns Observable<any>
 *  */  
submitCoupon(coupon: Coupon, coupon_id: string = ''): Observable<any> {
  coupon = JSON.parse(JSON.stringify(coupon)); // Ensure the coupon data is correctly formatted

  // Log coupon data for debugging
  console.log('Submitting Coupon:', coupon);

  const url = coupon_id =`projects/${this.project_id}/coupons/${coupon_id}`;

  return new Observable<any>((observer) => {
    this.db.setDatabase(url, coupon, true).subscribe(
      (response) => {
        console.log('Coupon Updated Successfully:', response);
        observer.next(response);
        observer.complete();
      },
      (error) => {
        console.error('Error Updating Coupon:', error);
        observer.error(error);
      }
    );
  });
}

  /**
   * this method is used to delete a coupon from the database (/coupons) so i can call this in a component.ts.
   * @param couponId which is string 
   * @returns  Observable<any>
   */
  deleteCoupon(couponId: string): Observable<any> {
    const path = `projects/${this.project_id}/coupons/${couponId}`;
    return this.db.removeDatabaseEndpoint(path);
  }

  /**
   * this method used to get a coupon from the database not all the coupons only one coupon 
   * @param couponId which is string 
   * @returns Observable<Coupon>
   */
  getCoupon(couponId: string): Observable<Coupon> {
    const url = `projects/${this.project_id}/coupons/${couponId}`;
    return this.db.getDatabase(url);
  }

 /**
   * this method used to get all the coupons from the database
   * @param null
   * @returns Observable<any>
   */  
  getCoupons(): Observable<any> {
    return new Observable<any>((observer) => {
      this.db.getDatabase(`projects/${this.project_id}/coupons/`).subscribe(
        (response) => {
          if (!response) {
            console.log("No data available");
            observer.next({});
            observer.complete(); // Complete the Observable
          } else {
            observer.next(response); // Notify the observer that the operation is complete
            observer.complete(); // Complete the Observable
          }
        },
        (error) => {
          observer.error(error); // Notify the observer of an error
        }
      );
    });
  }

/**
 * this method use to update a single coupon from the database so i can use it in the editing process to edit a coupon from the list.
 * @params (id which is a string, coupon which is a Coupon type which is an interface)
 * @returns  Observable<any>
 *  */ 
updateCoupon(id: string, coupon: Coupon): Observable<any> {
  const url = `projects/${this.project_id}/coupons/${id}`;
  console.log('Updating coupon at URL:', url, 'with data:', coupon);
  return new Observable<any>((observer) => {
    this.db.setDatabase(url, coupon, false).subscribe(
      (response) => {
        console.log('Update response:', response);
        observer.next(response);
        observer.complete();
      },
      (error) => {
        console.error('Update error:', error);
        observer.error(error);
      }
    );
  });
}
getUsers(): Observable<any> {
  return this.db.getDatabase(`projects/${this.project_id}/users/`);
}

}
