import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TemplatesService } from './templates.service';
import { BlockDataService } from '../block-services/block-data.service';
import { BuilderService } from '../builder-services/builder.service';
import { ViewsService } from '../views/views.service';
import { DatabaseService } from '../builder-services/database.service';
import { ProductsService } from '../e-commerce/products/products.service';
import { Observable } from 'rxjs';
import { CategoriesService } from '../e-commerce/categories/categories.service';

@Component({
  selector: 'templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss']
})
export class TemplatesComponent implements OnInit {

  templatesForm :any= this.formBuilder.group({
    templateName: '',
    templateSelected: '',
    templateImage: '',
    templateCategorySelected: ''
  });
  templatesList: Array<any> = [];
  templateImageSrc = '';
  templateName = '';
  templateId = '';
  templateCategorySelected = '';
  mobile = false;
  templatesCategories = [{
    'id' : 'restaurants',
    'name' : 'Restaurants',
  },{
    'id' : 'fashion',
    'name' : 'Fashion',
  },{
    'id' : 'grocery',
    'name' : 'Grocery & Market',
  },{
    'id' : 'electronics',
    'name' : 'Electronics',
  },{
    'id' : 'pharmacies',
    'name' : 'Pharmacies',
  },];
  constructor(private formBuilder: FormBuilder,
    private templateService: TemplatesService,
    private blockDataService: BlockDataService,
    private builderService: BuilderService,
    private viewsService: ViewsService,
    private database: DatabaseService,
    private productsService: ProductsService,
    private categoriesService: CategoriesService
  ) {
    this.getAllTemplates();
   } 
  showTemplatesProgress = false;
  ngOnInit(): void {
    if (window.screen.width <= 768) { // 768px portrait
      this.mobile = true;
    }
  }

  /**
   * Get all the templates to be rendered in the Drop Down Menu
   */
  getAllTemplates = () => {
    this.templatesList = [];
    this.database.getDatabase('templates_new/').subscribe((templates: any) => {
      for (const key in templates) {
        if (Object.prototype.hasOwnProperty.call(templates, key)) {
          const element = templates[key];
          element.template_id = key;
          this.templatesList.push(element);
        }
      }
      console.log(this.templatesList);
    });

  }
  onSubmit = ($event:any) => {
    // Get all the products and categories and save into the template
    if( $event.submitter.classList.contains('createTemplate') ) {
      this.database.getDatabase('projects/' + this.builderService.selectedProject).subscribe((project: any) => {
        let template = {
          'template_name' : this.templatesForm.value.templateName,
          'template_image' : this.templateImageSrc,
          'project' : project,
          'category' : this.templateCategorySelected
        }
        this.database.setDatabase('templates_new/', template, true).subscribe((response) => {

        });
      });
    } else {
      this.templatesList[this.templatesForm.value.templateSelected].template_id;
      let templateID = this.templatesList[this.templatesForm.value.templateSelected].template_id;
      this.database.getDatabase('projects/' + this.builderService.selectedProject).subscribe((project: any) => {
        let template = {
          'template_name' : this.templatesForm.value.templateName,
          'template_image' : this.templateImageSrc,
          'project' : project,
          'template_id' : templateID,
          'category' : this.templateCategorySelected
        }
        this.database.setDatabase('templates_new/'+templateID, template).subscribe((response) => {

        });
      });
    }
  }


  queryChange($event: any, type: string) {
    let data = 0;
    if (type == 'select') {
      data = $event.value;
    } else if (type == 'text') {
      data = $event.currentTarget.value - 1;
    } else if (type == 'slider') {
      data = $event.currentTarget.value - 1;
    }
    console.log(this.templatesList[data]);
    this.templateName = this.templatesList[data].template_name;
    this.templateImageSrc = this.templatesList[data].template_image;
    this.templateId = this.templatesList[data].template_id;
    this.templateCategorySelected = this.templatesList[data].category;

    this.templatesForm.value.templateName = this.templateName;
    this.templatesForm.value.templateImageSrc = this.templateImageSrc;

  }

  processFile(fileInput: any, type: string, name: string) {
    const file: File = fileInput.target.files[0];
    const reader = new FileReader();
    reader.addEventListener('load', (event: any) => {
      this.builderService.uploadImage(file, 'admin', 'templates').subscribe((imageResponse) => {
        this.templateImageSrc = imageResponse;
        this.builderService.showUpdateProgress = false;
      });
      // this.templateService.uploadFile(file, this.builderService.selectedProject, type, name).subscribe(
      //   (res) => {
      //     console.log(res);
      //     this.templateImageSrc = res;
      //     this.builderService.showUpdateProgress = false;
      //   },
      //   (err) => {
      //     this.builderService.showUpdateProgress = false;
      //     alert("error uploading file, please contact support");
      //   })
    });

    reader.readAsDataURL(file);
  }
}