import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  selectedLanguage: string = 'en'; // Default to English
  private translations: any;
  constructor(private http: HttpClient) {
    // Retrieve the language from localStorage if available
    const storedLang = localStorage.getItem('siteLang');
    if (storedLang) {
      this.selectedLanguage = storedLang;
    }
  }

  setLanguage(language: string) {
    this.selectedLanguage = language;
    // Store the language in localStorage
    localStorage.setItem('siteLang', language);
  }

  loadTranslations(lang: string): Promise<any> {
    return this.http.get(`/assets/i18n/${lang}.json`).toPromise()
      .then((translations: any) => {
        this.translations = translations;
      });
  }

  translate(key: string): string {
    return this.translations[key] || key;
  }
  getLanguage() {
    return this.selectedLanguage;
  }
}
