import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BuilderService } from 'src/app/builder-services/builder.service';
import { HelpersService } from 'src/app/helpers.service';

@Injectable({
  providedIn: 'root'
})
export class VersionsService {

  private builderUrl;

  constructor(
    private http: HttpClient, 
    public helpers: HelpersService, 
    private builderService: BuilderService,
  ) { 
    this.builderUrl = this.helpers.getBuilderUrl();
  }

  /**
   * Set a new version
   */
  setVersion = (project_id: any, version_name: string) => {

    let data = { 
      'projectId'     : project_id,
      'version_name'     : version_name,
      'token'         : this.builderService.getToken(),
    }

    let optionsTemplate = {
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    };
    return this.http.post(this.builderUrl + 'submit_version', JSON.stringify(data), optionsTemplate);
  }

  getVersions = (project_id: any) => {
    let data = { 
      'projectId'     : project_id,
      'token'         : this.builderService.getToken(),
    }

    let optionsTemplate = {
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    };
    return this.http.post(this.builderUrl + 'get_all_versions', JSON.stringify(data), optionsTemplate);
  }

  applyVersion = (project_id: any, version_name: string) => {
    let data = { 
      'projectId'       : project_id,
      'version_name'    : version_name,
      'token'           : this.builderService.getToken(),
    }

    let optionsTemplate = {
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    };
    return this.http.post(this.builderUrl + 'apply_version', JSON.stringify(data), optionsTemplate);
  }

  deleteVersion = (project_id: any, version_name: string) => {
    let data = { 
      'projectId'       : project_id,
      'version_name'    : version_name,
      'token'           : this.builderService.getToken(),
    }

    let optionsTemplate = {
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    };
    return this.http.post(this.builderUrl + 'delete_version', JSON.stringify(data), optionsTemplate);
  }
}
