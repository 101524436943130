import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { BuilderService } from 'src/app/builder-services/builder.service';
import { AppSettingsService } from '../services/app-settings.service';

class ImageSnippet {
  constructor(public src: string, public file: File) { }
}
@Component({
  selector: 'content-settings',
  templateUrl: './content-settings.component.html',
  styleUrls: ['./content-settings.component.scss']
})
export class ContentSettingsComponent {


  public disabled = false;
  public color: ThemePalette = 'primary';
  public touchUi = false;

  selectedFile!: ImageSnippet;


  contentSettingsForm = this.formBuilder.group({
    header_background: '',
    about_us_background: '',
    points_rewards_background: '',
    category_sub_page: '',
    contact_email: '',
    contact_phone: '',
    product_title_length: '',
    product_title_dots: '',
    mobile_webview_link: '',
    browser_type: '',
    stacks_extra_app_css: '',
    disable_guest: '',
    enable_website_checkout: 'disabled',
    search_results_posts: '',
  });

  contentSettingsData: any = {};
  constructor(private formBuilder: FormBuilder,
    private appSettingsService: AppSettingsService,
    public builderService: BuilderService) {

    let project_id = this.builderService.getSelectedProject();
    this.contentSettingsData = this.appSettingsService.getContentSettings(project_id).subscribe((settings: any) => {
      this.contentSettingsData = settings !== null ? settings : {};
      if (settings !== null) {
        this.contentSettingsForm.patchValue({ category_sub_page: settings.category_sub_page });
        this.contentSettingsForm.patchValue({ disable_guest: settings.disable_guest });
        this.contentSettingsForm.patchValue({ enable_website_checkout: settings.enable_website_checkout });
        this.contentSettingsForm.patchValue({ browser_type: settings.browser_type });
      }

    }, (err) => {
      console.log(err);
    });

  }

  onSubmit(): void {
    // Process checkout data here
    this.builderService.showUpdateProgress = true;

    let header_background = this.contentSettingsData.header_background ? this.contentSettingsData.header_background : this.contentSettingsForm.value.header_background;
    let points_rewards_background = this.contentSettingsData.points_rewards_background ? this.contentSettingsData.points_rewards_background : this.contentSettingsForm.value.points_rewards_background;
    let mobile_webview_link = this.contentSettingsForm.value.mobile_webview_link !== '' ? this.contentSettingsForm.value.mobile_webview_link : '';
    let browser_type = this.contentSettingsForm.value.browser_type !== '' ? this.contentSettingsForm.value.browser_type : 'appBrowser';
    header_background = decodeURI(header_background );
    points_rewards_background = decodeURI(points_rewards_background );
    // let splash_screen = this.applicationSettingsForm.value.splash_screen;
    // /**
    //  * Loop on the Values and if the value isn't changed from the UI then retrieve the current available value
    //  */
    let newSubmittedData: any = {};
    let submittedData:any = this.contentSettingsForm.value;
    let retrievedData = this.contentSettingsData;
    for (var k in submittedData) {
      if (submittedData[k] != "" || k  == "mobile_webview_link") {
        newSubmittedData[k] = submittedData[k];
      } else {
        newSubmittedData[k] = retrievedData[k];
      }
    }
    /**
     * Step 1 Submit the files
     */
    newSubmittedData['header_background'] = header_background;
    newSubmittedData['points_rewards_background'] = points_rewards_background;


    this.appSettingsService.setContentSettings(this.builderService.selectedProject, newSubmittedData).subscribe((response) => {
      this.builderService.showUpdateProgress = false;
    }, (err) => {

    });
  }

  processFile(fileInput: any, type: string, name: string) {
    const file: File = fileInput.target.files[0];
    const reader = new FileReader();
    reader.addEventListener('load', (event: any) => {
      this.appSettingsService.uploadFile(file, this.builderService.selectedProject, type).subscribe(
        (res) => {
          this.contentSettingsData[name] = res;
          this.builderService.showUpdateProgress = false;
        },
        (err) => {
          this.builderService.showUpdateProgress = false;
          alert("error uploading file, please contact support");
        })
    });

    reader.readAsDataURL(file);
  }

}
