import { AppSettingsService } from '../services/app-settings.service';
import { BuilderService } from 'src/app/builder-services/builder.service';
import { Component, Input, OnInit ,ViewChild ,ElementRef} from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { SettingsService } from 'src/app/e-commerce/settings/settings.service';
import {CdkDragDrop, CdkDropList, CdkDrag, moveItemInArray} from '@angular/cdk/drag-drop';
import {FormBuilder,FormControl,FormGroup,Validators,FormArray} from '@angular/forms';


@Component({
  selector: 'global-settings',
  templateUrl: './global-settings.component.html',
  styleUrls: ['./global-settings.component.scss']
})
export class GlobalSettingsComponent implements OnInit {
  mobile = false;
  googleFonts: any = {};
  @ViewChild('panel') panel!: MatExpansionPanel;

  expandPanel() {
    this.panel.toggle();
  }

  fonts = [
    {
      family: 'Poppins',
      variants: ['regular'],
    },
    {
      family: 'Lato',
      variants: ['regular'],
    },
  ];
  newFonts: any = [];
  selectedFont: any = {};
  indexData:any;

  @Input() indexed: any = '';

  sideMenueData: any[] = [];
  sideMenuName: any;
  selectOpt:any
  isMenueEdited:boolean=false; 

  panelStates: boolean[] = []; 
  activeMenuIndex: number | null = null; 
  panelState: number = -1; 

  constructor(
    public formBuilder: FormBuilder,
    public builderService: BuilderService,
    public appSettingsService: AppSettingsService,
    private settingsService: SettingsService,
    private elRef: ElementRef
  ) {
    this.getGlobalStyles();
  }

  showGlobalSettingsProgress = false;

  ngOnInit(): void {
    if (window.screen.width <= 768) {// 768px portrait
      this.mobile = true;
    }

    this.getMenueData();
    if(this.editSideMenuName || this.editSelectOpt){
    this.editMenuData();
    }
  }

  onSubmit = () => {};

  styleChange(style: any, $event: any, type: string, stylePosition = 'style') {
    let data: any = '';
    if (type == 'select') {
      data = $event.value;
    } else if (type == 'font') {
      data = this.newFonts[$event.value].family;
      this.selectedFont = this.newFonts[$event.value];
      this.builderService.globalSettings.file = 'https://fonts.googleapis.com/css2?family='+encodeURIComponent(this.selectedFont.family)+'&display=swap';
      this.addStylesheetURL(this.builderService.globalSettings.file);
      // this.builderService.globalSettings['font-variant'] = this.selectedFont.variants[0];
    } else if (type == 'text') {
      data = $event.currentTarget.value;
    } else if (type == 'slider') {
      data = $event.currentTarget.value + 'px';
    }
    this.builderService.globalSettings[style] = data;
    this.applyGlobalFonts();
    // if(this.builderService.globalSettings['font-variant']) {
    //   let fontVariant = this.builderService.globalSettings['font-variant'];
    //   // this.builderService.globalSettings.file = this.selectedFont.files[fontVariant]
    //   this.builderService.globalSettings.file = 'https://fonts.googleapis.com/css2?family='+encodeURIComponent(this.selectedFont.family)+'&display=swap';
    //   this.addStylesheetURL(this.builderService.globalSettings.file);
    // }
  }

  applyGlobalFonts = () => {
    const style = document.createElement('style');
    style.className = 'googleFontsStyle';
    let customStyles = this.builderService.globalSettings;
    if (customStyles) {
      style.innerHTML = `.mat-typography #mainMobileAppPreview, .mat-typography #mainMobileAppPreview p, .mat-typography #mainMobileAppPreview strong {
          font-family: "${customStyles['font-family']}", "Helvetica Neue", sans-serif !important;
          font-size: ${customStyles['font-size']} !important;
      }
      `;
      document.body.appendChild(style);
      this.addStylesheetURL(customStyles['file']);
    }
  };

  addStylesheetURL = (url: string) => {
    let link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = url;
    document.getElementsByTagName('head')[0].appendChild(link);
  };

  getGlobalStyles = () => {
    let project_id = this.builderService.getSelectedProject();
    this.appSettingsService.getGlobalSettings(project_id).subscribe(
      (data: any) => {
        if (!data) {
          this.builderService.globalSettings = {
            'font-family': 'Poppins',
            'font-size': '14px',
            file: '',
          };
        } else {
          this.builderService.globalSettings = data;
        }
        this.applyGlobalFonts();
        this.getGoogleFonts();
      },
      () => {
        console.log('error');
      }
    );
  };

  /**
   * Get Google Fonts
   */
  getGoogleFonts = () => {
    this.appSettingsService.getGoogleFonts().subscribe((data: any) => {
        this.googleFonts = data.items;
        this.fonts = this.fonts.concat(this.googleFonts);
        for (let index = 0; index < this.fonts.length; index++) {
          this.newFonts[this.fonts[index].family] = this.fonts[index];
        }
      }, (error) => {
        console.log(error);
      }
    );
  };

  setMenuData(input: any) {
    if(!this.sideMenuName) return ;
    let newObj = { name:input , link:this.selectOpt};
    this.sideMenueData.push(newObj);
    this.sideMenuName = '';
    this.selectOpt='';
    this.builderService.globalSettings.menu=this.sideMenueData;
  }

  getMenueData(){
    let project_id = this.builderService.getSelectedProject();
    this.appSettingsService.getMenueData(project_id).subscribe((response: any) => {
     if(response.length){
      this.sideMenueData=response;
      this.builderService.globalSettings.menu=this.sideMenueData;
     }
    })
  }

  remove(item:any){
    this.sideMenueData.splice(item,1);
    this.panelStates.splice(item, 1);
  }

  edit(item:any){
    this.isMenueEdited=true;
    let editedData=this.sideMenueData[item];
    console.log(this.sideMenueData[item]);
    this.editSideMenuName=editedData.name;
    this.editSelectOpt=editedData.link;
    this.activeMenuIndex=item;
  }

  editSideMenuName:any;
  editSelectOpt:any;

  editMenuData(){
    this.sideMenueData[this.indexData].name=this.editSideMenuName;
    this.sideMenueData[this.indexData].link=this.editSelectOpt;
    this.builderService.globalSettings.menu=this.sideMenueData;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.sideMenueData, event.previousIndex, event.currentIndex);
  }

  panelOpened(index: any) {
    console.log('Panel opened:', index);
    this.panelState = index;
    this.indexData=index;
    this.edit(index);
  }
}
