import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BuilderService } from 'src/app/builder-services/builder.service';
import { HelpersService } from 'src/app/helpers.service';
import { LoginService } from 'src/app/login/login.service';

@Injectable({
  providedIn: 'root'
})
export class stripeService {

  private builderUrl: any;
  
  public options = {
    headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
  };

  constructor(private helpers: HelpersService,
    private http: HttpClient,
    private builderService: BuilderService, private loginService: LoginService) {
    this.builderUrl = this.helpers.getBuilderUrl();
  }


  createSession(price_id: any, newLicense = false) {
    
    let postData = new URLSearchParams();
    let project_id:any = this.builderService.getSelectedProject();
    let user_email = this.loginService.getCurrentUser().email;

    if(this.builderService.selected_pricing_id) {
      postData.set('price_id', this.builderService.selected_pricing_id);
    } else {
      postData.set('price_id', price_id);
    }
    postData.set('project_id', project_id);
    postData.set('email', user_email);
    if(this.builderService.selected_coupon_id) {
      postData.set('coupon_id', this.builderService.selected_coupon_id);
    }
    if(newLicense) {
      postData.set('newLicense', "true");
    }


    return this.http.post(this.builderUrl + 'create_stripe_session', postData.toString(), this.options);
  }

  changePlan(price_id: any) {
    let postData = new URLSearchParams();
    let project_id:any = this.builderService.getSelectedProject();
    let user_email = this.loginService.getCurrentUser().email;

    postData.set('price_id', price_id);
    postData.set('project_id', project_id);
    postData.set('email', user_email);


    return this.http.post(this.builderUrl + 'change_plan', postData.toString(), this.options);
    
  }

  getInvoices(stripe_subscription_id: string) {
    let postData = new URLSearchParams();

    let project_id:any = this.builderService.getSelectedProject();

    postData.set('email', this.loginService.getCurrentUser().email);
    postData.set('stripe_subscription_id', stripe_subscription_id);
    postData.set('project_id', project_id);

    return this.http.post(this.builderUrl + 'retrieve_invoices', postData.toString(), this.options);
  }

  gotoBilling() {
    let postData = new URLSearchParams();
    let user_email = this.loginService.getCurrentUser().email;

    postData.set('email', user_email);


    return this.http.post(this.builderUrl + 'create_stripe_billing_session', postData.toString(), this.options);
  }

}
