<mat-toolbar color="warn">
    <span>All Subscibers</span>
</mat-toolbar>
<mat-form-field appearance="outline">
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applySubFilter($event)" placeholder="Ex. ium" #input>
</mat-form-field>
<button mat-raised-button class="fetchData" (click)="fetchSubscribers()">Fetch New Data</button>
<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <!-- Position Column -->
    <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef > date. </th>
    <td mat-cell *matCellDef="let elements"> {{elements.date}} </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="mail">
    <th mat-header-cell *matHeaderCellDef> Mail </th>
    <td mat-cell *matCellDef="let elements"> {{elements.mail}} </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let elements"> {{elements.name}} </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="subscribersColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: subscribersColumns;"></tr>
</table>
<mat-paginator [pageSizeOptions]="[5, 10, 20, 50, 100]"
            showFirstLastButtons 
            aria-label="Select page of periodic dataSource">
</mat-paginator>
<div class="fullGrid">
    <div class="widgetChart">
        <canvas baseChart
            [data]="subChartData"
            [options]="subChartOptions"
            type="bar"></canvas>
    </div>
</div>