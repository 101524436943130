import { Component, OnInit } from '@angular/core';
import { SettingsService } from './settings.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CountriesService } from 'src/app/builder-services/countries/countries.service';

export interface DropDownList {
  code: any
  symbol: any
  name: any
}
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  settingsProgress = false;
  settings: any = {};
  saveState = false;
  currency: any;
  settingsForm!: FormGroup;

  stripApiKey: any;
  geideaPublicKey!:string;
  geideaApiPassword!:string
  paymentMethod: any = [];
  paymentData: any = [];

  settingsIdFromRoute: any;
  isChecked = false;
  checked: any;
  indeterminate: any;
  paymentChecked: any;
  isValidGeidea!: boolean ;
  isVaildStripeApiKey: boolean = true;
  isStripChecked: boolean = false;
  isGeideaChecked: boolean = false;
  isCodChecked: boolean = false;
  invalidStripeApiKey: string = '';
  invalidGeideaKey:string=''
  enCodedGeideaAuth: any;
  paymentMethods = [
    {
      id: 'cod',
      description: "cash on delivery",
      title: "Cash On Delivery (COD)",
      checked: true
    },
    {
      id: 'stripe',
      description: "pay with card",
      title: "Pay With Stripe",
      checked: false
    },
    {
      id: 'geidea',
      description: "pay with card",
      title: "Pay With Geidea",
      checked: false
    }
  ];

  countries: any[] = [];
  countryOptions: string = '';
  selectedCountries: any[] = [];

  constructor(public settingService: SettingsService, private formBuilder: FormBuilder,private countriesService: CountriesService) { }

  ngOnInit(): void {
    this.countries = this.countriesService.getCountries();
    this.settingsForm = this.formBuilder.group({
      currency: '',
      countryOptions: '',
      selectedCountries: [[]],
      countryNumber: '',
      stripApiKey: '',
      geideaPublicKey: '',
      geideaApiPassword: ''
    });
    this.settingService.getSettings().subscribe((settings: any) => {
      if (settings) {
        this.settings = settings;
        this.currency = settings.currency;
        this.countryOptions = settings.countryOptions || 'all';
        this.selectedCountries = settings.selectedCountries || [];
        this.stripApiKey = settings.stripApiKey;
        this.geideaPublicKey=settings.geidea.publicKey
        this.geideaApiPassword=settings.geidea.apiPassword

        this.settingsForm.get('countryOptions')?.setValue(this.countryOptions);
        this.settingsForm.get('selectedCountries')?.setValue(this.selectedCountries);
        this.settingsForm.get('countryNumber')?.setValue(settings.countryNumber || '');
        this.settingsForm.get('stripApiKey')?.setValue(settings.stripApiKey);
        this.settingsForm.get('geideaPublicKey')?.setValue(settings.geidea.publicKey);
        this.settingsForm.get('geideaApiPassword')?.setValue(settings.geidea.apiPassword);

        this.paymentMethod = settings.paymentMethod;

        if (settings.paymentMethod) {
          this.compareAndSetChecked(this.paymentMethods, this.paymentMethod);
        }
        else{
          this.paymentMethods.map((paymentMethod)=>{
           if(paymentMethod.id=='cod'){
             paymentMethod.checked=true
             this.isCodChecked=true
             this.paymentData.push(paymentMethod)
           }
          })
        }
        console.log(this.paymentData);
      }

      if (!Array.isArray(this.settings)) {
        this.settings = {};
      }
    })

  }

  compareAndSetChecked(arr1: any[], arr2: any[]) {
    arr1.forEach(item1 => {
      const found = arr2.find(item2 => item1.id === item2.id);
      if (found) {
        item1.checked = true;
        if (found?.id == 'stripe') {
          this.isStripChecked = item1.checked;
        }
        if (found?.id == 'geidea') {
          this.isGeideaChecked = item1.checked;
        }
        this.paymentData.push(found);
      } else {
        item1.checked = false;
        if (found?.id == 'stripe') {
          this.isStripChecked = item1.checked;
        }
        if (found?.id == 'geidea') {
          this.isGeideaChecked = item1.checked;
        }
      }
    });
  }
  updatePaymentCheckedState(payment: any) {
    console.log(payment);
    this.paymentMethod = payment;
  }


  CurrencyList: DropDownList[] = [

    {
      symbol: "د.إ", code: "AED", name: "United Arab Emirates Dirham"
    },
    
    {
      symbol: "؋", code: "AFN", name: "Afghan Afghani"
    },
    {
      symbol: "₼", code: "AZN", name: "Azerbaijani Manat"
    },
    {
      symbol: "лв", code: "BGN", name: "Bulgarian Lev"
    },
    {
      symbol: "৳", code: "BDT", name: "Bangladeshi Taka"
    },
    {
      symbol: "Br", code: "BYN", name: "Belarusian Ruble"
    },
    {
      symbol: "៛", code: "KHR", name: "Cambodian Riel"
    },
    {
      symbol: "$", code: "CAD", name: "Canadian Dollar"
    },
    {
      symbol: "₡", code: "CRC", name: "Costa Rican Colón"
    },
    {
      symbol: "kn", code: "HRK", name: "Croatian Kuna"
    },
    {
      symbol: "Kč", code: "CZK", name: "Czech Koruna"
    },
    {
      symbol: "kr", code: "DKK", name: "Danish Krone"
    },
    {
      symbol: "RD$", code: "DOP", name: "Dominican Peso"
    },
    {
      symbol: "EGP", code: "EGP", name: "Egyptian Pound"
    },
    {
      symbol: "¢", code: "GHS", name: "Ghanaian Cedi"
    },
    {
      symbol: "Q", code: "GTQ", name: "Guatemalan Quetzal"
    },
    {
      symbol: "L", code: "HNL", name: "Honduran Lempira"
    },
    {
      symbol: "Ft", code: "HUF", name: "Hungarian Forint"
    },
    {
      symbol: "Rp", code: "IDR", name: "Indonesian Rupiah"
    },
    {
      symbol: "₪", code: "ILS", name: "Israeli New Shekel"
    },
    {
      symbol: "₹", code: "INR", name: "Indian Rupee"
    },
    {
      symbol: "¥", code: "JPY", name: "Japanese Yen"
    },
    {
      symbol: "₩", code: "KRW", name: "South Korean Won"
    },
    {
      symbol: "₭", code: "LAK", name: "Lao Kip"
    },
    {
      symbol: "₨", code: "LKR", name: "Sri Lankan Rupee"
    },
    {
      symbol: "ден", code: "MKD", name: "Macedonian Denar"
    },
    {
      symbol: "RM", code: "MYR", name: "Malaysian Ringgit"
    },
    {
      symbol: "₮", code: "MNT", name: "Mongolian Tugrik"
    },
    {
      symbol: "₨", code: "NPR", name: "Nepalese Rupee"
    },
    {
      symbol: "₦", code: "NGN", name: "Nigerian Naira"
    },
    {
      symbol: "kr", code: "NOK", name: "Norwegian Krone"
    },
    {
      symbol: "﷼", code: "OMR", name: "Omani Rial"
    },
    {
      symbol: "S/", code: "PEN", name: "Peruvian Sol"
    },
    {
      symbol: "₱", code: "PHP", name: "Philippine Peso"
    },
    {
      symbol: "zł", code: "PLN", name: "Polish Złoty"
    },
    {
      symbol: "lei", code: "RON", name: "Romanian Leu"
    },
    {
      symbol: "₽", code: "RUB", name: "Russian Ruble"
    },
    {
      symbol: "﷼", code: "SAR", name: "Saudi Riyal"
    },
    {
      symbol: "Дин.", code: "RSD", name: "Serbian Dinar"
    },
    {
      symbol: "₨", code: "PKR", name: "Pakistani Rupee"
    },
    {
      symbol: "₡", code: "SCR", name: "Seychellois Rupee"
    },
    {
      symbol: "kr", code: "SEK", name: "Swedish Krona"
    },
    {
      symbol: "₺", code: "TRY", name: "Turkish Lira"
    },
    {
      symbol: "$", code: "USD", name: "United States Dollar"
    },
    {
      symbol: "₴", code: "UAH", name: "Ukrainian Hryvnia"
    },
    {
      symbol: "₫", code: "VND", name: "Vietnamese Dong"
    },
    {
      symbol: "R", code: "ZAR", name: "South African Rand"
    },
    {
      symbol: "Z$", code: "ZWL", name: "Zimbabwean Dollar"
    },
  ];
  selected = "";


  onCheckboxChange(event: any, payment: any) {
    payment.checked = event.target.checked;
    this.saveState = true;
    console.log(payment, payment.checked);
    if (payment?.id == 'stripe') {
      this.isStripChecked = payment.checked;
    }
    if (payment?.id == 'geidea') {
      this.isGeideaChecked = payment.checked;
    }

    if (payment.checked) {
      const index = this.paymentData.findIndex((p: any) => p.id === payment.id);
      console.log(index);
      if (index === -1) {
        this.paymentData.push(payment);

      }
    } else {
      const index = this.paymentData.findIndex((p: any) => p.id === payment.id);
      console.log(index);
      if (index !== -1) {
        this.paymentData.splice(index, 1);
      }


    }

    console.log(this.paymentData);

  }

   saveSettings() {
    // alert('Save settings');
    // You can implement the save functionality here
    this.settingsProgress = true;
    this.settings['currency'] = this.currency;
    this.settings['selectedCountries'] = this.settingsForm.get('selectedCountries')?.value;
    this.settings['paymentMethod'] = this.paymentData;
    this.settings['stripApiKey'] = this.settingsForm.get('stripApiKey')?.value;
    this.settings['countryOptions'] = this.settingsForm.get('countryOptions')?.value;
    this.settings.countryNumber = this.settingsForm.get('countryNumber')?.value;

    if (this.settingsForm.get('stripApiKey')?.value && this.isStripChecked) {
      console.log('in validate ');
      this.validateStripeKey(this.settingsForm.get('stripApiKey')?.value)
    }else{
      this.settingsForm.get('stripApiKey')?.setValue('');
      this.settings['stripApiKey']='';
    }

    if (this.settingsForm.get('geideaPublicKey')?.value &&
      this.settingsForm.get('geideaApiPassword')?.value &&
      this.isGeideaChecked) 
      {
      let geideaAuth = `${this.settingsForm.get('geideaPublicKey')?.value}:${this.settingsForm.get('geideaApiPassword')?.value}`
      this.enCodedGeideaAuth = btoa(geideaAuth)
      console.log('encoded', this.enCodedGeideaAuth);
       this.validateGeidea(this.enCodedGeideaAuth)
      if(this.isValidGeidea == false){
        console.log("not vaild in geidea token");
        this.settingsProgress = false;
       return 
      }else{
        console.log("vaild in geidea token");
        this.settings['geidea'] = {
        publicKey: this.settingsForm.get('geideaPublicKey')?.value,
        apiPassword: this.settingsForm.get('geideaApiPassword')?.value,
        enCodedGeideaAuth: this.enCodedGeideaAuth
      }
    }
    }else{
      this.settings['geidea'] = {
        publicKey: '',
        apiPassword: '',
        enCodedGeideaAuth: ''
      }
    }
    this.settingService.submitSettings(this.settings).subscribe((result: any) => {
      console.log('inSettings');
      this.settingsProgress = false;
      this.saveState = false;
    })

  }

  startWithPlus(event: any) {
    const input = event.target.value;
    if (!input.startsWith('+')) {
      this.settingsForm.get('countryNumber')?.setValue('+' + input.replace(/\D/g, ''));
    } else {
      this.settingsForm.get('countryNumber')?.setValue('+' + input.substring(1).replace(/\D/g, ''));
    }
  }

  activateSave() {
    this.saveState = true;
  }

  validateStripeKey(event: any): any {
    let apiKey = event.target?.value ? event.target.value : event;
    if (apiKey) {
      this.settingService.validateStripeApiKey(apiKey).subscribe((response) => {
          this.settingsForm.get('stripApiKey')?.setErrors(null);
          this.isVaildStripeApiKey = true;
          return this.isVaildStripeApiKey
        },
        (error) => {
          console.error('API Key validation failed:', error.error.error.message);
          if (error.error.error.message.includes('Invalid API Key provided')) {
            this.invalidStripeApiKey = error.error.error.message;
            this.settingsForm.get('stripApiKey')?.setErrors({ 'invalidStripeApiKey': true });
            this.isVaildStripeApiKey = false;
            return this.isVaildStripeApiKey;

          }
          else if (error.error.error.message.includes('This API call cannot be made with a publishable API key')) {
            this.invalidStripeApiKey = error.error.error.message;
            this.settingsForm.get('stripApiKey')?.setErrors({ 'invalidStripeApiKey': true });
            this.isVaildStripeApiKey = false;
            return this.isVaildStripeApiKey;
          }

          else {
            this.settingsForm.get('stripApiKey')?.setErrors(null);
            this.isVaildStripeApiKey = false;
            return this.isVaildStripeApiKey;

          }
        }
      );
    }
  }


  validateGeidea(token:any){
    console.log(token);
    let geideaAuth = `${this.settingsForm.get('geideaPublicKey')?.value}:${this.settingsForm.get('geideaApiPassword')?.value}`
    this.enCodedGeideaAuth = btoa(geideaAuth)
    this.settingService.validateGeideaToken(this.enCodedGeideaAuth).subscribe(response => {
      console.log('Token is valid', response);
      if(response.detailedResponseCode=="000"){
        this.settingsForm.get('geideaApiPassword')?.setErrors(null);
        this.settingsForm.get('geideaPublicKey')?.setErrors(null);
        this.isValidGeidea=true;
      }else if(response.detailedResponseCode=="023"){
        console.error('Token validation failed');
       this.invalidGeideaKey= response.detailedResponseMessage;
       this.settingsForm.get('geideaPublicKey')?.setErrors({ 'invalidPublicKey': true });
       this.settingsForm.get('geideaApiPassword')?.setErrors({ 'invalidPassword': true });
       this.isValidGeidea=false;
       return 
 
      }
    }, error => {
      console.error('Token validation failed', error);
      this.settingsForm.get('geideaPublicKey')?.setErrors({ 'invalidPublicKey': true });
      this.settingsForm.get('geideaApiPassword')?.setErrors({ 'invalidPassword': true });
      this.isValidGeidea=false;
      return false

    });
  }
}

