import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { BuilderService } from '../builder-services/builder.service';
import { ConnectivityService } from './connectivity.service';
import { MatTableDataSource } from '@angular/material/table';
import { PeriodicElement } from '../addons/push-notifications/push-notifications.component';
import { WorksDialogComponent } from '../pages/works-dialog/works-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockDataService } from '../block-services/block-data.service';
import { MixpanelService } from '../tracking/mixpanel.service';
import { AppSettingsService } from '../settings/services/app-settings.service';
import { DatabaseService } from '../builder-services/database.service';
import { ViewsService } from '../views/views.service';

@Component({
  selector: 'app-connectivity',
  templateUrl: './connectivity.component.html',
  styleUrls: ['./connectivity.component.scss']
})
export class ConnectivityComponent implements OnInit {

  connectedLogo = true;
  showStatus = false;
  projectDomain = '';
  noConnect = false;

  displayedColumns: string[] = ['field', 'value'];
  connectivityData: any;
  connectivityCheck = false;
  constructor(
    private formBuilder: FormBuilder, 
    public builderService: BuilderService,
    private connectivityService: ConnectivityService,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private blockDataService: BlockDataService,
    private mixPanel: MixpanelService,
    private appSettings: AppSettingsService,
    private db: DatabaseService,
    private viewsService: ViewsService ) {
    let projectId = this.builderService.selectedProject;
    let formattedValidation: any = [];
    let latestVersion = '5.1.1';
    this.builderService.getProject(projectId).subscribe((project: any) => {

      this.builderService.selectedProjectDomain = project.details[0].domain;
      // Cache the response
      this.builderService.cacheProjectReq.response = project;
      this.connectivity.patchValue({ siteURL: project.details[0].domain });
      this.projectDomain = project.details[0].domain;
      this.builderService.user_type = project.user_type;
      if(this.connectivity.value.siteURL)
      this.connectivityService.checkPluginActivity(this.connectivity.value.siteURL).subscribe((pluginResponse: any) => {
        if (pluginResponse.success) {
          /**
           * Get the plugin version
           */
          if(this.connectivity.value.siteURL)
          this.connectivityService.checkPluginVersion(this.connectivity.value.siteURL).subscribe((response: any) => {
            formattedValidation = [{
              'field': 'Status',
              'value': 'Connected'
            },
            {
              'field': 'Plugin Version',
              'value': response.version
            },
            {
              'field': 'Latest Version',
              'value': latestVersion
            }];
            this.connectivityCheck = true;
            this.connectivityData = new MatTableDataSource<PeriodicElement>(formattedValidation);
          }, (err) => {
            //Can't Connect to server, plugin Version
            this.builderService.showUpdateProgress = false;
            formattedValidation = [{
              'field': 'Status',
              'value': 'Connected'
            },
            {
              'field': 'Plugin Version',
              'value': '< ' + latestVersion
            },
            {
              'field': 'Latest Version',
              'value': latestVersion
            }];
            this.connectivityCheck = true;
            this.connectivityData = new MatTableDataSource<PeriodicElement>(formattedValidation);
          });
        } else {
          formattedValidation = [{
            'field': 'Status',
            'value': 'Not Connected'
          },
          {
            'field': 'Plugin Version',
            'value': 'Please install Plugin'
          },
          {
            'field': 'Latest Version',
            'value': latestVersion
          }];
          this.connectivityCheck = true;
          this.connectivityData = new MatTableDataSource<PeriodicElement>(formattedValidation);
        }
      }, (err) => {
        formattedValidation = [{
          'field': 'Status',
          'value': 'Not Connected'
        },
        {
          'field': 'Plugin Version',
          'value': 'Please install Plugin'
        },
        {
          'field': 'Latest Version',
          'value': latestVersion
        }];
        this.connectivityData = new MatTableDataSource<PeriodicElement>(formattedValidation);
        this.connectivityCheck = true;
      });

    });
  }

  mobile = false;

  connectivity = this.formBuilder.group({
    siteURL: '',
  });
  
  ngOnInit(): void {
    if (window.screen.width <= 768) {
      this.mobile = true;
    }
  }

  /**
   * Validates the Website url to be connected to and makes sure that the plugin is activated
   */
  updateConnectionLink = () => {
    this.builderService.showUpdateProgress = true;
    if(this.connectivity.value.siteURL)
    this.connectivityService.checkPluginActivity(this.connectivity.value.siteURL).subscribe((pluginResponse: any) => {
      if (pluginResponse.success) {
        if(this.connectivity.value.siteURL)
        this.connectivityService.changeWebsiteDomain(this.builderService.selectedProject, this.connectivity.value.siteURL).subscribe((response: any) => {
          /**
           * This case is used for failure from the Backend and alerts the response, basically used only for duplicate domains
           */
          if (response.msg) {
            alert(response.msg);
            this.connectedLogo = false;
            this.noConnect = false;
            this.showStatus = true;
            this.builderService.logData('Website Not Connected' + this.connectivity.value.siteURL, 'Website Not Connected', 'Connection Failed').subscribe((response) => {

            }, (err) => {
        
            });
          } else {
           /**
           * Everything is Valid, Domain Changed
           */
            this.domainChanged();
            this.builderService.selectedProjectDomain = this.connectivity.value.siteURL;
            this.builderService.updateAllViews();
            this.builderService.logData('Website Connected' + this.connectivity.value.siteURL, 'Website Connected', 'Connection Success').subscribe((response) => {

            }, (err) => {
        
            });
            this.mixPanel.track('Website Connected', {
              projectid: this.builderService.selectedProject,
              connectedurl: this.connectivity.value.siteURL
            });
          }
          this.builderService.showUpdateProgress = false;
        }, (err) => {
          this.builderService.showUpdateProgress = false;
          this.builderService.logData('Website Not Connected' + this.connectivity.value.siteURL, 'Website Not Connected', 'Connection Failed').subscribe((response) => {

          }, (err) => {
      
          });
        });
      } else {
        //Can't Connect to server, plugin unavailable
        this.pluginUnavailable();
        this.builderService.showUpdateProgress = false;
        this.builderService.logData('Website Not Connected, Plugin unavailable' + this.connectivity.value.siteURL, 'Website Not Connected', 'Plugin Unavailable').subscribe((response) => {

        }, (err) => {
    
        });
      }
    }, (err) => {
      //Can't Connect to server, plugin unavailable
      this.pluginUnavailable();
      this.builderService.showUpdateProgress = false;
      this.builderService.logData('Website Not Connected, Plugin unavailable' + this.connectivity.value.siteURL, 'Website Not Connected', 'Plugin Unavailable').subscribe((response) => {

      }, (err) => {
  
      });
    });
    
  }

  /**
   * Can't Connect to server, plugin unavailable
   */
  pluginUnavailable = () => {
    this.showStatus = true;
    this.noConnect = true;
    this.connectedLogo = false;
  }
  /**
   * Everything is Valid, Domain Changed
   */
  domainChanged = () => {
    this.noConnect = false;
    this.connectedLogo = true;
    this.showStatus = true;
  }

  howItWorksDialog = () => {
    const dialogRef = this.dialog.open(WorksDialogComponent, {
      width: '700px',
      maxWidth: '80%',
      data: {name: "connectivity"},
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  tourNext = () => {
    this.router.navigate(['/site-type'], { relativeTo: this.route });
  }

  switchNative = (status = true) => {
    this.builderService.showUpdateProgress = true;
    let confirmation = 'Are you sure you want to switch to Native?, This will remove all the WordPress features and make Stacks Database your main source of data, you can always switch back to WordPress';
    if(!status) {
      confirmation = 'Are you sure you want to switch to WordPress?, This will remove all the Native features and make your WordPress site your main source of data, you can always switch back to Native'; 
    }
    if(confirm(confirmation)) {
      // native to wp
      if(this.builderService.standalone) {
        this.viewsService.getStandaloneViews(this.builderService.selectedProject).subscribe((response: any) => {
          // this.viewsService.allViews = response;
          // convert the native all views to wp all views
          this.viewsService.allViewsFormatter(response, true);
          
          this.db.setDatabase('projects/' + this.builderService.selectedProject + '/settings/standalone/', status).subscribe((response) => {
            this.builderService.standalone = status 
            // Update all the views
            this.builderService.updateProjectWithNewViews();
            // sync all the settings
            this.appSettings.syncSettings(this.builderService.selectedProject).subscribe((response) => {
            });
          });
        });
      } else {
        // wp to native
        this.viewsService.getViews(this.builderService.selectedProject, 'get_all_views').subscribe((response: any) => {
          this.viewsService.allViewsFormatter(response.data, false);

          this.db.setDatabase('projects/' + this.builderService.selectedProject + '/settings/standalone/', status).subscribe((response) => {
            this.builderService.standalone = status 
            // Update all the views
            this.builderService.updateProjectWithNewViews();
            // sync all the settings
            this.appSettings.syncSettings(this.builderService.selectedProject).subscribe((response) => {
            });
          });
        });
      }
      
    } else {
      this.builderService.showUpdateProgress = false;
    }
  };
}
