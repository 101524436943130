import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BuilderService } from 'src/app/builder-services/builder.service';
import { DatabaseService } from 'src/app/builder-services/database.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  constructor(private builderService: BuilderService, private db: DatabaseService,private http: HttpClient) { }

  submitSettings = (setting: any) => {
    let project_id = this.builderService.selectedProject;
    return new Observable<any>((observer) => {
      this.getSettings().subscribe((settings: any) => {
        if (settings) {
          setting = { ...settings, ...setting };
        }
        this.db.setDatabase('projects/' + project_id + '/settings/', setting).subscribe((response) => {
              observer.next(response); // Notify the observer that the operation is complete
              observer.complete(); // Complete the Observable
            },(error) => {
              observer.error(error); // Notify the observer of an error
            }
          );
      });
    });
  };

  getCurrencySettings = () => {
    let project_id = this.builderService.selectedProject;
    return new Observable<any>((observer) => {
      this.db.getDatabase(`projects/${project_id}/settings/currency`).subscribe((response) => {
          if (!response) {
            console.log("No currency data available");
            observer.next({});
            observer.complete(); // Complete the Observable
          } else {
            observer.next(response); // Notify the observer that the operation is complete
            observer.complete(); // Complete the Observable
          }
        },
        (error) => {
          observer.error(error); // Notify the observer of an error
        }
      );
    });
  };

  getSettings = () => {
    let project_id = this.builderService.selectedProject;
    return new Observable<any>((observer) => {
      this.db.getDatabase('projects/' + project_id + '/settings/').subscribe((response) => {
          if(!response) {
            console.log("No data available");
            observer.next({});
            observer.complete(); // Complete the Observable
          } else {
            observer.next(response); // Notify the observer that the operation is complete
            observer.complete(); // Complete the Observable
          }
        },
        (error) => {
          observer.error(error); // Notify the observer of an error
        }
      );
    });
  };

  // set menue
  setMenue = (menuData: any) => {
    let project_id = this.builderService.selectedProject;
    return new Observable<any>((observer) => {
        this.db.setDatabase('projects/' + project_id + '/settings/', menuData).subscribe((response) => {
          observer.next(console.log(response)); 
          observer.complete(); 
        }, (error) => {
          observer.error(error);
        });
    });
  }

  //validate Stripe Api Key
  validateStripeApiKey(apiKey: string) {
    return this.http.post<any>('https://api.stripe.com/v1/customers', {}, {
      headers: {
        'Authorization': `Bearer ${apiKey}`
      }
    });
  }

  //validate geidea api key 
  validateGeideaToken(token:string){
    let headers = new HttpHeaders({
      'accept':'application/json',
      'Content-Type': 'application/json',
      'Authorization':`Basic ${token}`      
    }); 

    let body= JSON.stringify({
      amount: 20,
      currency: 'EGP',
      customer: {
        name: 'Kiran',
        email: 'testmail@geidea.com',
        phoneCountryCode: '+20',
        phoneNumber: '8003030083'
      },
      eInvoiceDetails: {
        subtotal: 20,
        grandTotal: 20,
        extraChargesType: 'Amount',
        invoiceDiscountType: 'Amount',
        eInvoiceItems: [
          {
            eInvoiceItemId: '17e95202-d151-43ff-d6af-08db4614f0bb',
            description: 'Subscription for TFM tech',
            price: 10,
            quantity: 2,
            itemDiscountType: 'Amount',
            taxType: 'Amount',
            total: 20
          }
        ]
      }
    })
    const geideaApiUrl = 'https://api.merchant.geidea.net/payment-intent/api/v1/direct/eInvoice';
    console.log(geideaApiUrl);
                       
  return this.http.post<any>(`${environment.proxyUrl}${geideaApiUrl}`,body, { headers: headers });
  };
}