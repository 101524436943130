import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { BlockDataService } from 'src/app/block-services/block-data.service';
import { BlockFieldsService } from 'src/app/block-services/block-fields.service';
import { BuilderService } from 'src/app/builder-services/builder.service';
import { HelpersService } from 'src/app/helpers.service';
import { MixpanelService } from 'src/app/tracking/mixpanel.service';

@Component({
  selector: 'block-section',
  templateUrl: './block-section.component.html',
  styleUrls: ['./block-section.component.scss']
})
export class BlockSectionComponent implements OnInit {

  previewBlock = false;
  styleFields = false;
  blockData: any;

  previewFields = false;

  blockFields: any;

  blockId = this.blockDataService.getUniqueId(2);
  twocol : boolean;
  public sectionColumns = new FormControl();
  public sectionStyleForm = new FormControl();
  @Input() importedBlockData: any;
  sectionFullWidth = false;
  constructor(private blockDataService: BlockDataService, 
    private blockFieldsService: BlockFieldsService,
    private helpers: HelpersService,
    public builderService: BuilderService,
    private mixPanel: MixpanelService
    ) { 
    this.blockData = this.importedBlockData ? this.importedBlockData : this.blockFieldsService.getSectionBlockFields();
    this.twocol = false;
  }

  ngOnInit(): void {
    if(this.importedBlockData ) {
      this.previewBlock = true;
      this.previewFields = false;
      this.styleFields = false;
    }

    if( document.querySelector('video') ) {
      document.querySelector('video')?.play();
    }
  }

  /**
   * Appends Block to the Preview, Initial checks if the block is added as initial block addition or appended live
   */
  addBlockToPreview(initial: any = '') {
    let blockSectionData = {
      component: BlockSectionComponent,
      index: 'latest',
      element: this,
      initial: initial
    };
    this.blockDataService.sendBlockData(blockSectionData);
    this.builderService.blocksCollapsed = true;
  }

  appendBlock() {
    let sectionBlockData = {
      'id': 0,
      'type': 'section',
      'style': {

      },
      'elements': [{
        'elType': 'column',
        'elements': [

        ],
        'data': {

        },
        'style': {

        },
        'settings': {
          "_column_size": 100
        }
      }],
    };
    if(this.builderService.getTour()) {
      this.builderService.onboardingBlockAdded = true;
    }
    this.blockId = this.blockDataService.appendBlocksData(sectionBlockData);
    this.addBlockToPreview();
    this.builderService.logData('Section Block Added', 'Block Add', 'Section Block Added').subscribe((response) => {

    }, (err) => {

    });
    this.mixPanel.track('Block Added', {
      projectid: this.builderService.getSelectedProject(),
      block: 'section',
    });
  }

  /**
   * get all the image block fields from the service and adjust the src only
   * @param $event change event on the input field
   */
  sectionColumnsChange(blockId: any) {
    // Check Block Availability before taking action
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      return;
    }
    let sectionBlockFields = this.blockDataService.getSelectedBlockData(blockId);
    /**
     * Modifying Nested Object Directly doesn't work, so we have to clone the data object first then modify it
     */
    let clonedBlockFieldsData = { ...sectionBlockFields.data };
    clonedBlockFieldsData.columns = this.sectionColumns.value;
    sectionBlockFields.data = clonedBlockFieldsData;
    /**
     * for 2 columns in the we have to add the column structure and adjust the column size
     */
    let allblocksdata = this.blockDataService.getAllBlocksData();
    if (clonedBlockFieldsData.columns == '2') {
      // If there is no Second Column add it
      if (!sectionBlockFields.elements[1]) {
        sectionBlockFields.elements.push(this.helpers.cloneObject(this.blockFieldsService.columnBlockData) );
      }
      // Make the Column size 50 for both of them
      sectionBlockFields.elements[0].settings = {
        "_column_size" : 50
      }
      sectionBlockFields.elements[1].settings = {
        "_column_size": 50
      }
    } else if (clonedBlockFieldsData.columns == '1' ) {
      // Remove the Second Column if it exists
      if (sectionBlockFields.elements[1]) {
        sectionBlockFields.elements.pop()
        // this.blockDataService.blocksData = this.helpers.cloneObject( allblocksdata[blockId].elements.splice(1,1) )
      }
      // Make the Column Size 100
      sectionBlockFields.elements[0].settings._column_size = 100;
    }
  }

  /**
   * Get the columns Number
   * @param blockId 
   * @returns 
   */
  columnsNumber(blockId: any) {
    // Check Block Availability before taking action
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      return;
    }
    if (this.getBlockFields(blockId).data?.columns !== '2') {
      return 1;
    }
    return 2;
  }

  /**
   * Sync the clicked block on click inside the mobile preview
   */
  showData(blockId: any) {
    // Check Block Availability before taking action
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      // show data of the last element instead
      blockId = this.blockDataService.getLatestBlockId();
    }
    this.blockFieldsService.clickedBlockId = blockId;
    this.blockDataService.sendCurrentBlock(BlockSectionComponent);
  }

  styleChange(style: any, blockId: any, $event: any, type: string) {
    // Check Block Availability before taking action
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      return;
    }
    /**
     * Modifying Nested Object Directly won't work, so we have to clone the data object first then modify it
     */
    let itemBlockFields = this.blockDataService.getSelectedBlockData(blockId);
    let clonedBlockFieldsData = { ...itemBlockFields.style };
    let data = '';
    if (type == 'select') {
      data = $event.value
    } else if (type == 'text') {
      data = $event.currentTarget.value;
    } else if (type == 'slider') {
      data = $event.currentTarget.value + 'px';
    }

    clonedBlockFieldsData[style] = data;
    itemBlockFields.style = clonedBlockFieldsData;
    switch (style) {
      case 'sectionBackground':
        
        let backgroundValue = $event.currentTarget.value;
        if (backgroundValue.startsWith('http')) {
          clonedBlockFieldsData.background = 'url("' + backgroundValue + '")';
          itemBlockFields.style = clonedBlockFieldsData;
        } else {
          clonedBlockFieldsData.background = backgroundValue;
          itemBlockFields.style = clonedBlockFieldsData;
        }
        
        break;
      case 'firstColumnBackground':
        
        let colBackgroundValue = $event.currentTarget.value;
        if (colBackgroundValue.startsWith('http')) {
          itemBlockFields.elements[0].style.background = 'url("' + colBackgroundValue + '")';
        } else {
          itemBlockFields.elements[0].style.background = colBackgroundValue;
        }
        
        break;
      case 'section_height_auto': 
      if(data == 'yes') {
        clonedBlockFieldsData['height'] = 'auto';
        itemBlockFields.style = clonedBlockFieldsData;
      }
        
        break;
      default:
        break;
    }
    return;
  }

  getSectionBackgroundImage = (blockId: any) => {
    let sectionBlockFields = this.blockDataService.getSelectedBlockData(blockId);
    if (sectionBlockFields.style?.backgroundImg ) {
      return {'background': 'url("' + sectionBlockFields.style.backgroundImg + '")'};
    }
    return {}
  }
  /**
   * Gets the status of the full width section
   * 
   */
  getSectionWidth = (blockId: any) => {
    let sectionBlockFields = this.blockDataService.getSelectedBlockData(blockId);
    if (sectionBlockFields.sectionFullWidth) {
      return true;
    }
    return false;
  }

  sectionWidthChange = (blockId: any, value: MatSlideToggleChange) => {
    let sectionBlockFields = this.blockDataService.getSelectedBlockData(blockId);
    sectionBlockFields.sectionFullWidth = value.checked;
  }

  sectionFullScreenChange = (blockId: any, value: MatSlideToggleChange) => {
    let sectionBlockFields = this.blockDataService.getSelectedBlockData(blockId);
    sectionBlockFields.style.sectionFullScreen = value.checked;
  }

  /**
   * Controls the number of columns inside the section
   */
  // sectionColumns = ( $event: any ) => {
  //   // if( $event.value === '2' ) {
  //   //   this.twocol = true;
  //   // }
  //   this.twocol = true;
  // }
   

  getBlockFields = (id: any) => {
    if(this.importedBlockData) {
      return this.importedBlockData;
    }
    return this.blockDataService.getSelectedBlockData(id);
  }

  showStyleTab = () => {
    this.previewBlock = false;
    this.previewFields = false;
    this.styleFields = true;
  }

  showDataTab = () => {
    this.previewBlock = false;
    this.previewFields = true;
    this.styleFields = false;
  }

  checkSidebar = () => {
    if (!this.previewBlock && !this.previewFields && !this.styleFields) {
      return true;
    }
    return false;
  }

  checkPreview = () => {
    if (this.previewBlock && !this.previewFields && !this.styleFields) {
      return true;
    }
    return false;
  }

  checkData = () => {
    if (this.previewFields) {
      return true;
    }
    return false;
  }

  checkStyle = () => {
    if (this.styleFields && !this.previewFields && !this.previewBlock) {
      return true;
    }
    return false;
  }

  checkSettings = () => {
    if (!this.checkSidebar() && !this.checkPreview()) {
      return true;
    }
    return false;
  }

  /** 
  * Deletes the Block from the mobile Preview
  * Deletes the Block from the JSON Object
  */
  deleteBlock = (blockId: any) => {
    let element = document.getElementById("column" + blockId+"a");
    element?.closest('.stacksBlockContainer')?.parentElement?.remove();
    this.blockDataService.removeBlockData(blockId);
  }

  dropped(event: CdkDragDrop<string[]>) {
    /**
     *    event.container is the section
     *    event.previousContainer is the mobile preview
     *    event.item is the item being dragged
     * 
    console.log("We dragged an ");
    console.log(item.element.nativeElement);
    console.log(" from container column ");
    console.log(item.element.nativeElement.parentElement?.classList.contains('columnBlockContainer'));
    console.log(" to container ");
    console.log(container.element.nativeElement );
     **/
    
    const { previousContainer, container, item, previousIndex, currentIndex } = event;
    // console.log(item.element.nativeElement);
    // console.log(" from container column ? ");
    // console.log( item.element.nativeElement.closest('.columnBlockContainer') );
    // console.log(" to container ");
    // console.log(container.element.nativeElement);
    // console.log(" in second column ? ");
    // console.log(container.element.nativeElement.classList.contains("secondColumn"));
    // If dropping inside second column
    let droppedColumn = container.element.nativeElement.classList.contains("secondColumn") ? 1 : 0;
    /**
     * Check if Section inside Section
     */
    if (item.element.nativeElement.classList.contains("previewSection") && container.element.nativeElement.classList.contains("columnBlockContainer")) {
      alert("Can't Drag section inside Section");
      return;
    }

    /**
     * Check if moving from inside the Column itself
     */
    if (item.element.nativeElement.closest('.columnBlockContainer')) {
      let Container = container.element.nativeElement;
      let containerId: any = Container.getAttribute("blockid");
      let allblocksdata = this.blockDataService.getAllBlocksData();
      for (const key in allblocksdata) {
        if (Object.prototype.hasOwnProperty.call(allblocksdata, key)) {
          const element = allblocksdata[key];
          if (element.id == containerId) {
            containerId = key;
          }
        }
      }
      /**
       * Adjust the block data JSON Object
       */
      if (!allblocksdata[containerId].elements[droppedColumn]) {
        allblocksdata[containerId].elements.push(this.blockFieldsService.columnBlockData);
      }
      moveItemInArray(
        allblocksdata[containerId].elements[droppedColumn].elements,
        event.previousIndex+1,
        event.currentIndex+1);
      /**
       * Adjust the preview inside the Mobile Preview
       */

      /**
       * After Nested Elements the event.previousIndex is wrongly calculated as it counts the nested blocks as well, to avoid this confusion we have to count the nodes manually
       */
      let newPreviousIndex = 0;
      let itemId = item.element.nativeElement.getAttribute('id');
      if (document.querySelector("#" + itemId)?.closest('.stacksBlockContainer')?.parentNode?.parentNode) {
        let allNodesOfSelectedItem = document.querySelector("#" + itemId)?.parentNode?.parentNode?.parentNode?.childNodes;
        let currentNode = document.querySelector("#" + itemId)?.parentNode?.parentNode;
        newPreviousIndex = Array.prototype.indexOf.call(allNodesOfSelectedItem, currentNode);
      }
      /**
       * After Nested Elements the event.currentIndex is wrongly calculated, to avoid this confusion we have to count the nodes manually through the placeholder
       */
      // let newCurrentIndex = 0;
      // if (document.querySelector(".blockPlaceholder")?.parentNode) {
      //   let allNodesOfSelectedItem = document.querySelector(".blockPlaceholder")?.parentNode?.childNodes;
      //   let currentNode = document.querySelector(".blockPlaceholder")?.parentNode;
      //   newCurrentIndex = Array.prototype.indexOf.call(allNodesOfSelectedItem, currentNode) - 1; // -1 to subtract the index of the placeholder itself
      // }
      // console.log(newCurrentIndex);
      let newCurrentIndex = currentIndex;
      moveWithinContainer(
        container.element.nativeElement,
        event.previousIndex+1,
        event.currentIndex+1
      );
    } else {
      /**
       * Moving from outside to inside the section
       */
      /**
       * After Nested Elements the event.previousIndex is wrongly calculated as it counts the nested blocks as well, to avoid this confusion we have to count the nodes manually
       */
      let newPreviousIndex = 0;
      let itemId = item.element.nativeElement.getAttribute('id');
      // console.log(
      //   document.querySelector("#" + itemId), 
      //   document.querySelector("#" + itemId)?.closest('.stacksBlockContainer')?.parentNode, 
      //   document.querySelector("#" + itemId)?.closest('.stacksBlockContainer')?.parentNode?.parentNode);
      if (document.querySelector("#" + itemId)?.closest('.stacksBlockContainer')?.parentNode?.parentNode) {
        let allNodesOfSelectedItem = document.querySelector("#" + itemId)?.parentNode?.parentNode?.parentNode?.childNodes;
        // all nodes has all the blocks and random divs, even the comments generated from ng-if, we need to clean this up to our blocks only
        // for (const key in allNodesOfSelectedItem) {
        //   console.log(key);
        //   if (Object.prototype.hasOwnProperty.call(allNodesOfSelectedItem, key)) {
        //     const element = allNodesOfSelectedItem[key];
        //     console.log(element, element.nodeName);
        //     if(!element.nodeName.includes("BLOCK-") || element.nodeName.includes("BLOCK-FOOTER") || element.nodeName.includes("BLOCK-HEADER")) {
        //       allNodesOfSelectedItem[key].remove();
        //     }
        //   }
        // }
        let currentNode = document.querySelector("#" + itemId)?.parentNode?.parentNode;
        newPreviousIndex = Array.prototype.indexOf.call(allNodesOfSelectedItem, currentNode) - 1;
        // console.log(allNodesOfSelectedItem, currentNode, newPreviousIndex);
        // return;
      }
      let Container =  container.element.nativeElement;
      let Item = item.element.nativeElement.parentNode?.parentNode;
      if (Item) {
        document.querySelector("#mainMobileAppPreview  > .ng-star-inserted > .stacksBlockContainer > #"+itemId)?.parentElement?.parentElement?.remove();
        this.blockDataService.builderBlocksIndex++;
      }
      let containerId : any = Container.getAttribute("blockid");
      /**
       * Match the id of the Section as it is not a must to have its order equals to its id
       [
         {
           "id": 0,
           "type": "image",
         },
         {
           "id": 2,
           "type": "section",
           "data": {
             "column": [
               {
                 "id": 1,
                 "type": "text",
               }
             ]
           },
         }
       ]
       in the above example the number of elements are 3 however the id is 2
       */ 

      let allblocksdata = this.blockDataService.getAllBlocksData();
      for (const key in allblocksdata) {
        if (Object.prototype.hasOwnProperty.call(allblocksdata, key)) {
          const element = allblocksdata[key];
          if ( element.id == containerId ) {
            containerId = key;
          }
        }
      }
      if (!allblocksdata[containerId].elements[droppedColumn]) {
        allblocksdata[containerId].elements.push(this.blockFieldsService.columnBlockData);
      }
      
      
      // console.log("transferring to container id" + containerId + " column " + droppedColumn + " from index " + newPreviousIndex + " to index " + allblocksdata[containerId].elements[droppedColumn].elements.length + " item " + itemId);

      transferArrayItem(
        allblocksdata,
        allblocksdata[containerId].elements[droppedColumn].elements,
        newPreviousIndex,
        allblocksdata[containerId].elements[droppedColumn].elements.length);
    }

  }
}

function moveWithinContainer(container: any, fromIndex: any, toIndex: any) {
  if (fromIndex === toIndex) {
    return;
  }
  const nodeToMove = container.children[fromIndex];
  const targetNode = container.children[toIndex];
  // console.log("node to move",nodeToMove);
  // console.log("target node", targetNode);
  // console.log("from index", fromIndex);
  // console.log("to index", toIndex);
  // console.log("container children", container.children);
  if (fromIndex < toIndex) {
    targetNode.parentNode.insertBefore(nodeToMove, targetNode.nextSibling);
  } else {
    targetNode.parentNode.insertBefore(nodeToMove, targetNode);
  }
}
