<div class="containerWrapper">
    <h1>Add New Post</h1>
    <div class="postDetail">
        <mat-progress-bar mode="indeterminate" *ngIf="postProgress"></mat-progress-bar>
        <div class="postHeader">
            <h1>POSTS</h1>
            <button [routerLink]="['/posts/list']">SEE ALL POSTS</button>
        </div>
        <form [formGroup]="postForm" (ngSubmit)="onSubmit()">
            <div class="postForm">
                    <mat-form-field class="matUsername">
                        <mat-label>Username</mat-label>
                        <input required matInput formControlName="username">
                        <mat-error>
                            Username is required
                        </mat-error>
                    </mat-form-field>
                    <label>Post</label>
                    <editor 
                    [(ngModel)]="post" 
                    [ngModelOptions]="{standalone: true}"
                        apiKey="dh6nv3idqamf38bn1tnknqxd0qn9vz2yli1zjpjehily2stk" 
                        [init]="{
                        base_url: '/tinymce',
                        suffix: '.min',
                        height: 320,
                        menubar: false,
                        plugins: [
                        'lists',
                        'help',
                        'textcolor',
                        'colorpicker',
                        'code',
                        ],
                        toolbar:
                        'code | undo redo | bold italic | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent forecolor backcolor | help'
                        }"></editor>
                    <div *ngIf="!mobile" class="fileWrapper">
                        <mat-progress-bar mode="determinate" value="{{this.builderService.fileUploadProgress}}" *ngIf="showUploadProgress"></mat-progress-bar>
                        <img src="{{imageUrl}}" alt="">
                        <div class="inputContainer">
                            <input type="file" (change)="onFileSelected($event)" class="custom-file-input" accept="image/*">
                        </div>
                        <button (click)="removeImg()" type="button">Remove</button>
                    </div>
            </div>
            <button mat-icon-button class="publishBtn" type="submit">
                <span>PUBLISH</span>
            </button>
        </form>
    </div>
</div>