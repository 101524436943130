<div class="secondarySidebar">
    <div class="secondarySBTitle">
        <h2>Admin Analytics</h2>
    </div>
    <div class="secondarySBContent">
        <ul>
            <li [routerLink]="['/admin/self']">Self</li>
            <li class="selected" [routerLink]="['/admin/cloud']">Cloud</li>
            <li [routerLink]="['/admin/logs']">Logs</li>
            <li [routerLink]="['/admin/apps-uploaded']">
                Apps Uploaded
            </li>
        </ul>
    </div>
</div>
<div id="cloudWrapper" class="containerWrapper withSidebar noBG">
    <!-- <mat-progress-bar mode="indeterminate" *ngIf="PNProgress"></mat-progress-bar> -->
    <div id="titleContainer" class="innerContainerTitle">
      <h1>Stacks Cloud Subscriptions</h1>
    </div>
    <div id="cloudContainer" class="innerContainerContent">
        <div class="gridWrapper">
            <div class="fullGrid">
                <cloud-subs></cloud-subs>
            </div>
            <div class="fullGrid">
                <cloud-unsubs></cloud-unsubs>
            </div>
            <div class="fullGrid">
                <cloud-all-subs></cloud-all-subs>
            </div>
            <div class="fullGrid">
                <cloud-churn></cloud-churn>
            </div>
        </div>
    </div>
</div>