import { Component, OnInit } from '@angular/core';
import { SearchProjectsService } from './search-projects.service';

@Component({
  selector: 'search-projects',
  templateUrl: './search-projects.component.html',
  styleUrls: ['./search-projects.component.scss']
})
export class SearchProjectsComponent implements OnInit {
  showProjectsSearchProgress = false;
  mobile = false;
  searchProjectDomain = '';
  response : any;
  constructor(private SearchProjectsService: SearchProjectsService) { }

  ngOnInit(): void {
    if (window.screen.width <= 768) { // 768px portrait
      this.mobile = true;
    }
  }

  updateSearchValue = (event: any) => {
    this.searchProjectDomain = event.currentTarget.value;
  }
  searchProjects = () => {
    this.SearchProjectsService.searchProjects(this.searchProjectDomain).subscribe((response) => {
      this.response = response;
    }, () => {

    });
  }

}
