import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { PixelService } from 'ngx-multi-pixel';
import { BlockDataService } from 'src/app/block-services/block-data.service';
import { BlockFieldsService } from 'src/app/block-services/block-fields.service';
import { CategoriesService } from 'src/app/blocks-wrapper/block-categories/categories.service';
import { BuilderService } from 'src/app/builder-services/builder.service';
import { LoginService } from 'src/app/login/login.service';
import { MixpanelService } from 'src/app/tracking/mixpanel.service';
import { environment } from 'src/environments/environment';

declare var fpr: any;
@Component({
  selector: 'payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss']
})
export class PaymentSuccessComponent implements OnInit {

  constructor(private route: ActivatedRoute, private blockDataService: BlockDataService,
    private router: Router,private blockFieldsService: BlockFieldsService, private categoriesService: CategoriesService,
    private builderService: BuilderService, private mixPanel: MixpanelService, private pixel: PixelService,
    private gaService: GoogleAnalyticsService, private loginService: LoginService ) {}

  project_id:any;
  public package:any;

  user_email = '';
  trialsCount = 0;
  projectsLength = 0;
  premiumCount: any = 0;
  multiProjects = false;
  projectUrl = '';
  newLicense: any = false;
  token: any;
  name: any;
  type: any;
  period: any;
  trial: any;
  price_id: any;

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        this.route.paramMap.subscribe(params => {
          const token = params.get('token');
          if (token) {
            this.token = token;
            if (localStorage.getItem("token") !== this.token) {
              localStorage.setItem("token", this.token);
              this.blockDataService.sendTokenData(this.token);
            } else {
              localStorage.setItem("token", this.token);
              this.blockDataService.sendTokenData(this.token);
            }
          } else {
            console.error('No token found in query params.');
          }
          // Get the URL segments (last part is your encoded JSON object)
          const urlSegments = this.route.snapshot.url;

          // URL segment containing the encoded JSON object is the second to last segment
          const encodedJsonString = urlSegments[urlSegments.length - 2].toString();

          // URL-decode and parse the JSON string
          const decodedJsonString = decodeURIComponent(encodedJsonString);
          const parsedObject = JSON.parse(decodedJsonString);

          if(parsedObject.name){
          this.name = parsedObject.name;
          this.type = parsedObject.type;
          this.period = parsedObject.period;
          this.trial = parsedObject.trial;
          this.price_id = parsedObject.price_id;

          console.log('Extracted Data:', this.name, this.type, this.period, this.trial, this.price_id);
          this.package = {
            name: this.name,
            type: this.type,
            period: this.period,
            trial: this.trial,
            price_id: this.price_id
          }
        }else if(params.get('package')){
          this.token = params.get('token');
          this.package = params.get('package');
          console.log(this.package)
          this.package = decodeURIComponent(decodeURIComponent(decodeURIComponent(this.package)))
          console.log(this.package)
          this.package = this.package.replace(/^"""/, '').replace(/"""$/, '');
          this.package = this.package.slice(1, -1);
          console.log(this.package)
          this.package = JSON.parse(this.package.replace(/\\/g, ''))
          // this.package = JSON.parse(JSON.parse(this.package.replace(/^"|"$/g, ''))) 
          console.log(this.package)
          this.project_id = params.get('projectId');
          console.log(this.package)
        }
        });


        this.mixPanel.init(params.project_id);
        this.mixPanel.track('New Sale', {
          packageName: this.package.name,
          packageType: this.package.type,
          packagePlan: this.package.period,
          price_id: this.package.price_id,
          newLicense: this.newLicense,
          sale_value: this.package.sale_value,
        });
        this.pixel.track('Purchase', {
          value: this.package.sale_value,     // Value of all items
          currency: 'USD'                     // Currency of the value
        });
        this.gaService.gtag('event', 'Purchase', {'send_to': environment.gtm});
        this.gaService.gtag('event', 'Purchase', {'send_to': environment.ga});
        var email=this.loginService.getCurrentUser().email;
        fpr("referral",{email: email})
        
      }
      );

    if (localStorage.getItem("token") !== this.token) {
      localStorage.setItem("token", this.token)
      this.blockDataService.sendTokenData(this.token);
    } else {
      localStorage.setItem("token", this.token)
      this.blockDataService.sendTokenData(this.token);
    }
    this.builderService.setTour(true);

    setTimeout(() => {
      if( this.newLicense ) {
        this.builderService.showUpdateProgress = false;
        this.getAllProjects('latest');
      }
    }, 1500);
  }


  /**
   * Get all projects and sets the localstorage
   */
  getAllProjects = (orderItem:string = '') => {
    this.builderService.getProjects(this.user_email).subscribe(
      (allProjects) => {
        this.trialsCount = 0;
        this.premiumCount = 0;
        let projectsIds = [];
        // if(allProjects[0] == null) {
        //   alert("you don't have active subscriptions, please go to the main website to renew your subscription")
        // }
        this.builderService.projects = [];
        for (const key in allProjects) {
          if (Object.prototype.hasOwnProperty.call(allProjects, key)) {
            const element = allProjects[key];
            if(element.type == '' ) {
              element.type = 'purchase';
            }
            this.builderService.projects.push(
              { 'id': element.id, 
              "status": element.status, 
              "type": element.type,
              "domain": element.domain,
              "email": element.email,
              "first_name": element.first_name,
              "last_name": element.last_name,
              "premium_status": element.premium_status,
              "premium_package": element.premium_package,
             });
              // this.categoriesIds.push(element.term_id);
              projectsIds.push(element.id);
              if( element.status == 'active' ) {
                if( element.type == 'trial' ) {
                  this.trialsCount++;
                } else {
                  this.premiumCount++;
                }
              }

          }
          this.builderService.projectsPremiumCount = this.premiumCount;
        }
        this.projectsLength = allProjects.length;
        if (allProjects.length > 1) {
          this.multiProjects = true;
        }
        /**
         * If there is a selected project in the localstorage then retrieve
         */
        let projectId;
        // if (!projectsIds.includes(String(localStorage.getItem("selectedProject")))) {
          if( orderItem == 'latest' ) {
            for (let index = 0; index < allProjects.length; index++) {
              const element = allProjects[index];
              if(element.status == 'active') {
                projectId = element.id;
                localStorage.setItem("selectedProject", projectId);
              }
            }
          } else {
            projectId = String(localStorage.getItem("selectedProject"));
          }
          
        // } else {
          // projectId = String(localStorage.getItem("selectedProject"));
        // }
        this.blockDataService.sendProjectData(projectId);
        this.getProject(projectId);
      },
      (err) => {
      }
    );
  }

  /**
   * Send getProject Request after selection
   * @param projectId 
   */
  getProject = ( projectId: any, type: string = 'default') => {
    this.builderService.showUpdateProgress = true;
    this.builderService.selectedProject = projectId;
    localStorage.setItem("selectedProject", projectId);
    localStorage.removeItem("categories");
    this.builderService.getProject(projectId).subscribe((project: any) => {
      this.builderService.user_type = project.user_type;
      // Cache the response
      this.builderService.cacheProjectReq.response = project;
      // Update the Builder View
      if( this.blockDataService.blocksData == null ) {
        this.blockDataService.blocksData = [];
        let headerData = this.blockFieldsService.getHeaderBlockFields();
        headerData.id = this.blockDataService.getUniqueId(2);
        this.blockDataService.blocksData.unshift(headerData);
      }
      this.blockDataService.sendRenderAllBlocks();

      this.builderService.is_premium = false;
      if(project.premium.length) {
        this.builderService.is_premium = true;
        for (let index = 0; index < project.premium.length; index++) {
          const element = project.premium[index];
          this.builderService.premium_status = element.status;
          this.builderService.stripe_subscription_id = element.stripe_subscription_id;
          this.blockDataService.sendStripeData(element.stripe_subscription_id);
          this.builderService.premium_start_date = element.start_date;
          if(element.status == 'active') {
            break;
          }
        }
      }

      // Update the Settings
      this.builderService.selectedProjectDomain = project.details[0].domain;
      this.projectUrl = this.builderService.selectedProjectDomain;
      this.categoriesService.getInitialCategories();
      if(type == 'clicked') {
        this.blockDataService.blocksData = JSON.parse(project["data"]);
        // window.location.href = "/";
        this.router.navigate(['/']);
      }
      this.builderService.showUpdateProgress = false;
      this.builderService.setTour(true).subscribe((response) => {
        // this.router.navigate(['/site-type']);
        window.location.href = '/site-type';
      }, (err) => {

      })
    },
    (err) => {
      console.log(err);
    });
  }

}
