import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { BlockFieldsService } from 'src/app/block-services/block-fields.service';
import { BuilderService } from 'src/app/builder-services/builder.service';
import { BlockDataService } from '../../block-services/block-data.service';
import { MixpanelService } from 'src/app/tracking/mixpanel.service';
import { CategoriesService } from 'src/app/e-commerce/categories/categories.service';
@Component({
  selector: 'stacks-categories',
  templateUrl: './stacks-categories.component.html',
  styleUrls: ['./stacks-categories.component.scss']
})
export class StacksCategoriesComponent implements OnInit {

  previewBlock = false;
  styleFields = false;
  blockData: any;

  previewFields = false;

  blockFields: any;

  blockId = '';

  categoriesData = [];
  currency: any;
  showCategoriesProgress = false;
  categories = [];
  allCategories:any = [];
  shownCategories:any = [];
  allowedSubcategories:any = [];

  @Input() nestedBlock: any = {};
  @Input() importedBlockData: any;
  /**
   * If the block is rendered from saved data, then this should be true, if added dynamically by drag and drop or click then it will be "initial"
   **/
  initialData: any = false;

  constructor(
    public blockDataService: BlockDataService,
    private blockFieldsService: BlockFieldsService,
    public categoriesService: CategoriesService,
    public builderService: BuilderService,
    private mixPanel: MixpanelService
  ) {
  };

  ngOnInit() {
    if(this.importedBlockData ) {
      this.previewBlock = true;
      this.previewFields = false;
      this.styleFields = false;
    }
    if (this.initialData !== 'initial') {
      if (this.nestedBlock.type == "stacksCategories") {
        this.blockData = this.nestedBlock;
        this.blockId = this.nestedBlock.id;
        this.previewBlock = true;
      } else {
        this.blockData = this.importedBlockData ? this.importedBlockData : this.blockFieldsService.getStacksCategoriesBlockFields();
        if (!this.blockId) {
          this.blockId = this.blockDataService.getUniqueId(2);
        }
      }
    } else {
      this.blockData = this.blockDataService.getSelectedBlockData(this.blockId);
    }

    this.getCategories();
  }

  getCategories = () => {
    this.showCategoriesProgress = true;
    this.categoriesService.getCategories().subscribe((response:any) => {
      this.showCategoriesProgress = false;
      this.showCategoriesProgress = false;
      this.allCategories = [];
      if( !response ) {
        console.log("no response from categories, possibly empty result !!");
      } else {
        for (const key in response) {
            if (Object.prototype.hasOwnProperty.call(response, key)) {
              const element = response[key];
              this.allCategories.push(element);
            }
        }
      }
      this.shownCategories = this.allCategories;
      this.setCategoriesData(this.blockId, this.allCategories);
      this.adjustArgs(this.blockId, this.blockData.data);
      // this.currency = response.currency_symbol;
    }, (err) => {
      this.showCategoriesProgress = false;
    });
  }

  /**
   * Getter for the Categories Data, The case of changing the modifying in one component and reflects into another component needs getters and setters
   * @param blockId 
   * @returns 
   */
  getCategoriesData = (blockId: string) => {
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      return;
    }
    let categoriesBlockFields = this.blockDataService.getSelectedBlockData(blockId);
    return categoriesBlockFields.categoriesData;
  }

  /**
   * Setter for the Categories Data, The case of changing the modifying in one component and reflects into another component needs getters and setters
   * @returns
   */
  setCategoriesData = (blockId: string, data: object) => {
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      return;
    }
    let categoriesBlockFields = this.blockDataService.getSelectedBlockData(blockId);
    categoriesBlockFields.categoriesData = data;
  }

  /**
   * Appends Block to the Preview, Initial checks if the block is added as initial block addition or appended live
   */
  addBlockToPreview(initial: any = '') {
    let blockCategoriesData = {
      component: StacksCategoriesComponent,
      index: 'latest',
      element: this,
      initial: initial
    };
    this.blockDataService.sendBlockData(blockCategoriesData);
    this.builderService.blocksCollapsed = true;
  }

  appendBlock($event: any) {
    if( !this.builderService.standalone ) {
      return false;
    }
    let clonedBlockData = { ...this.blockData };
    this.blockId = this.blockDataService.appendBlocksData(clonedBlockData);
    this.addBlockToPreview();
    this.builderService.logData('Categories Block Added', 'Block Add', 'Categories Block Added').subscribe((response) => {

    }, (err) => {

    });
    this.mixPanel.track('Block Added', {
      projectid: this.builderService.getSelectedProject(),
      block: 'stacksCategories',
    });
    return true;
  }

  /**
   * Sync the clicked block on click inside the mobile preview
   */
  showData(blockId: any) {
    // Check Block Availability before taking action
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      // show data of the last element instead
      blockId = this.blockDataService.getLatestBlockId();
    }
    this.blockFieldsService.clickedBlockId = blockId;
    this.blockDataService.sendCurrentBlock(StacksCategoriesComponent);
  }

  queryChange(query: any, blockId: any, $event: any, type: string) {
    // Check Block Availability before taking action
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      return;
    }
    let categoriesBlockFields = this.blockDataService.getSelectedBlockData(blockId);
    /**
     * Modifying Nested Object Directly doesn't work, so we have to clone the data object first then modify it
     */
    let clonedBlockFieldsData = { ...categoriesBlockFields.data };
    let data: any = '';
    if (type == 'select') {
      data = $event.value
    } else if (type == 'text') {
      data = $event.currentTarget.value;
    } else if (type == 'slider') {
      data = $event.currentTarget.value;
    }
    clonedBlockFieldsData[query] = data;
    categoriesBlockFields.data = clonedBlockFieldsData;

    this.adjustArgs(blockId, clonedBlockFieldsData);
  }

  /**
   * Makes all the needed adjustments and Filterations to the Query Args and outputs the result, in the correct order
   */
  adjustArgs = (blockId:any, args:any) => {

    let filteredData:any;

    let limit = args.limit;
    let categories = args.categories;
    let sub_categories = args.sub_categories;
    let type = args.source;
    let order_by = args.orderby;
    let order_type = args.order;
    // First thing is to filter the categories selected
    if( type == 'by_id' && categories.length ) {
      this.categoryChange(categories);
      if(sub_categories && sub_categories.length) {
        filteredData = this.filterSubCategories(sub_categories);
        // filteredData = sub_categories;
      } else {
        filteredData = this.filterCategories(categories);
      }
    } else {
      filteredData = this.shownCategories;
    }

    // Next Adjust the Limit
    filteredData = this.filterLimit(limit, filteredData);

    // Then Order them
    filteredData = this.orderCategories(order_by, order_type, filteredData);

    this.setCategoriesData(blockId, filteredData);
  }

/**
 * Changes the limit of the elements shown for the product
 * @param blockId 
 * @param limit 
 * @returns products limited to the amount selected
 */
filterLimit = (limit: any, filteredData: any) => {
  return filteredData.slice(0,limit)
}

filterCategories = (categories: any) => {
  return this.categoriesService.findCategoryById(this.allCategories, categories);
}

filterSubCategories = (sub_categories: any) => {
  let subCatResult = this.categoriesService.findSubCategoryById(this.allCategories, sub_categories);
  let catResult:any = [];
  let count = 0;
  for (const subCat of subCatResult) {
    catResult[count] = [];
    catResult[count].categoryId = subCat.subCategoryId;
    catResult[count].categoryImage = subCat.categoryImage;
    catResult[count].categoryName = subCat.subcategory;
    count++;
  }
  return catResult;
}

orderCategories = (order_by: string, order_type: string, filteredData: any) => {
  if(order_by == 'title') {
      filteredData.sort((a:any, b:any) => {
        const titleA = a.categoryName.toLowerCase();
        const titleB = b.categoryName.toLowerCase();
        if(order_type == 'ASC') {
          return titleA.localeCompare(titleB);
        } else {
          return titleB.localeCompare(titleA);
        }
      });
  }
  
  return filteredData;
}

  styleChange(style: any, blockId: any, $event: any, type: string) {
    // Check Block Availability before taking action
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      return;
    }
    let categoriesBlockFields = this.blockDataService.getSelectedBlockData(blockId);
    /**
     * Modifying Nested Object Directly doesn't work, so we have to clone the data object first then modify it
     */
    let clonedBlockFieldsData = { ...categoriesBlockFields.style };
    let data = '';
    if (type == 'select') {
      data = $event.value
    } else if (type == 'text') {
      data = $event.currentTarget.value;
    } else if (type == 'slider') {
      data = $event.currentTarget.value + 'px';
    }

    clonedBlockFieldsData[style] = data;
    categoriesBlockFields.style = clonedBlockFieldsData;
  }

  getBlockFields = (id: string) => {
    if(this.importedBlockData) {
      return this.importedBlockData;
    }
    return this.blockDataService.getSelectedBlockData(id);
  }

  getCategoriesList = (blockid: string) => {
    let block = this.blockDataService.getSelectedBlockData(blockid);
    return [block.data?.categories];
  }

  showStyleTab = () => {
    this.previewBlock = false;
    this.previewFields = false;
    this.styleFields = true;
  }

  showDataTab = () => {
    this.previewBlock = false;
    this.previewFields = true;
    this.styleFields = false;
  }

  checkSidebar = () => {
    if (!this.previewBlock && !this.previewFields && !this.styleFields) {
      return true;
    }
    return false;
  }

  checkPreview = () => {
    if (this.previewBlock && !this.previewFields && !this.styleFields) {
      return true;
    }
    return false;
  }

  checkData = () => {
    if (this.previewFields) {
      return true;
    }
    return false;
  }

  checkStyle = () => {
    if (this.styleFields && !this.previewFields && !this.previewBlock) {
      return true;
    }
    return false;
  }

  checkSettings = () => {
    if (!this.checkSidebar() && !this.checkPreview()) {
      return true;
    }
    return false;
  }

  /** 
  * Deletes the Block from the mobile Preview
  * Deletes the Block from the JSON Object
  */
  deleteBlock = (blockId: any) => {
    this.blockDataService.removeBlockData(blockId);
  }

  categoryChange = (value: string) => {
    let selectedCategory = this.categoriesService.findCategoryById(this.allCategories, value);
    this.allowedSubcategories = [];
    for (const category of selectedCategory) {
      if(!category.subcategories) {
        continue;
      }
      for (const subcategory of category.subcategories) {
        this.allowedSubcategories.push(subcategory);
      }
    }
  }
}
