import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BlockFieldsService {

  staticBlockData : any;
  imageBlockData : any;
  textBlockData : any;
  productsBlockData : any;
  stacksProductsBlockData : any;
  sectionBlockData : any;
  columnBlockData : any;
  headerBlockData : any;
  footerBlockData : any;
  videoBlockData : any;
  sliderBlockData : any;
  // Clicking on an element in the Mobile View will set this value to the ID of the clicked block
  clickedBlockId : number;

  categoriesBlockData: any;
  stacksCategoriesBlockData: any;
  postsBlockData: any;
  buttonBlockData: any;
  readGlobalHeader:any = true;
  global_header:any;
  disable_header:any = true;
  toggleChecked = false;
  readGlobalFooter = true;

  menuIconToggleChecked = true;
  searchIconToggleChecked = true;
  cartIconToggleChecked = true;
  sidemenuIconToggleChecked = true;

  constructor() {

    this.imageBlockData = {
      'id': 0,
      'type': 'image',
      'data': {
        'src': '../../assets/images/blocks/image-placeholder.png',
      },
      'style': {}
    };

    this.staticBlockData = {
      'id': 0,
      'type': 'static',
      'data': {
        "block": "shop_filters"
      },
      'style': {}
    };

    this.sliderBlockData = {
      'id': 0,
      'type': 'slider',
      'data': {
        'slides': [
          {
            'img': '../../assets/images/blocks/image-placeholder.png',
            'title': '',
            'index' : 0,
            'link'  : 'home', 
          }  
        ]
      },
      'style': {}
    };

    this.videoBlockData = {
      'id': 0,
      'type': 'video',
      'data': {
        'src': 'https://www.youtube.com/embed/tLwp9JqgAM8'
      },
      'style': {}
    };


    this.headerBlockData = {
      'id': 0,
      'type': 'header',
      'data': {
        'src': '',
        'type': 'color',
        'bgColor': '#4fb371',
        'readGlobalHeader': true,
        'disable_header': false,
        'gHeaderBanners' : [{
          'index' : 0,
          'status' : 'inactive',
          'bannerAdId' : '',
          'gHeaderBannerPage' : 'home'
        }]
      },
      'style': {
        'menu_icon': "menu",
        'search_icon': "search",
        'cart_icon': "shopping_basket",
        'side_menu': "more_vert",
        'menuIcon': true,
        'searchIcon': true,
        'cartIcon': true,
        'sidemenuIcon': true,
      }
    };

    this.footerBlockData = {
      'id': 0,
      'type': 'footer',
      'data': {
        'src': '',
        'type': 'color',
        'bgColor': '#ffffff',
        'footerMenu': 'active',
        'readGlobalFooter': true,
        'menuItems' : [{
          'index' : 0,
          'icon'  : 'home',
          'color' : '#000000',
          'link'  : 'home', 
          'menuText'  : 'Home' 
        }],
        'gFooterBanners' : [{
          'index' : 0,
          'status' : 'inactive',
          'bannerAdId' : '',
          'gFooterBannerPage' : 'home'
        }]
      },
      'style': {
      }
    };

    this.textBlockData = {
      'id': 0,
      'type': 'text',
      'data': {
        'value': 'Lorem Ipsum Delarque ....'
      },
      'style': {}
    };

    this.productsBlockData = {
      'id': 0,
      'type': 'products',
      'data': {
        'query_post_type': 'product',
        'products_categories': [],
        'limit': 4,
        'query_orderby': 'date',
        'query_order': 'DESC',
      },
      "style": {
        "products_style": "simpleStyle",
        "productColumns": "twoColumns",
        "displayStyle": "gridStyle",
      },
    };

    this.categoriesBlockData = {
      'id': 0,
      'type': 'categories',
      'data': {
        'categories': [],
        'hide_empty': true,
        'orderby': 'date',
        'order': 'DESC',
        'source': 'all',
        'limit': 200
      },
      "style": {
        "categories_style": "grid"
      },
    };

    this.stacksProductsBlockData = {
      'id': 0,
      'type': 'stacksProducts',
      'data': {
        'query_post_type': 'product',
        'products_categories': [],
        'limit': 4,
        'query_orderby': 'date',
        'query_order': 'DESC',
      },
      "style": {
        "products_style": "simpleStyle",
        "productColumns": "twoColumns",
        "displayStyle": "gridStyle",
      },
    };

    this.stacksCategoriesBlockData = {
      'id': 0,
      'type': 'stacksCategories',
      'data': {
        'categories': [],
        'hide_empty': true,
        'orderby': 'date',
        'order': 'DESC',
        'source': 'all',
        'limit': 200
      },
      "style": {
        "categories_style": "grid"
      },
    };

    this.postsBlockData = {
      'id': 0,
      'type': 'posts',
      'data': {
        'posts' : [],
        'post_type': 'post',
        'hide_empty': true,
        'orderby': 'title',
        'order': 'DESC',
        'source': 'all',
        'limit': '200',
        'numberposts': '200',
        'category' : [],
        'wordpress_url': 'https://demo.stacksclients.com',
        'single_type': 'Native'
      },
      "style": {
        "posts_style": "grid"
      },
      'internalStyle': {},
    };


    this.sectionBlockData = {
      'id': 0,
      'type': 'section',
      'style': {

      },
      'elements': [{
        'elType' : 'column',
        'elements' : [
          
        ],
        'data' : {

        },
        'style' : {

        },
        'settings' : {
          "_column_size": 100
        }
      }],
    };

    this.columnBlockData = {
      'elType': 'column',
      'elements': [

      ],
      'data': {

      },
      'settings': {
        "_column_size": 100
      }
    }

    this.buttonBlockData = {
      'id': 0,
      'type': 'button',
      "buttonClass": {
        "buttonIcon": "leftIcon",
        "buttonSize": "LargeButton",
        "buttonStyle": "primaryButton"
      },
      'data': {
        'btnText': 'Click Here'
      },
      'style': {
        "align-items": "center",
        "background-color": "#7257FF",
        "border-radius": "7px",
        "color": "#000000",
        "cursor": "pointer",
        "display":"flex",
        "font-style": "normal",
        "font-weight": "normal",
        "icon": "done",
        "justify-content": "center",
        "padding-bottom": "10px",
        "padding-left": "20px",
        "padding-right": "20px",
        "padding-top": "10px",
      },
      "wrapperStyle": {
        // "justify-content": "center"
      }
    };

    this.clickedBlockId = 0;
   }

  /**
  * Getter for the Image Block Fields Object
  */
  getImageBlockFields = () => {
    return this.imageBlockData;
  }

  /**
  * Getter and Setter for the Text Block Fields Object
  */
  getTextBlockFields = () => {
    return this.textBlockData;
  }

  /**
  * Getter and Setter for the Text Block Fields Object
  */
  getSectionBlockFields = () => {
    return this.sectionBlockData;
  }

  /**
  * Getter for the Products Block Fields Object
  */
  getProductsBlockFields = () => {
    return this.productsBlockData;
  }

  /**
  * Getter for the Categories Block Fields Object
  */
  getCategoriesBlockFields = () => {
    return this.categoriesBlockData;
  }

  /**
  * Getter for the Stacks Products Block Fields Object
  */
  getStacksProductsBlockFields = () => {
    return this.stacksProductsBlockData;
  }

  /**
  * Getter for the Stacks Categories Block Fields Object
  */
  getStacksCategoriesBlockFields = () => {
    return this.stacksCategoriesBlockData;
  }


  /**
  * Getter for the Posts Block Fields Object
  */
   getPostsBlockFields = () => {
    return this.postsBlockData;
  }

  /**
  * Getter for the Header Block Fields Object
  */
  getHeaderBlockFields = () => {
    return this.headerBlockData;
  }

  /**
  * Getter for the Footer Block Fields Object
  */
   getFooterBlockFields = () => {
    return this.footerBlockData;
  }

  /**
  * Getter for the Video Block Fields Object
  */
  getVideoBlockFields = () => {
    return this.videoBlockData;
  }

  /**
  * Getter for the Video Block Fields Object
  */
  getSliderBlockFields = () => {
    return this.sliderBlockData;
  }

}
