import { BuilderService } from 'src/app/builder-services/builder.service';
import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/builder-services/database.service';
import { forkJoin, of } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { map } from 'rxjs/operators';
import { MultilingualPipe } from '../multilingual.pipe';
import { ProductsService } from '../../e-commerce/products/products.service';

@Component({
  selector: 'app-product-translations',
  templateUrl: './product-translations.component.html',
  styleUrls: ['./product-translations.component.scss']
})
export class ProductTranslationsComponent implements OnInit {

  displayedColumns: string[] = [];
  ELEMENT_DATA: any[] = [];
  languages: { title: string }[] = [];
  pagedTableData: any[] = [];
  pageSize: number = 5;
  pageSizeOptions: number[] = [5, 10, 25, 50];
  productProgress: boolean = false;
  productTranslations!: FormGroup;
  stacksTranslation = false;

  constructor(
    private builderService: BuilderService,
    private db: DatabaseService,
    private fb: FormBuilder,
    private productService: ProductsService,
    private translatePipe: MultilingualPipe
  ) { }

  /*
 * ngOnInit: Initializes the component by setting up the form group and loading necessary data.
 * - Creates a form group for product translations with a languages array.
 * - Uses Promise.all to concurrently load languages and product names.
 * - Upon successful loading, calls getTranslate() to fetch translations, resets the displayed data to the first page,
 *   and updates the displayed columns.
 * - Logs an error if any of the initialization processes fail.
 */

  ngOnInit(): void {
    this.productTranslations = this.fb.group({
      languages: this.fb.array([])
    });
    Promise.all([this.loadLanguages(), this.getProductNames()])
      .then(() => {
        this.getTranslate();
        this.updatePageData(0);
        this.updateDisplayedColumns();
      })
      .catch(error => console.error("Error initializing data:", error));
  }

  /*
  * updatePageData: Updates the displayed data for the current page in a paginated table.
  * - Calculates the starting and ending index based on the provided pageIndex and pageSize.
  * - Slices the ELEMENT_DATA array to retrieve the subset of data for the current page.
  * - Assigns the sliced data to pagedTableData for rendering in the table.
  */

  updatePageData(pageIndex: number) {
    const startIndex = pageIndex * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.pagedTableData = this.ELEMENT_DATA.slice(startIndex, endIndex);
  }

  /*
  * onPageChange: Handles changes in the pagination of the table.
  * - Updates the pageSize based on the new value from the pagination event.
  * - Calls updatePageData with the new page index to refresh the displayed data.
  */

  onPageChange(event: any) {
    this.pageSize = event.pageSize;
    this.updatePageData(event.pageIndex);
  }

  /*
  * loadLanguages: Loads available languages for the selected project from the database.
  * - Returns a Promise that resolves when the languages are successfully fetched and formatted.
  * - Constructs the database path using the selected project ID and subscribes to the database service.
  * - On successful retrieval, formats and sorts the languages, then resolves the Promise.
  * - Logs an error and rejects the Promise if fetching languages fails.
  */

  loadLanguages(): Promise<void> {
    return new Promise((resolve, reject) => {
      const project_id = this.builderService.selectedProject;
      const path = `/projects/${project_id}/translations/languages`;
      this.db.getDatabase(path).subscribe({
        next: (languagesData: any) => {
          this.languages = this.formatLanguages(languagesData);
          this.sortLanguages('English');
          resolve();
        },
        error: (error) => {
          console.error('Error fetching languages:', error);
          reject(error);
        }
      });
    });
  }

  /*
  * getProductNames: Fetches product names from the product service and updates the ELEMENT_DATA array.
  * - Returns a Promise that resolves when the products are successfully retrieved.
  * - Subscribes to the product service to get the list of products.
  * - On successful retrieval, converts the products object to an array and logs a success message.
  * - If translation is enabled, calls handleMissingTranslations to process the product data.
  * - Rejects the Promise and logs an error if fetching products fails.
  */

  getProductNames(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.productService.getProducts().subscribe({
        next: (productsObject) => {
          this.ELEMENT_DATA = Object.values(productsObject);
          console.log('Products loaded successfully.');
          if (this.stacksTranslation) {
            this.handleMissingTranslations(this.ELEMENT_DATA);
          }
          resolve();
        },
        error: (error) => {
          console.error('Error fetching products:', error);
          reject(error);
        }
      });
    });
  }

  /*
   * formatLanguages: Transforms the languages data object into an array of language objects.
   * - Takes `languagesData` as input and maps each key to an object containing the title and associated properties.
   * - Returns an array of formatted language objects, each with a title and its respective data.
  */

  formatLanguages(languagesData: any): any[] {
    return Object.keys(languagesData).map(key => ({ title: key, ...languagesData[key] }));
  }

  /*
   * sortLanguages: Reorders the languages array to prioritize a specified primary language.
   * - Takes `primaryLanguage` as an argument and finds its index in the languages array.
   * - If the primary language is found, it removes it from its current position and adds it to the start of the array.
  */

  sortLanguages(primaryLanguage: string): void {
    const index = this.languages.findIndex(lang => lang.title === primaryLanguage);
    if (index > -1) {
      const [primaryLang] = this.languages.splice(index, 1);
      this.languages.unshift(primaryLang);
    }
  }

  /*
   * callStacksTranslation: Initiates the stacks translation process.
   * - Sets the stacksTranslation flag to true to indicate that translation is active.
   * - Calls getProductNames to fetch product names and potentially handle translations.
  */

  callStacksTranslation() {
    this.stacksTranslation = true;
    this.getProductNames();
  }

  /*
   * handleMissingTranslations: Processes rows of data to fill in missing translations for each language.
   * - Takes an array of objects (`allData`) and maps each row to check for missing translations.
   * - For each language, if a translation for the product name is missing, it uses the translate pipe to obtain it.
   * - Uses forkJoin to execute all translation requests concurrently and updates the row with the translated text.
   * - After processing all rows, updates the ELEMENT_DATA with the translated rows and refreshes the displayed data to the first page.
  */

  handleMissingTranslations(allData: { [key: string]: any }[]): void {
    const translationRequests = allData.map(row => {
      const missingTranslations = this.languages.map(lang => {
        const langTitle = lang.title;
        // Proceed with translation if productName text is available
        if (!row[langTitle]) {
          return this.translatePipe.transform(row.productName, langTitle).pipe(
            map(translatedText => {
              row[langTitle] = translatedText;
              return translatedText;
            })
          );
        } else {
          console.log(`${langTitle} translation already exists:`, row[langTitle]);
          return of(row[langTitle]);
        }
      });

      return forkJoin(missingTranslations).pipe(
        map(translations => {
          this.languages.forEach((lang, index) => {
            row[lang.title] = translations[index];
          });
          return row;
        })
      );
    });

    forkJoin(translationRequests).subscribe(translatedRows => {
      this.ELEMENT_DATA = translatedRows;
      this.updatePageData(0);
    });
  }

  /*
   * getTranslate: Fetches translations for product names from the database for each language.
   * - Retrieves the selected project ID from the builder service.
   * - Iterates over each product in ELEMENT_DATA and each language in the languages array.
   * - Constructs the database path for each product's translation and subscribes to the database service.
   * - On successful retrieval, updates the corresponding product object with the fetched translation.
   * - Logs an error if fetching the translation fails for a specific product and language.
  */

  getTranslate(): void {
    const project_id = this.builderService.selectedProject;
    this.ELEMENT_DATA.forEach(product => {
      this.languages.forEach(lang => {
        const path = `/projects/${project_id}/translations/languages/${lang.title}/Products/${product.productName}`;
        this.db.getDatabase(path).subscribe({
          next: (response: any) => {
            if (response && response.value) {
              product[lang.title] = response.value;  // Update the product object with the fetched translation
            }
          },
          error: (error) => console.error(`Failed to load translation for ${product.productName} in ${lang.title}:`, error)
        });
      });
    });
  }

  /*
   * saveTranslation: Saves the translation of a product for a specified language to the database.
   * - Sets the productProgress flag to true to indicate that a save operation is in progress.
   * - Retrieves the selected project ID and determines the value to be saved based on the language title.
   * - Constructs a translation object and the unique database path for the product's translation.
   * - Calls the database service to save the translation and subscribes to handle the response.
   * - On successful save, logs a message, resets the editing state, and sets productProgress to false.
   * - Logs an error and sets productProgress to false if the save operation fails.
  */

  saveTranslation(product: any, langTitle: string): void {
    this.productProgress = true;
    const project_id = this.builderService.selectedProject;
    const value = langTitle === 'English' ? product.productName : product[langTitle] || '';
    const translationObject = { value: value };
    const uniquePath = `/projects/${project_id}/translations/languages/${langTitle}/Products/${product.productName}`;
    this.db.setDatabase(uniquePath, translationObject).subscribe({
      next: (response) => {
        console.log(`Translation for ${product.productName} in ${langTitle} saved:`, response);
        this.productProgress = false;
        product.editing = null;
      },
      error: (error) => {
        console.error(`Failed to save translation for ${product.productName} in ${langTitle}:`, error);
        this.productProgress = false;
      }
    });
  }

  /*
   * saveChanges: Iterates over all products and saves translations for each language (excluding English).
   * - For each product in ELEMENT_DATA, it loops through the available languages.
   * - If the language title is not 'English', it calls the saveTranslation function to save the translation.
  */

  saveChanges(): void {
    this.ELEMENT_DATA.forEach(product => {
      this.languages.forEach(lang => {
        if (lang.title !== 'English') {
          this.saveTranslation(product, lang.title);
        }
      });
    });
  }

  /*
   * toggleEdit: Toggles the editing state for a product in a specified language.
   * - Iterates over all products in ELEMENT_DATA to reset the editing state of products other than the specified one.
   * - Sets the editing property of the specified product to the provided language title, indicating it is in editing mode.
  */

  toggleEdit(product: any, langTitle: string): void {
    this.ELEMENT_DATA.forEach(p => {
      if (p !== product) {
        p.editing = null;
      }
    });
    product.editing = langTitle;
  }

  /*
   * updateDisplayedColumns: Updates the displayed columns in the table.
   * - Sets displayedColumns to an array of language titles derived from the languages array.
  */

  updateDisplayedColumns(): void {
    this.displayedColumns = this.languages.map(lang => lang.title);
  }
}
