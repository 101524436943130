<section class="stacksBlockContainer" id="block-image">
  <div id="settingsTabs" *ngIf="checkSettings()">
    <a (click)="showDataTab()" [ngClass]="checkData() ? 'active' : ''">{{'Data' | translate }}</a>
    <a (click)="showStyleTab()" [ngClass]="checkStyle() ? 'active' : ''">{{'Style' | translate }}</a>
  </div>
  <!-- Sidebar -->
  <div *ngIf="checkSidebar()" class="blockSidebar" (click)="appendBlock($event)">
    <img src="assets/images/blocks/image.png" alt="" srcset="" [ngClass]="builderService.getTour() ? 'glowingBlueElement' : ''">
    <p>{{'Image' | translate }}</p>
  </div>
  <!-- Mobile Preview -->
  <div class="previewImage previewMobile" *ngIf="checkPreview()" id="{{'blockid'+blockId}}"
    cdkDrag [cdkDragData]="blockId">
    <div class="blockControlHandlers" *ngIf="!builderService.globals">
      
       <div class="editBlock blockActionBtn" (click)="showData(blockId)">
         <mat-icon>edit</mat-icon>
       </div>
       <div class="blockHandle blockActionBtn" cdkDragHandle>
         <mat-icon [ngClass]="builderService.getTour() ? 'glowingRedElement' : ''">open_with</mat-icon>
       </div>
      <div class="deleteBlock blockActionBtn" (click)="deleteBlock(blockId)">
        <mat-icon>close</mat-icon>
      </div>
    </div>
    <div class="blockPlaceholder" *cdkDragPlaceholder></div>
    <img [ngStyle]="getBlockFields(blockId)?.style" src="{{getBlockFields(blockId).data?.src}}"  />
  </div>
  <!-- Data and Style Tabs -->
  <div class="previewFields" *ngIf="checkData()">
    <ul>
      <li>
        <label class="fileUpload" for="image">{{'Choose an Image' | translate }}
          <input type="file" id="image"
            (change)="this.processFile($event, '','logo', blockId)">
        </label>
      </li>
    </ul>
    <img src="{{getBlockFields(blockId).data?.src}}" />

    <h4 class="listTitle">{{'Linking' | translate }}</h4>
    <mat-divider></mat-divider>
    <button class="stacksBtn blue refetchData" (click)="refetchData()">
      <mat-icon class="material-icons material-icons-outlined">refresh</mat-icon>
      {{'Refetch Products & Categories' | translate}}
    </button>
    <mat-divider></mat-divider>
    <mat-progress-bar mode="indeterminate" *ngIf="builderService.showSettingsProgress"></mat-progress-bar>
      <ul>
        <li>
          <mat-form-field appearance="outline">
              <mat-label>{{'Link Type' | translate}}</mat-label>
              <mat-select (selectionChange)="queryChange('linkType', blockId, $event, 'select')"
                value="{{getBlockFields(blockId).data?.linkType}}">
                <mat-option value="home">
                  {{'Home' | translate }}
                </mat-option>
                <mat-option value="products" *ngIf="!builderService.standalone">
                  {{'Products' | translate }}
                </mat-option>
                <mat-option value="allProductsCategories">
                  {{'All Products Categories' | translate }}
                </mat-option>
                <mat-option value="products">
                  {{'Products' | translate }}
                </mat-option>
                <mat-option value="productsCategories">
                  {{'Product Category' | translate }}
                </mat-option>
                <mat-option value="posts" *ngIf="!builderService.standalone">
                  {{'Posts' | translate }}
                </mat-option>
                <mat-option value="externalURL">
                  {{'External URL' | translate }}
                </mat-option>
                <mat-option value="account">
                  {{'Account Page' | translate }}
                </mat-option>
                <mat-option value="signup">
                  {{'Signup / Signin' | translate }}
                </mat-option>
                <mat-option value="contact">
                  {{'Contact Us' | translate }}
                </mat-option>
                <mat-option value="about">
                  {{'About Us' | translate }}
                </mat-option>
                <mat-option value="orders">
                  {{'Orders' | translate }}
                </mat-option>
                <mat-option value="cart">
                  {{'Cart' | translate }}
                </mat-option>
                <mat-option value="terms">
                  {{'Terms' | translate }}
                </mat-option>
                <mat-option  *ngFor="let customView of builderService.getCustomViews()" value="{{customView.view_name}}" >
                  {{customView.view_name}}
                </mat-option>
              </mat-select>
          </mat-form-field>
        </li>
        <li *ngIf="getBlockFields(blockId).data?.linkType == 'products' && !builderService.standalone">
          <mat-form-field appearance="outline">
            <mat-label>{{'Product' | translate }}</mat-label>
            <mat-select (selectionChange)="queryChange('product', blockId, $event, 'select')" 
              [(value)]="getBlockFields(blockId).data.product" >
                <mat-option *ngFor="let product of storedProducts"
                  [value]="product.id">
                  {{product.name}} 
                </mat-option>
              </mat-select>
          </mat-form-field>
        </li>
        <li *ngIf="getBlockFields(blockId).data?.linkType == 'products' && builderService.standalone">
          <mat-form-field appearance="outline">
            <mat-label>{{'Standalone Product' | translate}}</mat-label>
            <mat-select (selectionChange)="queryChange('product', blockId, $event, 'select')" 
              [(value)]="getBlockFields(blockId).data.product" >
                <mat-option *ngFor="let product of standaloneProductsService.storedStandaloneProducts"
                  [value]="product.id">
                  {{product.name}} 
                </mat-option>
              </mat-select>
          </mat-form-field>
        </li>
        <li *ngIf="getBlockFields(blockId).data?.linkType == 'productsCategories' && !builderService.standalone">
          <mat-form-field appearance="outline">
              <mat-label>{{'Product Category' | translate }}</mat-label>
              <mat-select (selectionChange)="queryChange('categories', blockId, $event, 'select')" 
                [(value)]="getBlockFields(blockId).data.categories" >
                  <mat-option *ngFor="let productCategory of categoriesService.storedCategories"
                    [value]="productCategory.id">
                    {{productCategory.name}} 
                  </mat-option>
                </mat-select>
          </mat-form-field>
        </li>
        <li *ngIf="getBlockFields(blockId).data?.linkType == 'productsCategories' && builderService.standalone">
          <mat-form-field appearance="outline">
              <mat-label>{{'Product Category' | translate }}</mat-label>
              <mat-select (selectionChange)="queryChange('categories', blockId, $event, 'select')" 
                [(value)]="getBlockFields(blockId).data.categories" >
                  <mat-option *ngFor="let productCategory of standaloneCategoriesService.storedStandaloneCategories"
                    [value]="productCategory.id">
                    {{productCategory.name}} 
                  </mat-option>
                </mat-select>
          </mat-form-field>
        </li>
        <li *ngIf="getBlockFields(blockId).data?.linkType == 'posts'">
          <mat-form-field appearance="outline">
            <mat-label>{{'Post' | translate }}</mat-label>
            <mat-select (selectionChange)="queryChange('post', blockId, $event, 'select')" 
              [(value)]="getBlockFields(blockId).data.post" >
                <mat-option *ngFor="let post of storedPosts"
                  [value]="post.ID">
                  {{post.post_title}} 
                </mat-option>
              </mat-select>
          </mat-form-field>
        </li>
        <li *ngIf="getBlockFields(blockId).data?.linkType == 'externalURL'">
          <mat-form-field appearance="outline">
              <mat-label>{{'External URL' | translate }}</mat-label>
              <input matInput value="{{getBlockFields(blockId).data?.externalURL}}"
                    (keyup)="queryChange('externalURL', blockId, $event, 'text')" />
          </mat-form-field>
        </li>
      </ul>
  </div>
  <div class="styleFields" *ngIf="checkStyle()">
    <h4 class="listTitle">{{'Margins' | translate }}</h4>
    <mat-divider></mat-divider>
    <ul>
      <li>
        <mat-label>{{'Margin Top' | translate}}</mat-label>
        <mat-slider showTickMarks discrete  max="100" min="-100" step="1" thumbLabel="true">
          <input matSliderThumb ngModel="{{getBlockFields(blockId).style['margin-top']?.slice(0, -2)}}"
          (change)="styleChange('margin-top',
          blockId,
          $event, 'slider')">
        </mat-slider>
      </li>
      <li>
        <mat-label>{{'Margin Right' | translate}}</mat-label>
        <mat-slider showTickMarks discrete max="100" min="-100" step="1" thumbLabel="true">
          <input matSliderThumb ngModel="{{getBlockFields(blockId).style['margin-right']?.slice(0, -2)}}" (change)="styleChange('margin-right',
          blockId,
          $event, 'slider')">
        </mat-slider>
      </li>
      <li>
        <mat-label>{{'Margin Bottom' | translate}}</mat-label>
        <mat-slider showTickMarks discrete  max="100" min="-100" step="1" thumbLabel="true"
          >
          <input matSliderThumb ngModel="{{getBlockFields(blockId).style['margin-bottom']?.slice(0, -2)}}" (change)="styleChange('margin-bottom',
          blockId,
          $event, 'slider')">
        </mat-slider>
      </li>
      <li>
        <mat-label>{{'Margin Left' | translate}}</mat-label>
        <mat-slider showTickMarks discrete max="100" min="-100" step="1" thumbLabel="true"
          >
          <input matSliderThumb ngModel="{{getBlockFields(blockId).style['margin-left']?.slice(0, -2)}}" (change)="styleChange('margin-left',
          blockId,
          $event, 'slider')">
        </mat-slider>
      </li>
    </ul>
    <mat-label>{{'Paddings' | translate }}</mat-label>

    <mat-divider></mat-divider>
    <ul>
      <li>
        <mat-label>{{'Padding Top' | translate}}</mat-label>
        <mat-slider showTickMarks discrete max="100" min="1" step="1" thumbLabel="true"
          >
          <input matSliderThumb ngModel="{{getBlockFields(blockId).style['padding-top']?.slice(0, -2)}}" (change)="styleChange('padding-top',
          blockId,
          $event, 'slider')">
        </mat-slider>
      </li>
      <li>
        <mat-label>{{'Padding Right' | translate}}</mat-label>
        <mat-slider showTickMarks discrete max="100" min="1" step="1" thumbLabel="true"
          >
          <input matSliderThumb ngModel="{{getBlockFields(blockId).style['padding-right']?.slice(0, -2)}}"
          (change)="styleChange('padding-right',
          blockId,
          $event, 'slider')">
        </mat-slider>
      </li>
      <li>
        <mat-label>{{'Padding Bottom' | translate}}</mat-label>
        <mat-slider showTickMarks discrete max="100" min="1" step="1" thumbLabel="true"
          >
          <input matSliderThumb ngModel="{{getBlockFields(blockId).style['padding-bottom']?.slice(0, -2)}}"
          (change)="styleChange('padding-bottom',
          blockId,
          $event, 'slider')">
        </mat-slider>
      </li>
      <li>
        <mat-label>{{'Padding Left' | translate}}</mat-label>
        <mat-slider showTickMarks discrete max="100" min="1" step="1" thumbLabel="true"
          >
          <input matSliderThumb ngModel="{{getBlockFields(blockId).style['padding-left']?.slice(0, -2)}}" (change)="styleChange('padding-left',
          blockId,
          $event, 'slider')">
        </mat-slider>
      </li>
    </ul>
  </div>
</section>

