import { Component, OnInit } from '@angular/core';
import { BlockDataService } from 'src/app/block-services/block-data.service';
import { BuilderService } from 'src/app/builder-services/builder.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from 'src/app/login/login.service';
import { BlockFieldsService } from 'src/app/block-services/block-fields.service';
import { CategoriesService } from 'src/app/blocks-wrapper/block-categories/categories.service';

@Component({
  selector: 'projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {

  projectsLimit: any = 0;
  premiumCount: any = 0;
  mobile = false;
  user_email = '';
  trialsCount = 0;
  projectsLength = 0;
  multiProjects = false;
  projectUrl = '';
  checked = true; // Hide Inactive Projects
  showAllProjects = false;
  showActiveSubscriptions = false;
  renderProjectsLimit = 10;
  showSearchResults = false;
  projectResult:any;
  constructor(
    public builderService: BuilderService,
    public blockDataService: BlockDataService,
    private router: Router,
    public loginService: LoginService,
    private blockFieldsService: BlockFieldsService,
    private categoriesService: CategoriesService) { 
      this.user_email = this.loginService.getCurrentUser().email;
  }

  ngOnInit(): void {
    if (window.screen.width <= 768) { // 768px portrait
      this.mobile = true;
    }

    if(!this.loginService.getCurrentUser().admin) {
      this.showActiveSubscriptions = true;
      this.showAllProjects = true;
      this.checked = false;
    }
  }

  /**
   * Creates new project
   */
   createProject = () => {
    this.builderService.showUpdateProgress = true;
    this.builderService.createProject().subscribe((response) => {
      this.builderService.showUpdateProgress = false;
      this.resetTour();
      this.getAllProjects('latest');
    },
    (err) => {
      console.log(err);
    });
  }

  /**
   * Get all projects and sets the localstorage
   */
   getAllProjects = (orderItem:string = '') => {
    this.builderService.getProjects(this.user_email).subscribe(
      (allProjects) => {
        this.trialsCount = 0;
        this.premiumCount = 0;
        let projectsIds = [];
        // if(allProjects[0] == null) {
        //   alert("you don't have active subscriptions, please go to the main website to renew your subscription")
        // }
        this.builderService.projects = [];
        for (const key in allProjects) {
          if (Object.prototype.hasOwnProperty.call(allProjects, key)) {
            const element = allProjects[key];
            if(element.type == '' ) {
              element.type = 'purchase';
            }
            this.builderService.projects.push(
              { 'id': element.id, 
              "status": element.status, 
              "type": element.type,
              "domain": element.domain,
              "email": element.email,
              "first_name": element.first_name,
              "last_name": element.last_name,
              "premium_status": element.premium_status,
              "premium_package": element.premium_package,
             });
              // this.categoriesIds.push(element.term_id);
              projectsIds.push(element.id);
              if( element.status == 'active' ) {
                if( element.type == 'trial' ) {
                  this.trialsCount++;
                } else {
                  this.premiumCount++;
                }
              }

          }
          this.builderService.projectsPremiumCount = this.premiumCount;
        }
        this.projectsLength = allProjects.length;
        if (allProjects.length > 1) {
          this.multiProjects = true;
        }
        /**
         * If there is a selected project in the localstorage then retrieve
         */
        let projectId;
        // if (!projectsIds.includes(String(localStorage.getItem("selectedProject")))) {
          if( orderItem == 'latest' ) {
            for (let index = 0; index < allProjects.length; index++) {
              const element = allProjects[index];
              if(element.status == 'active') {
                projectId = element.id;
                localStorage.setItem("selectedProject", projectId);
              }
            }
          } else {
            projectId = String(localStorage.getItem("selectedProject"));
          }
          
        // } else {
          // projectId = String(localStorage.getItem("selectedProject"));
        // }
        this.blockDataService.sendProjectData(projectId);
        this.getProject(projectId);
      },
      (err) => {
      }
    );
  }

  /**
   * Send getProject Request after selection
   * @param projectId 
   */
   getProject = ( projectId: any, type: string = 'default') => {
    this.builderService.showUpdateProgress = true;
    this.builderService.selectedProject = projectId;
    localStorage.setItem("selectedProject", projectId);
    localStorage.removeItem("categories");
    this.builderService.getProject(projectId).subscribe((project: any) => {
      // Cache the response
      this.builderService.user_type = project.user_type;
      this.builderService.cacheProjectReq.response = project;
      // Update the Builder View
      if( this.blockDataService.blocksData == null ) {
        this.blockDataService.blocksData = [];
        let headerData = this.blockFieldsService.getHeaderBlockFields();
        headerData.id = this.blockDataService.getUniqueId(2);
        this.blockDataService.blocksData.unshift(headerData);
      }
      this.blockDataService.sendRenderAllBlocks();

      this.builderService.is_premium = false;
      if(project.premium.length) {
        this.builderService.is_premium = true;
        for (let index = 0; index < project.premium.length; index++) {
          const element = project.premium[index];
          this.builderService.premium_status = element.status;
          this.builderService.stripe_subscription_id = element.stripe_subscription_id;
          this.blockDataService.sendStripeData(element.stripe_subscription_id);
          this.builderService.premium_start_date = element.start_date;
          if(element.status == 'active') {
            break;
          }
        }
      }

      // Update the Settings
      this.builderService.selectedProjectDomain = project.details[0].domain;
      this.projectUrl = this.builderService.selectedProjectDomain;
      this.categoriesService.getInitialCategories();
      if(type == 'clicked') {
        this.blockDataService.blocksData = JSON.parse(project["data"]);
        // window.location.href = "/";
        this.router.navigate(['/']);
      }
      this.builderService.showUpdateProgress = false;
    },
    (err) => {
      console.log(err);
    });
  }

   /**
   * Reset the Tour and navigate to the first step of the tour
   */

    resetTour = () => {
      this.builderService.setTour(true).subscribe((response) => {
        this.router.navigate(['/site-type']);
        // this.router.navigate(['/templates']);
      }, (err) => {
  
      })
    }
    
    deleteProject = (id: number) => {
      this.builderService.showUpdateProgress = true;
      this.builderService.deleteProject(id).subscribe((response) => {
        for (let index = 0; index < this.builderService.projects.length; index++) {
          const element = this.builderService.projects[index];
          if( parseInt( element.id ) == id ) {
            element.status = 'deleted';
          }
        }
        // this.builderService.showUpdateProgress = false;
        this.getAllProjects();
      }, (error) => {

      })
    }

    activateProject = (id: number) => {
      this.builderService.showUpdateProgress = true;
      this.builderService.activateProject(id).subscribe((response) => {
        for (let index = 0; index < this.builderService.projects.length; index++) {
          const element = this.builderService.projects[index];
          if( parseInt( element.id ) == id ) {
            element.status = 'active';
          }
        }
        // this.builderService.showUpdateProgress = false;
        this.getAllProjects();
      }, (error) => {

      })
    }

    showAllProjectsFn = (event:any) => {
      if( this.showAllProjects ) {
        this.renderProjectsLimit = this.builderService.projects.length;
      } else {
        this.renderProjectsLimit = 10;
      }
    }

    showActiveSubscriptionsFn = () => {
      if( this.showActiveSubscriptions ) {
        this.showSearchResults = true;
        this.renderProjectsLimit = 0;
        this.projectResult = this.builderService.projects.find((item: { premium_status: any; }) => item.premium_status == 'active');
      } else {
        this.showSearchResults = false;
        if( this.showAllProjects ) {
          this.renderProjectsLimit = this.builderService.projects.length;
        } else {
          this.renderProjectsLimit = 10;
        }
        // this.projectResult = this.builderService.projects.find((item: { premium_status: any; }) => {item.premium_status == 'active' || item.premium_status !== 'active'});
      }
    }

    applyFilter(event: Event) {
      const filterValue = parseInt( (event.target as HTMLInputElement).value );
      if(filterValue) {
        this.showSearchResults = true;
        this.renderProjectsLimit = 0;
      } else {
        this.showSearchResults = false;
        if( this.showAllProjects ) {
          this.renderProjectsLimit = this.builderService.projects.length;
        } else {
          this.renderProjectsLimit = 10;
        }
      }
      this.projectResult = this.builderService.projects.find((item: { id: number; }) => item.id == filterValue);

    }

}
