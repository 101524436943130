<div class="secondarySidebar">
    <div class="secondarySBTitle">
        <h2>Admin Analytics</h2>
    </div>
    <div class="secondarySBContent">
        <ul>
            <li class="selected" [routerLink]="['/admin/self']">Self</li>
            <li [routerLink]="['/admin/cloud']">Cloud</li>
            <li [routerLink]="['/admin/logs']">Logs</li>
            <li [routerLink]="['/admin/apps-uploaded']">
                Apps Uploaded
            </li>
        </ul>
    </div>
</div>
<div id="selfWrapper" class="containerWrapper withSidebar noBG">
    <!-- <mat-progress-bar mode="indeterminate" *ngIf="PNProgress"></mat-progress-bar> -->
    <div id="titleContainer" class="innerContainerTitle">
      <h1>Stacks Self Sales</h1>
    </div>
    <div id="selfContainer" class="innerContainerContent">
        <div class="gridWrapper">
            <div class="fullGrid">
                <self-trials></self-trials>
            </div>
            <div class="fullGrid">
                <self-sales></self-sales>
            </div>
            <div class="fullGrid">
                <self-trials-conversion></self-trials-conversion>
            </div>
        </div>
    </div>
</div>