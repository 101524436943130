import { AppSettingsService } from 'src/app/settings/services/app-settings.service';
import { BuilderService } from 'src/app/builder-services/builder.service';
import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/builder-services/database.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from 'src/app/e-commerce/settings/settings.service';

@Component({
  selector: 'app-social-login',
  templateUrl: './social-login.component.html',
  styleUrls: ['./social-login.component.scss'],
})
export class SocialLoginComponent implements OnInit {
  auth0_clientId: string = '';
  auth0_domain: string = '';
  auth0_redirect_uri: string = '';
  error: string | null = null;
  generalSettingsData : any = {};
  social!: FormGroup;

  settingsForm = this.fb.group({
    application_name: '',
    package_name: '',
    ipa_sku_number: '',
    application_description: '',
    icon: '',
    splash_screen: '',
    release_app: '',
  });

  constructor(
    private appSettingsService: AppSettingsService,
    private builderService: BuilderService,
    private db: DatabaseService,
    private fb: FormBuilder,
    private http: HttpClient,
    private settingsService: SettingsService
  ) {}

  ngOnInit() {
    this.social = this.fb.group({});
    this.getApplicationSettingsData();
    this.getInitialSettings();
  }

  saveToApplicationSettings() {
    const projectId = this.builderService.selectedProject;

    this.appSettingsService.getApplicationSettings(projectId).subscribe(
      (currentSettings: any) => {
        const updatedSettings = {
          ...currentSettings,
          auth0_domain: this.auth0_domain,
          auth0_redirect_uri: this.auth0_redirect_uri,
          auth0_clientId: this.auth0_clientId,
        };

        this.appSettingsService
          .setApplicationSettings(projectId, updatedSettings)
          .subscribe(
            () => {
              this.appSettingsService
                .setWPApplicationSettings(
                  this.builderService.selectedProject,
                  updatedSettings
                )
                .subscribe(() => {
                  console.log(
                    'Social login data saved successfully',
                    updatedSettings
                  );
                  this.getInitialSettings();
                });
            },
            (error: any) => {
              console.error('Error updating social login data', error);
            }
          );
      },
      (error: any) => {
        console.error('Error saving social login data', error);
      }
    );
  }

  getApplicationSettingsData() {
    const projectId = this.builderService.selectedProject;
    this.appSettingsService.getApplicationSettings(projectId).subscribe(
      (data: any) => {
        console.log(data);
        if (data) {
          this.auth0_domain = data.auth0_domain || 'dev-3t3cfn38mfbo482t.us.auth0.com';
          this.auth0_redirect_uri = data.auth0_redirect_uri || 'com.stacks.www://dev-3t3cfn38mfbo482t.us.auth0.com/capacitor/com.stacks.www/callback';
          this.auth0_clientId = data.auth0_clientId || 'C7s44AeANQEyNkwIGk1aC935tfUPvkCT';
        } else {
          console.error('No data found');
        }
      },
      (error: any) => {
        console.error('Error loading data', error);
      }
    );
  }

  getInitialSettings = () => {
    let project_id = this.builderService.selectedProject;
    this.appSettingsService.getGeneralSettings(project_id).subscribe(
      (settings: any) => {
        this.generalSettingsData = settings !== null ? settings : {};
        this.builderService.showUpdateProgress = false;
        console.log(this.generalSettingsData.package_name);

        const redirectUriParts = this.auth0_redirect_uri.split('://');
        const firstPart = redirectUriParts[0];
        if (this.generalSettingsData.package_name !== firstPart) {
          this.error = 'The first part does not match Package name: ' + firstPart;
        } else {
          this.error = null;
        }
        const lastPart = this.auth0_redirect_uri.split('capacitor/')[1]?.split('/')[0]; // Extract the last part
        if (this.generalSettingsData.package_name !== lastPart) {
          this.error = 'The last part does not match Package name: ' + lastPart;
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
