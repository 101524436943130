import { Injectable } from '@angular/core';
import { getDatabase, ref, set, child, get, remove } from 'firebase/database';
import { Observable } from 'rxjs';
import { BuilderService } from 'src/app/builder-services/builder.service';
import { DatabaseService } from 'src/app/builder-services/database.service';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  constructor(private builderService: BuilderService,private db:DatabaseService) { }

  getOrder = (order_id: string) => {
    let project_id = this.builderService.selectedProject;
    const dbRef = ref(getDatabase());
    return new Observable<any>((observer) => {
      get(child(dbRef, 'projects/'+ project_id + '/users/')).then((snapshot) => {
        if (snapshot.exists()) {

          snapshot.forEach((userSnapshot) => {
            const userKey = userSnapshot.key;
            const orders = userSnapshot.child('orders');
            orders.forEach((orderSnapshot) => {
              if (orderSnapshot.key === order_id) {
                const order = orderSnapshot.val();
                let order_data = [{
                  'user_id' : userKey,
                  'address': Object.values(userSnapshot.val().address),
                  'user_data': userSnapshot.val().data,
                  'order_id' : order_id,
                  'order' : order,

                }]
                observer.next(order_data); // Notify the observer that the operation is complete
                observer.complete(); // Complete the Observable
              }
            });
          });
        } else {
          console.log("No data available");
          observer.next({});
          observer.complete(); // Complete the Observable

        }
      }).catch((error) => {
        observer.error(error); // Notify the observer of an error
      });
    });
  }

  getOrders = () => {
    let project_id = this.builderService.selectedProject;
    const dbRef = ref(getDatabase());
    let allOrders:any = [];
    return new Observable<any>((observer) => {
      this.db.getDatabase(`projects/${project_id}/users/`).subscribe((usersData) => {
        if (!usersData) {
          observer.next([]);
          observer.complete();
          return;
        }
        
        const userEmails: { [userId: string]: string } = {};
        Object.keys(usersData).forEach((userId) => {
          const user = usersData[userId];
          userEmails[userId] = user.email || (user.address?.billing_email || '');
        });
  
      get(child(dbRef, 'projects/'+ project_id + '/users/')).then((snapshot) => {
        if (snapshot.exists()) {
          snapshot.forEach((userSnapshot) => {
            const userId = userSnapshot.key as string;
            const orders = userSnapshot.child('orders');
            orders.forEach((orderSnapshot) => {
              const order = orderSnapshot.val();
              if(order.date)
              {
                allOrders.push({
                user: userId,
                user_data: userSnapshot.val().data,
                user_email: userEmails[userId],
                order_id: orderSnapshot.key,
                ...order,
              });
              }
            });
          });
          observer.next(this.sortOrdersByDate(allOrders));
          observer.complete(); // Complete the Observable}
        } else {
          console.log("No data available");
          observer.next([]);
          observer.complete(); // Complete the Observable
        }
      }).catch((error) => {
        observer.error(error); // Notify the observer of an error
      });
      });
    });
  }

  sortOrdersByDate = (allOrders: any) => {
    // Sort the orders by date (assuming the date field is a string)
    const sortedOrders = allOrders.sort((a:any, b:any) => {
      // Convert the date strings to actual Date objects for comparison
      const dateA:any = this.parseDate(a.date);
      const dateB:any = this.parseDate(b.date);
      return dateA - dateB;
    });
    return sortedOrders;
  }

  parseDate = (dateString:string) => {
    const [day, month, year] = dateString.split('-');
    return new Date(`${year}-${month}-${day}`);
  }

  removeOrder = (order_id: string, user_id: string) => {
    let project_id = this.builderService.selectedProject;
    const db = getDatabase();
    return new Observable<any>((observer) => {
      remove(ref(db, `projects/${project_id}/users/${user_id}/orders/${order_id}`))
      .then(() => {
        // Data remove successfully!
        observer.next({});
        observer.complete(); // Complete the Observable
      })
      .catch((error) => {
        // The write failed...
        observer.next({});
        observer.complete(); // Complete the Observable
      });
    });
  }

  updateOrderStatus = (order_id: string, status: string, user_id: string) => {
    let project_id = this.builderService.selectedProject;
    const db = getDatabase();
    return new Observable<any>((observer) => {
      set(ref(db, `projects/${project_id}/users/${user_id}/orders/${order_id}/status`), status)
      .then(() => {
        // Data saved successfully!
        observer.next({});
        observer.complete(); // Complete the Observable
      })
      .catch((error) => {
        // The write failed...
        observer.next({});
        observer.complete(); // Complete the Observable
      });
    });
  }
  
}
