<div id="loginLogo">
    <img src="assets/images/stacks.png" />
</div>
<div id="middleContainerWrapper">
    <div class="header">
        <button class="langSwitcher" mat-button [matMenuTriggerFor]="menu">
          <!-- Display flag or language code based on selected language -->
          <span *ngIf="languageService.getLanguage() === 'en'">English</span>
          <span *ngIf="languageService.getLanguage() === 'ar'">العربية</span>
        </button>
        <mat-menu #menu="matMenu">
          <!-- Language options -->
          <button mat-menu-item (click)="changeLang('en')">English</button>
          <button mat-menu-item (click)="changeLang('ar')">العربية</button>
        </mat-menu>
    </div>
    <mat-progress-bar mode="indeterminate" *ngIf="loginProgress"></mat-progress-bar>
    <form id="loginContainer" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <h1>{{'Welcome Back' | translate }}</h1>
        <h2 *ngIf="paymentIncluded">{{'Login to Claim offer' | translate}}</h2>
        <asl-google-signin-button shape="rectangular" size="large" type="standard"></asl-google-signin-button>
        <!-- <div class="socialLogin" (click)="signInWithGoogle()">
            <img src="assets/images/google_login.png" />
            <p>Sign in with google</p>
        </div> -->
        <ul>
            <li>
              <mat-error *ngIf="loginError">
                {{loginErrorMsg}}
              </mat-error>
            </li>
            <li>
                <mat-form-field appearance="outline">
                    <mat-label>{{'Email' | translate }}</mat-label>
                    <input matInput placeholder="" formControlName="username" type="text">
                </mat-form-field>
            </li>
            <li>
                <mat-form-field appearance="outline">
                    <mat-label>{{'Password' | translate }}</mat-label>
                    <input matInput type="password" formControlName="password">
                </mat-form-field>
            </li>
        </ul>
        <button class="submitLogin" type="submit">
          {{'Login' | translate }}
        </button>
        <a class="altAccess" [routerLink]="['/signup']" *ngIf="preservedReturnURL" [queryParams]="{ returnUrl: preservedReturnURL }">{{'Dont have account' | translate }} <span>{{'Signup' | translate }}</span></a>
        <a class="altAccess" [routerLink]="['/signup']" *ngIf="!preservedReturnURL">{{'Dont have account' | translate }} <span>{{'Signup' | translate }}</span></a>
        <a class="forgotPass" target="_blank" href="https://builder.stacksmarket.co/wp-login.php?action=lostpassword">{{'Forgot Password' | translate }}</a>
    </form>
</div>
