import { BuilderService } from 'src/app/builder-services/builder.service';
import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/builder-services/database.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MatTableDataSource } from '@angular/material/table';
import { SettingsService } from 'src/app/e-commerce/settings/settings.service';
import { forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { MultilingualPipe } from '../multilingual.pipe';

interface TableRow {
  [key: string]: any;
  Currency?: string;
  En: string;
}

@Component({
  selector: 'app-txt-translations',
  templateUrl: './txt-translations.component.html',
  styleUrl: './txt-translations.component.scss'
})
export class TxtTranslationsComponent implements OnInit {
  currency: any;
  currentPageIndex: number = 0;
  displayedColumns: string[] = [];
  editingCell: { row: TableRow | null, lang: string | null } = { row: null, lang: null };
  filteredData: TableRow[] = [];
  languages: { title: string }[] = [];
  pagedTableData: MatTableDataSource<TableRow> = new MatTableDataSource<TableRow>([]);
  pageSize: number = 10;
  pageSizeOptions: number[] = [5, 10, 25, 50, 100];
  searchText: string = '';
  stacksTranslation = false;
  tableData: TableRow[] = [];
  txtTranslations!: FormGroup;
  productProgress: boolean = false;

  constructor(
    private BuilderService: BuilderService,
    private db: DatabaseService,
    private http: HttpClient,
    private translatePipe: MultilingualPipe,
    private settingsService: SettingsService,
    private fb: FormBuilder
  ) { }

  /*
   * ngOnInit: Initializes the component.
   * - Sets up the form group for translations.
   * - Initializes an array for languages.
   * - Loads available languages and retrieves currency data.
   */

  ngOnInit(): void {
    this.txtTranslations = this.fb.group({
      languages: this.fb.array([])
    });
    this.loadLanguages();
    this.getCurrency();
  }

  /*
 * updatePageData: Updates the displayed data in the paginated table.
 * - Takes a pageIndex as a parameter to determine which data to display.
 * - Calculates start and end indices based on the current page index and page size.
 * - Slices the filtered data to set the data for the paginated table.
 */

  updatePageData(pageIndex: number): void {
    const startIndex = pageIndex * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.pagedTableData.data = this.filteredData.slice(startIndex, endIndex);
  }

  /*
 * onPageChange: Handles the page change event for the paginated table.
 * - Updates the page size and current page index based on the event.
 * - Calls updatePageData to refresh the displayed data for the current page.
 */

  onPageChange(event: any) {
    this.pageSize = event.pageSize;
    this.currentPageIndex = event.pageIndex;
    this.updatePageData(this.currentPageIndex);
  }
  /*
   * getTranslate: Fetches translations for the selected project and aggregates
   * the data from multiple languages.
   * - Retrieves the selected project ID from the BuilderService.
   * - Checks if any languages are available; logs a message and exits if none.
   * - Initializes a counter and an object to hold aggregated translation data.
   * - Iterates over each language to construct the unique database path for translations.
   * - Subscribes to the database observable to fetch translations for each language.
   * - Aggregates translations into the allData object, using the 'En' key as a reference.
   * - When all language data is loaded, converts the aggregated data to an array,
   *   adds a currency row, filters the data, and updates the displayed page data.
   * - Logs an error if the fetched data is not in the expected format or if the fetch fails.
   */

  getTranslate(): void {
    const project_id = this.BuilderService.selectedProject;
    if (!this.languages.length) {
      console.log('No languages available.');
      return;
    }

    let dataLoadedCount = 0;
    const allData: { [key: string]: any } = {};

    this.languages.forEach(lang => {
      const uniquePath = `/projects/${project_id}/translations/languages/${lang.title}/texts`;
      this.db.getDatabase(uniquePath).subscribe({
        next: (translationsData: any) => {
          if (translationsData && typeof translationsData === 'object') {
            // Aggregate data from each language
            for (const key in translationsData) {
              if (translationsData.hasOwnProperty(key)) {
                if (!allData[key]) {
                  allData[key] = { En: key };  // Assume 'En' key is your reference
                }
                allData[key][lang.title] = translationsData[key].value;
              }
            }
            // Check if all languages are loaded
            dataLoadedCount++;
            if (dataLoadedCount === this.languages.length) {
              this.tableData = Object.values(allData); // Convert aggregated data to array
              this.addCurrencyRow();
              this.filterData();
              this.updatePageData(this.currentPageIndex);
            }
          } else {
            console.error(`Translations data for ${lang.title} is not in the expected format:`, translationsData);
          }
        },
        error: (error) => console.error(`Failed to fetch translations for ${lang.title}:`, error)
      });
    });
  }

  /*
 * handleMissingTranslations: Processes translation requests for missing language translations in the data.
 * - Iterates over each entry in the allData object and identifies any missing translations for the defined languages.
 * - For each missing translation, it calls a translation pipe to fetch the appropriate translation.
 * - Uses `forkJoin` to combine multiple observable translation requests for each entry, ensuring that all translations
 *   are retrieved before proceeding.
 * - Once all translation observables are completed, it updates the tableData with the translated rows.
 * - Calls addCurrencyRow() to incorporate currency data, applies any active filters with filterData(),
 *   and refreshes the displayed data for the current page index.
 */

  handleMissingTranslations(allData: { [key: string]: any }): void {
    const translationRequests = Object.keys(allData).map(key => {
      const row = allData[key];
      const missingLanguages = this.languages.map(lang => {
        if (!row[lang.title]) {
          return this.translatePipe.transform(row.En, lang.title); // Call the pipe to get translations
        } else {
          return of(row[lang.title]); // If already exists, use existing translation
        }
      });
  
      return forkJoin(missingLanguages).pipe(
        map(translations => {
          this.languages.forEach((lang, index) => {
            row[lang.title] = translations[index]; // Assign translations back to row
          });
          return row;
        })
      );
    });
  
    // Wait for all translation observables to complete
    forkJoin(translationRequests).subscribe(translatedRows => {
      this.tableData = translatedRows;
      this.addCurrencyRow();
      this.filterData();
      this.updatePageData(this.currentPageIndex);
    });
  }

  /*
 * callStacksTranslation: Initiates the translation process by setting a loading indicator.
 * - Sets the stacksTranslation flag to true to indicate that translation is in progress.
 * - Calls the loadData() method to fetch the necessary translations.
 */
  callStacksTranslation() {
    this.stacksTranslation = true;
    this.loadData();
  }

  /*
 * loadData: Loads translation data from a JSON file and updates the tableData structure.
 * - Fetches data from the 'txtTranslation.json' file located in the assets/i18n directory.
 * - Maps the fetched string data into a new array of TableRow objects, each containing an 'En' key.
 * - Updates the tableData by checking for existing entries:
 *   - If an existing entry is found, it preserves that row's data.
 *   - If no existing entry is found, it adds the new row.
 * - Updates the tableData with the combined data, adds a currency row, filters the data,
 *   and refreshes the displayed data for the current page index.
 * - Logs an error if fetching the data fails.
 */

  loadData() {
    this.http.get<string[]>('assets/i18n/txtTranslation.json').subscribe(
      (data: string[]) => {
        // Map the fetched data to your tableData structure, only updating non-existing entries
        const newData: TableRow[] = data.map(text => ({ En: text }));
        const updatedData = newData.map(newRow => {
          const existingRow = this.tableData.find(row => row.En === newRow.En);
          return existingRow ? existingRow : newRow; // Preserve existing row data if it exists
        });

        this.tableData = updatedData;
        this.addCurrencyRow();
        this.filterData();
        this.updatePageData(this.currentPageIndex);
        if (this.stacksTranslation) {
          this.handleMissingTranslations(updatedData);
        }
      },
      (error) => {
        console.error('Error fetching data:', error);
      }
    );
  }

  /*
 * toggleEdit: Toggles the edit mode for a specific cell in the table.
 * - Accepts a row of type TableRow and a language string as parameters.
 * - If the specified row and language are already being edited, it resets the editingCell to null.
 * - Otherwise, it sets the editingCell to the provided row and language, enabling editing for that cell.
 */

  toggleEdit(row: TableRow, lang: string): void {
    if (this.editingCell.row === row && this.editingCell.lang === lang) {
      this.editingCell = { row: null, lang: null };
    } else {
      this.editingCell = { row, lang };
    }
  }

  /*
 * filterData: Filters the table data based on the user's search input.
 * - Checks if searchText is provided; if so, it filters tableData to find rows
 *   where the 'En' field, 'Currency' field, or any language-specific field contains
 *   the search text (case-insensitive).
 * - If no search text is provided, it copies all tableData to filteredData.
 * - Resets the displayed data to the first page after filtering.
 */

  filterData(): void {
    if (this.searchText) {
      this.filteredData = this.tableData.filter(row =>
        row.En.toLowerCase().includes(this.searchText.toLowerCase()) ||
        (row.Currency && row.Currency.toLowerCase().includes(this.searchText.toLowerCase())) ||
        this.languages.some(lang => row[lang.title]?.toLowerCase().includes(this.searchText.toLowerCase()))
      );
    } else {
      this.filteredData = [...this.tableData]; // Copy all data if no search text
    }
    this.updatePageData(0); // Reset to the first page after filtering
  }

  /*
 * loadLanguages: Loads available languages for the selected project from the database.
 * - Constructs a unique database path using the selected project ID.
 * - Fetches language data and logs it to the console.
 * - Checks if the fetched data is an object; if so, transforms it into an array of language objects,
 *   each containing a title and its corresponding translation.
 * - Searches for the index of the "English" language and, if found and not already first,
 *   moves it to the beginning of the languages array.
 * - Updates the displayedColumns to reflect the available languages and calls getTranslate() to
 *   load the translations.
 * - Logs an error if the data format is unexpected or if the fetch operation fails.
 */

  loadLanguages(): void {
    let project_id = this.BuilderService.selectedProject;
    const uniquePath = `/projects/${project_id}/translations/languages`;

    this.db.getDatabase(uniquePath).subscribe(
      (languagesData: any) => {
        console.log('Languages Data:', languagesData);

        if (languagesData && typeof languagesData === 'object') {
          // Transform languagesData object into an array of language objects
          this.languages = Object.keys(languagesData).map(key => ({
            title: key,
            translation: languagesData[key]
          }));

          // Find the index of "English" language
          const englishIndex = this.languages.findIndex(lang => lang.title === 'English');

          // If "English" is found and it's not already the first element
          if (englishIndex !== -1 && englishIndex !== 0) {
            // Move "English" to the beginning of the array
            const englishLanguage = this.languages.splice(englishIndex, 1)[0];
            this.languages.unshift(englishLanguage);
          }
          this.displayedColumns = [...this.languages.map(lang => lang.title)];
          this.getTranslate();
        } else {
          console.error('Languages data is not in the expected format:', languagesData);
        }
      },
      (error) => {
        console.error('Error fetching languages data:', error);
      }
    );
  }

  /*
 * saveChanges: Saves updated translations for all languages associated with the selected project.
 * - Sets a loading indicator to indicate that the save operation is in progress.
 * - Defines an interface for translations to ensure proper typing.
 * - Iterates over each row in tableData, initializing language-specific fields if they are undefined.
 * - For each language, creates a translationsObject where each key is a valid English term and
 *   its value is an object containing the translation.
 * - Special handling is applied for the English language, where the value is set to the term itself.
 * - Constructs a unique database path for each language's translations and saves the translationsObject
 *   to the database.
 * - Logs success messages upon saving or error messages if the save operation fails,
 *   and updates the loading indicator accordingly.
 */


  saveChanges(): void {
    this.productProgress = true;
    let project_id = this.BuilderService.selectedProject;
    interface Translations {
      [key: string]: { value: string };
    }
    // Iterate over each row in tableData and update local values if needed
    this.tableData.forEach(row => {
      this.languages.forEach(lang => {
        row[lang.title] = row[lang.title] || '';
      });
    });

    this.languages.forEach(lang => {
      let translationsObject: Translations = {}; // Define the type of translationsObject

      // Create an object where key is the English term and value is the nested translation
      this.tableData.forEach(row => {
        const key = this.generateValidKey(row.En);

        // Set the translation using the valid key
        if (lang.title === 'English') {
          // If the language is English, set the value to be equal to the key
          translationsObject[key] = { value: key.replace(/-/g, ' ') };
        } else {
          // Otherwise, set the value normally
          translationsObject[key] = { value: row[lang.title] };
        }
      });

      const uniquePath = `/projects/${project_id}/translations/languages/${lang.title}/texts`;

      this.db.setDatabase(uniquePath, translationsObject).subscribe({
        next: (response) => {
          console.log(`Translations for ${lang.title} saved:`, response)
          this.productProgress = false;
        },
        error: (error) => {
          this.productProgress = false;
          console.error(`Failed to save translations for ${lang.title}:`, error)
        }
      });
    });
  }
  /*
   * generateValidKey: Generates a valid key from the input string by replacing
   * specific characters (such as #, $, /, [, ], and spaces) with spaces.
   * - Returns the sanitized string, which can be used as a valid key in translations.
   */
  generateValidKey(input: string): string {
    return input.replace(/[#$\/\[\] ]/g, ' ');
  }

  /*
 * getCurrency: Fetches the currency settings for the application.
 * - Subscribes to the settingsService to retrieve currency data.
 * - On successful retrieval, assigns the currency data to the currency property
 *   and calls addCurrencyRow() to incorporate the currency into the table.
 * - Logs an error message if fetching the currency data fails.
 */

  getCurrency() {
    this.settingsService.getCurrencySettings().subscribe(
      data => {
        this.currency = data;
        this.addCurrencyRow();
      },
      error => {
        console.error('Error fetching currency data:', error);
      }
    );
  }

  /*
 * addCurrencyRow: Adds a new row for the currency to the table data.
 * - Checks if currency data is available; if so, creates a new currencyRow
 *   object with the currency symbol.
 * - For each language, constructs a unique database path to fetch the currency
 *   translation.
 * - Subscribes to the database to retrieve the currency translation for each language,
 *   adding it to the currencyRow.
 * - If the currencyRow does not already exist in the tableData, it adds it.
 * - Calls filterData() to apply any active search filters after updating the table.
 * - Logs an error if fetching the currency translation fails for any language.
 */
  addCurrencyRow() {
    if (this.currency) {
      const currencySymbol = this.currency;
      const currencyRow: TableRow = { En: currencySymbol };

      // Add the currency translations for each language
      this.languages.forEach(lang => {
        const uniquePath = `/projects/${this.BuilderService.selectedProject}/translations/languages/${lang.title}/texts/${currencySymbol}`;
        this.db.getDatabase(uniquePath).subscribe({
          next: (currencyTranslation: any) => {
            currencyRow[lang.title] = currencyTranslation.value;
            if (!this.tableData.some(row => row.En === currencyRow.En)) {
              this.tableData.push(currencyRow);
            }
            this.filterData(); // Apply search filter if there's any
          },
          error: (error) => console.error(`Failed to fetch currency translation for ${lang.title}:`, error)
        });
      });
    }
  }
}