import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';


@Component({
  selector: 'cloud',
  templateUrl: './cloud.component.html',
  styleUrls: ['./cloud.component.scss']
})

export class CloudComponent implements OnInit, AfterViewInit {

  
  constructor() { }
  
  ngAfterViewInit() {
  }

  
  ngOnInit(): void {
  }

}

