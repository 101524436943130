<section class="stacksBlockContainer" id="block-products">
  <div id="settingsTabs" *ngIf="checkSettings()">
    <a (click)="showDataTab()" [ngClass]="checkData() ? 'active' : ''">{{'Data' | translate }}</a>
    <a (click)="showStyleTab()" [ngClass]="checkStyle() ? 'active' : ''">{{'Style' | translate }}</a>
  </div>
  <!-- Sidebar -->
  <div *ngIf="checkSidebar()" class="blockSidebar" [ngClass]="blockDataService.woocommerceActive && !builderService.standalone ? '' : 'disabled'" (click)="appendBlock($event)">
    <img src="assets/images/blocks/products.png" alt="" srcset="">
    <p>{{'Woo Products' | translate}}</p>
  </div>
  <!-- Mobile Preview -->
  <div class="previewProducts previewMobile" [ngClass]="[getBlockFields(blockId)?.style?.products_style, getBlockFields(blockId)?.style?.productColumns]" *ngIf="checkPreview() && getBlockFields(blockId)" id="{{'blockid'+blockId}}" cdkDrag [cdkDragData]="blockId">
    <mat-progress-bar mode="indeterminate" *ngIf="builderService.showSettingsProgress"></mat-progress-bar>
    <div class="blockControlHandlers" *ngIf="!builderService.globals">
      <div class="editBlock blockActionBtn" (click)="showData(blockId)">
        <mat-icon>edit</mat-icon>
      </div>
      <div class="blockHandle blockActionBtn" cdkDragHandle>
        <mat-icon>open_with</mat-icon>
      </div>
      <div class="deleteBlock blockActionBtn" (click)="deleteBlock(blockId)">
        <mat-icon>close</mat-icon>
      </div>
    </div>
    <div class="blockPlaceholder" *cdkDragPlaceholder></div>
    <div
    [ngClass]="[getBlockFields(blockId)?.style?.products_style ? getBlockFields(blockId)?.style?.products_style : '']">
    <div class="horizontal-slider-container" class="{{getBlockFields(blockId)?.style?.displayStyle}}">
      <p *ngIf="!getProductsData(blockId)" style="color:red">No Products Available with the selected settings</p>
      <ul class="horizonal-slider" [ngClass]="
        getBlockFields(blockId)?.style?.productColumns ? getBlockFields(blockId)?.style?.productColumns : ''">
        <li  class="item" *ngFor="let product of getProductsData(blockId)">
          <div class="flex-container">
            <div class="picture-container">
              <img *ngIf="product['featured_img']" src="{{product['featured_img']}}">
              <img *ngIf="!product['featured_img']" src="assets/images/empty-image.png">
              <div class="sale" *ngIf="product.sale_price && product.sale_price !== '0.00'">
                SALE!
              </div>
            </div>
            <div class="fade">
              <div class="description">
                <!-- <div class="heading" [ngStyle]="getTitleStyles()"> -->
                <div class="heading">
                  <div class="headingName">
                    {{product["name"]}}
                  </div>
                  <button
                    *ngIf="product['type'] === 'simple' && getBlockFields(blockId)?.style?.products_style == 'roundedStyle' && getBlockFields(blockId)?.style?.productColumns != 'oneColumn'"
                    [ngStyle]="{'color': getBlockFields(blockId)?.style?.color}"
                    >
                    <mat-icon class="material-icons-outlined">shopping_bag</mat-icon>
                  </button>
                  <button
                    *ngIf="product['type'] === 'variable' && getBlockFields(blockId)?.style?.products_style == 'roundedStyle' && getBlockFields(blockId)?.style?.productColumns != 'oneColumn'"
                    [ngStyle]="{'color': getBlockFields(blockId)?.style?.color}">
                    <mat-icon class="material-icons-outlined">tune</mat-icon>
                  </button>
                </div>
                  <div *ngIf="product['type'] === 'variable'" class="pricesWrapper">
                    <div class="pricesMiniWrapper variableProductPrices"
                    *ngIf="product.min_price || product.max_price || product.min_sale_price || product.max_sale_price">
                      <div *ngIf="product.min_price"
                        class="currency-symbol price mainPrice"><span class="fromText">From</span><div class="minPriceAndSale">
                          {{currency}}{{product.min_price}}
                          <div *ngIf="product.min_sale_price" class="currency-symbol strike">{{currency}}{{product.min_sale_price}}</div>
                        </div>
                      </div>
                    </div>    
                    <!-- There are no Sale Prices, so show the normal prices -->
                    <div class="pricesMiniWrapper">
                      <span
                        *ngIf="(!product.min_price || !product.max_price && !product.min_sale_price || !product.max_sale_price) && !product.sale_price"
                        class="currency-symbol mainPrice"
                        [class]="product.sale_price ? '' : 'mainPrice'"><span class="fromText">From</span>  {{currency}}{{product.regular_price}}<br></span>

                      <span
                        *ngIf="(!product.min_price || !product.max_price || !product.min_sale_price || !product.max_sale_price) && product.sale_price"
                        class="currency-symbol mainPrice"><span class="fromText">From</span>  {{currency}}{{product.sale_price}}</span>
                    </div>
                      <button *ngIf=" getBlockFields(blockId)?.style?.products_style == 'glassStyle'"
                      [ngStyle]="{'color': getBlockFields(blockId)?.style?.color}">
                        <mat-icon 
                        [ngStyle]="{'color': getBlockFields(blockId)?.style?.color}"
                        class="material-icons-outlined">tune</mat-icon>
                      </button>
                      <button *ngIf=" getBlockFields(blockId)?.style?.products_style == 'sharpStyle'"
                      [ngStyle]="{'color': getBlockFields(blockId)?.style?.color}">
                        <mat-icon class="material-icons-outlined">tune</mat-icon>
                      </button>
                      <div class="roundedButton" *ngIf=" getBlockFields(blockId)?.style?.products_style == 'roundedStyle' ">
                        <button
                          *ngIf=" getBlockFields(blockId)?.style?.products_style == 'roundedStyle' && getBlockFields(blockId)?.style?.productColumns == 'oneColumn'"
                          [ngStyle]="{'color': getBlockFields(blockId)?.style?.color}">
                          <mat-icon class="material-icons-outlined">tune</mat-icon>
                        </button>
                      </div>
                  </div>
                  <div *ngIf="product['type'] === 'simple'" class="pricesWrapper">
                      <div class="price pricesMiniWrapper" *ngIf="product.sale_price !== '0.00'&& product.sale_price !== ''">
                        <div class="strike">
                          <span class="currency-symbol">{{currency}}</span>{{product.regular_price}}
                        </div>
                        <div class="mainPrice">
                          <span class="currency-symbol">{{currency}}</span>{{product.sale_price}}
                        </div>
                      </div>
                      <div class="price mainPrice" *ngIf="product.sale_price == '0.00' || product.sale_price == ''">
                        <span class="currency-symbol">{{currency}}</span>{{product.regular_price}}
                      </div>
                    <button *ngIf=" getBlockFields(blockId)?.style?.products_style == 'glassStyle'"
                    [ngStyle]="{'color': getBlockFields(blockId)?.style?.color}">
                      <span class="material-icons">add_shopping_cart</span>
                    </button>
                    <button *ngIf=" getBlockFields(blockId)?.style?.products_style == 'sharpStyle'"
                    [ngStyle]="{'color': getBlockFields(blockId)?.style?.color}">
                      <mat-icon class="material-icons-outlined">add</mat-icon>
                    </button>
                    <div class="roundedButton" *ngIf=" getBlockFields(blockId)?.style?.products_style == 'roundedStyle' ">
                      <button
                        *ngIf=" getBlockFields(blockId)?.style?.products_style == 'roundedStyle' && getBlockFields(blockId)?.style?.productColumns == 'oneColumn'"
                        [ngStyle]="{'color': getBlockFields(blockId)?.style?.color}">
                        <mat-icon class="material-icons-outlined">shopping_bag</mat-icon>
                      </button>
                    </div>
                  </div>
                  <!-- and this appear when roundedStyle is not active -->
                  <button *ngIf="product['type'] === 'variable' && getBlockFields(blockId)?.style?.products_style == 'simpleStyle'" 
                  [ngStyle]="{'color': getBlockFields(blockId)?.style?.color}">
                    <mat-icon class="material-icons-outlined">tune</mat-icon> Add to cart
                  </button>
                  
                  <button *ngIf="product['type'] === 'simple' && getBlockFields(blockId)?.style?.products_style == 'simpleStyle'" 
                  [ngStyle]="{'color': getBlockFields(blockId)?.style?.color}">
                    <mat-icon class="material-icons-outlined">shopping_bag</mat-icon> Add to cart
                  </button>

                  <div no-padding *ngIf="product['stock_status'] == 'outofstock'">
                    <span class="outofstock">Out Of Stock</span>
                  </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  </div>
  <!-- Data and Style Tabs -->
  <div class="previewFields" *ngIf="checkData() && getBlockFields(blockId)">
    <mat-progress-bar mode="indeterminate" *ngIf="builderService.showSettingsProgress"></mat-progress-bar>
    <h4 class="listTitle">
      {{ 'Query' | translate }}
    </h4>
    <mat-divider></mat-divider>
    <button class="stacksBtn blue refetchData" (click)="refetchData()" mat-icon-button title="Manual Fetch all Products">
      <mat-icon class="material-icons material-icons-outlined">refresh</mat-icon>
      {{ 'Refetch Products & Categories' | translate }}
    </button>
    <mat-divider></mat-divider>
    <ul>
      <li>
        <mat-label>{{ 'Source' | translate }}</mat-label>
        <mat-select (selectionChange)="queryChange('query_post_type', blockId, $event, 'select')"
          value="{{getBlockFields(blockId).data?.query_post_type}}">
          <mat-option value="product">
            {{ 'Latest Products' | translate }}
          </mat-option>
          <mat-option value="featured">
            {{ 'Featured' | translate }}
          </mat-option>
          <mat-option value="by_category">
             {{ 'Products Categories' | translate }}
          </mat-option>
        </mat-select>
      </li>
    </ul>
    <ul>
      <li *ngIf="getBlockFields(blockId).data.query_post_type == 'by_category'">
        <mat-label>{{ 'Categories' | translate }}</mat-label>
        <mat-select (selectionChange)="queryChange('products_categories', blockId, $event, 'select')"
          value="{{getBlockFields(blockId).data?.products_categories}}">
          <mat-option *ngFor="let productCategory of getCategoriesData(blockId)" value="{{productCategory.id}}">
            {{productCategory.name}}
          </mat-option>
        </mat-select>
      </li>
    </ul>
    <ul>
      <li>
        <mat-label>{{ 'Limit' | translate }}</mat-label>
        <mat-slider showTickMarks discrete max="10" min="1" step="1" thumbLabel="true">
          <input matSliderThumb 
          ngModel="{{getBlockFields(blockId).data?.limit}}"
          (change)="queryChange('limit',
          blockId,
          $event, 'slider')" />
        </mat-slider>
      </li>
    </ul>
    <ul>
      <li>
        <mat-label>{{ 'Order By' | translate }}</mat-label>
        <mat-select (selectionChange)="queryChange('query_orderby', blockId, $event, 'select')"
          value="{{getBlockFields(blockId).data?.query_orderby}}">
          <mat-option value="date">
            {{ 'Date' | translate }}
          </mat-option>
          <mat-option value="title">
            {{ 'Title' | translate }}
          </mat-option>
          <mat-option value="price">
            {{ 'Price' | translate }}
          </mat-option>
          <mat-option value="rand">
            {{ 'Random' | translate }}
          </mat-option>
        </mat-select>
      </li>
    </ul>
    <ul>
      <li>
        <mat-label>{{ 'Order' | translate }}</mat-label>
        <mat-select (selectionChange)="queryChange('query_order', blockId, $event, 'select')"
          value="{{getBlockFields(blockId).data?.query_order}}">
          <mat-option value="ASC">
            {{ 'Ascendingly' | translate }} 
          </mat-option>
          <mat-option value="DESC">
            {{ 'Descendingly' | translate }}
          </mat-option>
        </mat-select>
      </li>
    </ul>
  </div>
  <div class="styleFields" *ngIf="checkStyle()">
   <ul>
      <li>
        <mat-label>{{ 'List Style' | translate }}</mat-label>
        <mat-select (selectionChange)="styleChange('displayStyle', blockId, $event, 'select')"
          value="{{getBlockFields(blockId).style?.displayStyle}}">
          <mat-option value="gridStyle">
            {{ 'Grid Style' | translate }}
          </mat-option>
          <mat-option value="listStyle">
            {{ 'Columns Style' | translate }}
          </mat-option>
        </mat-select>
      </li>
      <li>
        <mat-label>{{ 'Columns Style' | translate }}</mat-label>
        <mat-select (selectionChange)="styleChange('productColumns', blockId, $event, 'select')"
          value="{{getBlockFields(blockId).style?.productColumns}}">
          <mat-option value="oneColumn">
            {{ 'One Column' | translate }}
          </mat-option>
          <mat-option value="twoColumns">
            {{ 'Two Columns' | translate }}
          </mat-option>
          <mat-option value="threeColumns">
            {{ 'Three Columns' | translate }}
          </mat-option>
        </mat-select>
      </li>
      <li>
        <mat-label>{{ 'Product Style' | translate }}</mat-label>
        <mat-select (selectionChange)="styleChange('products_style', blockId, $event, 'select')"
          value="{{getBlockFields(blockId).style?.products_style}}">
          <mat-option value="simpleStyle">
            {{ 'Simple Style' | translate }}
          </mat-option>
          <mat-option value="glassStyle">
            {{ 'Glass Style' | translate }}
          </mat-option>
          <mat-option value="sharpStyle">
            {{ 'Sharp Style' | translate }}
          </mat-option>
          <mat-option value="roundedStyle">
            {{ 'Rounded Style' | translate }}
          </mat-option>
        </mat-select>
      </li>
    </ul>
      <h4 class="listTitle">{{ 'Colors' | translate }}</h4>
      <mat-divider></mat-divider>
      <ul>
        <li class="contentCenter contentContainerColor">
          <mat-label>{{ 'Button Text Color' | translate }}</mat-label>
          <mat-form-field>
            <p class="colorPickerValue">{{getBlockFields(blockId).style?.color}}</p>
            <label for="color" class="colorLabel">
              <input type="color" id="color" matInput class="colorPicker" name="color"
                (change)="styleChange('color', blockId, $event, 'text')" value="{{getBlockFields(blockId).style?.color}}">
            </label>
          </mat-form-field>
        </li>
      </ul>
    <h4 class="listTitle">{{'Margins' | translate }}</h4>
    <mat-divider></mat-divider>
    <ul>
      <li>
        <mat-label>{{'Margin Top' | translate }}</mat-label>
        <mat-slider showTickMarks discrete  max="100" min="-100" step="1" thumbLabel="true">
          <input matSliderThumb ngModel="{{getBlockFields(blockId).style['margin-top']?.slice(0, -2)}}"
          (change)="styleChange('margin-top',
          blockId,
          $event, 'slider')">
        </mat-slider>
      </li>
      <li>
        <mat-label>{{'Margin Right' | translate }}</mat-label>
        <mat-slider showTickMarks discrete max="100" min="-100" step="1" thumbLabel="true">
          <input matSliderThumb ngModel="{{getBlockFields(blockId).style['margin-right']?.slice(0, -2)}}" (change)="styleChange('margin-right',
          blockId,
          $event, 'slider')">
        </mat-slider>
      </li>
      <li>
        <mat-label>{{'Margin Bottom' | translate }}</mat-label>
        <mat-slider showTickMarks discrete  max="100" min="-100" step="1" thumbLabel="true"
          >
          <input matSliderThumb ngModel="{{getBlockFields(blockId).style['margin-bottom']?.slice(0, -2)}}" (change)="styleChange('margin-bottom',
          blockId,
          $event, 'slider')">
        </mat-slider>
      </li>
      <li>
        <mat-label>{{'Margin Left' | translate }}</mat-label>
        <mat-slider showTickMarks discrete max="100" min="-100" step="1" thumbLabel="true"
          >
          <input matSliderThumb ngModel="{{getBlockFields(blockId).style['margin-left']?.slice(0, -2)}}" (change)="styleChange('margin-left',
          blockId,
          $event, 'slider')">
        </mat-slider>
      </li>
    </ul>
    <mat-label>{{'Paddings' | translate }}</mat-label>

    <mat-divider></mat-divider>
    <ul>
      <li>
        <mat-label>{{'Padding Top' | translate }}</mat-label>
        <mat-slider showTickMarks discrete max="100" min="1" step="1" thumbLabel="true"
          >
          <input matSliderThumb ngModel="{{getBlockFields(blockId).style['padding-top']?.slice(0, -2)}}" (change)="styleChange('padding-top',
          blockId,
          $event, 'slider')">
        </mat-slider>
      </li>
      <li>
        <mat-label>{{'Padding Right' | translate }}</mat-label>
        <mat-slider showTickMarks discrete max="100" min="1" step="1" thumbLabel="true"
          >
          <input matSliderThumb ngModel="{{getBlockFields(blockId).style['padding-right']?.slice(0, -2)}}"
          (change)="styleChange('padding-right',
          blockId,
          $event, 'slider')">
        </mat-slider>
      </li>
      <li>
        <mat-label>{{'Padding Bottom' | translate }}</mat-label>
        <mat-slider showTickMarks discrete max="100" min="1" step="1" thumbLabel="true"
          >
          <input matSliderThumb ngModel="{{getBlockFields(blockId).style['padding-bottom']?.slice(0, -2)}}"
          (change)="styleChange('padding-bottom',
          blockId,
          $event, 'slider')">
        </mat-slider>
      </li>
      <li>
        <mat-label>{{'Padding Left' | translate }}</mat-label>
        <mat-slider showTickMarks discrete max="100" min="1" step="1" thumbLabel="true"
          >
          <input matSliderThumb ngModel="{{getBlockFields(blockId).style['padding-left']?.slice(0, -2)}}" (change)="styleChange('padding-left',
          blockId,
          $event, 'slider')">
        </mat-slider>
      </li>
    </ul>
  </div>
</section>
