import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { BuilderService } from '../builder-services/builder.service';

@Injectable({
  providedIn: 'root'
})
export class WebviewGuard implements CanActivate {

  constructor(private builderService: BuilderService,
    private router: Router,
    private route: ActivatedRoute) { }

  canActivate = (route: any, state: RouterStateSnapshot) => {
    if ( this.builderService.projectAccess == 'webview' ) {
      this.router.navigate(['/contentsettings'], {queryParams: {returnUrl: state.url}});
      return false;
    } else {
      return true;
    }
  }

}
