<div class="containerWrapper">
  <div class="currencyWrapper">
    <form [formGroup]="settingsForm" id="settingsForm" (ngSubmit)="saveSettings()">
      <mat-form-field appearance="outline">
        <mat-label>{{ "Select-currency" | translate }}</mat-label>
        <mat-select
          [(ngModel)]="currency"
          (ngModelChange)="activateSave()"
          formControlName="currency"
        >
          <mat-option
            *ngFor="let currency of CurrencyList"
            value="{{ currency.code }}"
          >
            {{ currency.symbol }} :{{ currency.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ 'Select Country Option' | translate }}</mat-label>
        <mat-select formControlName="countryOptions">
          <mat-option [value]="'all'">{{ 'All Countries' | translate }}</mat-option>
          <mat-option [value]="'multi'">{{ 'Multi Countries' | translate }}</mat-option>
          <mat-option [value]="'single'">{{ 'Single Country' | translate }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" *ngIf="settingsForm.get('countryOptions')?.value === 'multi'">
        <mat-label>{{'Select Countries' | translate}}</mat-label>
        <mat-select formControlName="selectedCountries" multiple>
          <mat-option *ngFor="let country of countries" [value]="country.dial_code">
            {{country.emoji}} {{country.name}} ({{country.dial_code}})
          </mat-option>
        </mat-select>
      </mat-form-field>
      
      <div class="countryNumber" *ngIf="settingsForm.get('countryOptions')?.value === 'single'">
        <mat-form-field appearance="outline">
          <mat-label>{{'Enter Country Code' | translate}}</mat-label>
          <input matInput formControlName="countryNumber" placeholder="20" (input)="startWithPlus($event)">
        </mat-form-field>
      </div>
      <!-- <div class="paymentsWrapper"> -->
      <!-- <mat-form-field appearance="outline">
                    <mat-label>{{'Select-Payment-Method' | translate }}</mat-label>
                    <mat-select style="width: auto;" multiple [(ngModel)]="paymentMethod"
                        (selectionChange)="updatePaymentCheckedState($event.value)" (ngModelChange)="activateSave()"
                         formControlName="paymentMethod">
                        <mat-option *ngFor="let payment of paymentMethods" value="{{payment.title}}">
                            {{payment.title}}
                        </mat-option>
                    </mat-select>
                </mat-form-field> -->
      <!-- </div> -->
        <div>
        <div class="payment-methods">
          <mat-label class="payment-head">{{
            "Select-Payment-Method" | translate
          }}</mat-label>
          <mat-hint>{{ "payment method description" | translate }}</mat-hint>
          <div class="checkbox-opt">
            <div *ngFor="let payment of paymentMethods" class="option">
              <input
                type="checkbox"
                name="paymentOptions"
                [checked]="payment.checked"
                #payment
                (change)="onCheckboxChange($event, payment)"
                (ngModelChange)="activateSave()"
              />
              <label>{{ payment.title }}</label>
            </div>
          </div>
          <div *ngIf="isStripChecked">
            <div class="stripe-data">
              <mat-hint
                >{{ "Stripe API Key generated" | translate }}:
                <a
                  href="https://dashboard.stripe.com/login?redirect=%2Fapikeys"
                  target="_blank"
                  >stripe</a
                ></mat-hint
              >
              <mat-form-field
                appearance="outline"
                *ngIf="isStripChecked"
                class="strip-api"
              >
                <mat-label>{{ "Secret key" | translate }} </mat-label>
                <input
                  matInput
                  formControlName="stripApiKey"
                  [required]="isStripChecked"
                  (ngModelChange)="activateSave()"
                  (change)="validateStripeKey($event)"
                />
                <mat-error
                  *ngIf="settingsForm.get('stripApiKey')?.hasError('required')"
                >
                  {{ "Secret key required" | translate }}
                </mat-error>
                <mat-error
                  *ngIf="
                    settingsForm
                      .get('stripApiKey')
                      ?.hasError('invalidStripeApiKey')"
                >
                  {{ invalidStripeApiKey }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div *ngIf="isGeideaChecked">
            <mat-hint
              >{{ "geidea API Key generated" | translate }}:
              <a
                href="https://www.merchant.geidea.net/eg/merchant-portal"
                target="_blank"
                >geidea</a
              ></mat-hint
            >
            <div class="geidea-inputs">
              <mat-form-field appearance="outline" *ngIf="isGeideaChecked">
                <mat-label>Merchant public key</mat-label>
                <input
                  matInput
                  formControlName="geideaPublicKey"
                  [required]="isGeideaChecked"
                  (ngModelChange)="activateSave()"
                />
                <mat-error
                  *ngIf="
                    settingsForm.get('geideaPublicKey')?.hasError('required')
                  ">
                  {{ "Secret key required" | translate }}
                </mat-error>
                <mat-error
                *ngIf="
                  settingsForm.get('geideaPublicKey')?.hasError('invalidPublicKey') &&
                  settingsForm.get('geideaApiPassword')?.hasError('invalidPassword')" >
                {{ invalidGeideaKey }}
              </mat-error>
              </mat-form-field>

              <mat-form-field appearance="outline" *ngIf="isGeideaChecked">
                <mat-label>API password</mat-label>
                <input
                  matInput
                  formControlName="geideaApiPassword"
                  [required]="isGeideaChecked"
                  (ngModelChange)="activateSave()"
                  (change)="validateGeidea($event)"
                />
                <mat-error
                  *ngIf="
                    settingsForm.get('geideaApiPassword')?.hasError('required')">
                  {{ "Secret key required" | translate }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
