import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HelpersService } from 'src/app/helpers.service';
import { LoginService } from 'src/app/login/login.service';

@Injectable({
  providedIn: 'root'
})
export class AppPreviewService {

  constructor(private http: HttpClient, private loginService: LoginService, private helper: HelpersService) { }

  // public trials_token = 'tok_ge3rqdph5auqcgy83g0gt79ab4'; // Stacks Token marketing@stacksmarket.co 100
  // public trials_token = 'tok_zd8b64pjngre8r5ww79q3m8ac0'; // Customers Token info@stacksmarket.co 0
  // public customers_token = 'tok_tyaba1zrwfgncf28yrtg64ex0c'; // Customers Token support@stacksmarket.co 26
  // // public trials_token = 'tok_rnakxfuhjcrykn4ty209g97pfg'; // Testing Token bassam.tarek@creiden.com  0
  // public admins_token = 'tok_fr9vfmdmw5500zec63q41yu9fc'; // Testing Token eng.bassamtarek@gmail.com 44

  createApp = (appUrl: string, projectDetails: any, token: any) => {
    let url = '';
    let auth = '';
    url = 'https://'+token+'@api.appetize.io/v1/apps';
    auth = 'Basic '+btoa(token+':');
    let data = {
      'url' : appUrl,
      'platform': 'android',
      'fileType': 'apk',
      'timeout': '60',
      'buttonText': 'Start App',
      // 'disableHome': true,
    }
    let optionsTemplate = {
      headers: new HttpHeaders().append('Content-Type', 'application/json').append('Authorization', auth )
    };
    return this.http.post(url, data, optionsTemplate);
  }

  

  get_appetize_token = () => {
    const formData = new FormData();
    let token: any = localStorage.getItem('token');
    let builderUrl: any = this.helper.getBuilderUrl();
    formData.append('token', token);
    return this.http.post(builderUrl + 'get-appetize-token', formData);
  }
}
