import { Injectable } from '@angular/core';
import { BlockDataService } from '../block-services/block-data.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { JwtHelperService } from "@auth0/angular-jwt";
import { HelpersService } from '../helpers.service';
import { BuilderService } from '../builder-services/builder.service';
import { SocialAuthService } from '@abacritt/angularx-social-login';


@Injectable({
  providedIn: 'root'
})
export class LoginService extends BlockDataService {

  private builderUrl;

  projects = {};
  selectedProject = 0;
  headerBtn = '';
  options = {
    headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
  };
  constructor(
    private http: HttpClient, 
    public helpers: HelpersService, 
    private builderService: BuilderService,
    private authService: SocialAuthService) {
    super();
    this.builderUrl = this.helpers.getBuilderUrl();
  }


  /**
   * Get all projects from Backend
   */
  login = (email: string, password: string): Observable<any> => {

    let postData = new URLSearchParams();
    postData.set('process', "sync_ui");
    postData.set('email', email);
    postData.set('password', password);

    return this.http.post(this.builderUrl + 'loginBuilder', postData.toString(), this.options);
  }

  loginSocial = (email: string): Observable<any> => {

    let postData = new URLSearchParams();
    postData.set('process', "sync_ui");
    postData.set('email', email);

    return this.http.post(this.builderUrl + 'loginSocialBuilder', postData.toString(), this.options);
  }

  logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('selectedProject');
    localStorage.removeItem('categories');
    localStorage.removeItem('tourStart');
  }

  isLoggedIn = () => {
    let jwtHelper = new JwtHelperService();
    let token = this.builderService.getToken();
    if( token && token !== "undefined" && token !== "null" ) {
      const decodedToken = jwtHelper.decodeToken(token);
      const expirationDate = jwtHelper.getTokenExpirationDate(token);
      const isExpired = jwtHelper.isTokenExpired(token);

      return !isExpired;
    }
    return false;
  }
  
  getCurrentUser = () => {
    if (!this.isLoggedIn())
      return false;
    let jwtHelper = new JwtHelperService();
    let token = this.builderService.getToken();
    if (token) {
      return jwtHelper.decodeToken(token).data.user;
    }
  }
}
