<div class="containerWrapper">
    <div id="subscriptionDetails">
        <h1>{{'Billing' | translate }}</h1>
        <!-- Current Susbcription Plan -->
        <p *ngIf="builderService.is_premium == true && builderService.premium_status == 'active' "><mat-icon class="material-icons-outlined greenIcon">check_circle</mat-icon>{{builderService.premium_status}} Subscription</p>
        <p *ngIf="builderService.is_premium == true && builderService.premium_status == 'cancelled' "><mat-icon class="material-icons-outlined redIcon">cancel</mat-icon>{{builderService.premium_status}} Subscription</p>
        <p *ngIf="builderService.is_premium !== true">No Active Subscriptions</p>
        <p *ngIf="builderService.is_premium == true" class="pricingType"><span>Subscription Package : </span> {{builderService.subscription_package?.name}} - {{builderService.subscription_package?.type}}</p>
        <p *ngIf="builderService.is_premium == true"><span>Subscription Start Date: </span> {{builderService.premium_start_date}}</p>
        <!-- Manage Subscription -->
        <!-- <a *ngIf="builderService.is_premium == true" class="stacksBtn green manageSubscriptions" href="{{environment.manageSubscriptions}}">Manage Subscriptions</a> -->
        <a *ngIf="builderService.is_premium == true" class="stacksBtn green manageSubscriptions" (click)="billingRedirect()">Manage Subscriptions</a>
    </div>
    <!-- Invoices (List of paid and unpaid) -->
    <h1 *ngIf="builderService.is_premium == true">Invoices</h1>
    <div *ngIf="builderService.is_premium == true">
        <ul class="invoicesList">
            <li>
                <div class="invoicesListHeader">
                    <p>{{'Date' | translate }}</p>
                    <p>{{'Status' | translate }}</p>
                    <p>{{'Amount Paid' | translate }}</p>
                </div>
                <mat-divider></mat-divider>
            </li>
            <li *ngFor="let invoice of builderService.invoices">
                <div *ngIf="invoice.status !== 'void'">
                    <p>{{invoice.invoiceDate}}</p>
                    <p *ngIf="invoice.status !== 'open'"><mat-icon class="material-icons-outlined greenIcon" *ngIf="invoice.status == 'paid'">check_circle</mat-icon>{{invoice.status}}<a href="{{invoice.paymentLink}}" class="unpaidInvoice">{{'View Invoice' | translate }}</a> </p>
                    <a href="{{invoice.paymentLink}}" class="unpaidInvoice" *ngIf="invoice.status == 'open'">{{'Pay Invoice' | translate }}</a>
                    <p class="invoiceAmount">{{invoice.amount_paid / 100}} {{invoice.currency}}</p>
                </div>
                <mat-divider *ngIf="invoice.status !== 'void'"></mat-divider>
            </li>
        </ul>
    </div>
</div>