<div #ProjectsSearchWrapper *ngIf="!mobile">
    <div id="projectsSearchHeader">Projects Search</div>
    <div id="projectsSearchBody">
        <mat-progress-bar mode="indeterminate" *ngIf="showProjectsSearchProgress"></mat-progress-bar>
        <ul>
            <li>
            <mat-form-field appearance="outline">
                <mat-label>Search for Project ID</mat-label>
                <input matInput placeholder="https://demo.stacksclients.com" id="searchProjectDomain" (change)="updateSearchValue($event)" value="{{this.searchProjectDomain}}">
            </mat-form-field>
            <a href="#" class="stacksBtn blue" (click)="searchProjects()">Search</a> 
            </li>
            <li *ngFor="let result of response ">
                <br />
                Project ID : {{result.project_id}}
                <br />
                Order ID : {{result?.order_id}}
                <br />
                Status : {{result?.status}}
                <br />
                Type : {{result?.type}}
            </li>
        </ul>
    </div>
</div>