import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HelpersService } from 'src/app/helpers.service';
import { BuilderService } from '../../builder-services/builder.service';

@Injectable({
  providedIn: 'root'
})
export class IosbuildService {
  
  buildingIos = false;

  private builderUrl: string;

  options = {
    headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
  };
  token: any;

  constructor(private http: HttpClient, private helper: HelpersService, private builderService: BuilderService) {
    this.builderUrl = this.helper.getBuilderUrl();
    this.token = this.builderService.getToken();
    this.buildingIos = true;
  }

  startBuild = (id: any, version: string, type: string = '') => {
    this.token = this.builderService.getToken();
    let postData = new URLSearchParams();
    postData.set('process', "sync_ui");
    postData.set('projectId', id);
    postData.set('standalone', this.builderService.standalone ? 'true' : 'false');
    postData.set('version', JSON.stringify(version));
    postData.set('token', this.token);

    return this.http.post(this.builderUrl + 'build-ios', postData.toString(), this.options);
  }

  getProgress = (id: any, version: any) => {
    this.token = this.builderService.getToken();
    let postData = new URLSearchParams();
    postData.set('process', "sync_ui");
    postData.set('projectId', id);
    postData.set('version', JSON.stringify(version));
    postData.set('type', 'ios_status');
    postData.set('token', this.token);

    return this.http.post(this.builderUrl + 'get-build-progress', postData.toString(), this.options);
    
  }

  getBuildError = (id: any) => {
    this.token = this.builderService.getToken();
    let postData = new URLSearchParams();
    postData.set('projectId', id);
    postData.set('token', this.token);
    postData.set('platform', 'ios');

    return this.http.post(this.builderUrl + 'get-build-error', postData.toString(), this.options);
  }
}
