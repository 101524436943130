<div id="middleContainerWrapper">
    <div *ngIf="builderService.getTour()" id="chooseType">
        <div class="text">
            <h1 class="welcomeUser">{{'Welcome to your App' | translate}}, <span>{{ this.builderService.projects[0]?.first_name }}</span></h1>
            <!-- <p class="userSteps">you are 7 steps away from creating your app</p> -->
            <app-stepper step="1"></app-stepper>
            <p class="userBusiness">{{'Tell us about your business' | translate}}</p>
            <p>{{'info will help in creating' | translate}}</p>
        </div>
        <div class="inputContainer">
            <input type="text" class="userInput" [(ngModel)]="businessName" placeholder="{{ 'Business Name' | translate }}">
        </div>
        <div class="text">
            <p class="userBusiness">{{'What is the main' | translate }}</p>
            <p>{{'Example Selling Shoes' | translate }}</p>
        </div>
        <div class="inputContainer">
            <input type="text" class="userInput" [ngClass]="missingRequiredField ? 'requiredField' : ''" [(ngModel)]="businessCategory" placeholder="{{ 'Selling Clothes' | translate }}">
            <div class="radio-container">
                <mat-radio-group [(ngModel)]="businessStatus" aria-label="Select an option">
                    <mat-radio-button value="haveBusiness">{{'I have an online business' | translate}}</mat-radio-button>
                    <mat-radio-button value="noBusiness">{{'Im just starting' | translate}}</mat-radio-button>
                  </mat-radio-group>
            </div>
        </div>
        <button class="stacksBtn blue LargeButton" (click)="siteTypeSubmit()">
            {{'Next' | translate}}
        </button>
    </div>

</div>