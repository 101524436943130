import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import {MatSort, Sort} from '@angular/material/sort';
import { ChartConfiguration, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { SelfService } from '../self.service';
import { BuilderService } from 'src/app/builder-services/builder.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';


export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};

export interface salesTableColumns {
  order_id: number;
  date: string;
  date_formatted: any;
  email: string;
  apps_number: string;
  sale_attribution: string;
  coupon_description: string;
  coupon_name: string;
  product_name: string;
  value: string;
  status: string;
}


let ELEMENT_DATA: salesTableColumns[] = [];

@Component({
  selector: 'self-sales',
  templateUrl: './self-sales.component.html',
  styleUrls: ['./self-sales.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class SelfSalesComponent implements OnInit {

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatTable)
  table!: MatTable<salesTableColumns>;

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;
  
  constructor(private selfService: SelfService, public builderService: BuilderService ) { }
  public salesColumns: string[] = [];
  public dataSource: any = [];
  public selfSales : any;
  sales_dates: any = [];
  sales_data_counts: any = [];

  initial_datasource: any;
  form:any = new FormGroup({
    fromDate: new FormControl(null, { validators: [Validators.required]}),
    toDate: new FormControl(null, { validators: [Validators.required]})
  });

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  
  ngOnInit(): void {
    this.getSales();
  }

  /**
   * Fill Sales Table
   */
   getSales = () => {
    this.builderService.showUpdateProgress = true;
    this.salesColumns = ['date', 'email', 'order_id', 'apps_number', 'sale_attribution', 'coupon_description', 'coupon_name', 'product_name', 'value', 'status'];
    ELEMENT_DATA = [];
    let selfSales = localStorage.getItem('selfSales');
    if(selfSales) {
      this.renderSales(JSON.parse(selfSales));
    } else {
      this.fetchNewSales();
    }
  }
  fetchSales = () => {
    localStorage.removeItem('selfSales');
    this.getSales();
  }
  /**
   * Retrieves the new Sales from the server
   */
   fetchNewSales = () => {
    this.selfService.getSelfSales().subscribe((result: any) => {
      localStorage.setItem('selfSales',JSON.stringify(result));
      this.renderSales(result);
    });
  }
  /**
   * Renders the Sales data
   */
   renderSales = (result: any) => {
    this.builderService.showUpdateProgress = false;
    let all_sales = result;
    all_sales = Object.entries(all_sales).sort().reduce( (o:any,[k,v]) => (o[k]=v,o), {} );
    this.selfSales = all_sales;
    this.renderChartData(result);
    for (const key in all_sales) {
      if (Object.prototype.hasOwnProperty.call(all_sales, key)) {
        const element = all_sales[key];

        for (const key_trial in element) {
          if (Object.prototype.hasOwnProperty.call(element, key_trial)) {
            const element_trial = element[key_trial];
            ELEMENT_DATA.push(
              { date: element_trial.date, 
                date_formatted: new Date( element_trial.date ), 
                email: element_trial.email,
                order_id: element_trial.id, 
                apps_number: element_trial.apps_number, 
                sale_attribution: element_trial.sale_attribution, 
                coupon_description: element_trial.coupon_description, 
                coupon_name: element_trial.coupon_name, 
                product_name: element_trial.product_name, 
                value: element_trial.value, 
                status: element_trial.status, 
              },
            );
          }
        }
      }
    }
    this.dataSource = new MatTableDataSource<salesTableColumns>(ELEMENT_DATA);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.initial_datasource = [...this.dataSource.data];
    setTimeout(() => {
      this.table.renderRows();
    }, 1000);
      
  }

  renderChartData = (all_sales : any) => {
    all_sales = Object.entries(all_sales).sort().reduce( (o:any,[k,v]) => (o[k]=v,o), {} );
    this.sales_data_counts = [];
    this.sales_dates = [];
    for (const key in all_sales) {
      if (Object.prototype.hasOwnProperty.call(all_sales, key)) {
        const element = all_sales[key];
        this.sales_dates.push(key);
        this.sales_data_counts.push(element.length);
      }
    }
    this.subChartData.labels = this.sales_dates;
    this.subChartData.datasets[0].data = this.sales_data_counts;
    this.chart?.update();
  }

  applySubFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  applyDateFilter() {
    this.dataSource.data = this.dataSource.data.filter((e: { date_formatted: any; })=> e.date_formatted >= this.form.value.fromDate && e.date_formatted <= this.form.value.toDate);

    let selfSales = this.selfSales;
    let filteredChartSales: any = [];
    let fromDate:any = this.form.value.fromDate;
    let toDate:any = this.form.value.toDate;
    Object.keys(this.selfSales).map(function(key, index) {
      if(new Date(key) >= fromDate && new Date(key) <= toDate) {
        filteredChartSales[key] = selfSales[key];
      }
    });
    this.renderChartData(filteredChartSales);
    if (this.form.invalid) {
      return
    }
    //this.dataSource.data = this.dataSource.data.filter(e=>e.date >= this.fromDate && e.date <= this.toDate);
  }

  clearDateFilter = () => {
    this.dataSource.data = this.initial_datasource;
    this.renderChartData(this.selfSales);
  }


  public subChartData: ChartConfiguration['data'] = {
    datasets: [
      {
        data: [ ],
        label: 'Sales',
        yAxisID: 'y-axis-1',
        backgroundColor: 'rgba(255,0,0,0.3)',
        borderColor: 'red',
        pointBackgroundColor: 'rgba(148,159,177,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(148,159,177,0.8)',
        fill: 'origin',
      }
    ],
    // labels: [ 'January', 'February', 'March', 'April', 'May', 'June', 'July' ]
  };

  public subChartOptions: ChartConfiguration['options'] = {
    elements: {
      line: {
        tension: 0.5
      }
    },
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      x: {},
      'y-axis-1': {
        position: 'right',
        grid: {
          color: 'rgba(255,0,0,0.3)',
        },
        ticks: {
          color: 'red'
        }
      }
    },
  };
}
