import { Injectable } from '@angular/core';
import { SidebarService } from 'src/app/sidebar/sidebar.service';

@Injectable({
  providedIn: 'root'
})
export class PointsService {

  constructor(private sidebarService: SidebarService) { }


  init_plugin() {
    this.sidebarService.setMenuItem([
      {
        name: 'points',
        icon: 'points',
        route: '/points'
      },
    ]);
  }
}
