import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { WorksDialogComponent } from 'src/app/pages/works-dialog/works-dialog.component';
import { AppSettingsService } from 'src/app/settings/services/app-settings.service';
import { BuilderService } from '../../builder-services/builder.service';
import { IosbuildService } from './iosbuild.service';

@Component({
  selector: 'ios-build',
  templateUrl: './ios-build.component.html',
  styleUrls: ['./ios-build.component.scss'],
})
export class IosBuildComponent {

  buildStart = false;
  value = 0;
  qrLink = "";
  downloadBtn = '';
  downloadAABBtn = '';
  checkingStatus = true;
  error = false;
  buildErrorMsg = '';
  appleSettingsData: any = {};
  appleSettingsForm = [
    'key_id',
    'issuer_id',
    'key_filepath',
    'email',
    'app_specific_pass',
    'team_name',
    'team_id'
  ];
  settingsError: any = [];
  constructor(private formBuilder: FormBuilder,
    public builderService: BuilderService,
    private appSettingsService: AppSettingsService,
    private iosService: IosbuildService, 
    public dialog: MatDialog) { 
      let errorCount = 0;
      this.appleSettingsData = this.appSettingsService.getAppleSettings(this.builderService.getSelectedProject()).subscribe((settings: any) => {
        this.appleSettingsData = settings !== null ? settings : {};
        for (let i = 0; i < this.appleSettingsForm.length; i++) {
          for (let index = 0; index < Object.keys(this.appleSettingsData).length; index++) {
            let settingsFields = this.appleSettingsForm[i];
            if(!settings[settingsFields]) {
              this.settingsError[errorCount] = settingsFields;
              errorCount++;
            }
          }
        }
        if( settings == null ) {
          this.settingsError = this.appleSettingsForm.join(', ');
        } else {
          this.settingsError = [...new Set(this.settingsError)].join(', ');
        }
      }, (err) => {
        console.log(err);
      });
    this.getBuildStatus();
    }

  iosBuildForm = this.formBuilder.group({
    iosVersion: '',
  });

  /**
   * Submits a build request to the server
   */
  onSubmit(): void {
    this.value=0;
    this.buildStart = true;
    this.builderService.headerBtn = '';
    this.error = false;
    if (!this.iosBuildForm.value.iosVersion) {
      this.iosBuildForm.value.iosVersion = '0.1';
    }
    this.iosService.startBuild(this.builderService.getSelectedProject(), this.iosBuildForm.value.iosVersion).subscribe( (response: any) => {
      this.getBuildStatus();
    });
  }

  /**
   * Gets the build status from the builder and brain, incase the app is still progressing then we will rerun the same function checker again after 5 seconds
   */
  getBuildStatus = () => {
    this.iosService.getProgress(this.builderService.getSelectedProject(), this.iosBuildForm.value.iosVersion).subscribe((progressRes: any) => {
      this.value = progressRes.data;
      this.checkingStatus = false;
      if(this.value === 0 && progressRes.status !== "error") {
        this.buildStart = false;
      } else {
        if (this.value <= 100) {
          if (progressRes.status !== "error" && this.value == 100) {
            this.downloadBtn = progressRes.ios_url;
            this.downloadAABBtn = progressRes.aab_url;
            this.qrLink = "https://qrcode.tec-it.com/API/QRCode?data=" + progressRes.ios_url;
            this.error = false;
          } else if (progressRes.status == "error") {
            this.error = true;
            this.builderService.headerBtn == 'ios';
            this.getBuildError();
          } else {
            setTimeout(() => {
              this.getBuildStatus();
            }, 5000);
            this.error = false;
          }
          this.buildStart = true;
        }
      }
    })
  }

  getBuildError = () => {
    this.iosService.getBuildError(this.builderService.getSelectedProject()).subscribe((response: any) => {
      this.buildErrorMsg = decodeURIComponent(response.message.replace(/\+/g, ' '));
    })
  }

  rebuildApp = () => {
    this.buildStart = false;
    this.checkingStatus = false;
    this.error = false;
  }

  howItWorksDialog = () => {
    const dialogRef = this.dialog.open(WorksDialogComponent, {
      width: '700px',
      maxWidth: '80%',
      data: {name: "ios"},
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
}
